import { Injectable } from '@angular/core';
import { App } from '@capacitor/app';
import { Platform } from '@ionic/angular';
import { Store } from '@ngxs/store';
import { ToggleAppBackground } from '../state/app.actions';

@Injectable({
  providedIn: 'root',
})
export class BackgroundService {
  constructor(private platform: Platform, private store: Store) {}

  init() {
    App.addListener('appStateChange', ({ isActive }) => {
      // console.log('[App] state changed. Is active?', isActive);
      this.store.dispatch(new ToggleAppBackground({background: !isActive}))
    });
  }
}
