import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConflictResolution } from 'src/app/models/conflict-resolution.model';
import { GenericPost } from 'src/app/models/post-core.model';
import { SetSelectedPost } from 'src/app/state/app.actions';
import { FeedState } from 'src/app/state/feed.state';

@Component({
  selector: 'app-conflict-card-body',
  templateUrl: './conflict-card-body.component.html',
  styleUrls: ['./conflict-card-body.component.scss'],
})
export class ConflictCardBodyComponent implements OnInit {
  conflict: ConflictResolution;
  @Select(FeedState.allPosts) allPosts$: Observable<GenericPost[]>;
  @Input() showFullBody = true;
  @Input() post: GenericPost;

  constructor(
    private store: Store,
    private router: Router,
    private modalCtrl: ModalController
  ) {}

  ngOnInit() {
    this.conflict = this.post as ConflictResolution;
    this.store
      .select(FeedState.getPostById)
      .pipe(map((filterFn) => filterFn(this.post._UID)))
      .subscribe((post) => {
        // console.log('[Conflict Card Body] updated conflict: ', post);
        this.conflict = post as ConflictResolution;
      });
  }

  goToDetails() {
    this.store.dispatch(
      new SetSelectedPost({ post: this.conflict, location: 'DETAILS' })
    );
    this.router.navigateByUrl(`pages/post-details`);
  }

  async clickBody(ev: Event) {
    const target = ev.target as HTMLElement;
    if (target?.className === 'mentionified') {
      const uid = target.getAttribute('data-mention-uid');
      if (uid) {
        const existing = await this.modalCtrl.getTop();
        if (existing) await this.modalCtrl.dismiss();
        this.router.navigateByUrl(`member/${uid}`);
      }
    }
  }
}
