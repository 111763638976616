import { Injectable } from '@angular/core';
import { NgxsFirestore } from '@ngxs-labs/firestore-plugin';

import * as firebase from 'firebase';
import 'firebase/firestore';
import { from, of } from 'rxjs';
import { mapTo, timeoutWith } from 'rxjs/operators';
import { isPost } from '../models/post-core.model';
import { ReadReceipt } from '../models/read-receipt.model';
import { SupportRequest } from '../models/support-request.model';

@Injectable({
  providedIn: 'root',
})
export class SupportRequestService extends NgxsFirestore<SupportRequest> {
  idField = '_UID';

  protected get path() {
    return `VILLAGES/${this.villageId}/SUPPORT_REQUESTS`;
  }

  private VILLAGEID = '';

  public setVillageId(villageId) {
    this.VILLAGEID = villageId;
  }

  protected get villageId() {
    return this.VILLAGEID;
  }

  /**
   *
   * @deprecated
   */
  updateIfExists(id, data) {
    return of(
      this.adapter.firestore
        .doc(`${this.path}/${id}`)
        .ref.withConverter(this.converter)
        .update(data)
    );
    }

  updateWithoutConverter(id: string, update: Partial<SupportRequest>) {
    return of(
      this.adapter.firestore
        .doc(`${this.path}/${id}`)
        .update(update)
    );
  }

  converter: firebase.default.firestore.FirestoreDataConverter<SupportRequest> =
    {
      toFirestore: (value: Partial<SupportRequest>) => {
        let db = { ...value };
        if (db.hasOwnProperty('BODY')) {
          db = {
            ...db,
            BODY: encodeURI(value.BODY),
          };
        }
        return db;
      },
      fromFirestore: (snapshot, options) => {
        let data = snapshot.data(options) as SupportRequest;

        if (snapshot.metadata.hasPendingWrites) {
          const ts = firebase.default.firestore.Timestamp.now();
          data = {
            ...data,
            BODY: decodeURI(data.BODY),
            _CREATED_AT: ts.toDate(),
            UPDATED_AT: ts.toDate(),
          };
        } else {
          data = {
            ...data,
            BODY: decodeURI(data.BODY),
            _CREATED_AT: data._SERVER_TIMESTAMP.toDate(),
            UPDATED_AT: data.UPDATED_AT.toDate(),
          };
        }

        if (!data.hasOwnProperty('IMAGE_PATHS')) {
          data.IMAGE_PATHS = [];
        }

        if (!data.hasOwnProperty('PARTICIPANT_READ_RECEIPTS')) {
          data.PARTICIPANT_READ_RECEIPTS = [];
        }

        if (!data.hasOwnProperty('PARTICIPANT_UIDS')) {
          data.PARTICIPANT_UIDS = [...data.PARTICIPANTS];
        }

        // patch no REPORTED_BY
        if (!data.hasOwnProperty('REPORTED_BY')) {
          data.REPORTED_BY = [];
        }

        // patch no TOPICS
        if (!data.hasOwnProperty('TOPICS')) {
          data.TOPICS = [];
        }

        if (!data.hasOwnProperty('ARCHIVED')) {
          data.ARCHIVED = false;
        }

        // patch no CIRCLES
        if (!data.hasOwnProperty('CIRCLES')) {
          data.CIRCLES = [{ NAME: 'Members', UID: 'MEMBERS' }];
        }

        // patch no CIRCLE_UIDS
        if (!data.hasOwnProperty('CIRCLE_UIDS')) {
          data.CIRCLE_UIDS = ['MEMBERS'];
        }

        return data;
      },
    };
}
