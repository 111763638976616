import { Component, Input, OnInit } from '@angular/core';
import {
  AlertController,
  LoadingController,
  ModalController,
} from '@ionic/angular';
import { Disconnect } from '@ngxs-labs/firestore-plugin';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { concatMap, tap } from 'rxjs/operators';
import { Village } from 'src/app/models/village.model';
import { JoinedVillage, Villager } from 'src/app/models/villager.model';
import {
  ClearAllPosts,
  ClearAllVillagers,
  ClearShares,
  ClearConflicts,
  ClearExchanges,
  ClearHangouts,
  ClearSupportRequests,
  FetchShares,
  FetchConflicts,
  FetchExchanges,
  FetchHangouts,
  FetchSupportRequests,
  GetFellowVillagers,
  GetVillage,
  RemoveVillageFromVillager,
  RemoveVillagerFromVillage,
  SwitchVillage,
  ClearAnnouncements,
  FetchAnnouncements,
} from 'src/app/state/app.actions';
import { CircleActions } from 'src/app/state/circle.actions';
import { CircleState } from 'src/app/state/circle.state';
import { VillageState } from 'src/app/state/village.state';
import { VillagerState } from 'src/app/state/villager.state';

@Component({
  selector: 'app-leave-village-modal',
  templateUrl: './leave-village-modal.page.html',
  styleUrls: ['./leave-village-modal.page.scss'],
})
export class LeaveVillageModalPage implements OnInit {
  @Input('villager') villager: Villager;
  @Select(VillagerState.currentVillager) villager$: Observable<Villager>;
  @Select(VillageState.currentVillage) village$: Observable<Village>;
  currentVillageName: string;
  verifying = false;
  villagesToJoin: JoinedVillage[];
  newVillageSelected: string;
  village: Village;
  loading: any;

  constructor(
    private alertController: AlertController,
    private store: Store,
    public modalCtrl: ModalController,
    private loadingCtrl: LoadingController
  ) {}

  ngOnInit() {
    this.villagesToJoin = [];

    this.villager.VILLAGES.forEach((village) => {
      if (village.UID === this.villager.VILLAGE) {
        this.currentVillageName = village.NAME;
      } else {
        this.villagesToJoin.push(village);
      }
    });

    this.village$.subscribe((x) => {
      if (!x) return;
      this.village = x;
    });
  }

  selectVillageToJoin(villageUid: string) {
    this.newVillageSelected = villageUid;
  }

  disableLeave() {
    if (this.villager.VILLAGES.length === 1) {
      return false;
    }
    return !this.newVillageSelected;
  }

  async confirmLeaveVillage() {
    const alert = await this.alertController.create({
      header: 'Careful!',
      message: `Are you sure you want to leave ${this.currentVillageName}?`,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'dark',
          handler: () => {},
        },
        {
          text: `Leave`,
          role: 'delete',
          cssClass: 'danger',
          handler: () => {
            this.leaveAndSwitchVillage();
          },
        },
      ],
    });

    await alert.present();
  }

  async leaveAndSwitchVillage() {
    const allVillageCircles = this.store.selectSnapshot(CircleState.allCircles);

    await this.presentLoading();
    this.store.dispatch([
      new RemoveVillagerFromVillage({
        villager: this.villager,
        village: this.village,
      }),
      new RemoveVillageFromVillager({
        villager: this.villager,
        village: this.village,
      }),
    ]);

    allVillageCircles.forEach((circle) => {
      this.store.dispatch(
        new CircleActions.RemoveVillagersFromCircle({
          circleId: circle._UID,
          villagerIds: [this.villager._UID],
        })
      );
    });

    if (this.newVillageSelected) {
      this.store
        .dispatch(
          new SwitchVillage({
            villagerUid: this.villager._UID,
            villageUid: this.newVillageSelected,
          })
        )
        .subscribe(() => {
          if (this.loading) {
            this.loading.dismiss();
            this.loading = null;
          }
        });
    } else {
      this.store
        .dispatch([
          new Disconnect(GetVillage),
          new Disconnect(FetchShares),
          new Disconnect(FetchHangouts),
          new Disconnect(FetchSupportRequests),
          new Disconnect(FetchExchanges),
          new Disconnect(FetchConflicts),
          new Disconnect(FetchAnnouncements),
          new Disconnect(GetFellowVillagers),
        ])
        .pipe(
          concatMap(() =>
            this.store.dispatch([
              new ClearShares(),
              new ClearExchanges(),
              new ClearHangouts(),
              new ClearSupportRequests(),
              new ClearConflicts(),
              new ClearAnnouncements(),
              new ClearAllPosts(),
              new ClearAllVillagers(),
            ])
          )
        )
        .subscribe(() => {
          if (this.loading) {
            this.loading.dismiss();
            this.loading = null;
          }
        });
    }

    this.modalCtrl.dismiss();
  }

  async presentLoading() {
    if (!this.loading) {
      this.loading = await this.loadingCtrl.create({
        duration: 5000,
        backdropDismiss: true,
      });
      return this.loading.present();
    }
  }
}
