import { Injectable } from '@angular/core';
import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from '@capacitor/push-notifications';
import { Store } from '@ngxs/store';
import {
  AppDMsLoaded,
  CourtyardNotificationLookup,
  FetchDirectChatrooms,
  PushAllowed,
  PushNotificationPostLookup,
  PushRevoked,
  RemoveFCMToken,
  SetFCMToken,
  SetSelectedDirectChatroomById,
  SwitchVillage,
} from '../state/app.actions';
import { Badge } from '@capawesome/capacitor-badge';
import { Platform, ToastController } from '@ionic/angular';
import { PushNotificationDataPayload } from '../models/notification.model';
import { Router } from '@angular/router';
import { VillageState } from '../state/village.state';
import { VillagerState } from '../state/villager.state';
import { AnalyticsService } from '../analytics.service';
import { combineLatest } from 'rxjs';
import { AppState } from '../state/app.state';
import { skipWhile, first, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PushNotificationService {
  component = 'push-notification-service';
  constructor(
    private store: Store,
    private platform: Platform,
    private router: Router,
    private toastCtrl: ToastController,
    private analytics: AnalyticsService,
  ) {}

  async register() {
    if (!this.platform.is('capacitor')) return;

    // alert('[Push] Registering for push notifications');
    PushNotifications.requestPermissions().then((result) => {
      console.log(
        '[Push] Notification Registration: ' + JSON.stringify(result)
      );
      if (result.receive === 'granted') {
        // Register with Apple / Google to receive push via APNS/FCM
        // result is handled in listener
        PushNotifications.register();
      } else {
        console.error('Did not receive push notification granted');
        // result is handled in listener
      }
    });
  }

  init() {
    if (!this.platform.is('capacitor')) return;
    // On success, we should be able to receive notifications
    PushNotifications.addListener('registration', (token: Token) => {
      // alert('[Push] registration success, token: ' + JSON.stringify(token));
      console.log(
        '[Push] registration success, token: ',
        JSON.stringify(token)
      );
      // alert('[Push] registration success, token: ' + token.value);
      if (token.value) {
        this.store.dispatch([
          new PushAllowed(),
          new SetFCMToken({ fcmToken: token.value }),
        ]);
      } else {
        console.error('No FCM Token provided in reg callback');
      }
    });

    // Some issue with our setup and push will not work
    PushNotifications.addListener('registrationError', (error: any) => {
      // alert('Error on registration: ' + JSON.stringify(error));
      this.analytics.logError(
        'error_register_push_token',
        this.component,
        error
      );
      console.error('Error on registration: ' + JSON.stringify(error));
      this.store.dispatch([new PushRevoked(), new RemoveFCMToken()]);
    });

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener(
      'pushNotificationReceived',
      async (notification: PushNotificationSchema) => {
        // alert('Push received: ' + JSON.stringify(notification));
        await Badge.increase();
        // alert('Added to badge count');
      }
    );

    // Method called when tapping on a notification
    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      (actionPerformed: ActionPerformed) => {
        combineLatest([
          this.store.select(VillageState.uid),
          this.store.select(VillagerState.uid),
        ])
          .pipe(
            skipWhile(
              ([currentVillageId, villagerId]) =>
                currentVillageId === undefined || villagerId === undefined
            ),
            first(),
            tap(async ([currentVillageId, villagerId]) => {
              if (currentVillageId && villagerId) {
                console.log(
                  '[Notification] Clicked notification: ',
                  JSON.stringify(actionPerformed)
                );
                await Badge.decrease();

                const data: PushNotificationDataPayload =
                  actionPerformed.notification.data;
                const { VILLAGE_UID } = data;
                if (VILLAGE_UID && VILLAGE_UID !== currentVillageId) {
                  this.presentToast('Switching Village');

                  this.store
                    .dispatch(
                      new SwitchVillage({
                        villageUid: VILLAGE_UID,
                        villagerUid: villagerId,
                      })
                    )
                    .subscribe(() => {
                      this.handlePushNotification(data);
                    });
                } else {
                  console.log('[Push] Handling Push Notification');
                  this.handlePushNotification(data);
                }
              }
            })
          )
          .subscribe();
      }
    );
  }

  handlePushNotification(notification: PushNotificationDataPayload) {
    const { TYPE } = notification;
    if (TYPE) {
      switch (TYPE) {
        case 'COURTYARD':
          this.store.dispatch(
            new CourtyardNotificationLookup({
              courtyardId: notification.CONTENT_UID,
              type: 'COURTYARDS',
              villageId: notification.VILLAGE_UID,
            })
          );
          break;
        case 'POST':
        case 'DISCUSSION':
          console.log('Have post type');
          this.store.dispatch(
            new PushNotificationPostLookup({
              postId: notification.CONTENT_UID,
              type: notification.CONTENT_COLLECTION,
              villageId: notification.VILLAGE_UID,
            })
          );
          break;
        case 'DIRECT':
          console.log('Have DM type');
          this.handleDMsWithCatchup(notification.CHATROOM_UID);

          break;
        default:
          this.router.navigateByUrl(`pages/home`);
      }
    } else {
      this.router.navigateByUrl(`pages/home`);
    }
  }

  handleDMsWithCatchup(chatroomId: string) {
    const hasDMsLoaded = this.store.selectSnapshot(AppState.dmsLoaded);
    if (!hasDMsLoaded) {
      const uid = this.store.selectSnapshot(VillagerState.uid);
      this.store
        .dispatch(new FetchDirectChatrooms({ villagerUid: uid }))
        .subscribe((response) => {
          setTimeout(() => {
            this.store
              .dispatch([
                new AppDMsLoaded(),
                new SetSelectedDirectChatroomById({
                  chatroomId,
                }),
              ])
              .subscribe(() => {
                this.router.navigateByUrl(`pages/messages`);
                if (
                  this.platform.is('mobile') ||
                  this.platform.is('capacitor')
                ) {
                  this.openDirectChatroomModal();
                }
              });
          }, 500);
        });
    } else {
      this.store
        .dispatch(
          new SetSelectedDirectChatroomById({
            chatroomId,
          })
        )
        .subscribe(() => {
          this.router.navigateByUrl(`pages/messages`);
          if (this.platform.is('mobile') || this.platform.is('capacitor')) {
            this.openDirectChatroomModal();
          }
        });
    }
  }

  public async openDirectChatroomModal() {
    this.router.navigateByUrl('chatroom');
    // const modal = await this.modalCtrl.create({
    //   component: DirectChatroomModalPage,
    // });

    // return await modal.present();
  }

  async setBadgeCount() {
    if (!this.platform.is('capacitor')) return;
    try {
      await PushNotifications.register();
      const permission = await Badge.isSupported();
      if (!permission) return;

      const delivered = await PushNotifications.getDeliveredNotifications();
      console.log('[Delivered] Notifications: ', JSON.stringify(delivered));

      await Badge.set({ count: delivered.notifications.length });
      // alert('Set Count: ' + delivered.notifications.length);
    } catch (e) {
      console.error('Error getting push state: ', JSON.stringify(e));
    }
  }

  async clearNotificationsByContentId(id: string) {
    if (!this.platform.is('capacitor')) return;
    // 1. Get the delivered system notifications
    const deliveredNotifications =
      await PushNotifications.getDeliveredNotifications();
    console.log('[Push] Delivered Notifications: ', deliveredNotifications);

    // 2. Find the notifications that match the current selected content
    const toRemove = deliveredNotifications.notifications.filter(
      (notification) => {
        const data: PushNotificationDataPayload = notification.data;
        const { TYPE } = data;
        switch (TYPE) {
          case 'POST':
          case 'DISCUSSION':
            const { CONTENT_UID } = notification.data;
            console.log(
              '[Push] Checking against current selected: ',
              CONTENT_UID,
              id
            );
            if (CONTENT_UID === id) {
              // alert('Found a match. Clearing');
              return notification;
            }
            break;
          // 3. DIRECT has CHATROOM_UID
          case 'DIRECT':
            const { CHATROOM_UID } = notification.data;
            console.log(
              '[Push] Checking DM against current selected: ',
              CHATROOM_UID,
              id
            );
            if (CHATROOM_UID === id) {
              // alert('Found a match. Clearing');
              return notification;
            }
            break;
        }
      }
    );

    if (toRemove.length > 0) {
      await PushNotifications.removeDeliveredNotifications({
        notifications: toRemove,
      });

      // 3. Debug check to see if cleared
      const remainingNotifications =
        await PushNotifications.getDeliveredNotifications();
      console.log('[Push] Remaining Notifications: ', remainingNotifications);
      // alert(
      //   'Setting badge count to: ' + remainingNotifications.notifications.length
      // );
      await Badge.set({ count: remainingNotifications.notifications.length });
    }
  }

  // not tested yet
  async clearVillagePostNotifications(contentType: string) {
    if (!this.platform.is('capacitor')) return;
    // alert('[Push] Clearning all notifications by type: ' + contentType)
    // 1. Get the delivered system notifications
    const deliveredNotifications =
      await PushNotifications.getDeliveredNotifications();
    console.log('[Push] Delivered Notifications: ', deliveredNotifications);

    // 2. Find the notifications that match the current selected content
    const toRemove = deliveredNotifications.notifications.filter(
      (notification) => {
        const data: PushNotificationDataPayload = notification.data;
        const { TYPE } = data;
        if (TYPE === contentType) {
          return notification;
        }
      }
    );
    if (toRemove.length > 0) {
      await PushNotifications.removeDeliveredNotifications({
        notifications: toRemove,
      });

      // 3. Debug check to see if cleared
      const remainingNotifications =
        await PushNotifications.getDeliveredNotifications();
      console.log('[Push] Remaining Notifications: ', remainingNotifications);
      // alert(
      //   'Setting badge count to: ' + remainingNotifications.notifications.length
      // );
      await Badge.set({ count: remainingNotifications.notifications.length });
    }
  }

  async clearBadgeCount() {
    await Badge.set({ count: 0 });
  }

  async presentToast(message: string) {
    const toast = await this.toastCtrl.create({
      message,
      color: 'primary',
      duration: 3000,
      position: 'top',
    });
    toast.present();
  }
}
