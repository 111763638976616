import { Injectable } from '@angular/core';
import { NgxsFirestore } from '@ngxs-labs/firestore-plugin';
import * as firebase from 'firebase';
import 'firebase/firestore';
import { of } from 'rxjs';
import { Courtyard } from '../models/courtyard.model';
import { MemberInvite } from '../models/member-invite.model';

@Injectable({
  providedIn: 'root',
})
export class MemberInviteService extends NgxsFirestore<MemberInvite> {
  idField = '_UID';

  protected get path() {
    return `INVITES`;
  }

  /**
   * @deprecated
   */
   updateIfExists(id, data) {
    return of(
      this.adapter.firestore
        .doc(`${this.path}/${id}`)
        .ref.withConverter(this.converter)
        .update(data)
    );
  }

  updateWithoutConverter(id: string, update: Partial<MemberInvite>) {
    return of(this.adapter.firestore.doc(`${this.path}/${id}`).update(update));
  }

  converter: firebase.default.firestore.FirestoreDataConverter<MemberInvite> = {
    toFirestore: (value: MemberInvite) => {
      let db = { ...value };
      // console.log('[MEMBER INVITE SERVICE] Sending to firestore:  ', db);
      return db;
    },
    fromFirestore: (snapshot, options) => {
      let data = snapshot.data(options) as MemberInvite;

      return data;
    },
  };
}
