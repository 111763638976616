<ion-content #loginContent [fullscreen]="true" class="village-teal-bg-always">
  <ion-grid *ngIf="(isAuthenticated$ | async) == false">
    <ion-row>
      <ion-col size="12" sizeLg="6" offsetLg="3">
        <ion-card>
          <ion-card-header>
            <ion-card-title>Welcome back to {{appName}}</ion-card-title>
          </ion-card-header>

          <ion-card-content>
            <div *ngIf="inviteId">
              You've been invited to join a new community! Login to accept the
              invitation.
            </div>
            <ion-item color="transparent" class="clear-input">
              <ion-label position="stacked">Email</ion-label>
              <ion-input
                #emailInput
                [(ngModel)]="email"
                type="email"
                name="email"
                enterkeyhint="next"
                placeholder="Enter your email"
              >
              </ion-input>
            </ion-item>

            <ion-item color="transparent" class="clear-input">
              <ion-label position="stacked">Password</ion-label>
              <ion-input
                [type]="passwordType"
                #passwordInput
                [(ngModel)]="password"
                name="password"
                enterkeyhint="done"
                placeholder="Enter your password"
              >
              </ion-input>
              <ion-icon
                slot="end"
                [name]="passwordIcon"
                class="passwordIcon"
                (click)="hideShowPassword()"
              ></ion-icon>
            </ion-item>

            <!-- [disabled]="loginForm.invalid" -->
            <ion-button
              style="margin-top: 20px"
              expand="block"
              color="primary"
              (click)="login()"
            >
              Login
              <ion-spinner
                style="margin-left: 10px"
                slot="end"
                name="lines-small"
                *ngIf="running"
              ></ion-spinner>
            </ion-button>
            <!--
            <div class="ion-padding">
              By using {{appName}}, you agree to follow our
              <a
                href="https://revillager.com/terms-of-service"
                rel="external nofollow noopener"
                target="_blank"
                >terms of service</a
              >
              and
              <a
                href="https://revillager.com/privacy-policy"
                rel="external nofollow noopener"
                target="_blank"
                >privacy policy</a
              >.
            </div> -->

            <div
              style="
                text-align: center;
                font-weight: 600;
                font-size: 1em;
                margin-top: 40px;
                margin-bottom: 15px;
                position: relative;
              "
            >
              <!-- <div style="position: absolute" ></div> -->
              <div style="margin-bottom: 15px;">
                <div style="display: inline-block; font-size: 20px;" [@tada]="waveHello">👋</div>
                <div style="display: inline-block; margin-left: 10px;">Are you new here?</div>
              </div>
              <ion-button
                (click)="goToGetStarted()"
                color="primary"
                expand="block"
              >
                Create Account
              </ion-button>
            </div>

            <ion-button
              style="margin-top: 60px"
              (click)="passwordReset()"
              fill="outline"
              size="small"
              color="dark"
              expand="block"
            >
              Forgot your password?
            </ion-button>

            <p style="text-align: center; margin-top: 30px; margin-bottom: 5px">
              Need help? Click the help button below 👇
            </p>
          </ion-card-content>
        </ion-card>
      </ion-col>
    </ion-row>
  </ion-grid>

  <div style="height: 20vh"></div>
</ion-content>

<app-support-fab></app-support-fab>
