import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ToastController } from '@ionic/angular';
import {
  Connected,
  Disconnect,
  Emitted,
  NgxsFirestoreConnect,
  StreamConnected,
  StreamEmitted,
} from '@ngxs-labs/firestore-plugin';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { concatMap } from 'rxjs/operators';
import {
  MemberInvite,
  MemberInviteStateModel,
} from '../models/member-invite.model';
import { MemberInviteService } from '../services/member-invite.service';
import { MemberInviteActions } from './member-invite.actions';
import { VillageState } from './village.state';
import { VillagerState } from './villager.state';

@State<MemberInviteStateModel>({
  name: 'memberInvites',
  defaults: {
    allInvites: [],
  },
})
@Injectable()
export class MemberInviteState {
  constructor(
    private angularFire: AngularFirestore,
    private memberInviteSvc: MemberInviteService,
    private toastCtrl: ToastController,
    private store: Store,
    private ngxsFirestoreConnect: NgxsFirestoreConnect
  ) {}

  ngxsOnInit() {
    this.ngxsFirestoreConnect.connect(MemberInviteActions.FetchAll, {
      to: (action) =>
        this.memberInviteSvc.collection$((ref) =>
          ref.where('VILLAGE_UID', '==', action.payload.villageId)
        ),
      cancelPrevious: true,
    });
  }

  @Selector()
  static allInvites(state: MemberInviteStateModel): MemberInvite[] {
    return [...state.allInvites].sort((a: MemberInvite, b: MemberInvite) => {
      if(a._EMAIL < b._EMAIL) { return -1; }
      if(a._EMAIL > b._EMAIL) { return 1; }
      return 0;
    });
  }

  @Action(MemberInviteActions.Create)
  async createCourtyard(
    ctx: StateContext<MemberInviteStateModel>,
    {
      payload: { invite, onAlreadyExists, onSuccess, onError },
    }: MemberInviteActions.Create
  ) {
    const _UID = this.angularFire.createId();
    invite = {
      ...invite,
      _UID,
    };
    const { _EMAIL, VILLAGE_UID } = invite;

    // 1. check to make sure no invitation already exists,
    // we don't want to spam our users with invitations
    let response = this.checkForExistingInvite(ctx, _EMAIL, VILLAGE_UID);
    if (response == 'already exists') {
      return onAlreadyExists('Invite already exists');
    }

    response = this.checkForExistingVillager(_EMAIL);
    if (response == 'already exists') {
      const membersLabel = this.store.selectSnapshot(VillageState.membersLabel);
      const villageLabel = this.store.selectSnapshot(
        VillageState.configVillageLabel
      );
      return onAlreadyExists(
        `${membersLabel} is already in this ${villageLabel}`
      );
    }

    console.log('[Member Invite State] creating invite: ', invite);
    await this.memberInviteSvc.create$(invite).toPromise();
    return onSuccess();
  }

  @Action(MemberInviteActions.RefreshAll)
  refreshChats(
    ctx: StateContext<MemberInviteStateModel>,
    action: MemberInviteActions.RefreshAll
  ) {
    return this.store
      .dispatch([new Disconnect(MemberInviteActions.FetchAll)])
      .pipe(
        concatMap(() =>
          this.store.dispatch(new MemberInviteActions.FetchAll(action.payload))
        )
      );
  }

  @Action(StreamConnected(MemberInviteActions.FetchAll))
  streamConnected(
    ctx: StateContext<MemberInviteStateModel>,
    { action }: Connected<MemberInviteActions.FetchAll>
  ) {
    console.log('[Courtyard State] Courtyard Stream connected');
  }

  @Action(StreamEmitted(MemberInviteActions.FetchAll))
  streamEmitted(
    ctx: StateContext<MemberInviteStateModel>,
    { action, payload }: Emitted<MemberInviteActions.FetchAll, MemberInvite[]>
  ) {
    console.log('patching with: ', payload);
    // 1. Patch all invitations
    ctx.patchState({
      allInvites: payload,
    });
  }

  @Action(MemberInviteActions.MarkInviteAsAccepted)
  markInviteAsAccepted(
    ctx: StateContext<MemberInviteStateModel>,
    { payload: { inviteId } }: MemberInviteActions.MarkInviteAsAccepted
  ) {
    return this.memberInviteSvc.updateWithoutConverter(inviteId, {
      STATUS: 'ACCEPTED',
    });
  }

  private checkForExistingInvite(
    ctx: StateContext<MemberInviteStateModel>,
    email: string,
    villageUid: string
  ) {
    const { allInvites } = ctx.getState();
    const existing = allInvites.find((x) => {
      return x._EMAIL === email && x.VILLAGE_UID === villageUid;
    });

    if (existing) {
      console.log('[Member Invite State] invite already exists');
      const message = `${email} has already been invited to this village!`;
      return 'already exists';
    } else {
      console.log('[Member Invite State] no existing invite. creating new one');
      return 'create new invite record';
    }
  }

  private checkForExistingVillager(email: string) {
    const allVillagers = this.store.selectSnapshot(VillagerState.allVillagers);
    const existing = allVillagers.find((x) => {
      return x._EMAIL === email;
    });

    if (existing) {
      console.log('[Member Invite State] Villager is already in this village');
      const message = `${email} is already in this village!`;
      return 'already exists';
    } else {
      console.log('[Member Invite State] no existing villager. inviting them');
      return 'create new invite record';
    }
  }
}
