import { Component, Input, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Share } from 'src/app/models/share.model';
import { GenericPost } from 'src/app/models/post-core.model';
import { EMOTIONS } from 'src/app/models/support-request.model';
import { FeedState } from 'src/app/state/feed.state';
import { Router } from '@angular/router';
import { SetSelectedPost } from 'src/app/state/app.actions';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-bulletin-card-body',
  templateUrl: './bulletin-card-body.component.html',
  styleUrls: ['./bulletin-card-body.component.scss'],
})
export class BulletinCardBodyComponent implements OnInit {
  bulletin: Share;
  emotions = EMOTIONS;
  emoji: string = null;

  @Select(FeedState.allPosts) allPosts$: Observable<GenericPost[]>;
  @Input() showFullBody = true;
  @Input() post: GenericPost;

  constructor(
    private store: Store,
    private router: Router,
    private modalCtrl: ModalController
  ) {}

  ngOnInit() {
    this.bulletin = this.post as Share;
    // console.log('[Share Card] has post: ', this.bulletin);

    if (this.bulletin.FEELING) this.getEmoji();

    this.store
      .select(FeedState.getPostById)
      .pipe(map((filterFn) => filterFn(this.post._UID)))
      .subscribe((post) => {
        if (post) {
          // console.log('[Share Card Body] updated share: ', post);
          this.bulletin = post as Share;
        }
      });
  }

  goToDetails() {
    this.store.dispatch(
      new SetSelectedPost({ post: this.bulletin, location: 'DETAILS' })
    );
    this.router.navigateByUrl(`pages/post-details`);
  }

  async clickBody(ev: Event) {
    const target = ev.target as HTMLElement;
    if (target?.className === 'mentionified') {
      const uid = target.getAttribute('data-mention-uid');
      if (uid) {
        const existing = await this.modalCtrl.getTop();
        if (existing) await this.modalCtrl.dismiss();
        this.router.navigateByUrl(`member/${uid}`);
      }
    }
  }

  getEmoji() {
    this.emoji = this.emotions.find(
      (x) => x.emotion === this.bulletin.FEELING
    ).icon;
  }
}
