<ion-header class="ion-no-border" translucent="false">
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button (click)="modalCtrl.dismiss({action: 'regular-close'})">
        <ion-icon color="dark" slot="icon-only" name="close-circle"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title
      ><span *ngIf="!edit">Create</span
      ><span *ngIf="edit">Update</span> Share</ion-title
    >
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-card>
    <ion-card-content style="padding: 0">
      <app-post-mentionable-input
        [mentionables]="mentionables"
        [placeholderText]="placeholderText"
        [shouldDisplayAbove]="false"
      >
      </app-post-mentionable-input>

      <ion-grid *ngIf="edit">
        <ion-row *ngIf="tmpImageUrls.length > 0" class="ion-align-items-center">
          <ion-col size="12">
            <div class="subheader" style="margin-top: 10px; margin-bottom: 5px">
              Edit Photos
            </div>
          </ion-col>
          <ion-col *ngFor="let path of tmpImageUrls; let i = index">
            <div class="post-image-wrapper">
              <app-edit-images
                (deletePhotoEvent)="handleDeletePhoto($event, i)"
                [path]="path"
              ></app-edit-images>
            </div>
          </ion-col>
        </ion-row>
        <ion-row *ngIf="tmpDocUrls.length > 0" class="ion-align-items-center">
          <ion-col>
            <div class="subheader" style="margin-top: 10px; margin-bottom: 5px">
              Edit Documents
            </div>
            <app-post-document
              *ngFor="let doc of tmpDocUrls; let i = index"
              (click)="handleDeleteDocument(i)"
              [document]="doc"
              [edit]="true"
            ></app-post-document>
          </ion-col>
        </ion-row>
      </ion-grid>

      <ion-grid>
        <ion-row>
          <ion-col>
            <div class="subheader">Add to your post</div>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="12" sizeLg="4">
            <ion-button
              color="danger"
              expand="block"
              (click)="showFeeling = !showFeeling"
            >
              <ion-icon slot="start" name="heart"></ion-icon>
              <ion-label>Feeling</ion-label>
            </ion-button>
          </ion-col>
          <ion-col size="12" sizeLg="4">
            <ion-button
              color="secondary"
              expand="block"
              (click)="showPhotos = !showPhotos"
            >
              <ion-icon slot="start" name="images"></ion-icon>
              <ion-label>Photos/Docs</ion-label>
            </ion-button>
          </ion-col>
          <ion-col size="12" sizeLg="4">
            <ion-button
              color="dark"
              expand="block"
              (click)="showTopics = !showTopics"
            >
              <ion-icon slot="start" name="pricetags"></ion-icon>
              <ion-label>Topics</ion-label>
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>

      <div *ngIf="showFeeling">
        <div class="subheader" style="margin-top: 10px; margin-bottom: 5px">
          Add a feeling
        </div>
        <p>
          Let your <app-village-label></app-village-label> know how you're
          feeling
        </p>
        <div class="scroll-row" style="padding-left: 0">
          <ion-chip
            (click)="selectEmotion(emotion)"
            [color]="selectedEmotion === emotion.emotion ? 'primary' : 'medium'"
            [outline]="selectedEmotion === emotion.emotion"
            style="margin-top: 3px"
            *ngFor="let emotion of emotions"
          >
            <ion-label
              >{{emotion.icon }} {{emotion.emotion | titlecase}}</ion-label
            >
            <ion-icon
              *ngIf="emotion.emotion === selectedEmotion"
              name="close-circle"
            ></ion-icon>
          </ion-chip>
        </div>
      </div>

      <app-post-file-upload
        *ngIf="showPhotos"
        [tmpDocUrls]="tmpDocUrls"
        [tmpImageUrls]="tmpImageUrls"
      ></app-post-file-upload>

      <app-add-topics
        *ngIf="showTopics"
        [topics]="topics"
        (setTopics)="onSetTopics($event)"
      ></app-add-topics>

      <app-post-circle-assignment
        [alreadyAssignedCircles]="assignedCircles"
      ></app-post-circle-assignment>

      <ion-button
        expand="block"
        *ngIf="edit === false"
        (click)="createPost()"
        color="primary"
      >
        <ion-label>Create </ion-label>

        <ion-chip
          *ngIf="(showKarma$ | async) === true"
          style="position: absolute; right: 5px"
          slot="end"
          color="light"
          class="karma-chip"
        >
          <ion-avatar>
            <img src="../../../assets/images/badge-images/karma.png" />
          </ion-avatar>
          <ion-label><strong>+{{SHARE_KARMA}}</strong></ion-label>
        </ion-chip>
      </ion-button>

      <ion-button *ngIf="edit === true" expand="block" (click)="updatePost()"
        >Save Changes
      </ion-button>
      <div style="height: 20px"></div>
    </ion-card-content>
  </ion-card>
</ion-content>
