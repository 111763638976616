import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-board-karma-chip',
  templateUrl: './board-karma-chip.component.html',
  styleUrls: ['./board-karma-chip.component.scss'],
})
export class BoardKarmaChipComponent implements OnInit {

  @Input() amount: number;
  constructor() { }


  ngOnInit() {}

}
