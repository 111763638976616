<ion-header class="ion-no-border" translucent="false">
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button (click)="modalCtrl.dismiss({action: 'regular-close'})">
        <ion-icon color="dark" slot="icon-only" name="close-circle"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title
      ><span *ngIf="!edit">Create</span><span *ngIf="edit">Update</span>
      <app-village-label [showTitlecase]="true"></app-village-label>
      List</ion-title
    >
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-card>
    <ion-card-content>
      <p style="margin-bottom: 5px">
        Create a shared list for your <app-village-label></app-village-label> to
        add items to and mark things as complete. This is an easy way to share
        shopping lists, to-do lists, anything your
        <app-village-label></app-village-label> needs a list of!
      </p>
      <form [formGroup]="listForm">
        <ion-item
          lines="none"
          class="full-width-item form-outline-item"
          [ngClass]="{
          invalid: title.invalid && (title.dirty || title.touched)
        }"
        >
          <ion-input
            formControlName="title"
            placeholder="Give your list a title.."
          ></ion-input>
        </ion-item>
        <ion-item class="full-width-item">
          <ion-label style="margin-left: 10px">Make list read-only</ion-label>
          <ion-checkbox
            (ionChange)="toggleReadOnly()"
            [checked]="readOnly"
            slot="end"
          ></ion-checkbox>
        </ion-item>
        <app-post-mentionable-input
          [mentionables]="mentionables"
          [placeholderText]="placeholderText"
          [shouldDisplayAbove]="false"
        >
        </app-post-mentionable-input>
      </form>

      <app-post-circle-assignment
        [alreadyAssignedCircles]="assignedCircles"
      ></app-post-circle-assignment>

      <div style="height: 20px"></div>

      <ion-button
        *ngIf="edit === false"
        [disabled]="listForm.invalid"
        expand="block"
        (click)="createSharedList()"
      >
        <span>Create</span>
      </ion-button>
      <ion-button
        *ngIf="edit === true"
        [disabled]="listForm.invalid"
        expand="block"
        (click)="updateSharedList()"
        >Save Changes
      </ion-button>
      <ion-item
        class="full-width-item"
        lines="none"
        *ngIf="villageName$ | async as name"
      >
        <ion-label class="ion-text-wrap">
          <div>Share with <strong>{{name}}</strong></div>
          <p>
            Only <app-members-label [plural]="true"></app-members-label> in this
            <app-village-label></app-village-label> will be able to see your
            list
          </p>
        </ion-label>
        <ion-icon slot="end" name="lock-closed"></ion-icon>
      </ion-item>
    </ion-card-content>
  </ion-card>
</ion-content>
