import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  ModalController,
  ToastController,
  AlertController,
  LoadingController,
} from '@ionic/angular';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { AnalyticsService } from 'src/app/analytics.service';
import { ConflictResolution } from 'src/app/models/conflict-resolution.model';
import { Villager } from 'src/app/models/villager.model';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { VillageState } from 'src/app/state/village.state';
import { VillagerState } from 'src/app/state/villager.state';
import * as firebase from 'firebase';
import 'firebase/firestore';
import { CreateConflict, UpdateConflict } from 'src/app/state/app.actions';
import { CONFLICT_KARMA } from 'src/app/app.config';
import { JoinedCircle } from 'src/app/models/circle.model';
import { AppState } from 'src/app/state/app.state';
import { StoredDocument } from 'src/app/models/post-core.model';
import { PostFileUploadComponent } from 'src/app/shared/post-file-upload/post-file-upload.component';
import { RecipientNotificationSettings } from 'src/app/models/notification-settings';
import { map } from 'rxjs/operators';
import { CreatePostHelpersService } from 'src/app/services/create-post-helpers.service';
import { PostMentionableInputComponent } from 'src/app/shared/post-mentionable-input/post-mentionable-input.component';
import { VillagerActions } from 'src/app/state/villager.actions';

@Component({
  selector: 'app-create-conflict-modal',
  templateUrl: './create-conflict-modal.page.html',
  styleUrls: ['./create-conflict-modal.page.scss'],
})
export class CreateConflictModalPage implements OnInit, AfterViewInit {
  postForm = new FormGroup({
    title: new FormControl('', [Validators.required]),
    whatsNeeded: new FormControl(''),
    whatsDesired: new FormControl(''),
  });
  creator: Villager;
  loading: any;

  CONFLICT_KARMA = CONFLICT_KARMA;
  component = 'create-post-modal';
  placeholderText =
    "Please describe how you're feeling in greater detail and whom you have this conflict with (if applicable)... ";

  mentionables: string[] = [];

  // for existing files on post
  tmpImageUrls: string[] = [];
  tmpDocUrls: StoredDocument[] = [];

  topics: string[] = [];
  assignedCircles: JoinedCircle[] = [];

  // showFeeling = false;
  showPhotos = false;
  showTopics = false;

  @Select(VillageState.villageName) villageName$: Observable<string>;
  @Select(VillageState.showKarma) showKarma$: Observable<boolean>;
  @Select(AppState.postCirclesAssigned) postCirclesAssigned$: Observable<
    JoinedCircle[]
  >;

  @Input() edit = false;
  @Input() post: ConflictResolution;
  @Input() conflictedPostUid: string;
  @Input() description?: string;

  @ViewChild(PostMentionableInputComponent)
  mentionInput: PostMentionableInputComponent;
  @ViewChild(PostFileUploadComponent) fileUpload: PostFileUploadComponent;

  get title() {
    return this.postForm.get('title');
  }

  get whatsNeeded() {
    return this.postForm.get('whatsNeeded');
  }

  get whatsDesired() {
    return this.postForm.get('whatsDesired');
  }

  constructor(
    public modalCtrl: ModalController,
    private store: Store,
    private toastCtrl: ToastController,
    private alertCtrl: AlertController,
    private loadingCtrl: LoadingController,
    private analytics: AnalyticsService,
    private utils: UtilitiesService,
    private helpers: CreatePostHelpersService
  ) {}

  ngAfterViewInit(): void {
    if (this.edit === true) {
      console.log('edit post: ', this.post._UID);
      this.mentionInput.setBody(
        this.utils.removeExistingTags(this.post.BODY),
        this.post._MENTION_UIDS
      );
    } else {
      if (this.description) {
        this.mentionInput.setBody(
          this.utils.removeExistingTags(this.description),
          []
        );
      }
    }
  }

  ngOnInit() {
    this.postCirclesAssigned$.subscribe((assignedCircles: JoinedCircle[]) => {
      this.assignedCircles = assignedCircles;
    });

    if (this.edit === true) {
      // required fields
      this.topics = [...this.post.TOPICS];
      this.title.setValue(this.post.TITLE);
      this.tmpImageUrls = [...this.post.IMAGE_PATHS];
      this.tmpDocUrls = [...this.post.DOCUMENT_PATHS];
      this.assignedCircles = [...this.post.CIRCLES];
      // optional fields
      if (this.post.WHATS_NEEDED)
        this.whatsNeeded.setValue(
          this.utils.removeExistingTags(this.post.WHATS_NEEDED)
        );
      if (this.post.WHATS_DESIRED)
        this.whatsDesired.setValue(
          this.utils.removeExistingTags(this.post.WHATS_DESIRED)
        );
    }

    this.loadMentionables();
  }

  onSetTopics(topics: string[]) {
    this.topics = topics;
  }

  handleDeletePhoto(event, index: number) {
    this.tmpImageUrls.splice(index, 1);
  }

  handleDeleteDocument(index: number) {
    this.tmpDocUrls.splice(index, 1);
  }

  async createConflict() {
    const { _MENTION_UIDS, BODY } = this.mentionInput.parseMentionsAndBody();
    let photoPaths = [];
    let documentPaths = [];
    if (!BODY || BODY.trim().length === 0) {
      this.missingDataError(
        'Missing description. Please add a description and try again.'
      );
    } else {
      try {
        if (this.fileUpload) {
          photoPaths = await this.fileUpload.uploadPhotos();
          documentPaths = await this.fileUpload.uploadDocuments();
        }
      } catch (e) {
        this.analytics.logEvent('conflict_create_error', {
          error: e,
        });
        alert('Error creating post');
      }
    }

    this.analytics.logEvent('conflict_save_new', {});

    this.presentLoading();
    const uid = this.store.selectSnapshot(VillagerState.uid);
    const firstName = this.store.selectSnapshot(VillagerState.firstName);
    const lastName = this.store.selectSnapshot(VillagerState.lastName);
    const CIRCLE_UIDS = this.assignedCircles.map((x) => x.UID);
    const RECIPIENT_NOTIFICATION_SETTINGS: RecipientNotificationSettings =
      this.helpers.getNotificationRecipientsForCircle(CIRCLE_UIDS);

    let conflict: ConflictResolution = {
      _CREATOR_UID: uid,
      _CREATOR_FIRST_NAME: firstName,
      _CREATOR_LAST_NAME: lastName,
      _SERVER_TIMESTAMP:
        firebase.default.firestore.FieldValue.serverTimestamp(),
      _CREATED_AT: new Date(),
      _TYPE: 'CONFLICT',
      TITLE: this.title.value,
      BODY: this.utils.parseLinksFromText(BODY),
      WHATS_NEEDED: this.utils.parseLinksFromText(this.whatsNeeded.value),
      WHATS_DESIRED: this.utils.parseLinksFromText(this.whatsDesired.value),
      REPORTED_BY: [],
      PARTICIPANT_UIDS: [uid],
      PARTICIPANT_READ_RECEIPTS: [{ UID: uid, LAST_READ_MESSAGE_COUNT: 0 }],
      RECIPIENT_NOTIFICATION_SETTINGS,
      MESSAGE_COUNT: 0,
      UPDATED_AT: firebase.default.firestore.FieldValue.serverTimestamp(),
      IMAGE_PATHS: photoPaths,
      DOCUMENT_PATHS: documentPaths,
      TOPICS: this.topics,
      ARCHIVED: false,
      CIRCLES: this.assignedCircles,
      CIRCLE_UIDS,
      _MENTION_UIDS: _MENTION_UIDS,
    };

    if (this.conflictedPostUid) {
      conflict._CONFLICTED_POST_UID = this.conflictedPostUid;
    }

    this.store.dispatch(new CreateConflict({ post: conflict })).subscribe(
      (result) => {
        this.store.dispatch(new VillagerActions.MarkOnboardedTaskComplete({ villagerId: uid, field: "HAS_CREATED_POST" }))
        this.analytics.logEvent('conflict_save_new_success', {});
        this.presentToast('Successfully created conflict.', false);
        this.postForm.reset();
        this.modalCtrl.dismiss({ action: 'create-close' });
        this.loading.dismiss();
        this.loading = null;
      },
      (err) => {
        this.analytics.logEvent('conflict_save_new_error', {
          error: err,
        });
        console.error(err);
        this.showErrorAlert();
        this.loading.dismiss();
        this.loading = null;
      }
    );
  }

  async updateConflict() {
    const { _MENTION_UIDS, BODY } = this.mentionInput.parseMentionsAndBody();
    let newPhotoPaths = [];
    let newDocumentPaths = [];
    if (!BODY || BODY.trim().length === 0) {
      this.missingDataError(
        'Missing description. Please add a description and try again.'
      );
    } else {
      try {
        if (this.fileUpload) {
          newPhotoPaths = await this.fileUpload.uploadPhotos();
          newDocumentPaths = await this.fileUpload.uploadDocuments();
        }
      } catch (e) {
        this.analytics.logEvent('conflict_create_error', {
          error: e,
        });
        alert('Error creating post');
      }
    }

    let allImages = [...this.tmpImageUrls];
    let allDocuments = [...this.tmpDocUrls];
    if (newPhotoPaths.length > 0) {
      allImages = this.tmpImageUrls.concat(newPhotoPaths);
    }
    if (newDocumentPaths.length > 0) {
      allDocuments = this.tmpDocUrls.concat(newDocumentPaths);
    }

    this.analytics.logEvent('conflict_udpate', { shareId: this.post._UID });

    const CIRCLE_UIDS = this.assignedCircles.map((x) => x.UID);
    const RECIPIENT_NOTIFICATION_SETTINGS: RecipientNotificationSettings =
      this.helpers.getNotificationRecipientsForCircle(CIRCLE_UIDS);

    const post: ConflictResolution = {
      ...this.post,
      TITLE: this.title.value,
      BODY: this.utils.parseLinksFromText(BODY),
      WHATS_NEEDED: this.utils.parseLinksFromText(this.whatsNeeded.value),
      WHATS_DESIRED: this.utils.parseLinksFromText(this.whatsDesired.value),
      TOPICS: this.topics,
      IMAGE_PATHS: allImages,
      DOCUMENT_PATHS: allDocuments,
      RECIPIENT_NOTIFICATION_SETTINGS,
      CIRCLES: this.assignedCircles,
      CIRCLE_UIDS,
      _MENTION_UIDS
    };

    this.store.dispatch(new UpdateConflict({ post })).subscribe(
      () => {
        this.analytics.logEvent('conflict_update_success', {});
        this.presentToast('Successfully updated conflict.', true);
        this.postForm.reset();
        this.modalCtrl.dismiss({ action: null });
        this.loading.dismiss();
        this.loading = null;
      },
      (err) => {
        this.analytics.logEvent('conflict_update_error', {
          postId: this.post._UID,
          error: err,
        });
        console.error(err);
        this.showErrorAlert();
        this.loading.dismiss();
        this.loading = null;
      }
    );
  }

  async presentToast(header: string, update = false) {
    let message;
    if (update) {
      message = `You earned ${CONFLICT_KARMA} karma!`;
    }
    const toast = await this.toastCtrl.create({
      header: header,
      message,
      color: 'primary',
      duration: 3000,
      position: 'top',
    });
    toast.present();
  }

  async presentLoading() {
    if (!this.loading) {
      this.loading = await this.loadingCtrl.create({
        duration: 15000,
        backdropDismiss: true,
      });
      return this.loading.present();
    }
  }

  async showErrorAlert() {
    const alert = await this.alertCtrl.create({
      header: 'Error',
      message:
        'We ran into an error creating the post. Please check that everything is filled out and try again.',
      buttons: ['OK'],
    });

    await alert.present();
  }

  async missingDataError(message: string) {
    const alert = await this.alertCtrl.create({
      header: 'Error',
      message,
      buttons: ['OK'],
    });

    await alert.present();
  }

  async loadMentionables() {
    let tmpMentionables = [];

    const allUnblockedVillagers = this.store.selectSnapshot(
      VillagerState.allUnblockedVillagersExcludeCurrent
    );
    tmpMentionables = [
      ...allUnblockedVillagers.map((villager) => villager._UID),
    ];

    this.mentionables = [...new Set(tmpMentionables)];
    console.log('[POST BODY INPUT] Mentionables: ', this.mentionables);
  }
}
