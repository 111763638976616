<div *ngIf="hangout.START_DATETIME">
  <ion-icon name="time"></ion-icon>
  {{ hangout.START_DATETIME | date: "MMM d, h:mm a" }} {{ timezone }}
  <span *ngIf="hangout.END_DATETIME">
    -
    <div style="margin-left: 20px" >
      {{ hangout.END_DATETIME | date: "MMM d, h:mm a" }} {{ timezone }}
    </div>
  </span>

</div>
<div *ngIf="hangout.LOCATION">
  <ion-icon name="location"></ion-icon>
  <span [innerHtml]="hangout.LOCATION | safehtml"></span>
</div>
