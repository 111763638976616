<ion-header class="ion-no-border">
  <ion-toolbar>
    <!-- <ion-buttons slot="end">
      <ion-button (click)="close()">
        <ion-icon color="dark" slot="icon-only" name="close-circle"></ion-icon>
      </ion-button>
    </ion-buttons> -->
  </ion-toolbar>
</ion-header>

<ion-content [fullscreen]="true">
  <div class="cover"></div>

  <ion-card style="margin-top: 200px">
    <ion-progress-bar [value]="75"></ion-progress-bar>
    <ion-card-header>
      <ion-card-title style="font-size: 24px"
        >Turn Notifications into Connections</ion-card-title
      >
    </ion-card-header>
    <ion-card-content>
      You'll receive notifications when your fellow <app-members-label [plural]="true"></app-members-label> send you messages
      or want to connect with you!

      <div *ngIf="registered; else notRegistered">
        <ion-button
          (click)="next()"
          style="margin-top: 30px"
          color="primary"
          expand="block"
          >Next
          <ion-icon name="arrow-forward" slot="end"></ion-icon>
        </ion-button>
      </div>
      <ng-template #notRegistered>
        <ion-button
          (click)="register()"
          style="margin-top: 30px"
          color="primary"
          expand="block"
          >Ok !</ion-button
        >
        <ion-button
          (click)="notNow()"
          fill="clear"
          color="medium"
          size="small"
          expand="block"
          style="margin-top: 30px"
          >Not Now</ion-button
        >
      </ng-template>
    </ion-card-content>
  </ion-card>
</ion-content>
