<ion-fab vertical="bottom" horizontal="end" slot="fixed">
  <ion-fab-button color="primary">
    <ion-icon name="help"></ion-icon>
  </ion-fab-button>
  <ion-fab-list side="top">
    <ion-fab-button color="primary" (click)="sendSupportEmail()">
      <ion-icon name="chatbubbles-outline"></ion-icon>
    </ion-fab-button>
    <ion-fab-button color="primary" (click)="refreshApp()">
      <ion-icon name="refresh"></ion-icon>
    </ion-fab-button>
    <!-- <ion-fab-button color="primary" (click)="goToVillageAbout()">
      <ion-icon name="help-buoy-outline"></ion-icon>
    </ion-fab-button> -->
  </ion-fab-list>
</ion-fab>
