<!-- <div style="height: 40px"></div> -->

<div>
  <ion-chip
    [color]="chipInfo.color"
    style="margin-left: -5px; margin-bottom: 10px"
  >
    <ion-icon [name]="chipInfo.icon"></ion-icon>
    <ion-label>{{ chipInfo.name }}</ion-label>
  </ion-chip>
  <ion-chip [color]="chipInfo.color" *ngFor="let circle of post.CIRCLES">
    <ion-icon name="people-circle-outline"></ion-icon>
    <ion-label>{{circle.UID | circleLabel: (circles$ | async) }}</ion-label>
  </ion-chip>

  <ion-chip [color]="chipInfo.color" *ngFor="let topic of post.TOPICS">
    <ion-label>{{ topic }}</ion-label>
  </ion-chip>
</div>
<div class="subheader">
  Created {{ post._CREATED_AT | timeago }} by {{ post._CREATOR_FIRST_NAME }}
  {{ post._CREATOR_LAST_NAME }}
</div>

<div class="header" style="margin-top: 2px; font-size: 18px">
  {{ post.TITLE }}
</div>

<div *ngIf="post._TYPE == 'SHARE'">
  <app-bulletin-card-body [post]="post"></app-bulletin-card-body>
</div>

<div *ngIf="post._TYPE == 'HANGOUT'">
  <app-hangout-card-body
    [post]="post"
    [showFullBody]="true"
  ></app-hangout-card-body>
</div>

<div *ngIf="post._TYPE == 'EXCHANGE'">
  <app-exchange-card-body [post]="post"></app-exchange-card-body>
</div>

<div *ngIf="post._TYPE == 'SUPPORT'">
  <app-support-card-body [post]="post"></app-support-card-body>
</div>

<div *ngIf="post._TYPE == 'CONFLICT'">
  <app-conflict-card-body [post]="post"></app-conflict-card-body>
</div>

<div *ngIf="post._TYPE == 'ANNOUNCEMENT'">
  <app-village-announcement-card-body
    [post]="post"
  ></app-village-announcement-card-body>
</div>

<app-post-document
  *ngFor="let doc of post.DOCUMENT_PATHS"
  [document]="doc"
></app-post-document>

<app-shared-list-body
  *ngIf="post._TYPE == 'LIST'"
  [post]="post"
></app-shared-list-body>

<!-- <ion-button
  style="margin-top: 20px; float: right"
  *ngIf="(isCurrentVillager$ | async) === false"
  (click)="leaveDiscussion()"
  fill="outline"
  [color]="chipInfo.color"
>
  <ion-icon slot="start" name="log-out"></ion-icon>
  Leave Discussion
</ion-button> -->
