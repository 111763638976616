import { Injectable } from '@angular/core';
import { AnalyticsService } from '../analytics.service';
import { QuickReply } from '../models/share.model';
import { ExchangeItem } from '../models/exchange.model';
import { GenericPost } from '../models/post-core.model';

@Injectable({
  providedIn: 'root',
})
export class QuickReplyService {
  constructor(private analytics: AnalyticsService) {}

  getQuickReplies(post: GenericPost): QuickReply[] {
    if (!post) return [];
    let replies = [];
    let quickReplies: QuickReply[] = [];
    switch (post._TYPE) {
      case 'ANNOUNCEMENT':
      case 'SHARE':
        quickReplies = [
          { MESSAGE: 'I hear you', COLOR: 'tertiary' },
          { MESSAGE: 'Tell me more about how that feels', COLOR: 'primary' },
          { MESSAGE: 'I agree', COLOR: 'medium' },
          { MESSAGE: 'This resonates with me', COLOR: 'danger' },
          { MESSAGE: 'Congratulations!', COLOR: 'success' },
          { MESSAGE: 'Okay', COLOR: 'secondary' },
        ];
        break;
      case 'HANGOUT':
        quickReplies = [
          { MESSAGE: 'I would love to join!', COLOR: 'tertiary' },
          { MESSAGE: 'Where should we meet?', COLOR: 'primary' },
          { MESSAGE: 'Can I bring my kids?', COLOR: 'medium' },
          { MESSAGE: 'What can I do to help?', COLOR: 'danger' },
          { MESSAGE: 'What should I pack?', COLOR: 'success' },
          { MESSAGE: 'Okay', COLOR: 'secondary' },
        ];
        break;
      case 'EXCHANGE':
        let exchange = post as ExchangeItem;
        if (exchange._EXCHANGE_TYPE === 'OFFERING') {
          quickReplies = [
            { MESSAGE: 'I am interested!', COLOR: 'tertiary' },
            { MESSAGE: 'What can I do to help?', COLOR: 'primary' },
            { MESSAGE: 'Sounds good!', COLOR: 'medium' },
            { MESSAGE: 'Okay', COLOR: 'danger' },
            { MESSAGE: 'Do you want to trade?', COLOR: 'success' },
            { MESSAGE: 'Is this still available?', COLOR: 'secondary' },
          ];
        } else {
          quickReplies = [
            { MESSAGE: 'I am interested!', COLOR: 'tertiary' },
            { MESSAGE: "Let's talk in person", COLOR: 'primary' },
            { MESSAGE: 'Sounds good!', COLOR: 'medium' },
            { MESSAGE: 'Okay', COLOR: 'danger' },
            { MESSAGE: 'I can help!', COLOR: 'success' },
            { MESSAGE: 'Do you want to trade?', COLOR: 'secondary' },
          ];
        }
        break;
      case 'SUPPORT':
        quickReplies = [
          { MESSAGE: 'How does that make you feel?', COLOR: 'tertiary' },
          { MESSAGE: 'How can I support you best?', COLOR: 'primary' },
          { MESSAGE: "I'm here for you", COLOR: 'medium' },
          { MESSAGE: 'Would you like to meet in-person?', COLOR: 'danger' },
          { MESSAGE: 'I feel you', COLOR: 'success' },
          { MESSAGE: 'What would you like to do?', COLOR: 'secondary' },
        ];
        break;
      case 'CONFLICT':
        quickReplies = [
          { MESSAGE: 'How does that make you feel?', COLOR: 'tertiary' },
          { MESSAGE: 'How can I support you best?', COLOR: 'primary' },
          { MESSAGE: 'Thanks for sharing this', COLOR: 'medium' },
          { MESSAGE: 'Would you like to chat in-person?', COLOR: 'danger' },
          { MESSAGE: 'I feel the same way', COLOR: 'success' },
          {
            MESSAGE: `I see where you're both coming from`,
            COLOR: 'secondary',
          },
        ];
        break;
      case 'LIST':
        return replies;
      default:
        this.analytics.logEvent('quick_replies_post_type_error', {
          postId: post._UID,
        });
        return replies;
    }
    return this.getRandom(quickReplies, 4);
  }

  private getRandom(arr: any[], n: number) {
    var result = new Array(n),
      len = arr.length,
      taken = new Array(len);
    if (n > len)
      throw new RangeError('getRandom: more elements taken than available');
    while (n--) {
      var x = Math.floor(Math.random() * len);
      result[n] = arr[x in taken ? taken[x] : x];
      taken[x] = --len in taken ? taken[len] : len;
    }
    return result;
  }
}
