<!-- ngClass="{'has-rections': true}" -->
<div *ngIf="message" class="message-wrapper has-reactions">
  <div
    *ngIf="shouldShowTimeago()"
    class="message-timestamp"
    [ngClass]="
      message._CREATOR_UID === currentUserUid
        ? 'user-timestamp'
        : 'other-timestamp'
    "
  >
    {{ message._CREATED_AT | timeago }}
  </div>
  <div
    [id]="message._UID"
    class="message"
    [ngClass]="
      message._CREATOR_UID === currentUserUid ? 'user-message' : 'other-message'
    "
  >
    <div
      *ngIf="message._CREATOR_UID !== currentUserUid && message.SHOW_AVATAR"
      class="message-profile-pic"
    >
      <app-villager-small-avatar
        [firstName]="message._CREATOR_FIRST_NAME"
        [lastName]="message._CREATOR_LAST_NAME"
        [uid]="message._CREATOR_UID"
      ></app-villager-small-avatar>
    </div>

    <strong
      *ngIf="message._CREATOR_UID !== currentUserUid && message.SHOW_AVATAR"
      style="font-size: 0.8em"
      >{{ message._CREATOR_FIRST_NAME }} <br
    /></strong>
    <div *ngIf="message.DELETED; else showMessage" class="deleted message-body">
      <em>This message was deleted</em>
    </div>
    <ng-template #showMessage>
      <div
        (click)="onClickMessage($event)"
        class="message-body"
        [ngSwitch]="_MESSAGE_TYPE"
        [ngClass]="
          message._CREATOR_UID === currentUserUid
            ? 'user-message'
            : 'other-message'
        "
        [ngClass]="_MESSAGE_TYPE === 'photo' ? 'image-message' : ''"
      >
        <!-- start of message images -->
        <div *ngSwitchCase="'photo'" class="message-image-container">
          <div *ngFor="let url of message._IMAGE_URLS">
            <app-chat-image
              [url]="url"
              [message]="message"
              [type]="type"
              [isOwner]="message._CREATOR_UID === currentUserUid"
            ></app-chat-image>
          </div>

          <div *ngIf="message._BODY !== 'null'">
            <div
              class="image-text"
              [innerHtml]="
                message._BODY | mentionified: message._MENTION_UIDS | safehtml
              "
            ></div>
          </div>
        </div>
        <!-- end of message images -->
        <!-- start of message voice note -->
        <div *ngSwitchCase="'voice_note'">
          <app-chat-voice-note
            [voiceNotePath]="message._VOICE_NOTE_URL"
            [duration]="message._VOICE_NOTE_DURATION"
            [messageBody]="message._BODY | mentionified: message._MENTION_UIDS"
          ></app-chat-voice-note>
        </div>
        <!-- end of message voice note -->
        <!-- start of message text -->
        <div *ngSwitchCase="'text'">
          <div *ngIf="message._BODY !== 'null'">
            <span
              [innerHtml]="
                message._BODY | mentionified: message._MENTION_UIDS | safehtml
              "
            ></span>
          </div>
        </div>
        <!-- end of message text -->
        <!-- start of message replies (with image, voice & text) -->
        <div *ngSwitchCase="'reply'">
          <div *ngIf="message._REPLIED_TO_UID">
            <span
              style="
                font-weight: bold;
                font-size: 12px;
                display: block;
                margin-bottom: 5px;
              "
              >Reply to
              {{
                message._REPLIED_TO_CREATOR_UID
                  | villageridToName: (allVillagers$ | async)
              }}</span
            >
            <app-chat-voice-note
              *ngIf="message._REPLIED_TO_VOICE_NOTE"
              [voiceNotePath]="message._REPLIED_TO_VOICE_NOTE_URL"
              [duration]="message._REPLIED_TO_VOICE_NOTE_DURATION"
            >
            </app-chat-voice-note>

            <div *ngFor="let url of message._REPLIED_TO_IMAGE_URLS">
              <app-chat-image
                [url]="url"
                [message]="message"
                [type]="type"
              ></app-chat-image>
            </div>

            <div class="reply">
              <ion-text
                style="display: block"
                *ngIf="message._REPLIED_TO_BODY"
                color="medium"
                style="font-size: 0.8em"
                [innerHtml]="
                  message._REPLIED_TO_BODY
                    | mentionified: message._MENTION_UIDS
                    | safehtml
                "
              ></ion-text>
            </div>

            <ion-text
              style="display: block"
              *ngIf="message._BODY"
              [innerHtml]="
                message._BODY | mentionified: message._MENTION_UIDS | safehtml
              "
            ></ion-text>
          </div>
        </div>
        <!-- end of message replies (with image, voice & text) -->
      </div>
    </ng-template>
  </div>

  <div
    *ngIf="!message.DELETED"
    [ngClass]="
      message._CREATOR_UID === currentUserUid ? 'user-message' : 'other-message'
    "
  >
    <ion-chip
      outline="true"
      color="dark"
      *ngFor="let reaction of calculatedReactions | keyvalue"
    >
      <ion-label (click)="react(reaction.key)">
        {{ reaction.key }}
        <span style="padding-left: 5px" *ngIf="reaction.value > 1">{{
          reaction.value
        }}</span>
      </ion-label>
    </ion-chip>
  </div>
</div>
