import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Store } from '@ngxs/store';
import { debounceTime } from 'rxjs/operators';
import { VillageState } from 'src/app/state/village.state';

@Component({
  selector: 'app-add-topics',
  templateUrl: './add-topics.component.html',
  styleUrls: ['./add-topics.component.scss'],
})
export class AddTopicsComponent implements OnInit {
  // newTopic: string = null;

  topicForm = new FormGroup({
    newTopic: new FormControl(''),
  });

  get newTopic() {
    return this.topicForm.get('newTopic');
  }

  mentionConfig = {
    triggerChar: '#',
    items: [],
    dropUp: true,
    mentionSelect: (value: { label: string }) => this.selectTopic(value),
  };

  @Input() topics: string[] = [];
  @Output() setTopics: EventEmitter<string[]> = new EventEmitter<string[]>();
  constructor(private store: Store) {}

  ngOnInit() {
    const villageTopics = this.store.selectSnapshot(VillageState.topics);
    this.mentionConfig.items = villageTopics;

    this.newTopic.valueChanges.pipe(debounceTime(5)).subscribe((value) => {
      if (!value) return;
      value = value.toLowerCase();
      this.newTopic.setValue(value, { emitEvent: false });
    });
  }

  selectTopic(value: { label: string }): string {
    console.log('Selected topic: ', value);
    if (!value) return;
    const multiples = value.label.split('#');
    multiples.forEach((x) => {
      if (!x) return;
      this.parseInput(x);
    });
    return '';
  }

  addTopic() {
    if (!this.newTopic.value) return;

    const multiples = this.newTopic.value.split('#');
    multiples.forEach((x) => {
      if (!x) return;
      this.parseInput(x);
    });
    this.newTopic.reset();
  }

  parseInput(value: string) {
    console.log('Adding topic: ', value);
    let temp = value.replace(/\s/g, '');
    console.log('Trimmed topic: ', temp);
    if (temp.charAt(0) !== '#') temp = `#${temp}`;
    const newSet = [...this.topics];
    newSet.push(temp);
    console.log('New Set: ', newSet);
    this.topics = [...new Set(newSet)];
    this.setTopics.emit(this.topics);
    this.newTopic.reset();
  }

  removeTopic(topic: string) {
    console.log('Removing topic: ', topic);
    const idx = this.topics.indexOf(topic);
    this.topics.splice(idx, 1);
    console.log('New topics: ', this.topics);
  }
}
