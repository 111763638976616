<ion-button
  [style.margin-top]="iconOnly ? '' : '10px'"
  [expand]="iconOnly ? '' : 'block'"
  [color]="iconOnly ? 'dark' : 'primary'"
  (click)="showJoinCodeAlert()"
>
  <ion-label *ngIf="!iconOnly">Join a&nbsp;<app-village-label [showTitlecase]="true"></app-village-label></ion-label>
  <ion-icon
    [slot]="iconOnly ? 'icon-only' : 'end'"
    name="enter-outline"
  ></ion-icon>
</ion-button>
