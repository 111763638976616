import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import {
  Connected,
  Disconnected,
  Disconnect,
  Emitted,
  NgxsFirestoreConnect,
  StreamConnected,
  StreamDisconnected,
  StreamEmitted,
} from '@ngxs-labs/firestore-plugin';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { catchError, concatMap, tap } from 'rxjs/operators';
import {
  PushRecipient,
  Village,
  VillageStateModel,
} from '../models/village.model';
import { InviteCodeService } from '../services/invite-code.service';
import { ShareService } from '../services/share.service';
import { VillageService } from '../services/village.service';
import { ExchangeService } from '../services/exchange.service';
import {
  AddNameToVillage,
  AddVillagerToVillage,
  BuildFeed,
  ClearVillage,
  CreateEmptyVillage,
  CreateNewVillage,
  FetchShares,
  FetchConflicts,
  FetchExchanges,
  FetchHangouts,
  FetchSupportRequests,
  GetFellowVillagers,
  GetVillage,
  FeedBuildComplete,
  RefreshVillageAndVillager,
  RemoveVillagerFromVillage,
  FetchAnnouncements,
  UpdateVillageTopics,
  FetchSharedLists,
  GetInitialVillageData,
  GetVillageBulletinBoard,
  JoinVillage,
  AppBoardDataLoaded,
  UpdateVillage,
} from './app.actions';
import { CircleActions } from './circle.actions';
import { CourtyardActions } from './courtyard.actions';
import { MemberInviteActions } from './member-invite.actions';
import { VillagerState } from './villager.state';
import { ConflictResolutionService } from '../services/conflict-resolution.service';
import { SupportRequestService } from '../services/support-request.service';

@State<VillageStateModel>({
  name: 'village',
  defaults: {
    currentVillage: null,
  },
})
@Injectable()
export class VillageState {
  alert: HTMLIonAlertElement;

  constructor(
    private villageSvc: VillageService,
    private ngxsFirestoreConnect: NgxsFirestoreConnect,
    private store: Store,
    private inviteCodeSvc: InviteCodeService,
    private alertCtrl: AlertController,
    private shareSvc: ShareService,
    private exchangeSvc: ExchangeService,
    private conflictSvc: ConflictResolutionService,
    private supportSvc: SupportRequestService
  ) {}

  ngxsOnInit() {
    this.ngxsFirestoreConnect.connect(GetVillage, {
      to: (action) => this.villageSvc.doc$(action.payload.uid),
      cancelPrevious: true,
    });
  }

  @Selector()
  static currentVillage(state: VillageStateModel): Village {
    return state.currentVillage;
  }

  @Selector()
  static villageCreator(state: VillageStateModel): string {
    return state.currentVillage.CREATED_BY;
  }

  @Selector()
  static villageName(state: VillageStateModel): string {
    return state.currentVillage.NAME;
  }

  @Selector()
  static villageCode(state: VillageStateModel): string {
    return state.currentVillage.INVITE_CODE;
  }

  @Selector()
  static uid(state: VillageStateModel): string {
    return state.currentVillage._UID;
  }

  @Selector()
  static vision(state: VillageStateModel): string {
    return state.currentVillage.VISION;
  }

  @Selector()
  static values(state: VillageStateModel): string {
    return state.currentVillage.VALUES;
  }

  @Selector()
  static topics(state: VillageStateModel): string[] {
    return state.currentVillage.TOPICS;
  }

  @Selector()
  static showEmails(state: VillageStateModel): boolean {
    return state.currentVillage.CONFIG_SHOW_EMAIL;
  }

  @Selector()
  static showConflictResolution(state: VillageStateModel): boolean {
    return state.currentVillage.CONFIG_SHOW_CONFLICT_RES;
  }

  @Selector()
  static showCreateCircle(state: VillageStateModel): boolean {
    const { CONFIG_SHOW_CREATE_CIRCLE } = state.currentVillage;
    return CONFIG_SHOW_CREATE_CIRCLE ? CONFIG_SHOW_CREATE_CIRCLE : false;
  }

  @Selector()
  static showSwitchVillage(state: VillageStateModel): boolean {
    return state.currentVillage.CONFIG_SHOW_SWITCH_VILLAGE;
  }

  @Selector()
  static hasProAccess(state: VillageStateModel): boolean {
    return state.currentVillage.CONFIG_SHOW_CREATE_CIRCLE; // can be updated to something for official later
  }

  @Selector()
  static configVillageLabel(state: VillageStateModel): string {
    const { CONFIG_VILLAGE_LABEL } = state.currentVillage;
    return CONFIG_VILLAGE_LABEL ? CONFIG_VILLAGE_LABEL : 'community';
  }

  @Selector()
  static courtyardsLabel(state: VillageStateModel): string {
    const { CONFIG_COURTYARDS_LABEL } = state.currentVillage;
    return CONFIG_COURTYARDS_LABEL ? CONFIG_COURTYARDS_LABEL : 'channel';
  }

  @Selector()
  static membersLabel(state: VillageStateModel): string {
    const { CONFIG_MEMBERS_LABEL } = state.currentVillage;
    return CONFIG_MEMBERS_LABEL ? CONFIG_MEMBERS_LABEL : 'member';
  }

  @Selector()
  static stewardsLabel(state: VillageStateModel): string {
    const { CONFIG_STEWARDS_LABEL } = state.currentVillage;
    return CONFIG_STEWARDS_LABEL ? CONFIG_STEWARDS_LABEL : 'steward';
  }

  @Selector()
  static karmaLabel(state: VillageStateModel): string {
    const { CONFIG_KARMA_LABEL } = state.currentVillage;
    return CONFIG_KARMA_LABEL ? CONFIG_KARMA_LABEL : 'karma';
  }

  @Selector()
  static showKarma(state: VillageStateModel): boolean {
    const { CONFIG_SHOW_KARMA } = state.currentVillage;
    return CONFIG_SHOW_KARMA ? CONFIG_SHOW_KARMA : false;
  }

  @Selector()
  static showPotluck(state: VillageStateModel): boolean {
    const { CONFIG_SHOW_POTLUCK } = state.currentVillage;
    return CONFIG_SHOW_POTLUCK ? CONFIG_SHOW_POTLUCK : false;
  }

  @Selector()
  static showCoffee(state: VillageStateModel): boolean {
    const { CONFIG_SHOW_COFFEE } = state.currentVillage;
    return CONFIG_SHOW_COFFEE ? CONFIG_SHOW_COFFEE : false;
  }

  @Selector()
  static showParty(state: VillageStateModel): boolean {
    const { CONFIG_SHOW_PARTY } = state.currentVillage;
    return CONFIG_SHOW_PARTY ? CONFIG_SHOW_PARTY : false;
  }

  @Selector()
  static showAdventure(state: VillageStateModel): boolean {
    const { CONFIG_SHOW_ADVENTURE } = state.currentVillage;
    return CONFIG_SHOW_ADVENTURE ? CONFIG_SHOW_ADVENTURE : false;
  }

  @Selector()
  static showDuo(state: VillageStateModel): boolean {
    const { CONFIG_SHOW_DUO } = state.currentVillage;
    return CONFIG_SHOW_DUO ? CONFIG_SHOW_DUO : false;
  }

  @Selector()
  static showTrio(state: VillageStateModel): boolean {
    const { CONFIG_SHOW_TRIO } = state.currentVillage;
    return CONFIG_SHOW_TRIO ? CONFIG_SHOW_TRIO : false;
  }

  @Selector()
  static showEmotionalSupport(state: VillageStateModel): boolean {
    const { CONFIG_SHOW_EMOTIONAL_SUPPORT } = state.currentVillage;
    return CONFIG_SHOW_EMOTIONAL_SUPPORT
      ? CONFIG_SHOW_EMOTIONAL_SUPPORT
      : false;
  }

  @Selector()
  static showOffer(state: VillageStateModel): boolean {
    const { CONFIG_SHOW_OFFER } = state.currentVillage;
    return CONFIG_SHOW_OFFER ? CONFIG_SHOW_OFFER : false;
  }

  @Selector()
  static showRequest(state: VillageStateModel): boolean {
    const { CONFIG_SHOW_REQUEST } = state.currentVillage;
    return CONFIG_SHOW_REQUEST ? CONFIG_SHOW_REQUEST : false;
  }

  @Selector()
  static showSharedList(state: VillageStateModel): boolean {
    const { CONFIG_SHOW_SHARED_LIST } = state.currentVillage;
    return CONFIG_SHOW_SHARED_LIST ? CONFIG_SHOW_SHARED_LIST : false;
  }

  @Selector()
  static isPrivateWhitelabel(state: VillageStateModel): boolean {
    const { CONFIG_IS_PRIVATE_WHITELABEL } = state.currentVillage;
    return CONFIG_IS_PRIVATE_WHITELABEL ? CONFIG_IS_PRIVATE_WHITELABEL : false;
  }

  @Selector()
  static villagersCanInvite(state: VillageStateModel): boolean {
    const { CONFIG_VILLAGERS_CAN_INVITE } = state.currentVillage;
    return CONFIG_VILLAGERS_CAN_INVITE ? CONFIG_VILLAGERS_CAN_INVITE : false;
  }

  @Selector()
  static allowCircleJoin(state: VillageStateModel): boolean {
    const { CONFIG_ALLOW_CIRCLE_JOIN } = state.currentVillage;
    return CONFIG_ALLOW_CIRCLE_JOIN ? CONFIG_ALLOW_CIRCLE_JOIN : false;
  }

  @Selector()
  static guideVideoUrls(state: VillageStateModel): any {
    const { VIDEO_URL_WELCOME, VIDEO_URL_OVERVIEW, VIDEO_URL_APP_TUTORIAL } =
      state.currentVillage;
    return {
      welcomeVideoUrl:
        VIDEO_URL_WELCOME || 'https://www.youtube.com/embed/2sIKIBesgHQ',
      overviewVideoUrl:
        VIDEO_URL_OVERVIEW || 'https://www.youtube.com/embed/z5fCGAxOSG0',
      appTutorialVideoUrl:
        VIDEO_URL_APP_TUTORIAL || 'https://www.youtube.com/embed/dLWRJ3-DEDk',
    };
  }

  @Selector()
  static villagerCanAnnounce(
    state: VillageStateModel
  ): (villagerId: string) => boolean {
    const { CONFIG_CAN_ANNOUNCE } = state.currentVillage;
    return (villagerId: string) => CONFIG_CAN_ANNOUNCE.includes(villagerId);
  }

  @Selector()
  static canManageVillagers(
    state: VillageStateModel
  ): (villagerId: string) => boolean {
    const { CONFIG_CAN_MANAGE_VILLAGERS } = state.currentVillage;
    return (villagerId: string) =>
      CONFIG_CAN_MANAGE_VILLAGERS.includes(villagerId);
  }

  @Selector()
  static canManagePosts(
    state: VillageStateModel
  ): (villagerId: string) => boolean {
    const { CONFIG_CAN_MANAGE_POSTS } = state.currentVillage;
    return (villagerId: string) => CONFIG_CAN_MANAGE_POSTS.includes(villagerId);
  }

  @Selector()
  static canManageCourtyards(
    state: VillageStateModel
  ): (villagerId: string) => boolean {
    const { CONFIG_CAN_MANAGE_COURTYARDS } = state.currentVillage;
    return (villagerId: string) =>
      CONFIG_CAN_MANAGE_COURTYARDS.includes(villagerId);
  }

  @Selector()
  static canInviteVillagers(
    state: VillageStateModel
  ): (villagerId: string) => boolean {
    const { CONFIG_CAN_INVITE_VILLAGERS } = state.currentVillage;
    console.log('can invite villagers state', CONFIG_CAN_INVITE_VILLAGERS);
    return (villagerId: string) =>
      CONFIG_CAN_INVITE_VILLAGERS.includes(villagerId);
  }

  @Selector()
  static isCurrentVillage(
    state: VillageStateModel
  ): (villageId: string) => boolean {
    return (villageId: string) => {
      if (!state.currentVillage) return false;
      return villageId === state.currentVillage._UID;
    };
  }

  @Selector()
  static postCount(state: VillageStateModel): number {
    const { currentVillage } = state;
    const {
      _POST_COUNT,
      _EXCHANGE_COUNT,
      _CONFLICT_COUNT,
      _SUPPORT_COUNT,
      _HANGOUT_COUNT,
    } = currentVillage;
    return [
      _POST_COUNT,
      _EXCHANGE_COUNT,
      _CONFLICT_COUNT,
      _SUPPORT_COUNT,
      _HANGOUT_COUNT,
    ].reduce((sum, x) => sum + x, 0);
  }

  @Selector()
  static duoLabel(state: VillageStateModel): string {
    const { CONFIG_DUO_LABEL } = state.currentVillage;
    return CONFIG_DUO_LABEL ? CONFIG_DUO_LABEL : 'Duo';
  }

  @Selector()
  static trioLabel(state: VillageStateModel): string {
    const { CONFIG_TRIO_LABEL } = state.currentVillage;
    return CONFIG_TRIO_LABEL ? CONFIG_TRIO_LABEL : 'Trio';
  }

  @Selector()
  static theme(
    state: VillageStateModel
  ): 'revillager' | 'northern' | 'southern' {
    const { THEME } = state.currentVillage;
    console.log('[VILLAGE STATE] theme', THEME ? THEME : 'revillager');
    return THEME ? THEME : 'revillager';
  }

  @Action(AddNameToVillage)
  addNameToVillage(
    ctx: StateContext<VillageStateModel>,
    action: AddNameToVillage
  ) {
    return this.villageSvc.updateWithoutConverter(action.payload.villageUID, {
      NAME: action.payload.name,
    });
  }

  @Action(CreateEmptyVillage)
  async createEmptyVillage(
    ctx: StateContext<VillageStateModel>,
    action: CreateEmptyVillage
  ) {
    const villagers: string[] = [action.payload.creatorUID];
    const village: Village = {
      _UID: action.payload.villageUID,
      NAME: '',
      VALUES: '',
      VISION: '',
      CREATED_AT: new Date(),
      CREATED_BY: action.payload.creatorUID,
      INVITE_CODE: await this.inviteCodeSvc.generateCode(8),
      TYPE: 'OTHER',
      _POST_COUNT: 0,
      _SUPPORT_COUNT: 0,
      _EXCHANGE_COUNT: 0,
      _HANGOUT_COUNT: 0,
      _CONFLICT_COUNT: 0,
      _ANNOUNCEMENT_COUNT: 0,
      _COURTYARD_COUNT: 0,
      _LIST_COUNT: 0,
      VILLAGERS: villagers,
      PUSH_RECIPIENTS: [],
      TOPICS: [],
      CONFIG_SHOW_EMAIL: true,
      CONFIG_SHOW_CONFLICT_RES: true,
      CONFIG_SHOW_CREATE_CIRCLE: false,
      CONFIG_SHOW_SWITCH_VILLAGE: true,
      THEME: 'revillager',
    };

    return this.villageSvc.create$(village).pipe(
      tap(() => {
        ctx.patchState({
          currentVillage: village,
        });
        console.log('[Village State] successfully created village: ', village);
      })
    );
  }

  @Action(AddVillagerToVillage)
  addVillagerToVillage(
    ctx: StateContext<VillageStateModel>,
    action: AddVillagerToVillage
  ) {
    const { village, villager } = action.payload;

    // 1. add villager to village.VILLAGERS
    const newVillagers = [...new Set(village.VILLAGERS)];
    newVillagers.push(villager._UID);

    // 2. if villager has push enabled, add to village.PUSH_RECIPIENTS
    const pushRecipients: PushRecipient[] = [...village.PUSH_RECIPIENTS];
    if (villager.NOTIFICATIONS_ALLOWED && villager.FCM_TOKEN) {
      pushRecipients.push({
        UID: villager._UID,
        FCM_TOKEN: villager.FCM_TOKEN,
      });
    }

    // 3. save changes
    return this.villageSvc.updateWithoutConverter(village._UID, {
      VILLAGERS: newVillagers,
      PUSH_RECIPIENTS: pushRecipients,
    });
  }

  @Action(RemoveVillagerFromVillage)
  removeVillagerFromVillage(
    ctx: StateContext<VillageStateModel>,
    action: RemoveVillagerFromVillage
  ) {
    const { village, villager } = action.payload;
    const villagers = [...village.VILLAGERS];
    const idx = villagers.indexOf(villager._UID);
    if (idx > -1) {
      villagers.splice(idx, 1);
    }

    let pushRecipients = [...village.PUSH_RECIPIENTS];
    pushRecipients = pushRecipients.filter(
      (recipient) => recipient.UID !== villager._UID
    );

    return this.villageSvc.updateWithoutConverter(village._UID, {
      VILLAGERS: villagers,
      PUSH_RECIPIENTS: pushRecipients,
    });
  }

  @Action(CreateNewVillage)
  createNewVillage(
    ctx: StateContext<VillageStateModel>,
    action: CreateNewVillage
  ) {
    const { village } = action.payload;

    /**
     * Creating a village document triggers some firebase functions to run
     * which create courtyards and circles
     */

    return this.villageSvc.create$(village).pipe(
      tap(async () => {
        ctx.patchState({
          currentVillage: village,
        });

        const villager = this.store.selectSnapshot(
          VillagerState.currentVillager
        );

        /**
         * Set updateVillageCircles to false because the server handles
         * onCreate for a new village which creates the CIRCLES subcollection
         */
        this.store
          .dispatch(
            new JoinVillage({
              villager,
              village,
              updateVillageCircles: false,
            })
          )
          .subscribe(() => {});
      })
    );
  }

  @Action(UpdateVillage)
  updateEthos(ctx: StateContext<VillageStateModel>, action: UpdateVillage) {
    const { villageUID, village } = action.payload;
    if (!villageUID) {
      console.error('Missing Id: ', villageUID);
      alert('Error Updating Village');
      return false;
    }
    return this.villageSvc.update$(villageUID, village);
  }

  @Action(UpdateVillageTopics)
  updateTopics(
    ctx: StateContext<VillageStateModel>,
    action: UpdateVillageTopics
  ) {
    const { currentVillage } = ctx.getState();
    const { topics } = action.payload;
    if (topics && topics.length > 0) {
      const currentTopics = currentVillage.TOPICS;
      console.log('[Village State] Updating existing topics: ', currentTopics);

      const updatedTopics = [...new Set([...currentTopics, ...topics])];

      console.log('[Village State] Updating topics: ', updatedTopics);

      return this.villageSvc.updateWithoutConverter(currentVillage._UID, {
        TOPICS: updatedTopics,
      });
    }
  }

  @Action(ClearVillage)
  clearVillage(ctx: StateContext<VillageStateModel>) {
    ctx.setState({
      currentVillage: null,
    });
    console.log('[Village State] successfully cleared village');
  }

  @Action(GetVillage)
  getVillage(ctx: StateContext<VillageStateModel>, action: GetVillage) {}

  @Action(GetInitialVillageData)
  getInitialData(
    ctx: StateContext<VillageStateModel>,
    { payload: { uid, villagerCircleIds } }
  ) {
    // console.log(
    //   '[DEBUG] [Village State] Getting initial village data for village: ',
    //   uid,
    //   villagerCircleIds
    // );

    // its possible for this to be undefined in some edges where someone created an account
    // on 0.5.0 after the migration script ran
    if (!villagerCircleIds) {
      villagerCircleIds = ['MEMBERS'];
    } else if (villagerCircleIds && villagerCircleIds.length === 0) {
      villagerCircleIds = ['MEMBERS'];
    }

    // ensure these defaults are set because their state isnt fetched yet
    this.shareSvc.setVillageId(uid);
    this.exchangeSvc.setVillageId(uid);
    this.conflictSvc.setVillageId(uid);
    this.supportSvc.setVillageId(uid);

    return this.store
      .dispatch([
        new FetchAnnouncements({ villageUID: uid, villagerCircleIds }),
        new FetchSharedLists({ villageUID: uid, villagerCircleIds }),
        new FetchHangouts({ villageUID: uid, villagerCircleIds }),
        new CourtyardActions.FetchAll({ villageId: uid }),
        new CircleActions.FetchAll({ villageId: uid }),
        new MemberInviteActions.FetchAll({ villageId: uid }),
      ])
      .pipe(
        catchError((err) => {
          console.error('Error loading initial data: ', err);
          // this.showErrorAlert();
          return 'done';
        })
      );
  }

  @Action(GetVillageBulletinBoard)
  getBulletinBoard(
    ctx: StateContext<VillageStateModel>,
    action: GetVillageBulletinBoard
  ) {
    const { uid, villagerCircleIds } = action.payload;
    let patchedVillagerCircleIds = villagerCircleIds;
    // its possible for this to be undefined in some edges where someone created an account
    // on 0.5.0 after the migration script ran
    if (!villagerCircleIds) {
      patchedVillagerCircleIds = ['MEMBERS'];
    } else if (villagerCircleIds && villagerCircleIds.length === 0) {
      patchedVillagerCircleIds = ['MEMBERS'];
    }
    return this.store
      .dispatch([
        new Disconnect(FetchShares),
        new Disconnect(FetchHangouts),
        new Disconnect(FetchSupportRequests),
        new Disconnect(FetchExchanges),
        new Disconnect(FetchAnnouncements),
        new Disconnect(FetchConflicts),
        new Disconnect(FetchSharedLists),
      ])
      .pipe(
        concatMap(() =>
          this.store.dispatch([
            new FetchShares({
              villageUID: uid,
              villagerCircleIds: patchedVillagerCircleIds,
            }),
            new FetchHangouts({
              villageUID: uid,
              villagerCircleIds: patchedVillagerCircleIds,
            }),
            new FetchSupportRequests({
              villageUID: uid,
              villagerCircleIds: patchedVillagerCircleIds,
            }),
            new FetchExchanges({
              villageUID: uid,
              villagerCircleIds: patchedVillagerCircleIds,
            }),
            new FetchConflicts({
              villageUID: uid,
              villagerCircleIds: patchedVillagerCircleIds,
            }),
            new BuildFeed(),
            new FeedBuildComplete(),
            new AppBoardDataLoaded(),
          ])
        )
      );
  }

  @Action(RefreshVillageAndVillager)
  refreshVillage(
    ctx: StateContext<VillageStateModel>,
    action: RefreshVillageAndVillager
  ) {
    console.log('[Village State] Getting village & connecting subcollections');
    const { uid } = action.payload;
    return this.store
      .dispatch([
        new Disconnect(GetVillage),
        new Disconnect(GetInitialVillageData),
        new Disconnect(GetVillageBulletinBoard),
        new Disconnect(FetchShares),
        new Disconnect(FetchHangouts),
        new Disconnect(FetchSupportRequests),
        new Disconnect(FetchExchanges),
        new Disconnect(FetchAnnouncements),
        new Disconnect(FetchConflicts),
        new Disconnect(FetchSharedLists),
        new Disconnect(CourtyardActions.FetchAll),
        new Disconnect(GetFellowVillagers),
      ])
      .pipe(
        concatMap(() =>
          this.store.dispatch([
            new GetFellowVillagers({ villageUid: uid }),
            new GetVillage({ uid }),
          ])
        )
      );
  }

  @Action(StreamConnected(GetVillage))
  streamConnected(
    ctx: StateContext<VillageStateModel>,
    { action }: Connected<GetVillage>
  ) {
    // alert('Village Connected');
    console.log('[Village State] Stream connected');
  }

  @Action(StreamDisconnected(GetVillage))
  streamDisconncted(
    ctx: StateContext<VillageStateModel>,
    { action }: Disconnected<GetVillage>
  ) {
    // alert('Village Disconnected');
    console.log('[Village State] Stream Disconnected');
  }

  @Action(StreamEmitted(GetVillage))
  streamEmitted(
    ctx: StateContext<VillageStateModel>,
    { action, payload }: Emitted<GetVillage, Village>
  ) {
    ctx.patchState({
      currentVillage: payload,
    });
    console.log('[Village State] current village emitted: ', payload);
  }

  async showErrorAlert() {
    if (this.alert) {
      this.alert.dismiss();
    }
    this.alert = await this.alertCtrl.create({
      header: 'Error',
      message: 'We ran into an error loading app data. Please refresh.',
      buttons: ['OK'],
    });

    await this.alert.present();
  }
}
