import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  ModalController,
  ToastController,
  AlertController,
  LoadingController,
} from '@ionic/angular';
import { Select, Store } from '@ngxs/store';
import { Villager } from 'src/app/models/villager.model';
import { Observable } from 'rxjs';
import { AnalyticsService } from 'src/app/analytics.service';
import { SharedList, SharedListItem } from 'src/app/models/shared-list.model';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { VillageState } from 'src/app/state/village.state';
import { VillagerState } from 'src/app/state/villager.state';
import * as firebase from 'firebase';
import 'firebase/firestore';
import { ReadReceipt } from 'src/app/models/read-receipt.model';
import { CreateSharedList, UpdateSharedList } from 'src/app/state/app.actions';
import { JoinedCircle } from 'src/app/models/circle.model';
import { AppState } from 'src/app/state/app.state';
import { map } from 'rxjs/operators';
import { RecipientNotificationSettings } from 'src/app/models/notification-settings';
import { CreatePostHelpersService } from 'src/app/services/create-post-helpers.service';
import { PostMentionableInputComponent } from 'src/app/shared/post-mentionable-input/post-mentionable-input.component';
import { VillagerActions } from 'src/app/state/villager.actions';

@Component({
  selector: 'app-create-list-modal',
  templateUrl: './create-list-modal.page.html',
  styleUrls: ['./create-list-modal.page.scss'],
})
export class CreateListModalPage implements OnInit, AfterViewInit {
  listForm = new FormGroup({
    title: new FormControl('', [Validators.required]),
  });
  creator: Villager;
  loading: any;
  listItems: SharedListItem[] = [];
  assignedCircles: JoinedCircle[] = [];

  placeholderText = 'Describe your list (if desired...)'

  mentionables: string[] = [];
  readOnly: boolean = false;

  @Select(VillageState.villageName) villageName$: Observable<string>;
  @Select(AppState.postCirclesAssigned) postCirclesAssigned$: Observable<
    JoinedCircle[]
  >;

  @Input() edit = false;
  @Input() post: SharedList;

  get title() {
    return this.listForm.get('title');
  }

  @ViewChild(PostMentionableInputComponent)
  mentionInput: PostMentionableInputComponent;

  constructor(
    public modalCtrl: ModalController,
    private store: Store,
    private toastCtrl: ToastController,
    private alertCtrl: AlertController,
    private loadingCtrl: LoadingController,
    private analytics: AnalyticsService,
    private utils: UtilitiesService,
    private helpers: CreatePostHelpersService
  ) {}

  ngAfterViewInit(): void {
    if (this.edit === true) {
      console.log('edit post: ', this.post._UID);
      this.mentionInput.setBody(this.utils.removeExistingTags(this.post.BODY), this.post._MENTION_UIDS);
    }
  }

  ngOnInit() {
    this.postCirclesAssigned$.subscribe((assignedCircles: JoinedCircle[]) => {
      this.assignedCircles = assignedCircles;
    });

    if (this.edit === true) {
      // required fields
      this.title.setValue(this.post.TITLE);
      // optional fields
      if (this.post.ITEMS) this.listItems = this.post.ITEMS;
      this.assignedCircles = [...this.post.CIRCLES];
      this.readOnly = this.post.READ_ONLY;
    }

    this.loadMentionables();
  }

  // onSetCircles(assignedCircles: JoinedCircle[]) {
  //   this.assignedCircles = assignedCircles;
  // }

  async createSharedList() {
    this.analytics.logEvent('shared_list_save_new', {});

    this.presentLoading();
    const uid = this.store.selectSnapshot(VillagerState.uid);
    const firstName = this.store.selectSnapshot(VillagerState.firstName);
    const lastName = this.store.selectSnapshot(VillagerState.lastName);
    const allVillageVillagersUids: string[] = this.store
      .selectSnapshot(VillagerState.allVillagers)
      .map((x) => x._UID);
    const emptyReadReceipts: ReadReceipt[] = allVillageVillagersUids.map(
      (UID) => ({ UID, LAST_READ_MESSAGE_COUNT: 0 })
    );
    const CIRCLE_UIDS = this.assignedCircles.map((x) => x.UID);
    const RECIPIENT_NOTIFICATION_SETTINGS: RecipientNotificationSettings =
      this.helpers.getNotificationRecipientsForCircle(CIRCLE_UIDS);

      console.log('read only: ', this.readOnly)

    let sharedList: SharedList = {
      _CREATOR_UID: uid,
      _CREATOR_FIRST_NAME: firstName,
      _CREATOR_LAST_NAME: lastName,
      _SERVER_TIMESTAMP:
        firebase.default.firestore.FieldValue.serverTimestamp(),
      _CREATED_AT: new Date(),
      _TYPE: 'LIST',
      TITLE: this.title.value,
      BODY: null,
      ITEMS: this.listItems,
      REPORTED_BY: [],
      PARTICIPANT_UIDS: allVillageVillagersUids,
      PARTICIPANT_READ_RECEIPTS: emptyReadReceipts,
      RECIPIENT_NOTIFICATION_SETTINGS,
      MESSAGE_COUNT: 0,
      UPDATED_AT: firebase.default.firestore.FieldValue.serverTimestamp(),
      IMAGE_PATHS: [],
      TOPICS: [],
      ARCHIVED: false,
      CIRCLES: this.assignedCircles,
      CIRCLE_UIDS,
      _MENTION_UIDS: [],
      READ_ONLY: this.readOnly,
    };

    const { _MENTION_UIDS, BODY } = this.mentionInput.parseMentionsAndBody();
    sharedList._MENTION_UIDS = _MENTION_UIDS;
    sharedList.BODY = this.utils.parseLinksFromText(BODY);

    this.store.dispatch(new CreateSharedList({ post: sharedList })).subscribe(
      (result) => {
        this.store.dispatch(new VillagerActions.MarkOnboardedTaskComplete({ villagerId: uid, field: "HAS_CREATED_POST" }))
        this.analytics.logEvent('shared_list_save_new_success', {});
        this.presentToast('Successfully created shared list.', false);
        this.listForm.reset();
        this.modalCtrl.dismiss({ action: 'create-close' });
        this.loading.dismiss();
        this.loading = null;
      },
      (err) => {
        this.analytics.logEvent('shared_list_save_new_error', {
          error: err,
        });
        console.error(err);
        this.showErrorAlert();
        this.loading.dismiss();
        this.loading = null;
      }
    );
  }

  async updateSharedList() {
    this.analytics.logEvent('shared_list_udpate', { shareId: this.post._UID });

    const CIRCLE_UIDS = this.assignedCircles.map((x) => x.UID);
    const RECIPIENT_NOTIFICATION_SETTINGS: RecipientNotificationSettings =
      this.helpers.getNotificationRecipientsForCircle(CIRCLE_UIDS);

    let sharedList: SharedList = {
      ...this.post,
      TITLE: this.title.value,
      ITEMS: this.listItems,
      RECIPIENT_NOTIFICATION_SETTINGS,
      CIRCLES: this.assignedCircles,
      CIRCLE_UIDS,
      READ_ONLY: this.readOnly,
    };

    const { _MENTION_UIDS, BODY } = this.mentionInput.parseMentionsAndBody();
    sharedList._MENTION_UIDS = _MENTION_UIDS;
    sharedList.BODY = this.utils.parseLinksFromText(BODY);

    this.store
      .dispatch(new UpdateSharedList({ postId: sharedList._UID, partialPost: sharedList }))
      .subscribe(
        () => {
          this.analytics.logEvent('shared_list_update_success', {});
          this.presentToast('Successfully updated shared list.', true);
          this.listForm.reset();
          this.modalCtrl.dismiss({ action: null });
          this.loading.dismiss();
          this.loading = null;
        },
        (err) => {
          this.analytics.logEvent('shared_list_update_error', {
            postId: this.post._UID,
            error: err,
          });
          console.error(err);
          this.showErrorAlert();
          this.loading.dismiss();
          this.loading = null;
        }
      );
  }

  toggleReadOnly() {
    this.readOnly = !this.readOnly;
  }

  async presentToast(header: string, update = false) {
    let message;
    if (update) {
      message = `You created a shared village list!`;
    }
    const toast = await this.toastCtrl.create({
      header: header,
      message,
      color: 'primary',
      duration: 3000,
      position: 'top',
    });
    toast.present();
  }

  async presentLoading() {
    if (!this.loading) {
      this.loading = await this.loadingCtrl.create({
        duration: 15000,
        backdropDismiss: true,
      });
      return this.loading.present();
    }
  }

  async showErrorAlert() {
    const alert = await this.alertCtrl.create({
      header: 'Error',
      message:
        'We ran into an error creating the shared list. Please check that everything is filled out and try again.',
      buttons: ['OK'],
    });

    await alert.present();
  }

  async missingDataError(message: string) {
    const alert = await this.alertCtrl.create({
      header: 'Error',
      message,
      buttons: ['OK'],
    });

    await alert.present();
  }

  async loadMentionables() {
    let tmpMentionables = [];

    const allUnblockedVillagers = this.store.selectSnapshot(
      VillagerState.allUnblockedVillagersExcludeCurrent
    );
    tmpMentionables = [
      ...allUnblockedVillagers.map((villager) => villager._UID),
    ];

    this.mentionables = [...new Set(tmpMentionables)];
    console.log('[POST BODY INPUT] Mentionables: ', this.mentionables);
  }
}
