import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Badge, Villager } from 'src/app/models/villager.model';
import { ImageService } from 'src/app/services/images.service';
import { VillagerState } from 'src/app/state/villager.state';

@Component({
  selector: 'app-villager-small-avatar',
  templateUrl: './villager-small-avatar.component.html',
  styleUrls: ['./villager-small-avatar.component.scss'],
})
export class VillagerSmallAvatarComponent implements OnInit {
  avatarUrl$: Observable<string>;
  @Input() firstName: string;
  @Input() lastName: string;
  @Input() uid: string;
  @Input() recentlyActive?: boolean;
  @Input() navToProfile = true;
  villager$: Observable<Villager>;
  villager: Villager;
  badge: Badge;

  constructor(
    private imageSvc: ImageService,
    private router: Router,
    private modalCtrl: ModalController,
    private store: Store
  ) {}

  ngOnInit() {
    this.villager$ = this.store
      .select(VillagerState.getVillagerById)
      .pipe(map((filterFn) => filterFn(this.uid)));

    this.villager$.subscribe((villager) => {
      if (villager) {
        this.villager = villager;
        if (this.villager.BADGES && this.villager.BADGES.length > 0) {
          this.badge = this.villager.BADGES[0];
        }
      }
    });

    this.avatarUrl$ = this.imageSvc.getAvatar$(
      this.uid,
      this.firstName,
      this.lastName,
      '64'
    );
  }

  async goToProfile() {
    if (this.navToProfile) {
      const existing = await this.modalCtrl.getTop();
      if (existing) {
        this.modalCtrl.dismiss();
      }
      if (this.uid) {
        this.router.navigateByUrl(`member/${this.uid}`);
      }
    }
  }
}
