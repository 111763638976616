import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { ImageService } from 'src/app/services/images.service';

@Component({
  selector: 'app-edit-images',
  templateUrl: './edit-images.component.html',
  styleUrls: ['./edit-images.component.scss'],
})
export class EditImagesComponent implements OnInit {
  tryCount = 0;
  MAX_TRY = 2;
  errorWithFile = false;
  path$: Observable<string>;
  @Input() allowExpansion = true;
  @Input() path: string;
  @Input() size?: '64' | '256' | '512' | '1080';
  @Input() edit: boolean;

  @Output() deletePhotoEvent = new EventEmitter<number>();


  constructor(
    private imageSvc: ImageService,
    private alertCtrl: AlertController
  ) {}

  async showDeleteConfirm() {
    const alert = await this.alertCtrl.create({
      header: 'Careful!',
      message: `Are you sure you want to delete this photo?`,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'alertDarkButton',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          },
        },
        {
          text: 'Delete',
          role: 'delete',
          cssClass: 'alertDangerButton',
          handler: () => {
            this.deletePhoto();
          },
        },
      ],
    });

    await alert.present();
  }

  deletePhoto() {
    this.deletePhotoEvent.emit();
  }

  ngOnInit() {
    console.log('[Post Image] has path: ', this.path);
    if (!this.size) {
      this.size = '512';
    }

    if (this.path) {
      this.path$ = this.imageSvc.getPostPhoto(this.path, this.size);
      let timer;

      const sub = this.path$.subscribe(
        () => {
          sub.unsubscribe();
          clearInterval(timer);
        },
        (err) => {
          timer = setInterval(() => {
            if (this.tryCount < this.MAX_TRY) {
              this.tryCount++;

              this.path$ = this.imageSvc.getPostPhoto(this.path, '512');
              const newSub = this.path$.subscribe(() => {
                newSub.unsubscribe();
                clearInterval(timer);
              });
            } else {
              clearInterval(timer);
              this.errorWithFile = true;
            }
          }, 5000);
        }
      );
    }
  }
}
