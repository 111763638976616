import { Component, Input, OnInit } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { Store } from '@ngxs/store';
import { ChatMessage } from 'src/app/models/chat-message.model';
import {
  DirectChatroomReact,
  DiscussionReact,
} from 'src/app/state/app.actions';
import { CourtyardActions } from 'src/app/state/courtyard.actions';
import { VillagerState } from 'src/app/state/villager.state';

@Component({
  selector: 'app-emoji-picker',
  templateUrl: './emoji-picker.component.html',
  styleUrls: ['./emoji-picker.component.scss'],
})
export class EmojiPickerComponent implements OnInit {
  currentUserUid: string;
  @Input() message: ChatMessage;
  @Input() type: 'post' | 'dm' | 'courtyard';
  constructor(
    private store: Store,
    public modalCtrl: ModalController,
    private platform: Platform
  ) {}

  isNative = false;

  ngOnInit() {
    this.currentUserUid = this.store.selectSnapshot(VillagerState.uid);

    if (this.platform.is('capacitor')) {
      this.isNative = true;
    }
  }

  async react($event) {
    const payload = {
      message: this.message,
      emoji: $event.emoji.native,
      villagerUid: this.currentUserUid,
    };

    switch (this.type) {
      case 'courtyard':
        this.store.dispatch(new CourtyardActions.React(payload));
        break;
      case 'post':
        this.store.dispatch(new DiscussionReact(payload));
        break;
      case 'dm':
        this.store.dispatch(new DirectChatroomReact(payload));
        break;
      default:
        alert(
          'We ran into an error adding your reaction. Please try again or contact support'
        );
    }
    this.modalCtrl.dismiss();
  }
}
