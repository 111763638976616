<ion-grid>
  <!-- <ion-row>
    <ion-col>
      <div class="subheader">Type</div>
      <div>{{ exchange?._EXCHANGE_TYPE | titlecase }}</div>
    </ion-col>
    <ion-col>
    </ion-col>
  </ion-row> -->
  <ion-row>
    <ion-col>
      <!-- <div style="margin-top: 20px" [innerHtml]="exchange?.BODY | safehtml"></div> -->

      <div *ngIf="exchange?.BODY">
        <div
          class="post-message-body"
          *ngIf="!showFullBody; else fullBody"
          [innerHtml]="
            exchange.BODY
              | truncateBody: 400:true
              | mentionified: exchange._MENTION_UIDS
              | safehtml
          "
        ></div>
        <ion-button
          style="float: right; margin-top: 5px"
          size="small"
          color="dark"
          fill="outline"
          *ngIf="exchange?.BODY.length > 400 && !showFullBody"
          (click)="goToDetails()"
          >Read full post
          <ion-icon name="chevron-forward-outline"></ion-icon>
        </ion-button>
        <ng-template #fullBody>
          <div
            (click)="clickBody($event)"
            class="post-message-body"
            [innerHtml]="
              exchange.BODY | mentionified: exchange._MENTION_UIDS | safehtml
            "
          ></div>
        </ng-template>
      </div>
    </ion-col>
  </ion-row>
</ion-grid>
<div *ngIf="exchange?.IMAGE_PATHS.length > 0">
  <ion-grid style="padding: 0">
    <ion-row>
      <ion-col *ngFor="let path of exchange?.IMAGE_PATHS">
        <div class="post-image-wrapper">
          <app-post-image [path]="path"></app-post-image>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</div>
