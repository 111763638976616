import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import {
  ToastController,
  ModalController,
  LoadingController,
  AlertController,
} from '@ionic/angular';
import { Select, Store } from '@ngxs/store';
import { EMOTIONS, SupportRequest } from 'src/app/models/support-request.model';
import { VillagerState } from 'src/app/state/villager.state';
import * as firebase from 'firebase';
import 'firebase/firestore';
import {
  CreateSupportRequest,
  UpdateSupportRequest,
} from 'src/app/state/app.actions';
import { Observable } from 'rxjs';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { AnalyticsService } from 'src/app/analytics.service';
import { VillageState } from 'src/app/state/village.state';
import { SUPPORT_KARMA } from 'src/app/app.config';
import { JoinedCircle } from 'src/app/models/circle.model';
import { AppState } from 'src/app/state/app.state';
import { StoredDocument } from 'src/app/models/post-core.model';
import { PostFileUploadComponent } from 'src/app/shared/post-file-upload/post-file-upload.component';
import { RecipientNotificationSettings } from 'src/app/models/notification-settings';
import { CreatePostHelpersService } from 'src/app/services/create-post-helpers.service';
import { PostMentionableInputComponent } from 'src/app/shared/post-mentionable-input/post-mentionable-input.component';
import { VillagerActions } from 'src/app/state/villager.actions';

@Component({
  selector: 'app-create-support-request-modal',
  templateUrl: './create-support-request-modal.page.html',
  styleUrls: ['./create-support-request-modal.page.scss'],
})
export class CreateSupportRequestModalPage implements OnInit, AfterViewInit {
  emotions = EMOTIONS;
  selectedEmotion: string;
  showSupportDetails = false;
  wantsSupport = false;
  supportForm = new FormGroup({
    anonymous: new FormControl(false),
  });
  component = 'create-support-request-modal';
  placeholderText = 'What would you like support/advice for?'

  mentionables: string[] = [];

  loading: any;

  topics: string[] = [];
  assignedCircles: JoinedCircle[] = [];

  SUPPORT_KARMA = SUPPORT_KARMA;

  showFeeling = false;
  showPhotos = false;
  showTopics = false;

  tmpImageUrls: string[] = [];
  tmpDocUrls: StoredDocument[] = [];

  @Select(VillageState.villageName) villageName$: Observable<string>;
  @Select(VillageState.showKarma) showKarma$: Observable<boolean>;
  @Select(AppState.postCirclesAssigned) postCirclesAssigned$: Observable<
    JoinedCircle[]
  >;

  @Input() edit = false;
  @Input() supportRequest: SupportRequest;
  @Input() description: string;

  @ViewChild(PostMentionableInputComponent)
  mentionInput: PostMentionableInputComponent;
  @ViewChild(PostFileUploadComponent) fileUpload: PostFileUploadComponent;

  constructor(
    private store: Store,
    private toastCtrl: ToastController,
    public modalCtrl: ModalController,
    public loadingController: LoadingController,
    private alertCtrl: AlertController,
    private utils: UtilitiesService,
    private analytics: AnalyticsService,
    private helpers: CreatePostHelpersService
  ) {}

  ngAfterViewInit(): void {
    if (this.edit === true) {
      console.log('edit post: ', this.supportRequest._UID);
      this.mentionInput.setBody(this.utils.removeExistingTags(this.supportRequest.BODY), this.supportRequest._MENTION_UIDS);
    } else {
      if (this.description) {
        this.mentionInput.setBody(this.utils.removeExistingTags(this.description), []);
      }
    }
  }

  ngOnInit() {
    this.postCirclesAssigned$.subscribe((assignedCircles: JoinedCircle[]) => {
      this.assignedCircles = assignedCircles;
    });

    if (this.edit === true) {
      this.topics = [...this.supportRequest.TOPICS];
      this.tmpImageUrls = [...this.supportRequest.IMAGE_PATHS];
      this.tmpDocUrls = [...this.supportRequest.DOCUMENT_PATHS];
      if (this.supportRequest._EMOTION)
        this.selectedEmotion = this.supportRequest._EMOTION;
      this.assignedCircles = [...this.supportRequest.CIRCLES];
    }

    this.loadMentionables();
  }

  onSetTopics(topics: string[]) {
    this.topics = topics;
  }

  selectEmotion(emotion: string) {
    if (emotion === this.selectedEmotion) {
      this.selectedEmotion = null;
    } else {
      this.selectedEmotion = emotion;
    }
  }

  getColor(emotion: string) {
    if (emotion === this.selectedEmotion) {
      return 'primary';
    } else {
      return 'light';
    }
  }

  handleDeletePhoto(event, index: number) {
    this.tmpImageUrls.splice(index, 1);
  }

  handleDeleteDocument(index: number) {
    this.tmpDocUrls.splice(index, 1);
  }

  async submitSupportTunein() {
    const { BODY } = this.mentionInput.parseMentionsAndBody();
    if (!BODY || BODY.trim().length === 0) {
      this.missingDataError(
        'Missing description. Please add a description and try again.'
      );
    } else {
      try {
        let photoPaths = [];
        let documentPaths = [];
        if (this.fileUpload) {
          photoPaths = await this.fileUpload.uploadPhotos();
          documentPaths = await this.fileUpload.uploadDocuments();
        }
        this.save(photoPaths, documentPaths);
      } catch (e) {
        this.analytics.logEvent('support_create_error', {
          error: e,
        });
        alert('Error creating post');
      }
    }
  }

  async updateRequest() {
    await this.presentLoading();
    this.update();
  }

  async update() {
    this.analytics.logEvent('support_update', {
      supportId: this.supportRequest._UID,
    });
    let newPhotoPaths = [];
    let newDocumentPaths: StoredDocument[] = [];
    try {
      if (this.fileUpload) {
        newPhotoPaths = await this.fileUpload.uploadPhotos();
        newDocumentPaths = await this.fileUpload.uploadDocuments();
      }
    } catch (e) {
      alert('Error updating photos & documents');
      this.analytics.logError('error_updating_post_files', this.component, e);
    }
    let allImages = [...this.tmpImageUrls];
    let allDocuments = [...this.tmpDocUrls];
    if (newPhotoPaths.length > 0) {
      allImages = this.tmpImageUrls.concat(newPhotoPaths);
    }
    if (newDocumentPaths.length > 0) {
      allDocuments = this.tmpDocUrls.concat(newDocumentPaths);
    }

    const CIRCLE_UIDS = this.assignedCircles.map((x) => x.UID);
    const RECIPIENT_NOTIFICATION_SETTINGS: RecipientNotificationSettings =
      this.helpers.getNotificationRecipientsForCircle(CIRCLE_UIDS);

    const supportRequest: SupportRequest = {
      ...this.supportRequest,
      _EMOTION: this.selectedEmotion,
      IMAGE_PATHS: [...allImages],
      DOCUMENT_PATHS: [...allDocuments],
      TOPICS: this.topics,
      RECIPIENT_NOTIFICATION_SETTINGS,
      CIRCLES: this.assignedCircles,
      CIRCLE_UIDS,
    };

    const { _MENTION_UIDS, BODY } = this.mentionInput.parseMentionsAndBody();
    supportRequest._MENTION_UIDS = _MENTION_UIDS;
    supportRequest.BODY = this.utils.parseLinksFromText(BODY);

    this.store
      .dispatch(new UpdateSupportRequest({ post: supportRequest }))
      .subscribe(
        () => {
          this.analytics.logEvent('support_update_success', {});
          this.presentToast('Successfully updated request.', false);
          this.supportForm.reset();
          this.modalCtrl.dismiss({ action: null });
          this.loading.dismiss();
          this.loading = null;
        },
        (err) => {
          this.analytics.logEvent('support_update_error', {
            supportId: this.supportRequest._UID,
            error: err,
          });
          console.error(err);
          this.showErrorAlert();
          this.loading.dismiss();
          this.loading = null;
        }
      );
  }

  async showDeletePhotoConfirm() {
    const alert = await this.alertCtrl.create({
      header: 'Careful!',
      message: `Are you sure you want to delete images?`,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'dark',
          handler: () => {},
        },
        {
          text: 'Delete',
          role: 'delete',
          cssClass: 'danger',
          handler: () => {
            this.presentLoading();
            this.update();
          },
        },
      ],
    });

    await alert.present();
  }

  async save(imagePaths: string[], documentPaths: StoredDocument[]) {
    this.analytics.logEvent('support_save_new', {});
    await this.presentLoading();
    const firstName = this.store.selectSnapshot(VillagerState.firstName);
    const lastName = this.store.selectSnapshot(VillagerState.lastName);
    const uid = this.store.selectSnapshot(VillagerState.uid);

    const CIRCLE_UIDS = this.assignedCircles.map((x) => x.UID);
    const RECIPIENT_NOTIFICATION_SETTINGS: RecipientNotificationSettings =
      this.helpers.getNotificationRecipientsForCircle(CIRCLE_UIDS);

    let supportRequest: SupportRequest = {
      _CREATOR_UID: uid,
      _SERVER_TIMESTAMP:
        firebase.default.firestore.FieldValue.serverTimestamp(),
      _CREATED_AT: new Date(),
      _CREATOR_FIRST_NAME: firstName,
      _CREATOR_LAST_NAME: lastName,
      _TYPE: 'SUPPORT',
      _EMOTION: this.selectedEmotion,
      REPORTED_BY: [],
      TITLE: `${firstName} ${lastName} requested support`,
      UPDATED_AT: new Date(),
      IMAGE_PATHS: imagePaths,
      DOCUMENT_PATHS: documentPaths,
      MESSAGE_COUNT: 0,
      BODY: null,
      CELEBRATIONS: [],
      PARTICIPANT_UIDS: [uid],
      PARTICIPANT_READ_RECEIPTS: [{ UID: uid, LAST_READ_MESSAGE_COUNT: 0 }],
      RECIPIENT_NOTIFICATION_SETTINGS,
      SUPPORTERS: [],
      TOPICS: this.topics,
      ARCHIVED: false,
      CIRCLES: this.assignedCircles,
      CIRCLE_UIDS,
      _MENTION_UIDS: []
    };

    const { _MENTION_UIDS, BODY } = this.mentionInput.parseMentionsAndBody();
    supportRequest._MENTION_UIDS = _MENTION_UIDS;
    supportRequest.BODY = this.utils.parseLinksFromText(BODY);

    this.store.dispatch(new CreateSupportRequest({ supportRequest })).subscribe(
      (result) => {
        this.store.dispatch(new VillagerActions.MarkOnboardedTaskComplete({ villagerId: uid, field: "HAS_CREATED_POST" }))
        this.analytics.logEvent('support_save_new_success', {});
        this.presentToast('Successfully created request.', true);
        this.supportForm.reset();
        this.modalCtrl.dismiss({ action: 'create-close' });
        this.loading.dismiss();
        this.loading = null;
      },
      (err) => {
        this.analytics.logEvent('support_save_new_error', {
          error: err,
        });
        console.error(err);
        this.showErrorAlert();
        this.loading.dismiss();
        this.loading = null;
      }
    );
  }

  async presentToast(header: string, update = false) {
    let message;
    if (update) {
      message = `You earned ${SUPPORT_KARMA} karma!`;
    }
    const toast = await this.toastCtrl.create({
      header: header,
      message,
      color: 'primary',
      duration: 3000,
      position: 'top',
    });
    toast.present();
  }

  async presentLoading() {
    if (!this.loading) {
      this.loading = await this.loadingController.create({
        duration: 15000,
      });
      return this.loading.present();
    }
  }

  async showErrorAlert() {
    const alert = await this.alertCtrl.create({
      header: 'Error',
      message:
        'We ran into an error creating the post. Please check that everything is filled out and try again.',
      buttons: ['OK'],
    });

    await alert.present();
  }

  async missingDataError(message: string) {
    const alert = await this.alertCtrl.create({
      header: 'Error',
      message,
      buttons: ['OK'],
    });

    await alert.present();
  }

  async loadMentionables() {
    let tmpMentionables = [];

    const allUnblockedVillagers = this.store.selectSnapshot(
      VillagerState.allUnblockedVillagersExcludeCurrent
    );
    tmpMentionables = [
      ...allUnblockedVillagers.map((villager) => villager._UID),
    ];

    this.mentionables = [...new Set(tmpMentionables)];
    console.log('[POST BODY INPUT] Mentionables: ', this.mentionables);
  }
}
