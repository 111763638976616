import { Injectable } from '@angular/core';
import {
  Connected,
  Disconnected,
  Disconnect,
  Emitted,
  NgxsFirestoreConnect,
  StreamConnected,
  StreamDisconnected,
  StreamEmitted,
} from '@ngxs-labs/firestore-plugin';
import {
  Action,
  NgxsOnInit,
  Selector,
  State,
  StateContext,
  Store,
} from '@ngxs/store';
import { catchError, concatMap, tap } from 'rxjs/operators';
import {
  JoinedVillage,
  Villager,
  VillagerStateModel,
} from '../models/villager.model';
import { NotificationService } from '../services/notification.service';
import { VillagerService } from '../services/villager.service';
import {
  ClearVillager,
  GetFellowVillagers,
  GetVillager,
  SwitchVillage,
  SetVillagerHasProfilePhoto,
  UpdateVillagerLastActive,
  SetStatusOnline,
  SetStatusOffline,
  SetJoinedVillagePushSetting,
  SetFCMToken,
  RemoveFCMToken,
  AddVillageToVillager,
  FetchShares,
  FetchExchanges,
  FetchHangouts,
  FetchSupportRequests,
  GetVillage,
  ClearAllPosts,
  PushAllowed,
  PushRevoked,
  SetPushBadgeCount,
  ClearShares,
  ClearExchanges,
  ClearHangouts,
  ClearSupportRequests,
  ClearAllVillagers,
  SetVillagerOnFeed,
  Logout,
  FetchConflicts,
  ClearConflicts,
  RemoveVillageFromVillager,
  ClearAnnouncements,
  FetchAnnouncements,
  FetchSharedLists,
  ClearSharedList,
  StoreVillagerDeviceInfo,
  GetInitialVillageData,
  JoinVillage,
  AddVillagerToVillage,
  GetVillageBulletinBoard,
  SignupJoinVillage,
} from './app.actions';
import * as firebase from 'firebase';
import 'firebase/firestore';
import { AngularFirestore } from '@angular/fire/firestore';
import { Platform } from '@ionic/angular';
import { of } from 'rxjs';
import { CourtyardActions } from './courtyard.actions';
import { Device } from '@capacitor/device';
import { JoinedCircle } from 'src/app/models/circle.model';
import { VillageState } from './village.state';
import { uniqBy } from 'lodash';
import { CircleActions } from './circle.actions';
import { AnalyticsService } from '../analytics.service';
import { VillagerActions } from './villager.actions';
import { RecipientNotificationSettings } from '../models/notification-settings';
import { DirectParticipant } from '../models/direct-chatroom.model';

@State<VillagerStateModel>({
  name: 'villager',
  defaults: {
    currentVillager: null,
    fellowVillagers: [],
    allVillagers: [],
  },
})
@Injectable()
export class VillagerState implements NgxsOnInit {
  loading: HTMLIonLoadingElement = null;

  constructor(
    private villagerSvc: VillagerService,
    private ngxsFirestoreConnect: NgxsFirestoreConnect,
    private store: Store,
    private notificationSvc: NotificationService,
    private db: AngularFirestore,
    private platform: Platform,
    private analytics: AnalyticsService
  ) {}

  ngxsOnInit() {
    this.ngxsFirestoreConnect.connect(GetVillager, {
      to: (action) =>
        this.villagerSvc.doc$(action.payload.uid).pipe(
          catchError((err) => {
            this.analytics.logError(
              'error_get_villager',
              'villager state',
              err
            );
            console.error('[Villager] Error connecting to villager: ', err);
            return of(null);
          })
        ),
      cancelPrevious: true,
    });

    this.ngxsFirestoreConnect.connect(GetFellowVillagers, {
      to: (action) =>
        this.villagerSvc
          .collection$((ref) =>
            ref.where(
              'VILLAGES_UIDS',
              'array-contains',
              action.payload.villageUid
            )
          )
          .pipe(
            catchError((err) => {
              this.analytics.logError(
                'error_get_villagers',
                'villager state',
                err
              );
              console.error('[Villager] Error fetching villagers: ', err);
              return of(null);
            })
          ),
      cancelPrevious: true,
    });
  }

  @Selector()
  static currentVillager(state: VillagerStateModel): Villager {
    return state.currentVillager;
  }

  @Selector()
  static currentVillage(state: VillagerStateModel): string {
    return state.currentVillager.VILLAGE;
  }

  @Selector()
  static fellowVillagers(state: VillagerStateModel): Villager[] {
    return state.fellowVillagers;
  }

  @Selector()
  static allUnblockedVillagers(state: VillagerStateModel): Villager[] {
    const currentVillager = state.currentVillager;
    const { BLOCKED_VILLAGERS } = currentVillager;
    return state.allVillagers.filter((x) => {
      if (!BLOCKED_VILLAGERS.includes(x._UID)) {
        return x;
      } else {
        console.log('[Villager State] Not returning blocked villager: ', x);
      }
    });
  }

  @Selector()
  static allUnblockedVillagersExcludeCurrent(
    state: VillagerStateModel
  ): Villager[] {
    const currentVillager = state.currentVillager;
    const { BLOCKED_VILLAGERS } = currentVillager;
    return state.fellowVillagers.filter((x) => {
      if (!BLOCKED_VILLAGERS.includes(x._UID)) {
        return x;
      } else {
        console.log('[Villager State] Not returning blocked villager: ', x);
      }
    });
  }

  @Selector()
  static allBlockedVillagers(state: VillagerStateModel): Villager[] {
    const currentVillager = state.currentVillager;
    const { BLOCKED_VILLAGERS } = currentVillager;
    return state.fellowVillagers.filter((x) => {
      if (BLOCKED_VILLAGERS.includes(x._UID)) {
        return x;
      }
    });
  }

  @Selector()
  static onlineSortedVillagers(state: VillagerStateModel): Villager[] {
    const maxDisplayCount = 5;
    const { allVillagers } = state;
    const onlineVillagers = allVillagers
      .filter((x) => x.RECENTLY_ACTIVE)
      .splice(0, maxDisplayCount);
    const leftOver = maxDisplayCount - onlineVillagers.length;
    const offlineVillagers = allVillagers
      .filter((x) => !x.RECENTLY_ACTIVE)
      .splice(0, leftOver);
    const sortedList = [...onlineVillagers, ...offlineVillagers];
    return sortedList;
  }

  @Selector()
  static allVillagers(state: VillagerStateModel): Villager[] {
    return state.allVillagers;
  }

  @Selector()
  static allVillagersMap(state: VillagerStateModel): {
    [key: string]: Villager;
  } {
    return state.allVillagers.reduce((acc, v) => ({ ...acc, [v._UID]: v }), {});
  }

  @Selector()
  static loggedInStatus(state: VillagerStateModel): boolean {
    return state.currentVillager.IS_ACTIVE;
  }

  @Selector()
  static firstName(state: VillagerStateModel): string {
    return state.currentVillager.FIRST_NAME;
  }

  @Selector()
  static role(state: VillagerStateModel): string {
    return state.currentVillager.ROLE;
  }

  @Selector()
  static lastName(state: VillagerStateModel): string {
    return state.currentVillager.LAST_NAME;
  }

  @Selector()
  static uid(state: VillagerStateModel): string {
    return state.currentVillager._UID;
  }

  @Selector()
  static fcmToken(state: VillagerStateModel): string {
    return state.currentVillager.FCM_TOKEN;
  }

  @Selector()
  static getVillagerById(
    state: VillagerStateModel
  ): (villagerId: string) => Villager {
    return (villagerId: string) => {
      return state.allVillagers.find((x) => x._UID === villagerId);
    };
  }

  @Selector()
  static isCurrentVillager(
    state: VillagerStateModel
  ): (villagerId: string) => boolean {
    return (villagerId: string) => {
      if (!state.currentVillager) return false;
      return villagerId === state.currentVillager._UID;
    };
  }

  @Selector()
  static currentVillagerIsStewardInVillage(
    state: VillagerStateModel
  ): (villageId: string) => boolean {
    return (villageId: string) => {
      const { currentVillager } = state;
      if (!currentVillager) return false;
      const joinedVillage = currentVillager.VILLAGES.find(
        (x) => x.UID === villageId
      );

      if (
        joinedVillage &&
        joinedVillage.CIRCLE_UIDS &&
        joinedVillage.CIRCLE_UIDS.includes('STEWARDS')
      ) {
        return true;
      } else return false;
    };
  }

  @Selector()
  static villagerCircleIds(
    state: VillagerStateModel
  ): (villagerId: string) => string[] {
    return (villagerId: string) => {
      const villager: Villager = state.allVillagers.find(
        (x) => x._UID === villagerId
      );
      if (!villager) return undefined;
      const selectedVillageId = villager.VILLAGE;
      if (selectedVillageId) {
        const selectedVillage = villager.VILLAGES.find(
          (x) => x.UID === selectedVillageId
        );
        if (selectedVillage.CIRCLE_UIDS.length > 0) {
          return selectedVillage.CIRCLE_UIDS;
        } else {
          // Provided as a failsafe against no CIRCLE_UIDS to query against
          return ['MEMBERS'];
        }
      }
    };
  }

  @Selector()
  static villagerCircles(
    state: VillagerStateModel
  ): (villagerId: string, currentVillageId: string) => JoinedCircle[] {
    return (villagerId: string, currentVillageId: string) => {
      const villager: Villager = state.allVillagers.find(
        (x) => x._UID === villagerId
      );
      if (!villager) return undefined;
      if (currentVillageId) {
        const selectedVillage = villager.VILLAGES.find(
          (x) => x.UID === currentVillageId
        );
        if (selectedVillage) {
          return selectedVillage.CIRCLES;
        } else {
          return undefined;
        }
      }
    };
  }

  @Selector()
  static currentVillagerCircleIds(state: VillagerStateModel): string[] {
    if (!state.currentVillager) return undefined;
    const selectedVillageId = state.currentVillager.VILLAGE;
    if (selectedVillageId) {
      const selectedVillage = state.currentVillager.VILLAGES.find(
        (x) => x.UID === selectedVillageId
      );
      if (selectedVillage.CIRCLE_UIDS.length > 0) {
        return selectedVillage.CIRCLE_UIDS;
      } else {
        // Provided as a failsafe against no CIRCLE_UIDS to query against
        return ['MEMBERS'];
      }
    }
  }

  @Selector()
  static currentVillagerCircles(state: VillagerStateModel): JoinedCircle[] {
    if (!state.currentVillager) return undefined;
    const selectedVillageId = state.currentVillager.VILLAGE;
    if (selectedVillageId) {
      const selectedVillage = state.currentVillager.VILLAGES.find(
        (x) => x.UID === selectedVillageId
      );
      return selectedVillage.CIRCLES;
    }
  }

  /**
   * This is an overloaded create method.
   * @param ctx
   * @param action
   * @returns
   */
  @Action(VillagerActions.CreateVillager)
  createVillager(
    ctx: StateContext<VillagerStateModel>,
    action: VillagerActions.CreateVillager
  ) {
    const { villager } = action.payload;
    // console.log('[DEBUG] Creating Villager Doc: ', villager);
    return this.villagerSvc.create$(villager).pipe(
      tap(() => {
        ctx.patchState({
          currentVillager: villager,
        });
        ctx.dispatch(
          new SetVillagerOnFeed({
            villagerId: villager._UID,
            blockedVillagers: [],
          })
        );
      }),
      concatMap(() => {
        console.log(
          '[DEBUG] [Villager State] successfully created villager. Triggering fetch to listen to changes'
        );
        return ctx.dispatch(new GetVillager({ uid: villager._UID }));
      })
    );
  }

  @Action(VillagerActions.UpdateVillager)
  updateVillager(
    ctx: StateContext<VillagerStateModel>,
    action: VillagerActions.UpdateVillager
  ) {
    const { villagerId, villager } = action.payload;
    if (!villagerId) {
      console.error('Missing Id: ', villagerId);
      this.analytics.logError(
        'error_missingId_update_villager',
        'Villager State',
        new Error('Missing Id Villager Update')
      );
      alert('Error Updating data');
      return false;
    }
    return this.villagerSvc.update$(villagerId, villager);
  }

  @Action(VillagerActions.MarkOnboardedTaskComplete)
  markOnboardedTaskComplete(
    ctx: StateContext<VillagerStateModel>,
    action: VillagerActions.MarkOnboardedTaskComplete
  ) {
    const { villagerId, field } = action.payload;
    if (villagerId && field) {
      const { currentVillager } = ctx.getState();
      if (!currentVillager[field]) {
        let update: Partial<Villager> = {};
        update[field] = true;
        console.log('returning update in villager state...');
        return this.villagerSvc.update$(villagerId, update);
      }
    } else {
      console.log('returning false in villager state...');
      return false;
    }
  }

  @Action(VillagerActions.AddCircleToVillager)
  addCircle(
    ctx: StateContext<VillagerStateModel>,
    action: VillagerActions.AddCircleToVillager
  ) {
    const { villagerId, circle } = action.payload;
    const currentVillageId = this.store.selectSnapshot(VillageState.uid);

    const { allVillagers } = ctx.getState();
    const villager = allVillagers.find((x) => x._UID === villagerId);
    const idx = villager.VILLAGES.findIndex((x) => x.UID === currentVillageId);
    if (idx > -1) {
      let joinedVillageClone: JoinedVillage = {
        ...villager.VILLAGES[idx],
      };

      const circlesClone = [...joinedVillageClone.CIRCLES];

      console.log(
        '[DEBUG] villager is in: ' + circlesClone.length + ' circles'
      );
      console.log('[DEBUG] circles: ', circlesClone);
      if (circlesClone.length > 9) {
        console.error('You have reached the maximum number of circles');
        throw new Error('You can only join 10 circles');
      } else {
        circlesClone.push({
          NAME: circle.NAME,
          UID: circle.UID,
        });

        let circlesToUpdate = uniqBy(circlesClone, 'UID');

        if (circlesToUpdate.length === 0) {
          circlesToUpdate = [
            {
              NAME: 'Members',
              UID: 'MEMBERS',
            },
          ];
        }

        const circleIdsToUpdate = circlesClone.map((x) => x.UID);

        joinedVillageClone = {
          ...joinedVillageClone,
          CIRCLES: circlesToUpdate,
          CIRCLE_UIDS: circleIdsToUpdate,
        };

        const updatedJoinedVillages = [...villager.VILLAGES];
        updatedJoinedVillages[idx] = joinedVillageClone;

        return this.villagerSvc.updateWithoutConverter(villagerId, {
          VILLAGES: updatedJoinedVillages,
        });
      }
    } else {
      alert('Error updating circle access');
    }
  }

  @Action(VillagerActions.RemoveCircleFromVillager)
  removeCircle(
    ctx: StateContext<VillagerStateModel>,
    action: VillagerActions.RemoveCircleFromVillager
  ) {
    const { villagerId, circle } = action.payload;
    // console.log('Remove villager circle: ', villagerId, circle);
    const currentVillageId = this.store.selectSnapshot(VillageState.uid);

    const { allVillagers } = ctx.getState();
    const villager = allVillagers.find((x) => x._UID === villagerId);
    // console.log('removing circle from current villager: ', villager.VILLAGES);
    const idx = villager.VILLAGES.findIndex((x) => x.UID === currentVillageId);
    // console.log('Updating joined village at index: ', idx);
    if (idx > -1) {
      let joinedVillageClone: JoinedVillage = {
        ...villager.VILLAGES[idx],
      };

      // console.log('Updating joined villaged: ', joinedVillageClone);

      const circlesClone = [...joinedVillageClone.CIRCLES];
      const circleIdsClone = [...joinedVillageClone.CIRCLE_UIDS];

      circlesClone.splice(
        circlesClone.findIndex((x) => x.UID === circle._UID),
        1
      );

      circleIdsClone.splice(
        circleIdsClone.findIndex((x) => x === circle._UID),
        1
      );

      joinedVillageClone = {
        ...joinedVillageClone,
        CIRCLES: uniqBy(circlesClone, 'UID'), // use lodash to unique array of objects
        CIRCLE_UIDS: [...new Set(circleIdsClone)], // use set for unique array of strings
      };

      // console.log('UpdatED unique joined village: ', joinedVillageClone);

      const updatedJoinedVillages = [...villager.VILLAGES];
      updatedJoinedVillages[idx] = joinedVillageClone;

      return this.villagerSvc.updateWithoutConverter(villagerId, {
        VILLAGES: updatedJoinedVillages,
      });
    } else {
      alert('Error updating circle access');
    }
  }

  @Action(VillagerActions.BlockVillager)
  blockVillager(
    ctx: StateContext<VillagerStateModel>,
    action: VillagerActions.BlockVillager
  ) {
    const { currentVillager } = ctx.getState();
    const { _UID, BLOCKED_VILLAGERS } = currentVillager;
    const updatedBlocked = [...BLOCKED_VILLAGERS];
    updatedBlocked.push(action.payload.villagerId);
    return this.villagerSvc.updateWithoutConverter(_UID, {
      BLOCKED_VILLAGERS: updatedBlocked,
    });
  }

  @Action(VillagerActions.UnblockVillager)
  unblockVillager(
    ctx: StateContext<VillagerStateModel>,
    action: VillagerActions.UnblockVillager
  ) {
    const { currentVillager } = ctx.getState();
    const { _UID, BLOCKED_VILLAGERS } = currentVillager;
    const updatedBlocked = [...BLOCKED_VILLAGERS];
    const idx = updatedBlocked.findIndex(
      (x) => x === action.payload.villagerId
    );
    if (idx > -1) {
      updatedBlocked.splice(idx, 1);
    }
    return this.villagerSvc.updateWithoutConverter(_UID, {
      BLOCKED_VILLAGERS: updatedBlocked,
    });
  }

  @Action(AddVillageToVillager)
  addVillageToVillager(
    ctx: StateContext<VillagerStateModel>,
    action: AddVillageToVillager
  ) {
    const { village, villager } = action.payload;

    let role = village.CREATED_BY === villager._UID ? 'Leader' : 'Member';
    const circles: JoinedCircle[] = [{ NAME: 'Members', UID: 'MEMBERS' }];
    // 1. add village info villager.VILLAGES
    const newVillage: JoinedVillage = {
      NAME: village.NAME,
      UID: village._UID,
      PUSH: false,
      ROLE: role,
      CIRCLE_UIDS: [],
      CIRCLES: [],
    };

    if (role === 'Leader') {
      circles.push({ NAME: 'Stewards', UID: 'STEWARDS' });
    }

    newVillage.CIRCLES = circles;
    newVillage.CIRCLE_UIDS = circles.map((x) => x.UID);

    // 2. enable push if villager has
    if (villager.NOTIFICATIONS_ALLOWED && villager.FCM_TOKEN) {
      newVillage.PUSH = true;
    }

    const newVillages = [...villager.VILLAGES];
    newVillages.push(newVillage);

    // 3. add village id to villager.VILLAGES_UIDS
    const villagesUids = [...new Set(villager.VILLAGES_UIDS)];
    villagesUids.push(village._UID);

    // 4. save changes
    return this.villagerSvc.updateWithoutConverter(villager._UID, {
      VILLAGES: newVillages,
      VILLAGES_UIDS: villagesUids,
    });
  }

  @Action(RemoveVillageFromVillager)
  removeVillageFromVillager(
    ctx: StateContext<VillagerStateModel>,
    action: RemoveVillageFromVillager
  ) {
    const { villager, village } = action.payload;
    let villages = [...villager.VILLAGES];
    let villageUIDs = [...villager.VILLAGES_UIDS];

    const villagesToStay = villages.filter((v) => v.UID !== village._UID);
    const villagesToStayUIDs = villageUIDs.filter(
      (uid) => uid !== village._UID
    );

    // Side Effect. Remove villager from circles subcollection records
    // TODO handle case where this would leave a village without a steward
    const villageToLeave: JoinedVillage = villages.find(
      (x) => x.UID === village._UID
    );
    villageToLeave.CIRCLE_UIDS.forEach((circleId) => {
      this.store.dispatch(
        new CircleActions.RemoveVillagersFromCircle({
          circleId,
          villagerIds: [villager._UID],
        })
      );
    });

    return this.villagerSvc.updateWithoutConverter(villager._UID, {
      VILLAGES: villagesToStay,
      VILLAGES_UIDS: villagesToStayUIDs,
      VILLAGE: '',
    });
  }

  /**
   *
   * this is essentially a combination of AddVillageToVillager
   * and SwitchVillage with some defaults set.
   * It requires some "waterfall" like flow where data from the first is needed
   * in later parts and the normal Firebase flow doesnt guarantee we get changes back
   * before the next action is called so it's all in one here to share local vars
   *
   * @param ctx
   * @param action
   * @returns
   */
  @Action(JoinVillage)
  joinVillage(ctx: StateContext<VillagerStateModel>, action: JoinVillage) {
    const { village, villager, updateVillageCircles, circlesToJoin } =
      action.payload;

    // 0. Add villager to village (can be safely handled external to this actions)
    this.store.dispatch(new AddVillagerToVillage({ villager, village }));

    let role = village.CREATED_BY === villager._UID ? 'Leader' : 'Member';
    const circles: JoinedCircle[] = [{ NAME: 'Members', UID: 'MEMBERS' }];
    const villagerId = villager._UID;
    const villageId = village._UID;

    // 1. add village info villager.VILLAGES
    const newVillage: JoinedVillage = {
      NAME: village.NAME,
      UID: villageId,
      PUSH: false,
      ROLE: role,
      CIRCLE_UIDS: [],
      CIRCLES: [],
    };

    if (
      role === 'Leader' ||
      (circlesToJoin && circlesToJoin.find((x) => x.UID === 'STEWARDS'))
    ) {
      circles.push({ NAME: 'Stewards', UID: 'STEWARDS' });
    }

    if (circlesToJoin && circlesToJoin.length > 0) {
      const newCirclesWithoutDefaults = circlesToJoin.filter((x) => {
        return x.UID !== 'STEWARDS' && x.UID !== 'MEMBERS';
      });

      circles.push(...newCirclesWithoutDefaults);
    }

    newVillage.CIRCLES = circles;
    newVillage.CIRCLE_UIDS = circles.map((x) => x.UID);

    // 2. enable push if villager has
    if (villager.NOTIFICATIONS_ALLOWED && villager.FCM_TOKEN) {
      newVillage.PUSH = true;
    }

    const newVillages: JoinedVillage[] = [...villager.VILLAGES];
    newVillages.push(newVillage);

    // 3. add village id to villager.VILLAGES_UIDS
    const villagesUids: string[] = newVillages.map((x) => x.UID);
    villagesUids.push(villageId);

    const update: Partial<Villager> = {
      VILLAGE: villageId,
      VILLAGES: newVillages,
      VILLAGES_UIDS: [...new Set(villagesUids)],
    };

    // console.log(
    //   '[DEBUG] Confirming missing CIRCLES on new joined village: ',
    //   update
    // );

    // 4. save changes
    this.villagerSvc.updateWithoutConverter(villagerId, update);

    const villageCircleAccess: string[] = newVillage.CIRCLE_UIDS;

    return this.store
      .dispatch([
        new Disconnect(GetVillage),
        new Disconnect(FetchShares),
        new Disconnect(FetchHangouts),
        new Disconnect(FetchSupportRequests),
        new Disconnect(FetchExchanges),
        new Disconnect(FetchConflicts),
        new Disconnect(FetchSharedLists),
        new Disconnect(FetchAnnouncements),
        new Disconnect(GetFellowVillagers),
        new Disconnect(CourtyardActions.FetchAll),
        new Disconnect(CircleActions.FetchAll),
      ])
      .pipe(
        concatMap(() =>
          this.store.dispatch([
            new ClearShares(),
            new ClearExchanges(),
            new ClearHangouts(),
            new ClearSupportRequests(),
            new ClearConflicts(),
            new ClearSharedList(),
            new ClearAnnouncements(),
            new CourtyardActions.Clear(),
            new ClearAllPosts(),
            new ClearAllVillagers(),
            new CircleActions.Clear(),
          ])
        ),
        concatMap(() =>
          this.store.dispatch([
            new GetFellowVillagers({ villageUid: villageId }),
            new GetVillage({ uid: villageId }),
            new GetInitialVillageData({
              uid: village._UID,
              villagerCircleIds: villageCircleAccess,
            }),
            new GetVillageBulletinBoard({
              uid: villageId,
              villagerCircleIds: villageCircleAccess,
            }),
          ])
        ),
        // THIS ONE STEP IS DIFFERENT FROM SWITCH VILLAGE ACTION
        concatMap(() => {
          if (updateVillageCircles) {
            return this.store.dispatch(
              new CircleActions.AddVillagerToJoinedCircles({
                joinedCircles: newVillage.CIRCLES,
                villagerId,
              })
            );
          } else return of('done');
        })
      );
  }

  @Action(SwitchVillage)
  switchVillage(ctx: StateContext<VillagerStateModel>, action: SwitchVillage) {
    const { villageUid, villagerUid } = action.payload;

    this.villagerSvc.updateWithoutConverter(villagerUid, {
      VILLAGE: villageUid,
    });

    const { currentVillager } = ctx.getState();
    const newJoinedVillage = currentVillager.VILLAGES.find(
      (x) => x.UID === villageUid
    );

    const newCircleAccess: string[] = newJoinedVillage.CIRCLE_UIDS;

    return this.store
      .dispatch([
        new Disconnect(GetVillage),
        new Disconnect(FetchShares),
        new Disconnect(FetchHangouts),
        new Disconnect(FetchSupportRequests),
        new Disconnect(FetchExchanges),
        new Disconnect(FetchConflicts),
        new Disconnect(FetchSharedLists),
        new Disconnect(FetchAnnouncements),
        new Disconnect(GetFellowVillagers),
        new Disconnect(CourtyardActions.FetchAll),
        new Disconnect(CircleActions.FetchAll),
      ])
      .pipe(
        concatMap(() =>
          this.store.dispatch([
            new ClearShares(),
            new ClearExchanges(),
            new ClearHangouts(),
            new ClearSupportRequests(),
            new ClearConflicts(),
            new ClearSharedList(),
            new ClearAnnouncements(),
            new CourtyardActions.Clear(),
            new ClearAllPosts(),
            new ClearAllVillagers(),
            new CircleActions.Clear(),
          ])
        ),
        concatMap(() =>
          this.store.dispatch([
            new GetFellowVillagers({ villageUid }),
            new GetVillage({ uid: villageUid }),
            new GetInitialVillageData({
              uid: villageUid,
              villagerCircleIds: newCircleAccess,
            }),
          ])
        ),
        concatMap(() =>
          this.store.dispatch(
            new GetVillageBulletinBoard({
              uid: villageUid,
              villagerCircleIds: newCircleAccess,
            })
          )
        )
      );
  }

  @Action(SignupJoinVillage)
  signupJoinVillage(
    ctx: StateContext<VillagerStateModel>,
    action: SignupJoinVillage
  ) {
    const { village, villager } = action.payload;

    console.log('[DEBUG] SignupJoinVillage ', villager, village);

    // 0. Add villager to village (can be safely handled external to this actions)
    this.store.dispatch(new AddVillagerToVillage({ villager, village }));

    // let role = village.CREATED_BY === villager._UID ? 'Leader' : 'Member';
    // const circles: JoinedCircle[] = [{ NAME: 'Members', UID: 'MEMBERS' }];
    const villagerId = villager._UID;
    const villageId = village._UID;

    const joinedVillage: JoinedVillage = villager.VILLAGES.find(
      (x) => x.UID === villageId
    );

    const villageCircleAccess: string[] = joinedVillage.CIRCLE_UIDS;

    return this.store
      .dispatch([
        new GetFellowVillagers({ villageUid: villageId }),
        new GetVillage({ uid: villageId }),
        new GetInitialVillageData({
          uid: village._UID,
          villagerCircleIds: villageCircleAccess,
        }),
      ])
      .pipe(
        concatMap(() => {
          console.log(
            '[DEBUG] Got villagers, village and initial data.\n Now adding villager to village circle records'
          );
          return this.store.dispatch(
            new CircleActions.AddVillagerToJoinedCircles({
              joinedCircles: joinedVillage.CIRCLES,
              villagerId,
            })
          );
        })
      );
  }

  @Action(UpdateVillagerLastActive)
  updateLastActive(ctx, action: UpdateVillagerLastActive) {
    return this.villagerSvc.updateWithoutConverter(action.payload.villagerId, {
      LAST_ACTIVE: firebase.default.firestore.FieldValue.serverTimestamp(),
    });
  }

  @Action(SetVillagerHasProfilePhoto)
  setHasPhoto(ctx: StateContext<VillagerStateModel>) {
    const id = ctx.getState().currentVillager._UID;
    if (id) {
      return this.villagerSvc.updateWithoutConverter(id, {
        HAS_PROFILE_PIC: true,
      });
    }
  }

  @Action(SetStatusOnline)
  setOnline(ctx, action: SetStatusOnline) {
    const { currentVillager } = ctx.getState();
    if (currentVillager) {
      console.log('[App] Setting status online');
      ctx.dispatch(new SetPushBadgeCount());
      return this.villagerSvc.updateWithoutConverter(currentVillager._UID, {
        LAST_ACTIVE: firebase.default.firestore.FieldValue.serverTimestamp(),
      });
    }
  }

  @Action(SetStatusOffline)
  setOffline(ctx, action: SetStatusOffline) {}

  @Action(VillagerActions.OptIntoOnboardingEmails)
  optIn(ctx, action: VillagerActions.OptIntoOnboardingEmails) {
    const { optIn } = action.payload;
    const { currentVillager } = ctx.getState();
    if (currentVillager) {
      console.log('updating record');
      return this.villagerSvc.updateWithoutConverter(currentVillager._UID, {
        ONBOARDING_EMAILS_OPT_IN: optIn,
      });
    }
  }

  @Action(SetFCMToken)
  setFCMToken(ctx, action: SetFCMToken) {
    const { currentVillager } = ctx.getState();
    const { fcmToken } = action.payload;
    return this.villagerSvc.updateWithoutConverter(currentVillager._UID, {
      FCM_TOKEN: fcmToken,
    });
  }

  @Action(RemoveFCMToken)
  removeFCMToken(ctx, action: RemoveFCMToken) {
    const { currentVillager } = ctx.getState();
    return this.villagerSvc.updateWithoutConverter(currentVillager._UID, {
      FCM_TOKEN: null,
    });
  }

  @Action(PushAllowed)
  pushAllowed(ctx: StateContext<VillagerStateModel>) {
    const { currentVillager } = ctx.getState();
    return this.villagerSvc.updateWithoutConverter(currentVillager._UID, {
      NOTIFICATIONS_ALLOWED: true,
    });
  }

  @Action(PushRevoked)
  pushRevoked(ctx: StateContext<VillagerStateModel>) {
    const { currentVillager } = ctx.getState();
    return this.villagerSvc.updateWithoutConverter(currentVillager._UID, {
      NOTIFICATIONS_ALLOWED: false,
    });
  }

  @Action(SetJoinedVillagePushSetting)
  updateJoinedVillagePushNotificationSettings(
    ctx: StateContext<VillagerStateModel>,
    action: SetJoinedVillagePushSetting
  ) {
    const { currentVillager } = ctx.getState();
    const { villageId, PUSH } = action.payload;
    return this.updateJoinedVillage(currentVillager, villageId, { PUSH });
  }

  updateJoinedVillage(
    currentVillager: Villager,
    villageId,
    updatedVillage: Partial<JoinedVillage>
  ) {
    const joinedVillages = [...currentVillager.VILLAGES];
    const villageIndex = joinedVillages.findIndex((v) => v.UID === villageId);
    const currentVillage = joinedVillages[villageIndex];
    if (!currentVillage) return;

    joinedVillages[villageIndex] = { ...currentVillage, ...updatedVillage };

    const batch = this.db.firestore.batch();
    const villagerUpdateRef = this.db.firestore.doc(
      `VILLAGERS/${currentVillager._UID}`
    );
    batch.update(villagerUpdateRef, { VILLAGES: joinedVillages });
    return batch.commit();
  }

  @Action(ClearVillager)
  clearVillager(ctx: StateContext<VillagerStateModel>) {
    ctx.setState({
      currentVillager: null,
      fellowVillagers: [],
      allVillagers: [],
    });
    console.log('[Villager State] successfully cleared villager');
  }

  @Action(GetVillager)
  getVillager(ctx, action: GetVillager) {
    const { uid } = action.payload;
    console.log('[Villager State] Getting villager: ', uid);
    this.notificationSvc.setVillagerId(uid);
    return this.store.dispatch([
      new SetVillagerOnFeed({ villagerId: uid, blockedVillagers: [] }),
    ]);
  }

  @Action(StreamConnected(GetVillager))
  streamConnected(
    ctx: StateContext<VillagerStateModel>,
    { action }: Connected<GetVillager>
  ) {
    console.log('[Villager State] Stream connected');
    if (this.platform.is('capacitor')) {
      this.storeDeviceInfo();
    }
  }

  @Action(StreamEmitted(GetVillager))
  streamEmitted(
    ctx: StateContext<VillagerStateModel>,
    { action, payload }: Emitted<GetVillager, Villager>
  ) {
    if (!payload || payload == null) {}
    else {
      ctx.patchState({
        currentVillager: payload,
      });
      const { _UID, BLOCKED_VILLAGERS } = payload;
      ctx.dispatch([
        new SetVillagerOnFeed({
          villagerId: _UID,
          blockedVillagers: BLOCKED_VILLAGERS,
        }),
      ]);
    }
  }

  @Action(StoreVillagerDeviceInfo)
  storeVillagerDeviceInfo(
    ctx: StateContext<VillagerStateModel>,
    action: StoreVillagerDeviceInfo
  ) {
    const { currentVillager } = ctx.getState();
    let deviceInfo: string[] = [];
    if (currentVillager.DEVICE_INFO) {
      deviceInfo = [...currentVillager.DEVICE_INFO];
    }

    if (!deviceInfo.includes(action.payload.deviceModel)) {
      deviceInfo.push(action.payload.deviceModel);

      this.villagerSvc.updateWithoutConverter(currentVillager._UID, {
        DEVICE_INFO: deviceInfo,
      });
    }
  }

  @Action(GetFellowVillagers)
  getFellowVillagers(
    ctx: StateContext<VillagerStateModel>,
    action: GetFellowVillagers
  ) {
    // console.log('[DEBUG] Getting fellow villagers ');
  }

  @Action(StreamConnected(GetFellowVillagers))
  villagersStreamConnected(
    ctx: StateContext<VillagerStateModel>,
    { action }: Connected<GetFellowVillagers>
  ) {
    console.log('[Villager State] Stream connected');
  }

  @Action(StreamDisconnected(GetFellowVillagers))
  villagersStreamDisconnected(
    ctx: StateContext<VillagerStateModel>,
    { action }: Disconnected<GetFellowVillagers>
  ) {
    console.log('[Villager State] Stream disconnected');
  }

  @Action(StreamEmitted(GetFellowVillagers))
  villagersStreamEmitted(
    ctx: StateContext<VillagerStateModel>,
    { action, payload }: Emitted<GetFellowVillagers, Villager[]>
  ) {
    const villager = ctx.getState().currentVillager;
    payload = payload.sort((a, b) => {
      if (`${a.FIRST_NAME} ${a.LAST_NAME}` < `${b.FIRST_NAME} ${b.LAST_NAME}`) {
        return -1;
      }
      if (`${a.FIRST_NAME} ${a.LAST_NAME}` > `${b.FIRST_NAME} ${b.LAST_NAME}`) {
        return 1;
      }
      return 0;
    });
    const villagers = payload.filter((x) => x._UID !== villager._UID);
    ctx.patchState({
      fellowVillagers: villagers,
      allVillagers: payload,
    });
  }

  @Action(ClearAllVillagers)
  clearVillagers(ctx: StateContext<VillagerStateModel>) {
    ctx.patchState({
      fellowVillagers: [],
      allVillagers: [],
    });
  }

  private storeDeviceInfo = async () => {
    const info = await Device.getInfo();
    this.store.dispatch(
      new StoreVillagerDeviceInfo({ deviceModel: info.model })
    );
  };
}

export function convertVillagerToParticipant(
  villager: Villager,
  villageId: string
): DirectParticipant {
  const { FIRST_NAME, LAST_NAME, _UID, FCM_TOKEN } = villager;
  const { PUSH } = villager.VILLAGES.find((v) => v.UID === villageId) || {};
  return {
    UID: _UID,
    FIRST_NAME,
    LAST_NAME,
    FCM_TOKEN,
    PUSH,
    ACCEPTED: true,
    REJECTED: false,
  };
}
