import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-post-circle-explain-popover',
  template: `
    <div style="padding: 10px; background: var(--ion-color-light);">
      <div class="subheader">Control Circle Access</div>
      <p>
        You can control who in your
        <app-village-label></app-village-label> see's your post. Select one or
        multiple circles. You can edit this later as well.
      </p>
      <p>
        The default is to share with all <app-members-label [plural]="true" [showTitlecase]="true"></app-members-label> in your
        <app-village-label></app-village-label>.
      </p>
    </div>
  `,
})
export class PostCircleExplainPopoverComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
