import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-create-bulletin-modal',
  templateUrl: './create-bulletin-modal.page.html',
  styleUrls: ['./create-bulletin-modal.page.scss'],
})
export class CreateBulletinModalPage implements OnInit {

  @Input() description?: string;
  constructor(private modalCtrl: ModalController) { }

  ngOnInit() {
  }

  close() {
    this.modalCtrl.dismiss()
  }

}
