import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Store } from '@ngxs/store';
import { ChatMessage } from 'src/app/models/chat-message.model';
import { CourtyardActions } from 'src/app/state/courtyard.actions';
import { VillagerState } from 'src/app/state/villager.state';
import { PickerModule } from '@ctrl/ngx-emoji-mart';

@Component({
  selector: 'app-emoji-mart-modal',
  templateUrl: './emoji-mart-modal.page.html',
  styleUrls: ['./emoji-mart-modal.page.scss'],
})
export class EmojiMartModalPage implements OnInit {
  currentUserUid: string;
  emojiMart: boolean = false;

  @Input() message: ChatMessage;
  @Input() type: 'post' | 'dm' | 'courtyard';
  constructor(private store: Store, public modalCtrl: ModalController) {}

  ngOnInit() {
    this.currentUserUid = this.store.selectSnapshot(VillagerState.uid);
  }

  closeEmojiMart() {
    this.emojiMart = false;
  }
}
