<ion-grid>
  <ion-row>
    <ion-col>
      <div class="subheader">Description</div>
      <!-- <div [innerHtml]="conflict?.BODY | safehtml"></div> -->

      <div *ngIf="conflict?.BODY">
        <div
          class="post-message-body"
          *ngIf="!showFullBody; else fullBody"
          [innerHtml]="
            conflict.BODY
              | truncateBody: 400:true
              | mentionified: conflict._MENTION_UIDS
              | safehtml
          "
        ></div>
        <ion-button
          style="float: right; margin-top: 5px"
          size="small"
          color="dark"
          fill="outline"
          *ngIf="conflict.BODY.length > 400 && !showFullBody"
          (click)="goToDetails()"
          >Read full post
          <ion-icon name="chevron-forward-outline"></ion-icon>
        </ion-button>
        <ng-template #fullBody>
          <div
            (click)="clickBody($event)"
            class="post-message-body"
            [innerHtml]="
              conflict.BODY | mentionified: conflict._MENTION_UIDS | safehtml
            "
          ></div>
        </ng-template>
      </div>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col *ngIf="conflict?.WHATS_NEEDED">
      <div class="subheader">What's Needed</div>
      <div [innerHtml]="conflict?.WHATS_NEEDED | safehtml"></div>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col *ngIf="conflict?.WHATS_DESIRED">
      <div class="subheader">Desired Outcome</div>
      <div [innerHtml]="conflict?.WHATS_DESIRED | safehtml"></div>
    </ion-col>
  </ion-row>
</ion-grid>
