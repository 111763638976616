import { MemberInvite } from '../models/member-invite.model';

export namespace MemberInviteActions {
  export class Create {
    static readonly type = '[Member Invite] Create';
    constructor(
      public payload: {
        invite: MemberInvite;
        onError: (err: Error) => void;
        onSuccess: () => void;
        onAlreadyExists: (message: string) => void;
      }
    ) {}
  }

  export class FetchAll {
    static readonly type = '[Member Invite] Fetch all invites';
    constructor(public payload: { villageId: string }) {}
  }

  export class RefreshAll {
    static readonly type = '[Member Invite] Refresh all invites';
    constructor(public payload: { villageId: string }) {}
  }

  export class MarkInviteAsAccepted {
    static readonly type = '[Member Invite] Mark As Accepted';
    constructor(public payload: { inviteId: string; email: string }) {}
  }
}
