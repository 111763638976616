<ion-header class="ion-no-border" translucent="false">
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button (click)="close()">
        <ion-icon color="dark" slot="icon-only" name="close-circle"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content fullscreen class="ion-padding" scroll-y="false">
  <!-- If we need navigation buttons -->
  <div class="swiper-button-prev" (click)="previous()"></div>
  <div class="swiper-button-next" (click)="next()"></div>
  <ion-slides
    [pager]="true"
    #slides
    (ionSlideNextEnd)="onNext()"
    (ionSlidePrevEnd)="onPrevious()"
  >
    <!-- <ion-slide>
      <div class="slide">
        <img src="../../../assets/images/onboarding-header.png" />
        <h2>Welcome</h2>
        <p>Welcome to <b>reVillager</b>, let's give you a short walkthrough!</p>
      </div>
    </ion-slide> -->

    <ion-slide>
      <img src="../../../assets/images/onboarding-header.png" />

      <h2 class="header">Welcome!</h2>
      <p>
        <b>reVillager</b> is a platform to help strengthen your community. This
        is a space you can chat directly or in groups, organize get togethers,
        exchange goods and services, and even support each other through the
        highs and lows of life. Best of all, only those in your community can
        see what you talk about or share!
      </p>
    </ion-slide>

    <ion-slide>
      <h2>Here's a quick video on how to setup a village</h2>
      <iframe
        width="90%"
        height="315"
        [src]="youTubeOnboardingLink | safeurl"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>
    </ion-slide>

    <ion-slide>
      <img src="../../../assets/images/onboarding_courtyards.png" />
      <h2 class="header">Where can I chat and find information?</h2>
      <p>
        The home page is where you will find group chatrooms, upcoming events,
        community announcements and even your fellow members. Only those in your
        community can see the chatrooms.
      </p>
    </ion-slide>

    <ion-slide>
      <img src="../../../assets/images/onboarding_bulletin.png" />
      <h2 class="header">Where can I post or share things?</h2>
      <p>
        The board is where you will find all posts made by members of your
        community. You can search for events, exchanges, shares, and so much
        more! Clicking on any post will show you the full details as well as the
        chatroom for that post.
      </p>
    </ion-slide>

    <ion-slide>
      <img src="../../../assets/images/profile.png" />
      <h2 class="header">How can I help others get to know me?</h2>
      <p>
        Your profile includes what you need to help others get to know you
        better. Add a photo and write your bio so when others come to your page,
        they know who they are talking to. This builds trust, a foundational
        block to all communities.
      </p>
    </ion-slide>

    <ion-slide>
      <img src="../../../assets/images/tutorial_onboarding_email.png" />
      <h2 class="header">Want to learn more?</h2>
      <p>
        Would you like to receive 7 messages from our team over the next week
        with guides showing you how to get the most out of reVillager?
      </p>
      <div>
        <ion-button (click)="optIntoOnboardingEmails() && next()">
          Count me in! <ion-icon slot="end" name="mail"></ion-icon>
        </ion-button>
      </div>
      <div style="margin-top: 20px">
        <ion-button size="small" fill="clear" color="dark" (click)="next()">
          No thanks <ion-icon slot="end" name="close-circle-outline"></ion-icon>
        </ion-button>
      </div>
    </ion-slide>

    <ion-slide>
      <img src="../../../assets/images/onboarding-header.png" />
      <h2>Have fun!</h2>
      <p>
        Fill out your profile, send a message, create an event or offer a
        skill.. And always remember, only members of your community have access
        to your posts or messages that you send them. Invite your closest
        connections and keep your space safe, secure, and trusted.
      </p>
      <ion-button fill="outline" (click)="goToHome()"
        >Done! <ion-icon slot="end" name="arrow-forward"></ion-icon
      ></ion-button>
    </ion-slide>
  </ion-slides>
</ion-content>
