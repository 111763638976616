<ion-header class="ion-no-border" translucent="false">
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button (click)="modalCtrl.dismiss({action: 'regular-close'})">
        <ion-icon color="dark" slot="icon-only" name="close-circle"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>Request Support</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-card>
    <ion-card-content>
      <p style="margin-bottom: 5px">
        Describe how you're feeling and how your
        <app-village-label></app-village-label> can support you.
      </p>
      <ion-grid>
        <ion-row>
          <ion-col size="4" *ngFor="let emotion of emotions">
            <ion-button
              class="checkin"
              [color]="getColor(emotion.emotion)"
              expand="block"
              (click)="selectEmotion(emotion.emotion)"
            >
              <div>
                <span style="font-size: 28px">{{ emotion.icon }}</span>
                <br />
                {{ emotion.emotion | titlecase }}
              </div>
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
      <div style="margin: 12px">
        <div class="step-text">Step 2:</div>
        <form [formGroup]="supportForm">
          <app-post-mentionable-input
            [mentionables]="mentionables"
            [placeholderText]="placeholderText"
            [shouldDisplayAbove]="false"
          >
          </app-post-mentionable-input>
        </form>

        <ion-grid *ngIf="edit">
          <ion-row
            *ngIf="tmpImageUrls.length > 0"
            class="ion-align-items-center"
          >
            <ion-col size="12">
              <div
                class="subheader"
                style="margin-top: 10px; margin-bottom: 5px"
              >
                Edit Photos
              </div>
            </ion-col>
            <ion-col *ngFor="let path of tmpImageUrls; let i = index">
              <div class="post-image-wrapper">
                <app-edit-images
                  (deletePhotoEvent)="handleDeletePhoto($event, i)"
                  [path]="path"
                ></app-edit-images>
              </div>
            </ion-col>
          </ion-row>
          <ion-row *ngIf="tmpDocUrls.length > 0" class="ion-align-items-center">
            <ion-col>
              <div
                class="subheader"
                style="margin-top: 10px; margin-bottom: 5px"
              >
                Edit Documents
              </div>
              <app-post-document
                *ngFor="let doc of tmpDocUrls; let i = index"
                (click)="handleDeleteDocument(i)"
                [document]="doc"
                [edit]="true"
              ></app-post-document>
            </ion-col>
          </ion-row>
        </ion-grid>

        <ion-grid>
          <ion-row>
            <ion-col>
              <div class="subheader">Add to your post</div>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col size="12" sizeLg="4">
              <ion-button
                color="secondary"
                expand="block"
                (click)="showPhotos = !showPhotos"
              >
                <ion-icon slot="start" name="images"></ion-icon>
                <ion-label>Photos/Docs</ion-label>
              </ion-button>
            </ion-col>
            <ion-col size="12" sizeLg="4">
              <ion-button
                color="dark"
                expand="block"
                (click)="showTopics = !showTopics"
              >
                <ion-icon slot="start" name="pricetags"></ion-icon>
                <ion-label>Topics</ion-label>
              </ion-button>
            </ion-col>
          </ion-row>
        </ion-grid>

        <app-post-file-upload
          *ngIf="showPhotos"
          [tmpDocUrls]="tmpDocUrls"
          [tmpImageUrls]="tmpImageUrls"
        ></app-post-file-upload>

        <app-add-topics
          *ngIf="showTopics"
          [topics]="topics"
          (setTopics)="onSetTopics($event)"
        ></app-add-topics>

        <app-post-circle-assignment
          [alreadyAssignedCircles]="assignedCircles"
        ></app-post-circle-assignment>

        <ion-grid class="ion-no-padding">
          <ion-row>
            <ion-col size="12">
              <ion-button
                *ngIf="!edit"
                [disabled]="!selectedEmotion"
                style="margin-bottom: 10px"
                expand="block"
                color="primary"
                (click)="submitSupportTunein()"
              >
                <ion-label>Get Support</ion-label>

                <ion-chip
                  *ngIf="(showKarma$ | async) === true"
                  style="position: absolute; right: 5px"
                  slot="end"
                  color="light"
                  class="karma-chip"
                >
                  <ion-avatar>
                    <img src="../../../assets/images/badge-images/karma.png" />
                  </ion-avatar>
                  <ion-label><strong>+{{SUPPORT_KARMA}}</strong></ion-label>
                </ion-chip>
              </ion-button>
              <ion-button
                *ngIf="edit"
                style="margin-bottom: 10px"
                expand="block"
                color="primary"
                (click)="updateRequest()"
                >Update Request
              </ion-button>
            </ion-col>
          </ion-row>
        </ion-grid>
      </div>
    </ion-card-content>
  </ion-card>
</ion-content>
