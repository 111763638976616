<!-- not using in favor of isNative -->
<!-- [set]="emojiSet" -->
<emoji-mart
  [style]="{ position: 'absolute', left: '0', right: '0', width: '100%' }"
  [autoFocus]="true"
  [showPreview]="false"
  (emojiClick)="react($event)"
  title="Skin Tone"
  [isNative]="isNative"
  [useButton]="true"
></emoji-mart>
