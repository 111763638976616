<ion-header class="ion-no-border" translucent="false">
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button (click)="modalCtrl.dismiss({action: 'regular-close'})">
        <ion-icon color="dark" slot="icon-only" name="close-circle"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title
      ><span *ngIf="!edit">Begin</span><span *ngIf="edit">Update</span> Conflict
      Resolution</ion-title
    >
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-card>
    <ion-card-content>
      <p style="margin-bottom: 5px">
        Thanks for taking the time to move towards conflict resolution. Please
        describe how you're feeling and what outcome you'd like to arrive at.
      </p>
      <form [formGroup]="postForm">
        <ion-item
          lines="none"
          class="full-width-item form-outline-item"
          [ngClass]="{
          invalid: title.invalid && (title.dirty || title.touched)
        }"
        >
          <ion-input
            formControlName="title"
            placeholder="What is your main conflict?"
          ></ion-input>
        </ion-item>
        <app-post-mentionable-input
          [mentionables]="mentionables"
          [placeholderText]="placeholderText"
          [shouldDisplayAbove]="false"
        >
        </app-post-mentionable-input>

        <ion-item lines="none" class="full-width-item">
          <ion-textarea
            rows="8"
            autocapitalize="on"
            autocorrect="on"
            formControlName="whatsNeeded"
            placeholder="What do you need in this moment? (optional) "
            [ngClass]="{
              invalid: whatsNeeded.invalid && whatsNeeded.touched
            }"
          >
          </ion-textarea>
        </ion-item>

        <ion-item lines="none" class="full-width-item">
          <ion-textarea
            rows="8"
            autocapitalize="on"
            autocorrect="on"
            formControlName="whatsDesired"
            placeholder="What outcome would you like to arrive at? (optional) "
            [ngClass]="{
              invalid: whatsDesired.invalid && whatsDesired.touched
            }"
          >
          </ion-textarea>
        </ion-item>
      </form>

      <ion-grid *ngIf="edit">
        <ion-row *ngIf="tmpImageUrls.length > 0" class="ion-align-items-center">
          <ion-col size="12">
            <div class="subheader" style="margin-top: 10px; margin-bottom: 5px">
              Edit Photos
            </div>
          </ion-col>
          <ion-col *ngFor="let path of tmpImageUrls; let i = index">
            <div class="post-image-wrapper">
              <app-edit-images
                (deletePhotoEvent)="handleDeletePhoto($event, i)"
                [path]="path"
              ></app-edit-images>
            </div>
          </ion-col>
        </ion-row>
        <ion-row *ngIf="tmpDocUrls.length > 0" class="ion-align-items-center">
          <ion-col>
            <div class="subheader" style="margin-top: 10px; margin-bottom: 5px">
              Edit Documents
            </div>
            <app-post-document
              *ngFor="let doc of tmpDocUrls; let i = index"
              (click)="handleDeleteDocument(i)"
              [document]="doc"
              [edit]="true"
            ></app-post-document>
          </ion-col>
        </ion-row>
      </ion-grid>

      <ion-grid>
        <ion-row>
          <ion-col>
            <div class="subheader">Add to your post</div>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="12" sizeLg="4">
            <ion-button
              color="secondary"
              expand="block"
              (click)="showPhotos = !showPhotos"
            >
              <ion-icon slot="start" name="images"></ion-icon>
              <ion-label>Photos/Docs</ion-label>
            </ion-button>
          </ion-col>
          <ion-col size="12" sizeLg="4">
            <ion-button
              color="dark"
              expand="block"
              (click)="showTopics = !showTopics"
            >
              <ion-icon slot="start" name="pricetags"></ion-icon>
              <ion-label>Topics</ion-label>
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>

      <app-post-file-upload
        *ngIf="showPhotos"
        [tmpDocUrls]="tmpDocUrls"
        [tmpImageUrls]="tmpImageUrls"
      ></app-post-file-upload>

      <app-add-topics
        *ngIf="showTopics"
        [topics]="topics"
        (setTopics)="onSetTopics($event)"
      ></app-add-topics>

      <app-post-circle-assignment
        [alreadyAssignedCircles]="assignedCircles"
      ></app-post-circle-assignment>

      <ion-button
        *ngIf="edit === false"
        [disabled]="postForm.invalid"
        expand="block"
        (click)="createConflict()"
      >
        <ion-label>Create</ion-label>
        <ion-chip
          *ngIf="(showKarma$ | async) === true"
          style="position: absolute; right: 5px"
          slot="end"
          color="light"
          class="karma-chip"
        >
          <ion-avatar>
            <img src="../../../assets/images/badge-images/karma.png" />
          </ion-avatar>
          <ion-label><strong>+{{CONFLICT_KARMA}}</strong></ion-label>
        </ion-chip>
      </ion-button>
      <ion-button
        *ngIf="edit === true"
        [disabled]="postForm.invalid"
        expand="block"
        (click)="updateConflict()"
      >
        <ion-label>Save Changes</ion-label>
      </ion-button>
    </ion-card-content>
  </ion-card>
</ion-content>
