import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AudioService } from 'src/app/services/audio.service';
import { VoiceNoteService } from 'src/app/services/voice-note.service';
import { SetVoiceNoteUrl, VoiceNoteEnded } from 'src/app/state/app.actions';
import { AppState } from 'src/app/state/app.state';

@Component({
  selector: 'app-chat-voice-note',
  templateUrl: './chat-voice-note.component.html',
  styleUrls: ['./chat-voice-note.component.scss'],
})
export class ChatVoiceNoteComponent implements OnInit, OnDestroy {
  tryCount = 0;
  MAX_TRY = 2;
  errorWithFile = false;
  voiceNoteUrl$: Observable<string>;
  durationDisplay: string;
  isPlaying = false;
  isLoading = false;
  hasEnded = false;
  playback: number;
  currentTime: number = 0;
  currentTimeDisplay: string;
  voiceServiceVoiceNote: string;
  timer: any;
  stateSubscription: Subscription;
  loadingSub: Subscription;

  @Input() voiceNotePath: string;
  @Input() duration: number;
  @Input() messageBody?: string;
  constructor(
    private voiceService: VoiceNoteService,
    private audioSvc: AudioService,
    private store: Store
  ) {}

  ngOnInit() {
    if (this.voiceNotePath) {
      this.voiceNoteUrl$ = this.voiceService.getVoiceNote(this.voiceNotePath);
      let timer;
      const sub = this.voiceNoteUrl$.subscribe(
        (v: string) => {
          this.voiceServiceVoiceNote = v;
          this.stateListener();
          this.loadingListener();
          sub.unsubscribe();
          clearInterval(timer);
        },
        (err) => {
          timer = setInterval(() => {
            if (this.tryCount < this.MAX_TRY) {
              this.tryCount++;
              this.voiceNoteUrl$ = this.voiceService.getVoiceNote(
                this.voiceNotePath
              );
              const newSub = this.voiceNoteUrl$.subscribe((v: string) => {
                this.voiceServiceVoiceNote = v;
                this.stateListener();
                this.loadingListener();
                newSub.unsubscribe();
                clearInterval(timer);
              });
            } else {
              clearInterval(timer);
              this.errorWithFile = true;
            }
          }, 5000);
        }
      );
    }
  }

  loadingListener() {
    if (this.loadingSub) {
      this.loadingSub.unsubscribe();
    }

    this.loadingSub = combineLatest([
      this.store.select(AppState.voiceNoteUrl),
      this.store.select(AppState.audioIsLoading),
    ]).subscribe(([url, loading]) => {
      if (url === this.voiceServiceVoiceNote) {
        console.log('Loading: ', loading);
        this.isLoading = loading;
      } else {
        this.isLoading = false;
      }
    });
  }

  stateListener() {
    if (this.stateSubscription) {
      this.stateSubscription.unsubscribe();
    }
    this.stateSubscription = combineLatest([
      this.voiceNoteUrl$,
      this.store.select(AppState.voiceNoteUrl),
      this.store.select(AppState.voiceNoteCurrentTime),
      this.store.select(AppState.voiceNoteEnded),
    ]).subscribe(([localUrl, globalUrl, currentTime, ended]) => {
      if (localUrl && globalUrl && currentTime) {
        if (globalUrl === this.voiceServiceVoiceNote) {
          this.currentTime = Math.ceil(currentTime);
          this.playback = currentTime / this.duration;
          // console.log('Component playback to display: ', this.playback)
          if (this.hasEnded) this.currentTime = this.duration; // a hack for now
          if (ended) {
            console.log('current note ended');
            this.isPlaying = false;
            this.hasEnded = true;
            this.store.dispatch(new VoiceNoteEnded({ voiceNoteUrl: null }));
          }
        } else {
          this.isPlaying = false;
          this.currentTime = 0;
        }
      }
    });
  }

  togglePlayback(url: any) {
    this.isPlaying = !this.isPlaying;
    this.hasEnded = false;
    this.audioSvc.toggle(url);
  }

  ngOnDestroy(): void {
    if (this.stateSubscription) {
      this.stateSubscription.unsubscribe();
    }

    if (this.loadingSub) {
      this.loadingSub.unsubscribe();
    }
  }
}
