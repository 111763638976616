import { ExchangeItem } from '../models/exchange.model';
import { Share } from '../models/Share.model';
import { GenericPost } from '../models/post-core.model';
import { SupportRequest } from '../models/support-request.model';
import { Hangout } from '../models/hangout.model';
import { ChatMessage } from 'src/app/models/chat-message.model';
import { JoinedVillage, Villager } from '../models/villager.model';
import { Village } from '../models/village.model';
import {
  DirectChatroom,
  DirectParticipant,
} from '../models/direct-chatroom.model';
import { ConflictResolution } from '../models/conflict-resolution.model';
import { AppNotification } from '../models/notification.model';
import { Announcement } from '../models/announcement.model';
import { SharedList } from '../models/shared-list.model';
import { JoinedCircle } from '../models/circle.model';

export class Login {
  static readonly type = '[Auth] Login';
  constructor(public payload: { email: string; password: string }) {}
}

export class Logout {
  static readonly type = '[Auth] Logout';
}

export class BuildFeed {
  static readonly type = '[Feed] Build Feed';
}

export class SetVillagerOnFeed {
  static readonly type = '[Feed] Set Villager';
  constructor(
    public payload: { villagerId: string; blockedVillagers: string[] }
  ) {}
}

export class FeedBuildComplete {
  static readonly type = '[Feed] Feed Fetch Complete';
}

export class FeedBuildStart {
  static readonly type = '[Feed] Feed Fetch Started';
}

export class FetchShares {
  static readonly type = '[Shares] Fetch Shares';
  constructor(
    public payload: { villageUID: string; villagerCircleIds: string[] }
  ) {}
}

export class FetchAnnouncements {
  static readonly type = '[Announcements] Fetch Announcements';
  constructor(
    public payload: { villageUID: string; villagerCircleIds: string[] }
  ) {}
}

export class FetchConflicts {
  static readonly type = '[Conflicts] Fetch Conflicts';
  constructor(
    public payload: { villageUID: string; villagerCircleIds: string[] }
  ) {}
}

export class CreateConflict {
  static readonly type = '[Conflicts] Create Conflict';
  constructor(public payload: { post: ConflictResolution }) {}
}

export class FetchExchanges {
  static readonly type = '[Exchange] Fetch Exchanges';
  constructor(
    public payload: { villageUID: string; villagerCircleIds: string[] }
  ) {}
}

export class FetchHangouts {
  static readonly type = '[Hangout] Fetch Hangouts';
  constructor(
    public payload: { villageUID: string; villagerCircleIds: string[] }
  ) {}
}

export class FetchSupportRequests {
  static readonly type = '[Support] Fetch Support Requests';
  constructor(
    public payload: { villageUID: string; villagerCircleIds: string[] }
  ) {}
}

export class SetSelectedPostById {
  static readonly type = '[Village Chat] Set selected post by id';
  constructor(
    public payload: { postId: string; location: 'CHAT' | 'HOME' | 'DETAILS' }
  ) {}
}

export class SetSelectedPost {
  static readonly type = '[Village Chat] Set selected post';
  constructor(
    public payload: { post: GenericPost; location: 'CHAT' | 'HOME' | 'DETAILS' }
  ) {}
}

export class ReportPost {
  static readonly type = '[Post] Report Post';
  constructor(public payload: { post: GenericPost; reporterId: string }) {}
}

export class SilentlyUpdateSelectedPost {
  static readonly type = '[Village State] Silently set selected post';
  constructor(public payload: { post: GenericPost }) {}
}

export class FetchChatrooms {
  static readonly type = '[Village Chat] Fetch Chatrooms';
  constructor(public payload: { villageUID: string }) {}
}

export class SetSelectedChatroom {
  static readonly type = '[Village Chat] Set selected chatroom';
  constructor(public payload: { post: GenericPost }) {}
}

export class FetchChatroomMessages {
  static readonly type = '[Village Chat] Fetch Messages';
}

export class RefreshChatroomMessages {
  static readonly type = '[Village Chat] Refresh chat Messages';
}

export class FetchMessagesComplete {
  static readonly type = '[Village Chat] Messages Update Complete';
}

export class DisconnectDiscussionChat {
  static readonly type = '[Village Chat] Disconnect Discussion Chatroom';
}

export class ClearVillageChatroom {
  static readonly type = '[Village Chat] Clear Chatroom';
}

export class DiscussionReact {
  static readonly type = '[Village Chat] Add Reaction';
  constructor(
    public payload: { message: ChatMessage; emoji: string; villagerUid: string }
  ) {}
}

export class UpdatePostReadReceipts {
  static readonly type = '[Village Chat] Update Participant Read Receipts';
  constructor(public payload: { post: GenericPost }) {}
}

export class FilterPosts {
  static readonly type = '[Feed] Filter posts';
  constructor(public payload: { type: string }) {}
}

export class AddPostToParticipated {
  static readonly type = '[Feed] Add Post to Participated List';
  constructor(public payload: { post: GenericPost }) {}
}

export class ClearPostsFilter {
  static readonly type = '[Feed] Clear Posts Filter';
}

export class ClearAllPosts {
  static readonly type = '[Feed] Clear All Posts';
}

export class UpdateShare {
  static readonly type = '[Share] Update Share';
  constructor(public payload: { post: Share }) {}
}

export class UpdateAnnouncement {
  static readonly type = '[Announcement] Update Announcement';
  constructor(public payload: { announcement: Announcement }) {}
}

export class UpdateConflict {
  static readonly type = '[Conflict] Update Conflict';
  constructor(public payload: { post: ConflictResolution }) {}
}

export class UpdateHangout {
  static readonly type = '[Hangout] Update Hangout';
  constructor(public payload: { post: Hangout }) {}
}

export class UpdateExchange {
  static readonly type = '[Exchange] Update Exchange';
  constructor(public payload: { post: ExchangeItem }) {}
}

export class UpdateSupportRequest {
  static readonly type = '[Support Request] Update Support Request';
  constructor(public payload: { post: SupportRequest }) {}
}

export class DeleteShare {
  static readonly type = '[Share] Delete Share';
  constructor(public payload: { post: Share }) {}
}

export class DeleteAnnouncement {
  static readonly type = '[Announcement] Delete Announcement';
  constructor(public payload: { post: Announcement }) {}
}

export class DeleteConflict {
  static readonly type = '[Conflict] Delete Conflict';
  constructor(public payload: { post: ConflictResolution }) {}
}

export class DeleteHangout {
  static readonly type = '[Hangout] Delete Hangout';
  constructor(public payload: { post: Hangout }) {}
}

export class DeleteExchange {
  static readonly type = '[Exchange] Delete Exchange';
  constructor(public payload: { post: ExchangeItem }) {}
}

export class DeleteSupportRequest {
  static readonly type = '[Support Request] Delete Support Request';
  constructor(public payload: { post: SupportRequest }) {}
}

export class CreateShare {
  static readonly type = '[Share] Create Share';
  constructor(public payload: { post: Share }) {}
}

export class CreateAnnouncement {
  static readonly type = '[Announcement] Create Announcement';
  constructor(public payload: { announcement: Announcement }) {}
}

export class ClearAnnouncements {
  static readonly type = '[Announcement] Clear All Announcements';
}

export class ClearShares {
  static readonly type = '[Share] Clear All Shares';
}

export class ClearConflicts {
  static readonly type = '[Conflict] Clear All Conflicts';
}

export class ClearExchanges {
  static readonly type = '[Exchange] Clear All Exchanges';
}

export class ClearHangouts {
  static readonly type = '[Hangout] Clear All Hangouts';
}

export class ClearSupportRequests {
  static readonly type = '[Support Request] Clear All Support Requests';
}

export class CreateSupportRequest {
  static readonly type = '[Support Request] Create Support Request';
  constructor(public payload: { supportRequest: SupportRequest }) {}
}

export class CreateHangout {
  static readonly type = '[Hangout] Create Hangout';
  constructor(public payload: { hangout: Hangout }) {}
}

export class CreateExchange {
  static readonly type = '[Exchange] Create Exchange';
  constructor(public payload: { exchangeItem: ExchangeItem }) {}
}

/**
 * @deprecated
 */
export class CreateVillage {
  static readonly type = '[Village] Create Village';
  constructor(public payload: { village: Village }) {}
}

export class AddNameToVillage {
  static readonly type = '[Village] Add Name to Village';
  constructor(public payload: { villageUID: string; name: string }) {}
}

export class CreateNewVillage {
  static readonly type = '[Village] Create New Village';
  constructor(public payload: { village: Village }) {}
}

/**
 * @deprecated
 */
export class CreateEmptyVillage {
  static readonly type = '[Village] Create Empty Village';
  constructor(public payload: { creatorUID: string; villageUID: string }) {}
}

export class ClearVillage {
  static readonly type = '[Village] Clear Village';
}

export class UpdateVillage {
  static readonly type = '[Village] Update Village Ethos';
  constructor(public payload: { villageUID: string; village: Village }) {}
}

export class UpdateVillageTopics {
  static readonly type = '[Village] Update Village Topics';
  constructor(public payload: { topics: string[] }) {}
}

export class ClearAllVillagers {
  static readonly type = '[Villager] Clear All Villagers';
}

export class SetFCMToken {
  static readonly type = '[Villager] Set FCM Token';
  constructor(public payload: { fcmToken: string }) {}
}

export class RemoveFCMToken {
  static readonly type = '[Villager] Remove FCM Token';
}

export class PushAllowed {
  static readonly type = '[Villager] Allow Push Permission';
}

export class PushRevoked {
  static readonly type = '[Villager] Remove Push Permission';
}

export class SetJoinedVillagePushSetting {
  static readonly type = '[Villager] Toggle on/off village push notifications';
  constructor(public payload: { villageId: string; PUSH: boolean }) {}
}

// /**
//  * @deprecated use GetInitialVillageData & GetVillageBulletinBoard instead
//  */
export class GetVillage {
  static readonly type = '[Village] Get Village';
  constructor(public payload: { uid: string }) {}
}

export class GetInitialVillageData {
  static readonly type = '[Village] Get Initial Village Data';
  constructor(public payload: { uid: string; villagerCircleIds: string[] }) {}
}

export class GetVillageBulletinBoard {
  static readonly type = '[Village] Get Village Bulletin Board';
  constructor(public payload: { uid: string; villagerCircleIds: string[] }) {}
}

export class RefreshVillageAndVillager {
  static readonly type = '[Village] Refresh Village';
  constructor(public payload: { uid: string }) {}
}

export class SwitchVillage {
  static readonly type = '[Villager] Switch Village';
  constructor(public payload: { villagerUid: string; villageUid: string }) {}
}

export class JoinVillage {
  static readonly type = '[Villager] Join Village';
  constructor(
    public payload: {
      villager: Villager;
      village: Village;
      updateVillageCircles: boolean;
      circlesToJoin?: JoinedCircle[];
    }
  ) {}
}

export class SignupJoinVillage {
  static readonly type = '[Villager] Signup Join Village';
  constructor(public payload: { villager: Villager; village: Village }) {}
}

export class AddVillageToVillager {
  static readonly type = '[Villager] Add Village';
  constructor(public payload: { villager: Villager; village: Village }) {}
}

export class AddVillagerToVillage {
  static readonly type = '[Village] Add Villager';
  constructor(public payload: { villager: Villager; village: Village }) {}
}

export class RemoveVillageFromVillager {
  static readonly type = '[Villager] Remove Village';
  constructor(public payload: { villager: Villager; village: Village }) {}
}

export class RemoveVillagerFromVillage {
  static readonly type = '[Village] Remove Villager';
  constructor(public payload: { villager: Villager; village: Village }) {}
}

export class SetVillagerHasProfilePhoto {
  static readonly type = '[Villager] Set Villager Has Profile Photo';
}

export class SetStatusOnline {
  static readonly type = '[Villager] Set Villager Status Online';
}

export class SetStatusOffline {
  static readonly type = '[Villager] Set Villager Status Offline';
}

export class UpdateVillagerLastActive {
  static readonly type = '[Villager] Updating Villager Last Active Timestamp';
  constructor(public payload: { villagerId: string }) {}
}

export class ClearVillager {
  static readonly type = '[Villager] Clear Villager';
}

export class GetVillager {
  static readonly type = '[Villager] Get Villager';
  constructor(public payload: { uid: string }) {}
}

export class StoreVillagerDeviceInfo {
  static readonly type = '[Villager] Store Device Info';
  constructor(public payload: { deviceModel: string }) {}
}

export class GetFellowVillagers {
  static readonly type = '[Villager] Get Fellow Villagers';
  constructor(public payload: { villageUid: string }) {}
}

export class FetchVillagerNotifications {
  static readonly type = '[Notifications] Fetching app notifications';
  constructor(public payload: { villagerId: string, villageName: string }) {}
}

export class FilterAppNotifications {
  static readonly type = '[Notifications] Filter app notifications';
  constructor(
    public payload: { filter: 'courtyard' | 'village' | 'messages' | 'all' }
  ) {}
}

export class RefreshVillagerNotifications {
  static readonly type = '[Notifications] Refresh notifications';
  constructor(public payload: { villagerUid: string }) {}
}

export class ClearAllNotitications {
  static readonly type = '[Notifications] Clear notifications';
}

export class CourtyardNotificationLookup {
  static readonly type =
    '[Notification] Fetching Courtyard from App Notification';
  constructor(
    public payload: {
      courtyardId: string;
      type: 'COURTYARDS';
      villageId: string;
    }
  ) {}
}

export class AppNotificationPostLookup {
  static readonly type = '[Notification] Fetching Post from App Notification';
  constructor(
    public payload: {
      postId: string;
      type:
        | 'SHARES'
        | 'EXCHANGE'
        | 'SUPPORT_REQUESTS'
        | 'HANGOUTS'
        | 'CONFLICTS'
        | 'ANNOUNCEMENTS'
        | 'DIRECT'
        | 'COURTYARDS';
      villageId: string;
    }
  ) {}
}

export class PushNotificationPostLookup {
  static readonly type = '[Notification] Fetching Post from Push Notification';
  constructor(
    public payload: { postId: string; type: string; villageId: string }
  ) {}
}

export class PushNotificationDMLookup {
  static readonly type = '[Notification] Fetching DM from Push Notification';
  constructor(public payload: { chatroomId: string }) {}
}

export class SetPushBadgeCount {
  static readonly type = '[Notification] Setting badge count';
}

export class MarkMatchingNotificationsAsRead {
  static readonly type =
    '[Notification] Updating All Matching Notification As Read';
  constructor(public payload: { contentId: string }) {}
}

export class DeleteAppNotification {
  static readonly type = '[Notification] Delete App Notification';
  constructor(public payload: { notification: AppNotification }) {}
}

export class SetNotificationVillageFilter {
  static readonly type = '[Notification] Set Village Filter';
  constructor(public payload: { villageName: string }) {}
}

export class ClearNotificationVillageFilter {
  static readonly type = '[Notification] Clear Village Filter';
}

export class MarkNotificationAsRead {
  static readonly type = '[Notification] Updating Notification As Read';
  constructor(public payload: { notificationId: string }) {}
}

export class ClearPushNotification {
  static readonly type = '[Notification] Clearing push notification';
  constructor(public payload: { contentId: string }) {}
}

export class Signup {
  static readonly type = '[Post] Set Auth Tokens';
  constructor(public payload: { email: string; uid: string }) {}
}

export class SendVillageMessage {
  static readonly type = '[Village Chatroom] Send Village Message';
  constructor(public payload: { message: ChatMessage; villageId: string }) {}
}

export class DeleteVillageMessage {
  static readonly type = '[Village Chatroom] Delete Village Message';
  constructor(public payload: { messageId: string }) {}
}

export class RemoveDiscussionParticipant {
  static readonly type = '[Village Chatroom] Remove Participant';
  constructor(public payload: { villagerId: string; post: GenericPost }) {}
}

export class MarkPostAsHeard {
  static readonly type = '[Post] Hear Post';
  constructor(
    public payload: {
      post: Share;
      villagerUid: string;
      villagerName: string;
    }
  ) {}
}

export class MarkPostAsUnheard {
  static readonly type = '[Post] Unhear Post';
  constructor(
    public payload: {
      post: Share;
      villagerUid: string;
    }
  ) {}
}

export class MarkPostAsCelebrated {
  static readonly type = '[Post] Celebrate Post';
  constructor(
    public payload: {
      post: Share;
      villagerUid: string;
      villagerName: string;
    }
  ) {}
}

export class MarkPostAsUnCelebrated {
  static readonly type = '[Post] Uncelebrate Post';
  constructor(
    public payload: {
      post: Share;
      villagerUid: string;
    }
  ) {}
}

export class MarkExchangeAsInterested {
  static readonly type = '[Exchange] Add Interest In Exchange';
  constructor(
    public payload: {
      post: ExchangeItem;
      villagerUid: string;
      villagerName: string;
    }
  ) {}
}

export class MarkExchangeAsNotInterested {
  static readonly type = '[Exchange] Remove Interest In Exchange';
  constructor(
    public payload: {
      post: ExchangeItem;
      villagerUid: string;
    }
  ) {}
}

export class MarkHangoutAsGoing {
  static readonly type = '[Hangout] Mark as going';
  constructor(
    public payload: {
      post: Hangout;
      villagerUid: string;
      firstName: string;
      lastName: string;
    }
  ) {}
}

export class MarkHangoutAsNotGoing {
  static readonly type = '[Hangout] Remove hangout as going';
  constructor(
    public payload: {
      post: Hangout;
      villagerUid: string;
    }
  ) {}
}

// export class MarkHangoutAsMaybe {
//   static readonly type = '[Hangout] Mark hangout as maybe';
//   constructor(
//     public payload: {
//       post: Hangout;
//       villagerUid: string;
//       villagerName: string;
//     }
//   ) {}
// }

// export class MarkHangoutAsNotMaybe {
//   static readonly type = '[Hangout] Remove Hangout as maybe';
//   constructor(
//     public payload: {
//       post: Hangout;
//       villagerUid: string;
//     }
//   ) {}
// }

export class OfferSupport {
  static readonly type = '[Support] Offer Support';
  constructor(
    public payload: {
      request: SupportRequest;
      villagerUid: string;
      villagerName: string;
    }
  ) {}
}

export class RemoveSupport {
  static readonly type = '[Support] Remove Support';
  constructor(
    public payload: {
      request: SupportRequest;
      villagerUid: string;
    }
  ) {}
}

export class CreateDyadChatroom {
  static readonly type = '[Direct] Create Dyad Chatroom';
  constructor(
    public payload: {
      creatorVillager: DirectParticipant;
      participant: DirectParticipant;
    }
  ) {}
}

export class CreateGroupChatroom {
  static readonly type = '[Direct] Create Group Chatroom';
  constructor(
    public payload: {
      creatorVillager: DirectParticipant;
      participants: DirectParticipant[];
      title: string;
      description?: string;
    }
  ) {}
}

export class SetSelectedDirectChatroomById {
  static readonly type = '[Direct] Set Selected Direct Chatroom By Id';
  constructor(public payload: { chatroomId: string }) {}
}

export class SetSelectedDirectChatroom {
  static readonly type = '[Direct] Set Selected Direct Chatroom';
  constructor(public payload: { chatroom: DirectChatroom }) {}
}

export class RemoveSelectedDirectChatroom {
  static readonly type = '[Direct] Remove Active Chatroom';
}

export class UpdateDirectChatReadReceipts {
  static readonly type = '[Direct] Update Participant Read Receipts';
  constructor(public payload: { chatroom: DirectChatroom }) {}
}

export class DirectChatroomReact {
  static readonly type = '[Direct] Add Reaction';
  constructor(
    public payload: { message: ChatMessage; emoji: string; villagerUid: string }
  ) {}
}

export class AddDirectMembers {
  static readonly type = '[Direct] Add New DM Members';
  constructor(
    public payload: { chatroomId: string; newParticipants: DirectParticipant[] }
  ) {}
}

export class FetchDirectChatroomVillagers {
  static readonly type = '[Direct] Fetch Direct Chatroom Villagers';
  constructor(public payload: { participantIds: string[] }) {}
}

export class LeaveGroupDM {
  static readonly type = '[Direct] Leave Group DM';
  constructor(public payload: { chatroomId: string; villagerId }) {}
}

export class ChangeDirectChatTitle {
  static readonly type = '[Direct] Change Direct Chatroom Title';
  constructor(public payload: { chatroomId: string; newTitle: string }) {}
}

export class FetchDirectChatrooms {
  static readonly type = '[Direct] Fetch all direct chatrooms for villager';
  constructor(public payload: { villagerUid: string }) {}
}

export class RefreshDirectChatrooms {
  static readonly type = '[Direct] Refresh direct chatrooms for villager';
  constructor(public payload: { villagerUid: string }) {}
}

export class FetchDirectChatroomMessages {
  static readonly type = '[Direct] Fetch direct chatroom messages';
}

export class SendDirectChatMessage {
  static readonly type = '[Direct] Send Direct Message';
  constructor(public payload: { message: ChatMessage; villageId: string }) {}
}

export class AcceptDirectRequest {
  static readonly type = '[Direct] Accept Message Request';
  constructor(
    public payload: { chatroom: DirectChatroom; villagerId: string }
  ) {}
}

export class RejectDirectRequest {
  static readonly type = '[Direct] Reject Message Request';
  constructor(
    public payload: { chatroom: DirectChatroom; villagerId: string }
  ) {}
}

export class ChangeDirectSegment {
  static readonly type = '[Direct] Change Segment';
  constructor(public payload: { segment: 'joined' | 'pending' }) {}
}

export class DeleteDirectChatMessage {
  static readonly type = '[Direct] Delete Direct Message';
  constructor(public payload: { messageId: string }) {}
}

export class ClearDirectChatrooms {
  static readonly type = '[Direct] Clear Direct Chatrooms';
}

export class ReplyPrivately {
  static readonly type = '[Direct] Reply Privately';
  constructor(
    public payload: {
      currentVillager: DirectParticipant;
      postOwner: DirectParticipant;
    }
  ) {}
}

export class ToggleChatroomMutedId {
  static readonly type = '[Direct] Add/Remove ID from MUTED_UIDS';
  constructor(public payload: { uid: string }) {}
}

export class AppInitialDataLoaded {
  static readonly type = '[App] Initial Data Loaded';
}

export class ClearInitialAppDataLoaded {
  static readonly type = '[App] Clear Data';
}

export class AppBoardDataLoaded {
  static readonly type = '[App] Initial Board Data Loaded';
}

export class ClearAppDataBoard {
  static readonly type = '[App] Clear Board Data';
}

export class AppDMsLoaded {
  static readonly type = '[App] DMs Loaded';
}

export class ClearDMsLoaded {
  static readonly type = '[App] Clear DMs Loaded';
}

export class AppResetLoadedStates {
  static readonly type = '[App] Reset Data Loaded States';
}

export class AppSetPostCirclesAssigned {
  static readonly type = '[App] Set Post Assigned Circle';
  constructor(public payload: { assignedCircles: JoinedCircle[] }) {}
}

export class AppClearPostCircles {
  static readonly type = '[App] Clear Post Assigned Circle';
}

export class SetVoiceNoteUrl {
  static readonly type = '[App] Setting Voice Note Url';
  constructor(public payload: { voiceNoteUrl: string }) {}
}

export class SetVoiceNoteCurrentTime {
  static readonly type = '[App] Setting Voice Note Current Time';
  constructor(public payload: { currentTime: number }) {}
}

export class VoiceNoteIsLoading {
  static readonly type = '[App] Toggle Voice Note Is Loading';
  constructor(public payload: { loading: boolean }) {}
}

export class VoiceNoteEnded {
  static readonly type = '[App] Current Voice Note Ended';
  constructor(public payload: { voiceNoteUrl: string }) {}
}

export class DmToggleInputHasPhoto {
  static readonly type = '[App] Toggle DM Input Has Photo';
  constructor(public payload: { hasPhoto: boolean }) {}
}

export class AppToggleIsOffline {
  static readonly type = '[App] Toggle App Offline';
  constructor(public payload: { isOffline: boolean }) {}
}

export class AppToggleIsMobile {
  static readonly type = '[App] Toggle App Mobile';
  constructor(public payload: { isMobile: boolean }) {}
}

export class ToggleAppBackground {
  static readonly type = '[App] Toggle App State';
  constructor(public payload: { background: boolean }) {}
}

export class SetSearchVisibility {
  static readonly type = '[App] Set Search Visibility';
  constructor(public payload: { showSearch: boolean }) {}
}

export class SetSearchTerm {
  static readonly type = '[App] Set Search Term';
  constructor(public payload: { searchTerm: string }) {}
}

export class SetCircleFilter {
  static readonly type = '[App] Set Circle Filter Term';
  constructor(public payload: { circleId: string }) {}
}

export class ClearSearchTerm {
  static readonly type = '[App] Clear Search Term';
}

export class SetVillagerSearchVisibility {
  static readonly type = '[App] Set Villager Search Visibility';
  constructor(public payload: { showSearch: boolean }) {}
}

export class SetVillagerSearchTerm {
  static readonly type = '[App] Set Villager Search Term';
  constructor(public payload: { searchTerm: string }) {}
}

export class SetVillagerCircleFilter {
  static readonly type = '[App] Set Villager Circle Filter Term';
  constructor(public payload: { circleId: string }) {}
}

export class ClearVillagerSearchTerm {
  static readonly type = '[App] Clear Villager Search Term';
}

export class FetchSharedLists {
  static readonly type = '[Shared List] Fetch Shared Lists';
  constructor(
    public payload: { villageUID: string; villagerCircleIds: string[] }
  ) {}
}

export class CreateSharedList {
  static readonly type = '[Shared Lists] Create Shared List';
  constructor(public payload: { post: SharedList }) {}
}

export class UpdateSharedList {
  static readonly type = '[Shared Lists] Update Shared List';
  constructor(
    public payload: { postId: string; partialPost: Partial<SharedList> }
  ) {}
}

export class DeleteSharedList {
  static readonly type = '[Shared Lists] Delete Shared List';
  constructor(public payload: { post: SharedList }) {}
}

export class ClearSharedList {
  static readonly type = '[Shared Lists] Clear Shared Lists';
}

export class SetReplyMessage {
  static readonly type = '[Reply Message] Set Reply Message';
  constructor(public payload: { message: ChatMessage }) {}
}

export class ClearReplyMessage {
  static readonly type = '[Reply Message] Clear Reply Message';
}
