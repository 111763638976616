<div *ngIf="!errorWithFile" class="container">
  <ion-icon
    class="delete-button"
    name="close-circle-outline"
    color="danger"
    (click)="showDeleteConfirm()"
  ></ion-icon>
  <img *ngIf="path$ | async as url; else loading" [src]="url" />
  <ng-template #loading>
    <ion-skeleton-text animated style="width: 100%"></ion-skeleton-text>
  </ng-template>
</div>
