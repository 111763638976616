import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  revillager = {
    light: {
      primary: '#57ACA3',
      secondary: '#395884',
      tertiary: '#185650',
      success: '#8E9B53',
      warning: '#F5B841',
      danger: '#d87644',
    },
    dark: {
      primary: '#57ACA3',
      secondary: '#395884',
      tertiary: '#185650',
      success: '#8E9B53',
      warning: '#F5B841',
      danger: '#d87644',
    },
  };

  northern = {
    light: {
      primary: '#152E30',
      secondary: '#185650',
      tertiary: '#52657E',
      success: '#64AB6D',
      warning: '#d87644',
      danger: '#AA4E3D',
    },
    dark: {
      primary: '#345F64',
      secondary: '#185650',
      tertiary: '#3A87A2',
      success: '#64AB6D',
      warning: '#d87644',
      danger: '#AA4E3D',
    },
  };

  southern = {
    light: {
      primary: '#CB653A', // '#EAA844',
      secondary: '#8A95A5',
      tertiary: '#474056',
      success: '#64AB6D',
      warning: '#F5B841',
      danger: '#CE5B44',
    },
    dark: {
      primary: '#CB653A', // '#EAA844',
      secondary: '#8A95A5',
      tertiary: '#6B6182',
      success: '#64AB6D',
      warning: '#F5B841',
      danger: '#CE5B44',
    },
  };

  themes = {
    revillager: this.revillager,
    northern: this.northern,
    southern: this.southern,
  };

  mode = 'light';

  constructor() {
    if(window && window.matchMedia) {
    this.mode = window.matchMedia('(prefers-color-scheme: dark)').matches
      ? 'dark'
      : 'light';

    window
      .matchMedia('(prefers-color-scheme: dark)')
      .addEventListener('change', (event) => {
        this.mode = event.matches ? 'dark' : 'light';
      });
    }
  }

  getThemes() {
    return this.themes;
  }

  getMode() {
    return this.mode;
  }

  getPrimaryColor(themeName) {
    return this.themes[themeName][this.mode].primary;
  }

  setTheme(themeName) {
    if(!themeName) {
      themeName = 'revillager';
    }
    const theme = this.themes[themeName];
    console.log('Setting theme: ', themeName, theme, this.mode);
    this.setColor('primary', theme[this.mode].primary);
    this.setColor('secondary', theme[this.mode].secondary);
    this.setColor('tertiary', theme[this.mode].tertiary);
    this.setColor('success', theme[this.mode].success);
    this.setColor('warning', theme[this.mode].warning);
    this.setColor('danger', theme[this.mode].danger);
  }

  setColor(name, hexColor) {
    this.setCSSVariable(`${name}`, hexColor);
    const rgb = this.hexToRgb(hexColor);
    this.setCSSVariable(`${name}-rgb`, `${rgb.r}, ${rgb.g}, ${rgb.b}`);
    this.setCSSVariable(
      'primary-contrast',
      this.getContrastYIQ(rgb.r, rgb.g, rgb.b)
    );
    this.setCSSVariable(
      'primary-contrast-rgb',
      this.getContrastYIQ(rgb.r, rgb.g, rgb.b)
    );
    this.setCSSVariable(`${name}-shade`, this.shadeRGBColorToHex(rgb, -0.1));
    this.setCSSVariable(`${name}-tint`, this.tintRGBColorToHex(rgb, 0.1));
  }

  tintRGBColorToHex(color, percent) {
    const R = color.r;
    const G = color.g;
    const B = color.b;
    const rgb = {
      r: Math.round((255 - R) * percent) + R,
      g: Math.round((255 - G) * percent) + G,
      b: Math.round((255 - B) * percent) + B,
    };

    return this.rgbToHex(rgb.r, rgb.g, rgb.b);
  }

  shadeRGBColorToHex(color, percent) {
    const t = percent < 0 ? 0 : 255;
    const p = percent < 0 ? percent * -1 : percent;
    const R = color.r;
    const G = color.g;
    const B = color.b;
    const rgb = {
      r: Math.round((t - R) * p) + R,
      g: Math.round((t - G) * p) + G,
      b: Math.round((t - B) * p) + B,
    };

    return this.rgbToHex(rgb.r, rgb.g, rgb.b);
  }

  getContrastYIQ(r, g, b) {
    const yiq = (r * 299 + g * 587 + b * 114) / 1000;
    // return yiq >= 128 ? 'black' : 'white';
    return '#fff';
  }

  setCSSVariable(name, value, unit = '') {
    const rootStyles = document.styleSheets[0].cssRules[0] as any;
    rootStyles.style.setProperty('--ion-color-' + name, value + (unit || ''));
  }

  componentToHex(c) {
    const hex = c.toString(16);
    return hex.length === 1 ? '0' + hex : hex;
  }

  hexToRgb(hex) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  }

  rgbToHex(r, g, b) {
    return (
      '#' +
      this.componentToHex(r) +
      this.componentToHex(g) +
      this.componentToHex(b)
    );
  }
}
