import { Injectable } from '@angular/core';
import {
  Connected,
  Emitted,
  NgxsFirestoreConnect,
  StreamConnected,
  StreamEmitted,
} from '@ngxs-labs/firestore-plugin';
import {
  Action,
  NgxsAfterBootstrap,
  NgxsOnInit,
  Selector,
  State,
  StateContext,
  Store,
} from '@ngxs/store';
import * as moment from 'moment';
import { tap } from 'rxjs/operators';
import { Hangout, HangoutStateModel } from '../models/hangout.model';
import { HangoutService } from '../services/hangout.service';
import {
  ClearHangouts,
  CreateHangout,
  DeleteHangout,
  FetchHangouts,
  MarkHangoutAsGoing,
  MarkHangoutAsNotGoing,
  SilentlyUpdateSelectedPost,
  UpdateHangout,
  UpdateVillageTopics,
} from './app.actions';
import { StateUtilities } from './state-utilities';
import { DiscussionState } from './discussion.state';

@State<HangoutStateModel>({
  name: 'hangouts',
  defaults: {
    hangouts: [],
    upcomingHangouts: [],
  },
})
@Injectable()
export class HangoutState implements NgxsOnInit, NgxsAfterBootstrap {
  constructor(
    private hangoutSvc: HangoutService,
    private ngxsFirestoreConnect: NgxsFirestoreConnect,
    private utilities: StateUtilities,
    private store: Store
  ) {}

  ngxsOnInit() {
    // subtract 1 day to allow for timezone stuff...
    // ref.where(
    //   'END_DATETIME',
    //   '>',
    //   moment().subtract(1, 'day').toISOString()
    // )
    this.ngxsFirestoreConnect.connect(FetchHangouts, {
      to: (action) =>
        this.hangoutSvc.collection$((ref) =>
          ref.where(
            'CIRCLE_UIDS',
            'array-contains-any',
            action.payload.villagerCircleIds
          )
        ),
      cancelPrevious: true,
    });
  }

  ngxsAfterBootstrap(ctx: StateContext<HangoutStateModel>) {}

  @Selector()
  static allHangouts(state: HangoutStateModel): Hangout[] {
    return state.hangouts;
  }

  @Selector()
  static upcomingHangouts(state: HangoutStateModel): Hangout[] {
    return state.upcomingHangouts;
  }

  @Action(FetchHangouts)
  startBulletinListener(
    ctx: StateContext<HangoutStateModel>,
    action: FetchHangouts
  ) {
    this.hangoutSvc.setVillageId(action.payload.villageUID);
  }

  @Action(StreamConnected(FetchHangouts))
  streamConnected(
    ctx: StateContext<HangoutStateModel>,
    { action }: Connected<FetchHangouts>
  ) {
    console.log('[Hangouts State] Stream connected');
  }

  @Action(StreamEmitted(FetchHangouts))
  streamEmitted(
    ctx: StateContext<HangoutStateModel>,
    { action, payload }: Emitted<FetchHangouts, Hangout[]>
  ) {
    console.log('payload: ', payload)
    const selectedChatroom = this.store.selectSnapshot(
      DiscussionState.selectedPost
    );
    if (payload && selectedChatroom) {
      const matchingPost = payload.find(
        (x) => x._UID === selectedChatroom._UID
      );
      if (matchingPost) {
        this.store.dispatch(
          new SilentlyUpdateSelectedPost({ post: matchingPost })
        );
      }
    }

    const today = moment();
    const upcoming = payload.filter(
      (x) => x.START_DATETIME && moment(x.START_DATETIME).isAfter(today)
    );
    const sortedUpcoming = upcoming.sort((a: Hangout, b: Hangout) => {
      if (moment(a.START_DATETIME).isBefore(moment(b.START_DATETIME))) {
        return -1;
      } else if (moment(a.START_DATETIME).isAfter(moment(b.START_DATETIME))) {
        return 1;
      } else {
        return 0;
      }
    });

    ctx.patchState({
      hangouts: payload,
      upcomingHangouts: sortedUpcoming,
    });
  }

  @Action(ClearHangouts)
  clear(ctx: StateContext<HangoutStateModel>) {
    ctx.setState({
      hangouts: [],
      upcomingHangouts: [],
    });
  }

  @Action(CreateHangout)
  createHangout(ctx: StateContext<HangoutStateModel>, action: CreateHangout) {
    const { hangout } = action.payload;
    this.store.dispatch(new UpdateVillageTopics({ topics: hangout.TOPICS }));

    return this.hangoutSvc.create$(hangout).pipe(
      tap(() => {
        console.log('[Hangouts State] successfully created hangout');
      })
    );
  }

  @Action(UpdateHangout)
  updateHangout(ctx, action: UpdateHangout) {
    const { post } = action.payload;
    const { _UID } = post;
    if (!_UID) {
      console.error('Missing Id: ', post);
      alert('Error Updating Event');
      return false;
    }
    this.store.dispatch(new UpdateVillageTopics({ topics: post.TOPICS }));
    return this.hangoutSvc.update$(_UID, post);
  }

  @Action(DeleteHangout)
  deleteHangout(ctx, action: DeleteHangout) {
    const { post } = action.payload;
    return this.hangoutSvc.delete$(post._UID);
  }

  @Action(MarkHangoutAsGoing)
  async markAsGoing(ctx: StateContext<any>, action: MarkHangoutAsGoing) {
    const { post, villagerUid, firstName, lastName } = action.payload;
    const existingGoing = [...post.GOING];

    console.log(
      `Adding going ${villagerUid} to Chatroom going: ${existingGoing}`
    );

    let newGoing = [...existingGoing];
    newGoing.push({
      UID: villagerUid,
      FIRST_NAME: firstName,
      LAST_NAME: lastName,
    });
    newGoing = [...new Set(newGoing)];

    if (newGoing.length > existingGoing.length) {
      await this.utilities.updateParticipants(post, villagerUid);
      return this.hangoutSvc.updateWithoutConverter(post._UID, {
        GOING: newGoing,
      });
    } else {
      console.log(
        'Not updating hangout doc, current user already participant in this post'
      );
      return null;
    }
  }

  @Action(MarkHangoutAsNotGoing)
  markAsNotGoing(ctx: StateContext<any>, action: MarkHangoutAsNotGoing) {
    const { post, villagerUid } = action.payload;

    const going = [...post.GOING];
    console.log(`Remove going ${villagerUid} from Chatroom going: ${going}`);
    const idx = going.findIndex((x) => x.UID === villagerUid);
    if (idx > -1) {
      going.splice(idx, 1);

      return this.hangoutSvc.updateWithoutConverter(post._UID, {
        GOING: going,
      });
    } else {
      return null;
    }
  }
}
