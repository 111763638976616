import { Component, Input, OnInit } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { HangoutAttendeesModalPage } from 'src/app/modals/hangout-attendees-modal/hangout-attendees-modal.page';
import { Hangout } from 'src/app/models/hangout.model';
import { GenericPost } from 'src/app/models/post-core.model';
import {
  MarkHangoutAsNotGoing,
  MarkHangoutAsGoing,
} from 'src/app/state/app.actions';
import { FeedState } from 'src/app/state/feed.state';
import { VillagerState } from 'src/app/state/villager.state';

@Component({
  selector: 'app-hangout-card-actions',
  templateUrl: './hangout-card-actions.component.html',
  styleUrls: ['./hangout-card-actions.component.scss'],
})
export class HangoutCardActionsComponent implements OnInit {
  going = false;
  hangout: Hangout;
  goingText = '';

  postsSub: Subscription;
  @Select(FeedState.allPosts) allPosts$: Observable<GenericPost[]>;

  @Input() villagerUID: string;
  @Input() post: GenericPost;

  constructor(
    private store: Store,
    private alertCtrl: AlertController,
    private modalCtrl: ModalController
  ) {}

  ngOnInit() {
    this.hangout = this.post as Hangout;
    if (this.hangout) {
      this.patchGoingState();
    }

    this.postsSub = this.allPosts$
      .pipe(
        map((posts) => {
          return posts.find((x) => x._UID === this.post._UID);
        }),
        tap((post: Hangout) => {
          if (post) {
            this.hangout = post;
            this.patchGoingState();
            switch (this.hangout.GOING.length) {
              case 0:
                this.goingText = null;
                break;
              case 1:
                this.goingText = 'person going';
                break;
              default:
                this.goingText = 'people going';
                break;
            }
          }
        })
      )
      .subscribe();
  }

  async handleGoing() {
    const uid = this.store.selectSnapshot(VillagerState.uid);
    const firstName = this.store.selectSnapshot(VillagerState.firstName);
    const lastName = this.store.selectSnapshot(VillagerState.lastName);

    this.store
      .select(VillagerState.isCurrentVillager)
      .pipe(
        map((filterFn) => filterFn(this.hangout._CREATOR_UID)),
        take(1)
      )
      .subscribe((isCurrentVillager) => {
        if (isCurrentVillager) {
          this.showWarningAlert();
        } else {
          if (this.going) {
            this.going = false;
            this.store.dispatch(
              new MarkHangoutAsNotGoing({
                post: this.hangout,
                villagerUid: uid,
              })
            );
          } else {
            this.going = true;
            this.store.dispatch(
              new MarkHangoutAsGoing({
                post: this.hangout,
                villagerUid: uid,
                firstName,
                lastName,
              })
            );
          }
        }
      });
  }

  async showWarningAlert() {
    const alert = await this.alertCtrl.create({
      header: 'Oops!',
      message:
        'You cannot unattend your own event! You can always delete the event if you prefer.',
      buttons: ['OK'],
    });

    await alert.present();
  }

  patchGoingState() {
    const idx = this.hangout.GOING.findIndex((x) => x.UID === this.villagerUID);
    if (idx > -1) {
      this.going = true;
    } else {
      this.going = false;
    }
  }

  public async openAttendeeListModal() {
    const modal = await this.modalCtrl.create({
      component: HangoutAttendeesModalPage,
      componentProps: {
        hangout: this.hangout,
      },
      // swipeToClose: true,
      // presentingElement: this.routerOutlet.nativeEl,
    });

    return await modal.present();
  }

  ngOnDestroy() {
    if (this.postsSub) {
      this.postsSub.unsubscribe();
    }
  }
}
