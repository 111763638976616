import { Injectable } from '@angular/core';
import {
  Connected,
  Disconnected,
  Emitted,
  NgxsFirestoreConnect,
  StreamConnected,
  StreamDisconnected,
  StreamEmitted,
} from '@ngxs-labs/firestore-plugin';
import {
  Action,
  NgxsAfterBootstrap,
  NgxsOnInit,
  Selector,
  State,
  StateContext,
  Store,
} from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { Share, ShareStateModel } from '../models/share.model';
import { ShareService } from '../services/share.service';
import {
  ClearShares,
  CreateShare,
  DeleteShare,
  FetchShares,
  MarkPostAsCelebrated,
  MarkPostAsHeard,
  MarkPostAsUnCelebrated,
  MarkPostAsUnheard,
  SilentlyUpdateSelectedPost,
  UpdateShare,
  UpdateVillageTopics,
} from './app.actions';
import { StateUtilities } from './state-utilities';
import { DiscussionState } from './discussion.state';

@State<ShareStateModel>({
  name: 'shares',
  defaults: {
    shares: [],
  },
})
@Injectable()
export class ShareState implements NgxsOnInit, NgxsAfterBootstrap {
  constructor(
    private shareSvc: ShareService,
    private ngxsFirestoreConnect: NgxsFirestoreConnect,
    private utilities: StateUtilities,
    private store: Store
  ) {}

  ngxsOnInit() {
    this.ngxsFirestoreConnect.connect(FetchShares, {
      to: (action) =>
        this.shareSvc.collection$((ref) =>
          ref.where(
            'CIRCLE_UIDS',
            'array-contains-any',
            action.payload.villagerCircleIds
          )
        ),
        cancelPrevious: true
    });

    // this.ngxsFirestoreConnect.connect(FetchShares, {
    //   to: () => this.shareSvc.collection$(ref => ref.where('UPDATED_AT', '>' , moment().subtract(7, 'days').toDate())),
    // });

    // this.ngxsFirestoreConnect.connect(FetchVillagerBulletins, {
    //   to: () => this.shareSvc.collection$(ref => ref.where('_CREATOR_UID', '<' , action.payload.villagerId).limit(20),
    // });

    // this.ngxsFirestoreConnect.connect(FetchMoreBulletins, {
    //   to: () => {
    //     this.store.selectSnapshot(ShareState.fetchedIndex)
    //     return this.shareSvc.collection$(ref => ref.where('_CREATOR_UID', '<' , action.payload.villagerId).startAfter(fetchedIndex).limit(20)
    //   },
    // });
  }

  ngxsAfterBootstrap(ctx: StateContext<ShareStateModel>) {
    // ctx.dispatch();
  }

  @Selector()
  static allShares(state: ShareStateModel): Share[] {
    return state.shares;
  }

  @Action(FetchShares)
  startBulletinListener(
    ctx: StateContext<ShareStateModel>,
    action: FetchShares
  ) {
    const { villageUID } = action.payload;
    this.shareSvc.setVillageId(villageUID);
    // alert('Set Share Subcollection path for village: ' + villageUID)
  }

  @Action(StreamConnected(FetchShares))
  streamConnected(
    ctx: StateContext<ShareStateModel>,
    { action }: Connected<FetchShares>
  ) {
    // alert('Share connected')
    console.log('[Share State] Stream connected');
  }

  @Action(StreamDisconnected(FetchShares))
  streamDisconnected(
    ctx: StateContext<ShareStateModel>,
    { action }: Disconnected<FetchShares>
  ) {
    // alert('Share disconnected')
    console.log('[Share State] Stream disconnected');
  }

  @Action(StreamEmitted(FetchShares))
  streamEmitted(
    ctx: StateContext<ShareStateModel>,
    { action, payload }: Emitted<FetchShares, Share[]>
  ) {
    // console.log(
    //   '[DEBUG] Fetch Shares EMITTED: ',
    //   payload.map((x) => x._UID)
    // );

    const selectedChatroom = this.store.selectSnapshot(
      DiscussionState.selectedPost
    );
    if (payload && selectedChatroom) {
      const matchingPost = payload.find(
        (x) => x._UID === selectedChatroom._UID
      );
      if (matchingPost) {
        this.store.dispatch(
          new SilentlyUpdateSelectedPost({ post: matchingPost })
        );
      }
    }

    ctx.patchState({
      shares: payload,
    });
  }

  @Action(CreateShare)
  createShare(ctx: StateContext<ShareStateModel>, action: CreateShare) {
    const { post } = action.payload;
    this.store.dispatch(new UpdateVillageTopics({ topics: post.TOPICS }));

    return this.shareSvc.create$(post).pipe(
      tap(() => {
        console.log('[Share State] successfully created post');
      })
    );
  }

  @Action(ClearShares)
  clear(ctx: StateContext<ShareStateModel>) {
    ctx.setState({
      shares: [],
    });
  }

  @Action(UpdateShare)
  updateShare(ctx, action: UpdateShare) {
    const { post } = action.payload;
    const { _UID } = post;
    if (!_UID) {
      console.error('Missing Id: ', post);
      alert('Error Updating Post');
      return false;
    }
    this.store.dispatch(new UpdateVillageTopics({ topics: post.TOPICS }));
    return this.shareSvc.update$(_UID, post);
  }

  @Action(DeleteShare)
  deleteShare(ctx, action: DeleteShare) {
    const { post } = action.payload;
    return this.shareSvc.delete$(post._UID);
  }

  @Action(MarkPostAsHeard)
  async markAsHeard(
    ctx: StateContext<ShareStateModel>,
    action: MarkPostAsHeard
  ) {
    const { post, villagerUid, villagerName } = action.payload;
    const existingHearings = [...post.HEARINGS];

    console.log(
      `Adding hearing ${villagerUid} to Chatroom hearings: ${existingHearings}`
    );

    let newHearings = [...existingHearings];
    newHearings.push({
      CREATOR_UID: villagerUid,
      CREATOR_NAME: villagerName,
    });
    newHearings = [...new Set(newHearings)];

    if (newHearings.length > existingHearings.length) {
      await this.utilities.updateParticipants(post, villagerUid);

      return this.shareSvc.updateWithoutConverter(post._UID, {
        HEARINGS: newHearings,
      });
    } else {
      console.log(
        'Not updating share doc, current user already participant in this post'
      );
      return null;
    }
  }

  @Action(MarkPostAsUnheard)
  markAsUnheard(ctx: StateContext<ShareStateModel>, action: MarkPostAsUnheard) {
    const { post, villagerUid } = action.payload;

    const hearings = [...post.HEARINGS];
    console.log(
      `Remove hearing ${villagerUid} from Chatroom hearings: ${hearings}`
    );
    const idx = hearings.findIndex((x) => x.CREATOR_UID === villagerUid);
    if (idx > -1) {
      hearings.splice(idx, 1);

      return this.shareSvc.updateWithoutConverter(post._UID, {
        HEARINGS: hearings,
      });
    } else {
      return null;
    }
  }

  @Action(MarkPostAsCelebrated)
  async markAsCelebrated(
    ctx: StateContext<ShareStateModel>,
    action: MarkPostAsCelebrated
  ) {
    const { post, villagerUid, villagerName } = action.payload;
    const existingCelebrations = [...post.CELEBRATIONS];

    console.log(
      `Adding celebrations ${villagerUid} to share celebrations: ${existingCelebrations}`
    );

    let newCelebrations = [...existingCelebrations];
    newCelebrations.push({
      CREATOR_UID: villagerUid,
      CREATOR_NAME: villagerName,
    });
    newCelebrations = [...new Set(newCelebrations)];

    if (newCelebrations.length > existingCelebrations.length) {
      await this.utilities.updateParticipants(post, villagerUid);
      return this.shareSvc.updateWithoutConverter(post._UID, {
        CELEBRATIONS: newCelebrations,
      });
    } else {
      console.log(
        'Not updating chatroom doc, current user already celebreated this chatroom'
      );
      return null;
    }
  }

  @Action(MarkPostAsUnCelebrated)
  markAsUncelebrated(
    ctx: StateContext<ShareStateModel>,
    action: MarkPostAsUnheard
  ) {
    const { post, villagerUid } = action.payload;
    const celebrations = [...post.CELEBRATIONS];
    console.log(
      `Remove celebreation ${villagerUid} from Chatroom celebrations: ${celebrations}`
    );
    const idx = celebrations.findIndex((x) => x.CREATOR_UID === villagerUid);
    if (idx > -1) {
      celebrations.splice(idx, 1);

      return this.shareSvc.updateWithoutConverter(post._UID, {
        CELEBRATIONS: celebrations,
      });
    } else {
      return null;
    }
  }
}
