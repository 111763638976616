import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ExchangeItem } from 'src/app/models/exchange.model';
import { GenericPost } from 'src/app/models/post-core.model';
import { SetSelectedPost } from 'src/app/state/app.actions';
import { FeedState } from 'src/app/state/feed.state';

@Component({
  selector: 'app-exchange-card-body',
  templateUrl: './exchange-card-body.component.html',
  styleUrls: ['./exchange-card-body.component.scss'],
})
export class ExchangeCardBodyComponent implements OnInit {
  exchange: ExchangeItem;
  @Select(FeedState.allPosts) allPosts$: Observable<GenericPost[]>;
  @Input() showFullBody = true;
  @Input() post: GenericPost;

  constructor(
    private store: Store,
    private router: Router,
    private modalCtrl: ModalController
  ) {}

  ngOnInit() {
    this.exchange = this.post as ExchangeItem;

    this.store
      .select(FeedState.getPostById)
      .pipe(map((filterFn) => filterFn(this.post._UID)))
      .subscribe((post) => {
        // console.log('[Exchange Card Body] updated exchange: ', post);
        this.exchange = post as ExchangeItem;
      });
  }

  async clickBody(ev: Event) {
    const target = ev.target as HTMLElement;
    if (target?.className === 'mentionified') {
      const uid = target.getAttribute('data-mention-uid');
      if (uid) {
        const existing = await this.modalCtrl.getTop();
        if (existing) await this.modalCtrl.dismiss();
        this.router.navigateByUrl(`member/${uid}`);
      }
    }
  }

  goToDetails() {
    this.store.dispatch(
      new SetSelectedPost({ post: this.exchange, location: 'DETAILS' })
    );
    this.router.navigateByUrl(`pages/post-details`);
  }
}
