import {
  Component,
  HostListener,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Platform } from '@ionic/angular';
import { Store } from '@ngxs/store';
import { MENTIONIFY_CLASSNAME, MENTIONIFY_HIDDEN } from 'src/app/app.config';
import { MentionifyDirective } from 'src/app/directives/mentionify/mentionify.directive';
import { Villager } from 'src/app/models/villager.model';
import { MentionifiedPipe } from 'src/app/pipes/mentionified.pipe';
import { VillagerState } from 'src/app/state/villager.state';

interface MentionUidsAndBody {
  _MENTION_UIDS: string[];
  BODY: string;
}

@Component({
  selector: 'app-post-mentionable-input',
  templateUrl: './post-mentionable-input.component.html',
  styleUrls: ['./post-mentionable-input.component.scss'],
})
export class PostMentionableInputComponent implements OnInit {
  isMobile = false;

  @Input() mentionables: string[] = [];
  @Input() placeholderText: string = '';
  @Input() shouldDisplayAbove: boolean = true;
  // @Input() largeOffset = false;

  @ViewChild('messageInput') msgEl: { nativeElement: HTMLDivElement };
  @ViewChild(MentionifyDirective) mentionify: MentionifyDirective;
  constructor(private platform: Platform, private store: Store) {}

  ngOnInit() {
    this.isMobile = this.platform.is('mobile');

    this.placeholderText = `${this.placeholderText} (type "@" to mention someone)`;
  }

  setBody(body: string, mentionUids: string[]) {
    const villagers = this.store.selectSnapshot(VillagerState.allVillagers);
    let idx = 0;
    this.msgEl.nativeElement.innerHTML = body.replace(
      /\uFFF9.*?\uFFFB/g,
      (name) => {
        const villagerId = mentionUids[idx];
        const villager = villagers.find((v) => v._UID === villagerId);
        const { _UID, FIRST_NAME, LAST_NAME } = villager;
        const mentionJSON = JSON.stringify({ _UID, FIRST_NAME, LAST_NAME });
        const ts = new Date().getTime();
        const value = `<span id="mention-${ts}"
        class="${MENTIONIFY_CLASSNAME}">${FIRST_NAME} ${LAST_NAME}
        <span class="${MENTIONIFY_HIDDEN}">${mentionJSON}</span></span>&nbsp;`;
        idx++;
        return value;
      }
    );
  }

  handleFocus(e: Event) {
    if(!this.isMobile) {
      this.msgEl.nativeElement.scrollIntoView({
        behavior: 'smooth',
        inline: 'start',
      });
    }

  }

  parseMentionsAndBody(): MentionUidsAndBody {
    let _MENTION_UIDS = [];
    let BODY = '';
    if (this.msgEl.nativeElement.innerHTML) {
      const hiddenElements = document.getElementsByClassName(MENTIONIFY_HIDDEN);
      const mentionsJson: Partial<Villager>[] = Array.from(hiddenElements)
        .map((e) => JSON.parse(e.innerHTML))
        .filter(Boolean);
      _MENTION_UIDS = mentionsJson.map((i) => i._UID);

      const clone = this.msgEl.nativeElement.cloneNode(true) as HTMLDivElement;
      clone.innerHTML = clone.innerHTML.replace(
        /(<br>|<div><br><\/div>?)/g,
        '\n'
      );
      this.transformMentionifyNodes(clone);
      BODY = clone.innerText;
    }
    return { _MENTION_UIDS, BODY };
  }

  transformMentionifyNodes(clone: HTMLElement) {
    Array.from(clone.children).forEach((el: HTMLElement) => {
      const elChildren = Array.from(el.children || []);
      if (elChildren.length) {
        if (el.className === MENTIONIFY_CLASSNAME) {
          elChildren.forEach((hidEl) => hidEl.remove());
          el.innerText = `\uFFF9@${el.innerText}\uFFFB`;
        } else {
          this.transformMentionifyNodes(el);
        }
      }
    });
  }

  @HostListener('window:keydown.enter', ['$event'])
  enterKeyDown(e: Event) {
    // on desktop, we want to prevent enter from adding a new line to the message
    // because the enter key sends the message. new lines are added on desktop
    // via shift+enter
    if (!this.isMobile && !this.mentionify.popupRef) {
      e.preventDefault();
      // this.addMessage();
    }
  }
}
