<ion-header class="ion-no-border" translucent="false">
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button (click)="close()">
        <ion-icon color="dark" slot="icon-only" name="close-circle"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>People Attending</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-list *ngIf="hangout.GOING.length > 0">
    <ion-list-header>Going</ion-list-header>
    <ion-item *ngFor="let villager of hangout.GOING">
      <app-villager-small-avatar
        [firstName]="villager.FIRST_NAME"
        [lastName]="villager.LAST_NAME"
        [uid]="villager.UID"
      ></app-villager-small-avatar>
      <ion-label>{{villager.FIRST_NAME}} {{villager.LAST_NAME}}</ion-label>
    </ion-item>
  </ion-list>
</ion-content>
