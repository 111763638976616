import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController, NavController, Platform } from '@ionic/angular';
import { Store } from '@ngxs/store';
import { PushNotificationService } from 'src/app/services/push-notification.service';
import { SetJoinedVillagePushSetting } from 'src/app/state/app.actions';
import { VillageState } from 'src/app/state/village.state';
import { VillagerState } from 'src/app/state/villager.state';
import { AnalyticsService } from 'src/app/analytics.service';

@Component({
  selector: 'app-notification-permission-modal',
  templateUrl: './notification-permission-modal.page.html',
  styleUrls: ['./notification-permission-modal.page.scss'],
})
export class NotificationPermissionModalPage implements OnInit {
  registered = false;

  constructor(
    private modalCtrl: ModalController,
    private router: Router,
    private pushSvc: PushNotificationService,
    private platform: Platform,
    private store: Store,
    private analytics: AnalyticsService
  ) {}

  ngOnInit() {}

  next() {
    const villager = this.store.selectSnapshot(VillagerState.currentVillager);
    const villageId = villager.VILLAGE;

    if (villager.NOTIFICATIONS_ALLOWED) {
      this.store
        .dispatch([new SetJoinedVillagePushSetting({ villageId, PUSH: true })])
        .subscribe(() => {
          this.close();
          this.router.navigateByUrl('/pages/home');
        });
    }
  }

  register() {
    if (this.platform.is('capacitor')) {
      this.pushSvc.register().then(
        () => {
          this.analytics.logEvent('notification_perms_modal_reg_success', {
            userId: this.store.selectSnapshot(VillagerState.uid),
            villageId: this.store.selectSnapshot(VillageState.uid),
          });
          this.registered = true;
        },
        (err) => {
          this.analytics.logEvent('notification_perms_modal_reg_error', {
            userId: this.store.selectSnapshot(VillagerState.uid),
            villageId: this.store.selectSnapshot(VillageState.uid),
          });
          console.error('Error registering: ', err);
          this.registered = true; // dont block further onboarding
        }
      );
    } else {
      alert('Notifications dont exist for desktop! Lets get into the app ');
    }
  }

  notNow() {
    this.close();
    this.router.navigate(['pages/home'], { replaceUrl: true });
  }

  close() {
    this.modalCtrl.dismiss();
  }
}
