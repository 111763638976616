import { Injectable } from '@angular/core';
import {
  Connected,
  Disconnected,
  Emitted,
  NgxsFirestoreConnect,
  StreamConnected,
  StreamDisconnected,
  StreamEmitted,
} from '@ngxs-labs/firestore-plugin';
import {
  State,
  NgxsOnInit,
  NgxsAfterBootstrap,
  Store,
  Selector,
  StateContext,
  Action,
} from '@ngxs/store';
import { tap } from 'rxjs/operators';
import {
  Announcement,
  AnnouncementStateModel,
} from '../models/announcement.model';

import { AnnouncementService } from '../services/announcement.service';

import {
  ClearAnnouncements,
  CreateAnnouncement,
  DeleteAnnouncement,
  FetchAnnouncements,
  SilentlyUpdateSelectedPost,
  UpdateAnnouncement,
} from './app.actions';
import { DiscussionState } from './discussion.state';

@State<AnnouncementStateModel>({
  name: 'announcements',
  defaults: {
    announcements: [],
  },
})
@Injectable()
export class AnnouncementState implements NgxsOnInit, NgxsAfterBootstrap {
  constructor(
    private announcementSvc: AnnouncementService,
    private ngxsFirestoreConnect: NgxsFirestoreConnect,
    private store: Store
  ) {}

  ngxsOnInit() {
    this.ngxsFirestoreConnect.connect(FetchAnnouncements, {
      to: (action) =>
        this.announcementSvc.collection$((ref) =>
          ref.where(
            'CIRCLE_UIDS',
            'array-contains-any',
            action.payload.villagerCircleIds
          )
        ),
      cancelPrevious: true,
    });
  }

  ngxsAfterBootstrap(ctx: StateContext<AnnouncementStateModel>) {}

  @Selector()
  static allAnnouncements(state: AnnouncementStateModel): Announcement[] {
    return state.announcements;
  }

  @Action(FetchAnnouncements)
  startAnnouncementListener(
    ctx: StateContext<AnnouncementStateModel>,
    action: FetchAnnouncements
  ) {
    const { villageUID } = action.payload;
    this.announcementSvc.setVillageId(villageUID);
  }

  @Action(StreamConnected(FetchAnnouncements))
  streamConnected(
    ctx: StateContext<AnnouncementStateModel>,
    { action }: Connected<FetchAnnouncements>
  ) {
    console.log('[Announcement State] Stream connected');
  }

  @Action(StreamDisconnected(FetchAnnouncements))
  streamDisconnected(
    ctx: StateContext<AnnouncementStateModel>,
    { action }: Disconnected<FetchAnnouncements>
  ) {
    console.log('[Announcement State] Stream disconnected');
  }

  @Action(StreamEmitted(FetchAnnouncements))
  streamEmitted(
    ctx: StateContext<AnnouncementStateModel>,
    { payload }: Emitted<FetchAnnouncements, Announcement[]>
  ) {
    // console.log(
    //   '[DEBUG] Fetch Announcements EMITTED: ',
    //   payload.map((x) => x._UID)
    // );

    const selectedChatroom = this.store.selectSnapshot(
      DiscussionState.selectedPost
    );
    if (payload && selectedChatroom) {
      const matchingPost = payload.find(
        (x) => x._UID === selectedChatroom._UID
      );
      if (matchingPost) {
        this.store.dispatch(
          new SilentlyUpdateSelectedPost({ post: matchingPost })
        );
      }
    }

    const sorted = payload.sort((a: Announcement, b: Announcement) => b._CREATED_AT - a._CREATED_AT)

    ctx.patchState({
      announcements: sorted,
    });
  }

  @Action(CreateAnnouncement)
  createAnnouncement(
    ctx: StateContext<AnnouncementStateModel>,
    action: CreateAnnouncement
  ) {
    return this.announcementSvc.create$(action.payload.announcement).pipe(
      tap(() => {
        console.log('[Announcement State] successfully created announcement');
      })
    );
  }

  @Action(ClearAnnouncements)
  clear(ctx: StateContext<AnnouncementStateModel>) {
    ctx.setState({
      announcements: [],
    });
  }

  @Action(UpdateAnnouncement)
  updateAnnouncement(
    ctx: StateContext<AnnouncementStateModel>,
    action: UpdateAnnouncement
  ) {
    const { announcement } = action.payload;
    const { _UID } = announcement;
    if (!_UID) {
      console.error('Missing Id. Not updating announcement');
      alert('Error Updating Annoucement');
      return false;
    }
    return this.announcementSvc.update$(_UID, announcement);
  }

  @Action(DeleteAnnouncement)
  deleteAnnouncement(
    ctx: StateContext<AnnouncementStateModel>,
    action: DeleteAnnouncement
  ) {
    const announcement = action.payload.post;
    return this.announcementSvc.delete$(announcement._UID);
  }
}
