import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mentionified',
})
export class MentionifiedPipe implements PipeTransform {
  transform(messageBody: string, mentionUids: string[]): string {
    if (!messageBody) {
      return 'null';
    }
    let idx = 0;
    const parsedMessageBody = messageBody.replace(
      /\uFFF9.*?\uFFFB/g,
      (name) => {
        const parsedName = name.replace(/\uFFF9|\uFFFB/g, '');
        const value = `<span data-mention-uid="${mentionUids[idx]}" class="mentionified">${parsedName}</span>`;
        idx++;
        return value;
      }
    );

    return parsedMessageBody;
  }
}
