import { Circle, JoinedCircle } from "../models/circle.model";
import { Villager } from "../models/villager.model";

export namespace VillagerActions {
  export class CreateVillager {
    static readonly type = '[Villager] Create Villager';
    constructor(public payload: { villager: Villager }) {}
  }

  export class BlockVillager {
    static readonly type = '[Villager] Block Villager';
    constructor(public payload: { villagerId: string }) {}
  }

  export class UnblockVillager {
    static readonly type = '[Villager] Unblock Villager';
    constructor(public payload: { villagerId: string }) {}
  }

  export class UpdateVillager {
    static readonly type = '[Villager] Update Villager';
    constructor(
      public payload: { villagerId: string; villager: Partial<Villager> }
    ) {}
  }

  export class MarkOnboardedTaskComplete {
    static readonly type = '[Villager] Completed Onboarding Task';
    constructor(
      public payload: { villagerId: string; field: string; }
    ) {}
  }

  export class AddCircleToVillager {
    static readonly type = '[Villager] Add Circle to Villager';
    constructor(
      public payload: { villagerId: string; circle: JoinedCircle }
    ) {}
  }

  export class RemoveCircleFromVillager {
    static readonly type = '[Villager] Remove Circle from Villager';
    constructor(public payload: { villagerId: string; circle: Circle }) {}
  }

  export class OptIntoOnboardingEmails {
    static readonly type = '[Villager] Opt Into Onboarding Emails';
    constructor(public payload: { optIn: boolean}) {}
  }
}
