import { Circle, JoinedCircle } from '../models/circle.model';

export namespace CircleActions {

  export class FetchAll {
    static readonly type = '[Circle] Fetch all circles';
    constructor(public payload: { villageId: string }) {}
  }

  export class Create {
    static readonly type = '[Circle] Create circle';
    constructor(public payload: {circle: Circle}) {}
  }

  /**
   * Accepts multiple villagers. To add just one, pass an array with a single value
   * eg: adminIds: ['abc123']
   */
  export class AddAdminsToCircle {
    static readonly type = '[Circle] Add admins to circles';
    constructor(public payload: {circle: Circle, adminIds: string[]}) {}
  }

   /**
   * Accepts multiple villagers. To remove just one, pass an array with a single value
   * * eg: adminIds: ['abc123']
   */
  export class RemoveAdminsFromCircle {
    static readonly type = '[Circle] Remove admins from circle';
    constructor(public payload: {circleId: string, adminIds: string[]}) {}
  }

  /**
   * Accepts multiple villagers. To add just one, pass an array with a single value
   * eg: villagerIds: ['abc123']
   */
  export class AddVillagersToCircle {
    static readonly type = '[Circle] Add villagers to circle';
    constructor(public payload: {circle: Circle, villagerIds: string[]}) {}
  }

  export class AddVillagerToJoinedCircles {
    static readonly type = '[Circle] Add villager to joined circle';
    constructor(public payload: {joinedCircles: JoinedCircle[], villagerId: string}) {}
  }

  /**
   * Accepts multiple villagers. To remove just one, pass an array with a single value
   * * eg: villagerIds: ['abc123']
   */
  export class RemoveVillagersFromCircle {
    static readonly type = '[Circle] Remove villagers from circle';
    constructor(public payload: {circleId: string, villagerIds: string[]}) {}
  }

  export class Clear {
    static readonly type = '[Circle] Clear';
  }
}
