<ion-header class="ion-no-border" translucent="false">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button color="dark" (click)="toggleInfo()">
        <ion-icon name="reader"></ion-icon>
        <ion-label *ngIf="!showInfo">Show Post</ion-label>
        <ion-label *ngIf="showInfo">Hide Post</ion-label>
      </ion-button>
      <ion-button
        *ngIf="(isCurrentVillager$ | async) === false"
        (click)="leaveDiscussion()"
        color="dark"
      >
        <ion-icon slot="start" name="log-out"></ion-icon>
        Leave Chat
      </ion-button>
    </ion-buttons>
    <ion-buttons slot="end">
      <ion-button (click)="close()">
        <ion-icon color="dark" slot="icon-only" name="close-circle"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content #content forceOverscroll="true">
  <div *ngIf="post$ | async as post">
    <div class="post-wrapper">
      <ion-card
        *ngIf="showInfo"
        style="box-shadow: none; border: none; margin-left: 0; margin-right: 0"
      >
        <ion-card-content>
          <app-post-dropdown-details
            (done)="onLeave()"
            [post]="post"
          ></app-post-dropdown-details>
        </ion-card-content>
      </ion-card>
    </div>

    <div *ngIf="(messages$ | async) as messages">
      <div
        *ngIf="messages.length === 0 && post.MESSAGE_COUNT === 0"
        style="text-align: center"
      >
        <p class="medium" style="margin-top: 10px">Send the first message!</p>
      </div>
      <div
        *ngIf="messages.length === 0 && post.MESSAGE_COUNT !== 0"
        style="text-align: center"
      >
        <p class="medium" style="margin-top: 10px">
          Don't see any messages?! Try refreshing!
        </p>
        <ion-button (click)="refreshChatMessages()"
          >Refresh Messages</ion-button
        >
      </div>
      <app-chat-message
        *ngFor="let message of messages; trackBy: trackByFn"
        [message]="message"
        type="post"
      ></app-chat-message>

    </div>

    <div style="height: 140px"></div>
    <div *ngIf="newMessageHasImages" style="height: 240px"></div>
  </div>
</ion-content>

<div *ngIf="viewLoaded && post$ | async as loadedPost">
  <ion-footer>
    <app-chat-input
      [chatroom]="loadedPost"
      type="post"
      (hasMedia)="onImageChange($event)"
    ></app-chat-input>
  </ion-footer>
</div>
