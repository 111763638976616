<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button (click)="close()">
        <ion-icon
          style="color: #fff"
          slot="icon-only"
          name="close-circle"
          color="dark"
        ></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title></ion-title>
  </ion-toolbar>
</ion-header>

<ion-content [fullscreen]="true">
  <div class="image-container">
    <img [src]="path$ | async" alt="" />
  </div>

  <!-- hide this if no message.
    this component is re-used for chat message image detail view
    AND post image detail view -->
  <app-chat-message-options
    *ngIf="message"
    [message]="message"
    [type]="type"
  ></app-chat-message-options>
</ion-content>
