<ion-grid>
  <ion-row>
    <ion-col>
      <!-- style="margin-top: 10px; margin-bottom: 5px" -->
         <div class="subheader" >
        Add Photos & Files
      </div>
    </ion-col>
  </ion-row>
  <ion-row class="ion-align-items-center">
    <ion-col>
      <ion-row class="document-preview">
        <ion-col *ngFor="let doc of documentFiles" size="4">
          <div class="doc-icon">
            <img src="../../../assets/images/badge-images/file.png" />
          </div>
          <ion-chip (click)="removeDocument(doc)" color="dark">
            <ion-label class="ion-text-wrap">{{ doc.name }}</ion-label>
            <ion-icon name="close-circle"></ion-icon>
          </ion-chip>
        </ion-col>
      </ion-row>
      <div
        class="custom-dropzone"
        ngx-dropzone
        [accept]="
          '
        application/vnd.openxmlformats-officedocument.wordprocessingml.document,
        application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
        application/vnd.openxmlformats-officedocument.presentationml.slideshow,
        application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
        text/plain, application/pdf, image/*'
        "
        (change)="onSelect($event)"
      >
        <ngx-dropzone-label>
          <div>
            <h2>
              <ion-icon
                color="medium"
                name="document-attach-outline"
              ></ion-icon>
              <ion-icon color="medium" name="image-outline"></ion-icon>
              Add up to 3 images or documents

              <br />
              (Accepts images, PDFs and Office Documents)
            </h2>
          </div>
        </ngx-dropzone-label>
        <ngx-dropzone-image-preview
          class="custom-dropzone"
          ngProjectAs="ngx-dropzone-preview"
          *ngFor="let f of photoFiles"
          [file]="f"
          [removable]="true"
          (removed)="removePhoto(f)"
        >
        </ngx-dropzone-image-preview>
      </div>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col>
      <p *ngIf="uploadDocumentPercent$ | async as percent">
        Document upload progress {{ percent | number: "1.0-0" }}%
      </p>
    </ion-col>
    <ion-col>
      <p *ngIf="uploadPhotoPercent$ | async as percent">
        Photo upload progress {{ percent | number: "1.0-0" }}%
      </p>
    </ion-col>
  </ion-row>
</ion-grid>
