<ion-card-content>
  <ion-grid>
    <ion-row class="ion-align-items-center">
      <ion-col size="7">
        <div *ngIf="hangout.CAPACITY === 0; else limitedSpots">
          <div class="subheader" style="padding-left: 7px; margin-top: 0;">
            Open to everyone
          </div>
        </div>
        <ng-template #limitedSpots>
          <div class="subheader" style="padding-left: 7px">
            {{ hangout.CAPACITY - hangout.GOING.length }} spot<span
              *ngIf="hangout.CAPACITY - hangout.GOING.length !== 1"
              >s</span
            >
            open
          </div>
        </ng-template>

        <div
          *ngIf="goingText; else beTheFirst"
          (click)="openAttendeeListModal()"
          class="going-text"
        >
          <ion-icon name="person-add" color="primary"></ion-icon>
          {{ hangout.GOING.length }} {{ goingText }}
          <ion-icon name="chevron-forward" color="medium"></ion-icon>
        </div>
        <ng-template #beTheFirst>
          <div style="padding-left: 10px">Be the first to join!</div>
        </ng-template>
      </ion-col>
      <ion-col size="5">
        <ion-button
          [fill]="going ? 'solid' : 'outline'"
          [color]="going ? 'primary' : 'dark'"
          expand="block"
          (click)="handleGoing()"
          *ngIf="hangout.GOING.length < hangout.CAPACITY || hangout.CAPACITY === 0"
        >
          <ion-icon
            [name]="going ? 'checkmark-circle-outline' : 'person-add-outline'"
            slot="start"
          ></ion-icon>
          Going
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-card-content>
