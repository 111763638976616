import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncateBody',
})
export class TruncateBodyPipe implements PipeTransform {
  transform(
    value: string,
    limit = 300,
    completeWords = false,
    ellipsis = '...'
  ) {
    if (value.length < limit) {
      return value;
    } else {
      if (completeWords) {
        limit = value.substring(0, limit).lastIndexOf(' ');
      }
      return value.length > limit ? value.substr(0, limit) + ellipsis : value;
    }
  }
}
