import { Injectable } from '@angular/core';
import {
  Connected,
  Disconnect,
  Emitted,
  NgxsFirestoreConnect,
  StreamConnected,
  StreamEmitted,
} from '@ngxs-labs/firestore-plugin';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import {
  ChatMessage,
  Reaction,
  RecipientToken,
} from '../models/chat-message.model';

import * as firebase from 'firebase';
import 'firebase/firestore';
import { VillagerState } from './villager.state';
import { ReadReceipt } from '../models/read-receipt.model';
import * as moment from 'moment';
import { UtilitiesService } from '../services/utilities.service';

import { Villager } from '../models/villager.model';
import { concatMap } from 'rxjs/operators';
import {
  AlertController,
  LoadingController,
  ToastController,
} from '@ionic/angular';
import {
  Courtyard,
  CourtyardParticipant,
  CourtyardStateModel,
} from '../models/courtyard.model';
import { CourtyardMessageService } from '../services/courtyard-message.service';
import { CourtyardService } from '../services/courtyard.service';
import { CourtyardActions } from './courtyard.actions';
import { PushNotificationService } from '../services/push-notification.service';
import { VillageState } from './village.state';
import { AngularFirestore } from '@angular/fire/firestore';
import { AnalyticsService } from '../analytics.service';
import { getParticipantTokens, StateUtilities } from './state-utilities';
import { MarkMatchingNotificationsAsRead } from './app.actions';
import { CircleState } from './circle.state';

@State<CourtyardStateModel>({
  name: 'courtyards',
  defaults: {
    allCourtyards: [],
    selectedCourtyard: null,
    // courtyardMessages: [],
  },
})
@Injectable()
export class CourtyardState {
  loading: HTMLIonLoadingElement;

  constructor(
    private courtyardSvc: CourtyardService,
    private courtyardMessageSvc: CourtyardMessageService,
    private ngxsFirestoreConnect: NgxsFirestoreConnect,
    private store: Store,
    private utilities: StateUtilities,
    private loadingCtrl: LoadingController,
    private utils: UtilitiesService,
    private toastCtrl: ToastController,
    private pushSvc: PushNotificationService,
    private alertCtrl: AlertController,
    private angularFire: AngularFirestore,
    private analytics: AnalyticsService
  ) {}

  ngxsOnInit() {
    this.ngxsFirestoreConnect.connect(CourtyardActions.FetchAll, {
      to: (action) =>
        this.courtyardSvc
          .collection$
          // (ref) =>
          // ref.where(
          //   'PARTICIPANT_UIDS',
          //   'array-contains',
          //   action.payload.villagerId
          // )
          (),
      cancelPrevious: true,
    });

    // this.ngxsFirestoreConnect.connect(CourtyardActions.FetchMessages, {
    //   to: () => this.courtyardMessageSvc.collection$(),
    //   cancelPrevious: true,
    // });
  }

  @Selector()
  static allCourtyards(state: CourtyardStateModel): Courtyard[] {
    return state.allCourtyards;
  }

  @Selector()
  static courtyardCount(state: CourtyardStateModel): number {
    return state.allCourtyards.length;
  }

  @Selector()
  static selectedCourtyard(state: CourtyardStateModel): Courtyard {
    return state.selectedCourtyard;
  }

  @Selector()
  static participants(state: CourtyardStateModel): CourtyardParticipant[] {
    return state.selectedCourtyard.PARTICIPANTS;
  }

  @Selector()
  static participantUids(state: CourtyardStateModel): string[] {
    return state.selectedCourtyard.PARTICIPANT_UIDS;
  }

  // @Selector()
  // static messages(state: CourtyardStateModel): ChatMessage[] {
  //   return state.courtyardMessages;
  // }

  @Selector()
  static isSelectedCourtyard(
    state: CourtyardStateModel
  ): (courtyardId: string) => boolean {
    return (courtyardId: string) => {
      if (!state.selectedCourtyard) {
        return false;
      }
      return courtyardId === state.selectedCourtyard._UID;
    };
  }

  @Selector()
  static getCourtyardById(
    state: CourtyardStateModel
  ): (postId: string) => Courtyard {
    return (courtyardId: string) =>
      state.allCourtyards.find((x) => x._UID === courtyardId);
  }

  @Action(CourtyardActions.RefreshAll)
  refreshChats(
    ctx: StateContext<CourtyardStateModel>,
    action: CourtyardActions.RefreshAll
  ) {
    return this.store
      .dispatch([
        new Disconnect(CourtyardActions.FetchAll),
        // new Disconnect(CourtyardActions.FetchMessages),
      ])
      .pipe(
        concatMap(() =>
          this.store.dispatch(new CourtyardActions.FetchAll(action.payload))
        )
      );
  }

  @Action(CourtyardActions.FetchAll)
  fetchChatrooms(
    ctx: StateContext<CourtyardStateModel>,
    action: CourtyardActions.FetchAll
  ) {
    console.log(
      '[Courtyard State] Fetching all courtyards for village: ',
      action.payload.villageId
    );
    this.courtyardSvc.setVillageId(action.payload.villageId);
  }

  @Action(StreamConnected(CourtyardActions.FetchAll))
  streamConnected(
    ctx: StateContext<CourtyardStateModel>,
    { action }: Connected<CourtyardActions.FetchAll>
  ) {
    console.log('[Courtyard State] Courtyard Stream connected');
  }

  @Action(StreamEmitted(CourtyardActions.FetchAll))
  streamEmitted(
    ctx: StateContext<CourtyardStateModel>,
    { action, payload }: Emitted<CourtyardActions.FetchAll, Courtyard[]>
  ) {
    const villager = this.store.selectSnapshot(VillagerState.currentVillager);
    const currentVillage = this.store.selectSnapshot(
      VillageState.currentVillage
    );
    const village = villager.VILLAGES.find(
      (v) => v.UID === currentVillage._UID
    );
    // 1. Patch all courtyards
    // console.log(
    //   '[Courtyard State] successfully fetched courtyards: ',
    //   payload.map((x) => x._UID)
    // );
    // const courtyards = payload.sort((a, b) => b.UPDATED_AT - a.UPDATED_AT);
    const courtyards = payload.filter((courtyard) =>
      village.CIRCLE_UIDS.includes(courtyard.CIRCLE_UID)
    );

    ctx.patchState({
      allCourtyards: courtyards,
    });

    // 2. Patch the selected courtyard
    const { selectedCourtyard } = ctx.getState();
    let courtyardToPatch;
    if (selectedCourtyard) {
      courtyardToPatch = payload.find((x) => x._UID === selectedCourtyard._UID);
      ctx.patchState({
        selectedCourtyard: courtyardToPatch,
      });
      console.log('Debug updating selected courtyard: ', courtyardToPatch);
    }
  }

  // @Action(StreamConnected(CourtyardActions.FetchMessages))
  // messageStreamConnected(
  //   ctx: StateContext<CourtyardStateModel>,
  //   { action }: Connected<CourtyardActions.FetchMessages>
  // ) {
  //   console.log('[Courtyard State] Message Stream connected');
  // }

  // @Action(StreamEmitted(CourtyardActions.FetchMessages))
  // async messageStreamEmitted(
  //   ctx: StateContext<CourtyardStateModel>,
  //   { action, payload }: Emitted<CourtyardActions.FetchMessages, ChatMessage[]>
  // ) {
  //   console.log(
  //     '[Debug] [Courtyard State] received chat messages: ',
  //     payload.length
  //     // payload.map((x) => x._UID)
  //   );

  //   // mark new incoming messages as read
  //   const { selectedCourtyard } = ctx.getState();
  //   console.log(
  //     '[Debug] [Courtyard State] selectedCourtyard: ',
  //     selectedCourtyard
  //   );
  //   if (selectedCourtyard) {
  //     ctx.dispatch(
  //       new CourtyardActions.UpdateReadReceipts({
  //         courtyard: selectedCourtyard,
  //       })
  //     );
  //   }

  //   const messages = payload.sort((a, b) => a._CREATED_AT - b._CREATED_AT);
  //   messages.forEach((x, idx) => {
  //     if (idx > 0) {
  //       const previousMessage = messages[idx - 1];
  //       if (previousMessage._CREATOR_UID !== x._CREATOR_UID) {
  //         x.SHOW_TIMESTAMP = true;
  //         x.SHOW_AVATAR = true;
  //       } else if (
  //         moment(previousMessage._CREATED_AT).isAfter(
  //           moment(x._CREATED_AT).subtract(15, 'minutes')
  //         )
  //       ) {
  //         x.SHOW_TIMESTAMP = false;
  //         x.SHOW_AVATAR = false;
  //       } else {
  //         x.SHOW_TIMESTAMP = true;
  //         x.SHOW_AVATAR = true;
  //       }
  //     } else {
  //       x.SHOW_TIMESTAMP = true;
  //       x.SHOW_AVATAR = true;
  //     }
  //   });
  //   ctx.patchState({
  //     courtyardMessages: messages,
  //   });

  //   const existing = await this.loadingCtrl.getTop();
  //   if (existing) {
  //     existing.dismiss();
  //   }
  //   if (this.loading) {
  //     await this.loading.dismiss();
  //     this.loading = null;
  //   }
  // }

  @Action(CourtyardActions.Create)
  async createCourtyard(
    ctx: StateContext<CourtyardStateModel>,
    action: CourtyardActions.Create
  ) {
    let { courtyard } = action.payload;
    const uid = this.angularFire.createId();
    courtyard = {
      ...courtyard,
      _UID: uid,
    };
    const { TITLE, CIRCLE_UID } = courtyard;

    // 1. check to make sure no courtyard exists
    const response = this.checkForExistingCourtyard(ctx, TITLE, CIRCLE_UID);
    if (response === 'already exists') {
      return false;
    }

    console.log('[Courtyard State] creating courtyard: ', courtyard);
    await ctx
      .dispatch(new CourtyardActions.SetSelected({ courtyard }))
      .toPromise();
    return this.courtyardSvc.create$(courtyard);
  }

  @Action(CourtyardActions.UpdateCourtyard)
  updateCourtyard(
    ctx: StateContext<CourtyardStateModel>,
    action: CourtyardActions.UpdateCourtyard
  ) {
    const { courtyard } = action.payload;
    if (!courtyard._UID) {
      this.analytics.logError(
        'error_missingId_courtyard_description',
        'CourtyardSate',
        new Error('Missing ID')
      );
    }
    return this.courtyardSvc.update$(courtyard._UID, {
      DESCRIPTION: courtyard.DESCRIPTION,
      TITLE: courtyard.TITLE,
    });
  }

  @Action(CourtyardActions.Delete)
  delete(
    ctx: StateContext<CourtyardStateModel>,
    action: CourtyardActions.Delete
  ) {
    const { courtyardId } = action.payload;
    return this.courtyardSvc.delete$(courtyardId);
  }

  @Action(CourtyardActions.SetSelectedById)
  setSelectedById(
    ctx: StateContext<CourtyardStateModel>,
    action: CourtyardActions.SetSelectedById
  ) {
    const { allCourtyards } = ctx.getState();
    const courtyard = allCourtyards.find(
      (x) => x._UID === action.payload.courtyardId
    );
    ctx.dispatch(new CourtyardActions.SetSelected({ courtyard }));
  }

  @Action(CourtyardActions.SetSelected)
  async setSelected(
    ctx: StateContext<CourtyardStateModel>,
    action: CourtyardActions.SetSelected
  ) {
    const { courtyard } = action.payload;
    const { selectedCourtyard } = ctx.getState();

    if (!courtyard || !courtyard._UID) {
      await this.showErrorAlert();
      return false;
    }

    // if (selectedCourtyard && courtyard._UID === selectedCourtyard._UID) {
    //   console.warn('not setting selected post to the same selected courtyard');
    //   return false;
    // }

    // set the message subcollection path
    const currentVillageUid = this.store.selectSnapshot(VillageState.uid);
    if (currentVillageUid) {
      this.courtyardMessageSvc.setVillageId(currentVillageUid);

      this.pushSvc.clearNotificationsByContentId(courtyard._UID);

      // clear existing messages
      await ctx.dispatch(new CourtyardActions.RemoveSelected()).toPromise();

      this.courtyardMessageSvc.setCourtyardId(courtyard._UID);

      ctx.patchState({
        selectedCourtyard: courtyard,
      });

      // ctx.dispatch(new CourtyardActions.FetchMessages());
      // ctx.dispatch(new CourtyardActions.UpdateReadReceipts({ courtyard }));
    } else {
      console.warn('No village set yet. Cant set default courtyard');
    }
  }

  @Action(CourtyardActions.AddParticipant)
  addParticipant(
    ctx: StateContext<CourtyardStateModel>,
    action: CourtyardActions.AddParticipant
  ) {
    const { selectedCourtyard } = ctx.getState();

    const { FIRST_NAME, LAST_NAME, _UID } = this.store.selectSnapshot(
      VillagerState.currentVillager
    );

    if (!selectedCourtyard.PARTICIPANT_UIDS.includes(_UID)) {
      // add the participant
      console.log('[Courtyard Participants] Adding participant to courtyard');
      const updatedParticipantIds: string[] = [
        ...selectedCourtyard.PARTICIPANT_UIDS,
      ];
      updatedParticipantIds.push(_UID);

      const updatedParticipants: CourtyardParticipant[] = [
        ...selectedCourtyard.PARTICIPANTS,
      ];
      updatedParticipants.push({
        UID: _UID,
        FIRST_NAME,
        LAST_NAME,
      });
      // this.showAddedToCourtyard();   // disable for now, I think its ok to silently join
      return this.courtyardSvc.updateWithoutConverter(selectedCourtyard._UID, {
        PARTICIPANT_UIDS: updatedParticipantIds,
        PARTICIPANTS: updatedParticipants,
      });
    } else {
      console.log('[Courtyard Participants] Participant already in courtyard');
    }
  }

  @Action(CourtyardActions.ToggleMutedId)
  toggleNotificationsMutedId(
    ctx: StateContext<CourtyardStateModel>,
    action: CourtyardActions.ToggleMutedId
  ) {
    const { uid } = action.payload;
    const { selectedCourtyard } = ctx.getState();
    const { MUTED_UIDS, _UID: courtyardUid } = selectedCourtyard;

    const newMutedIds = [...MUTED_UIDS];
    const index = MUTED_UIDS.indexOf(uid);
    if (index > -1) {
      newMutedIds.splice(index, 1);
    } else {
      newMutedIds.push(uid);
    }

    return this.courtyardSvc.updateWithoutConverter(courtyardUid, {
      MUTED_UIDS: newMutedIds,
    });
  }

  @Action(CourtyardActions.UpdateReadReceipts)
  UpdateDirectChatReadReceipts(
    ctx: StateContext<CourtyardStateModel>,
    action: CourtyardActions.UpdateReadReceipts
  ) {
    const { courtyard } = action.payload;
    const { _UID, PARTICIPANT_READ_RECEIPTS, MESSAGE_COUNT } = courtyard;

    if (!_UID) {
      console.error('ERROR: Missing UID on courtyard');
      this.analytics.logEvent(
        'error_courtyards_update_readreceipts_missingid',
        {
          missingId: _UID,
          PARTICIPANT_READ_RECEIPTS,
        }
      );
      return;
    }

    console.log('[Debug] [Courtyard State] Mark matching read receipts');
    // mark all associated in-app notifications as read
    this.store.dispatch(
      new MarkMatchingNotificationsAsRead({ contentId: _UID })
    );

    const currentVillagerId = this.store.selectSnapshot(VillagerState.uid);
    // console.log(
    //   '[Courtyard Read Receipts] Updating read receipts in courtyard: ',
    //   courtyard._UID
    // );
    const idx = PARTICIPANT_READ_RECEIPTS.findIndex(
      (x) => x.UID === currentVillagerId
    );
    if (idx > -1) {
      const currentReadReceipt: ReadReceipt = {
        ...PARTICIPANT_READ_RECEIPTS[idx],
      };
      // console.log(
      //   '[Courtyard Read Receipts] Villager read receipt (idx): ',
      //   currentVillagerId,
      //   idx,
      //   currentReadReceipt
      // );
      if (currentReadReceipt.LAST_READ_MESSAGE_COUNT < MESSAGE_COUNT) {
        // update the chatroom doc
        currentReadReceipt.LAST_READ_MESSAGE_COUNT = MESSAGE_COUNT;
        const updatedReadReceipts = [...PARTICIPANT_READ_RECEIPTS];
        updatedReadReceipts[idx] = currentReadReceipt;
        return this.courtyardSvc.updateWithoutConverter(_UID, {
          PARTICIPANT_READ_RECEIPTS: updatedReadReceipts,
        });
      } else {
        console.log(
          '[Courtyard Read Receipts] Villager has already read all messages in courtyard'
        );
      }
    } else {
      const newReceipt: ReadReceipt = {
        UID: currentVillagerId,
        LAST_READ_MESSAGE_COUNT: courtyard.MESSAGE_COUNT,
      };
      const updatedReadReceipts = [...courtyard.PARTICIPANT_READ_RECEIPTS];
      updatedReadReceipts.push(newReceipt);
      return this.courtyardSvc.updateWithoutConverter(courtyard._UID, {
        PARTICIPANT_READ_RECEIPTS: updatedReadReceipts,
      });
    }
  }

  @Action(CourtyardActions.RemoveSelected)
  clearActiveChatroom(ctx: StateContext<CourtyardStateModel>) {
    // this.store.dispatch(new Disconnect(CourtyardActions.FetchMessages));
    ctx.patchState({
      selectedCourtyard: null,
    });
  }

  @Action(CourtyardActions.RemoveParticipant)
  async removeParticipant(
    ctx: StateContext<CourtyardStateModel>,
    action: CourtyardActions.RemoveParticipant
  ) {
    const { villagerId, courtyard } = action.payload;
    return this.utilities.removeParticipantFromCourtyard(courtyard, villagerId);
  }

  // @Action(CourtyardActions.FetchMessages)
  // async fetchMessages(
  //   ctx: StateContext<CourtyardStateModel>,
  //   action: CourtyardActions.FetchMessages
  // ) {
  //   // return this.presentLoading('Loading Messages');
  // }

  @Action(CourtyardActions.DeleteMessage)
  deleteMessage(
    ctx: StateContext<CourtyardStateModel>,
    action: CourtyardActions.DeleteMessage
  ) {
    const { messageId } = action.payload;

    return this.courtyardMessageSvc.updateWithoutConverter(messageId, {
      DELETED: true,
    });
  }

  @Action(CourtyardActions.React)
  async react(
    ctx: StateContext<CourtyardStateModel>,
    action: CourtyardActions.React
  ) {

    const villageId = this.store.selectSnapshot(VillageState.uid);
    const { selectedCourtyard } = ctx.getState();
    this.courtyardMessageSvc.setVillageId(villageId);
    this.courtyardMessageSvc.setCourtyardId(selectedCourtyard._UID);

    console.log('Adding reaction. Confirming the village id and courtyard id: ', villageId, selectedCourtyard._UID);

    const { message, emoji, villagerUid } = action.payload;

    const reactions = [...message.REACTIONS];

    if (reactions.length > 0) {
      console.log('checking existing reactions');
      const index = reactions.findIndex(
        (x) => x.VILLAGER_UID === villagerUid && x.EMOJI === emoji
      );

      if (index > -1) {
        console.log('removing reaction');
        reactions.splice(index, 1);
      } else {
        console.log('adding reaction');
        const reaction: Reaction = {
          EMOJI: emoji,
          VILLAGER_UID: villagerUid,
        };
        reactions.push(reaction);
      }
    } else {
      console.log('adding first reaction');
      const reaction: Reaction = {
        EMOJI: emoji,
        VILLAGER_UID: villagerUid,
      };
      reactions.push(reaction);
    }

    console.log(`updating ${message._UID} reactions with: `, reactions);
    return this.courtyardMessageSvc.updateWithoutConverter(message._UID, {
      REACTIONS: reactions,
    });
  }

  @Action(CourtyardActions.SendMessage)
  async sendMessage(
    ctx: StateContext<CourtyardStateModel>,
    action: CourtyardActions.SendMessage
  ) {
    const { selectedCourtyard } = ctx.getState();

    if (!selectedCourtyard._UID) {
      console.error('Missing ID on courtyard');
      this.analytics.logError(
        'missing_id_not_sending_message',
        'CourtyardState',
        new Error('Missing Id Message Send')
      );
      alert('Error Sending Message');
      return false;
    }

    const { message, villageId } = action.payload;

    if (!villageId) {
      console.error('Missing village ID to send courtyard message in');
      this.analytics.logError(
        'missing__village_id_not_sending_message',
        'CourtyardState',
        new Error('Missing Id Message Send')
      );
      alert('Error Sending Message');
      return false;
    }

    this.courtyardMessageSvc.setVillageId(villageId);
    this.courtyardMessageSvc.setCourtyardId(selectedCourtyard._UID);

    // If villager has never participated in or has left the courtyard, add them
    if (!selectedCourtyard.PARTICIPANT_UIDS.includes(message._CREATOR_UID)) {
      ctx.dispatch(new CourtyardActions.AddParticipant());
    }

    const villagers = this.store.selectSnapshot(VillagerState.allVillagersMap);
    const recipientsAndMentionUids = [
      ...message._RECIPIENT_UIDS,
      ...message._MENTION_UIDS,
    ];

    message._PARTICIPANT_TOKENS = getParticipantTokens(
      recipientsAndMentionUids,
      villagers,
      villageId
    );
    message._BODY = this.utils.parseLinksFromText(message._BODY);

    // TODO I don't think these needs awaits
    // 1. update message count
    await this.courtyardSvc
      .updateWithoutConverter(selectedCourtyard._UID, {
        MESSAGE_COUNT: firebase.default.firestore.FieldValue.increment(1),
      })
      .toPromise();

    // 2. Update UPDATE_AT timestamp
    await this.courtyardSvc
      .updateWithoutConverter(selectedCourtyard._UID, {
        UPDATED_AT: firebase.default.firestore.FieldValue.serverTimestamp(),
      })
      .toPromise();

    // Update Sender's LOCAL Read Receipt Count To Equal The Amount Of Messages
    // So They Aren't Notified Of Their Own Message Being Sent
    const updatedCourtyard: Courtyard = {
      ...selectedCourtyard,
      MESSAGE_COUNT: selectedCourtyard.MESSAGE_COUNT + 1,
    };

    ctx.dispatch(
      new CourtyardActions.UpdateReadReceipts({
        courtyard: updatedCourtyard,
      })
    );

    // 3. write message to db
    try {
      await this.courtyardMessageSvc.sendMessage(message);
    } catch (e) {
      console.error(e);
      this.analytics.logError('error_sending_message', 'CourtyardState', e);
      alert('Error Sending Message');
      return false;
    }
  }

  @Action(CourtyardActions.Clear)
  clearDMs(ctx: StateContext<CourtyardStateModel>) {
    ctx.setState({
      allCourtyards: [],
      selectedCourtyard: null,
    });
  }

  @Action(CourtyardActions.SetDefault)
  setDefault(ctx: StateContext<CourtyardStateModel>) {
    // alert('setting default courtyard');
    const { allCourtyards, selectedCourtyard } = ctx.getState();
    if (selectedCourtyard) {
      console.warn('already have selected courtyard', selectedCourtyard);
      return false;
    }
    if (allCourtyards && allCourtyards.length > 0) {
      let courtyard = allCourtyards.find((x) => x._UID === 'village-square');
      if (!courtyard) {
        courtyard = allCourtyards[0];
      }
      return ctx.dispatch(new CourtyardActions.SetSelected({ courtyard }));
    } else {
      return 'done';
    }
  }

  private checkForExistingCourtyard(
    ctx: StateContext<CourtyardStateModel>,
    TITLE: string,
    CIRCLE_UID: string
  ) {
    const { allCourtyards } = ctx.getState();
    const existing = allCourtyards.find(
      (x) => x.TITLE === TITLE && x.CIRCLE_UID === CIRCLE_UID
    );

    if (existing) {
      console.log('[Courtyard State] courtyard already exists');
      ctx.dispatch(new CourtyardActions.SetSelected({ courtyard: existing }));
      this.showAlreadyExists();
      return 'already exists';
    } else {
      console.log('[Courtyard State] no existing courtyard. creating new one');
      return 'create new room';
    }
  }

  async showAlreadyExists() {
    const toast = await this.toastCtrl.create({
      message: 'A courtyard with this name already exists',
      position: 'top',
      duration: 4000,
      color: 'primary',
      buttons: ['OK'],
    });

    await toast.present();
  }

  async showAddedToCourtyard() {
    const toast = await this.toastCtrl.create({
      message: 'You will now receive notifications for this chat.',
      position: 'top',
      duration: 5000,
      color: 'primary',
      buttons: ['OK'],
    });

    await toast.present();
  }

  async presentLoading(message: string = '') {
    const existing = await this.loadingCtrl.getTop();
    if (existing) {
      existing.dismiss();
    }

    if (!this.loading) {
      this.loading = await this.loadingCtrl.create({
        duration: 3000,
        message,
        backdropDismiss: true,
      });
      return this.loading.present();
    }
  }

  async showErrorAlert() {
    const alert = await this.alertCtrl.create({
      header: 'Oops!',
      message:
        'We ran into a bug opening that courtyard, please try selecting it again or refresh the app.',
      buttons: ['OK'],
    });

    await alert.present();
  }
}
