import { Injectable } from '@angular/core';
import { NgxsFirestore } from '@ngxs-labs/firestore-plugin';
import * as firebase from 'firebase';
import 'firebase/firestore';
import { of } from 'rxjs';
import { Courtyard } from '../models/courtyard.model';

@Injectable({
  providedIn: 'root',
})
export class CourtyardService extends NgxsFirestore<Courtyard> {
  idField = '_UID';

  protected get path() {
    return `VILLAGES/${this.villageId}/COURTYARDS`;
  }

  private VILLAGEID = '';

  public setVillageId(villageId) {
    this.VILLAGEID = villageId;
  }

  protected get villageId() {
    return this.VILLAGEID;
  }

  /**
   * @deprecated
   */
  updateIfExists(id, data) {
    return of(
      this.adapter.firestore
        .doc(`${this.path}/${id}`)
        .ref.withConverter(this.converter)
        .update(data)
    );
  }

  updateWithoutConverter(id: string, update: Partial<Courtyard>) {
    return of(this.adapter.firestore.doc(`${this.path}/${id}`).update(update));
  }

  converter: firebase.default.firestore.FirestoreDataConverter<Courtyard> = {
    toFirestore: (value: Courtyard) => {
      let db = { ...value };
      if (db.hasOwnProperty('DESCRIPTION')) {
        db = {
          ...db,
          DESCRIPTION: encodeURI(value.DESCRIPTION),
        };
      }
      // console.log('[COURTYARD SERVICE] Sending to firestore:  ', db);
      return db;
    },
    fromFirestore: (snapshot, options) => {
      let data = snapshot.data(options) as Courtyard;
      if (snapshot.metadata.hasPendingWrites) {
        const ts = firebase.default.firestore.Timestamp.now();
        data = {
          ...data,
          _CREATED_AT: ts.toDate(),
          UPDATED_AT: ts.toDate(),
        };
      } else {
        data = {
          ...data,
          _CREATED_AT: data._SERVER_TIMESTAMP.toDate(),
          UPDATED_AT: data.UPDATED_AT.toDate(),
        };
      }

      if (!data.hasOwnProperty('CIRCLE_UID')) {
        data.CIRCLE_UID = 'MEMBERS';
      }

      if (!data.hasOwnProperty('CIRCLE')) {
        data.CIRCLE = {
          NAME: 'Members',
          UID: 'MEMBERS',
        };
      }

      if (!data.hasOwnProperty('MUTED_UIDS')) {
        data.MUTED_UIDS = [];
      }

      data = {
        ...data,
        DESCRIPTION: decodeURI(data.DESCRIPTION),
      };

      return data;
    },
  };
}
