import { Injectable } from '@angular/core';
import { NgxsFirestore } from '@ngxs-labs/firestore-plugin';
import * as firebase from 'firebase';
import 'firebase/firestore';
import * as moment from 'moment';
import { of } from 'rxjs';
import { Villager } from '../models/villager.model';
import packageInfo from '../../../package.json';

export const sanitizeVillager = (value: Villager): Villager => {
  let db = { ...value };
  if (db.hasOwnProperty('BIO')) {
    db = {
      ...db,
      BIO: encodeURI(value.BIO),
    };
  }
  if (db.hasOwnProperty('BIO_FORFUN')) {
    db = {
      ...db,
      BIO_FORFUN: encodeURI(value.BIO_FORFUN),
    };
  }
  if (db.hasOwnProperty('BIO_INTERESTS')) {
    db = {
      ...db,
      BIO_INTERESTS: encodeURI(value.BIO_INTERESTS),
    };
  }
  if (db.hasOwnProperty('BIO_THREETHINGS')) {
    db = {
      ...db,
      BIO_THREETHINGS: encodeURI(value.BIO_THREETHINGS),
    };
  }
  if (db.hasOwnProperty('VILLAGES_UIDS')) {
    db = {
      ...db,
      VILLAGES_UIDS: [...new Set(db.VILLAGES_UIDS)],
    };
  }
  db = {
    ...db,
    APP_VERSION: packageInfo.version || 'DNE'
  }

  // console.log('[VILLAGER SERVICE] sending to database: ', db);
  return db;
};

export const hydrateVillager = (snapshot, options): Villager => {
  let data = snapshot.data(options) as Villager;
  // console.log('[VILLAGER SERVICE] returning from database: ', data);
  if (snapshot.metadata.hasPendingWrites) {
    const ts = firebase.default.firestore.Timestamp.now();
    data = {
      ...data,
      BIO: decodeURI(data.BIO),
      BIO_FORFUN: decodeURI(data.BIO_FORFUN),
      BIO_INTERESTS: decodeURI(data.BIO_INTERESTS),
      BIO_THREETHINGS: decodeURI(data.BIO_THREETHINGS),
      _CREATED_AT: ts.toDate(),
      UPDATED_AT: ts.toDate(),
    };
  } else {
    if (data.hasOwnProperty('BIO')) {
      data = {
        ...data,
        BIO: decodeURI(data.BIO),
      };
    }
    if (data.hasOwnProperty('BIO_FORFUN')) {
      data = {
        ...data,
        BIO_FORFUN: decodeURI(data.BIO_FORFUN),
      };
    }
    if (data.hasOwnProperty('BIO_INTERESTS')) {
      data = {
        ...data,
        BIO_INTERESTS: decodeURI(data.BIO_INTERESTS),
      };
    }
    if (data.hasOwnProperty('BIO_THREETHINGS')) {
      data = {
        ...data,
        BIO_THREETHINGS: decodeURI(data.BIO_THREETHINGS),
      };
    }

    if (data.hasOwnProperty('UPDATED_AT')) {
      data = {
        ...data,
        UPDATED_AT: data.UPDATED_AT.toDate(),
      };
    }

    if (data.hasOwnProperty('LAST_ACTIVE')) {
      const lastActive = data.LAST_ACTIVE.toDate();

      data = {
        ...data,
        LAST_ACTIVE: lastActive,
        RECENTLY_ACTIVE: moment(lastActive).isAfter(
          moment().subtract(1, 'hour')
        ),
      };
    } else {
      data = {
        ...data,
        RECENTLY_ACTIVE: false,
      };
    }

    // if(!data.hasOwnProperty('FCM_TOKEN'))

    data = {
      ...data,
      _CREATED_AT: data._SERVER_TIMESTAMP.toDate(),
    };

    if (data.hasOwnProperty('VILLAGES_UIDS')) {
      data = {
        ...data,
        VILLAGES_UIDS: [...new Set(data.VILLAGES_UIDS)],
      };
    }
    if (data.hasOwnProperty('VILLAGES')) {
      data.VILLAGES.forEach((joined, idx) => {
        if (typeof joined.PUSH !== 'boolean') {
          data.VILLAGES[idx].PUSH = false;
        }
      });
    }

    if (!data.hasOwnProperty('BLOCKED_VILLAGERS')) {
      data = {
        ...data,
        BLOCKED_VILLAGERS: [],
      };
    }

    if (!data.hasOwnProperty('NOTIFICATIONS_ALLOWED')) {
      data = {
        ...data,
        NOTIFICATIONS_ALLOWED: false,
      };
    }

    if (!data.hasOwnProperty('FCM_TOKEN')) {
      data = {
        ...data,
        FCM_TOKEN: '',
      };
    }
  }

  data = {
    ...data,
    APP_VERSION: packageInfo.version || 'DNE'
  }

  return data;
};

@Injectable({
  providedIn: 'root',
})
export class VillagerService extends NgxsFirestore<Villager> {
  idField = '_UID';
  path = 'VILLAGERS';

  /**
   * @deprecated
   */
  updateIfExists(id, data) {
    return of(
      this.adapter.firestore
        .doc(`${this.path}/${id}`)
        .ref.withConverter(this.converter)
        .update(data)
    );
  }

  updateWithoutConverter(id: string, update: Partial<Villager>) {
    return of(this.adapter.firestore.doc(`${this.path}/${id}`).update(update));
  }

  converter: firebase.default.firestore.FirestoreDataConverter<Villager> = {
    toFirestore: sanitizeVillager,
    fromFirestore: hydrateVillager,
  };
}
