import { Injectable } from '@angular/core';
import {
  Action,
  NgxsAfterBootstrap,
  Selector,
  State,
  StateContext,
  Store,
} from '@ngxs/store';
import { GenericPost } from '../models/post-core.model';
import {
  ClearVillageChatroom,
  DeleteVillageMessage,
  DisconnectDiscussionChat,
  DiscussionReact,
  FetchChatroomMessages,
  FetchMessagesComplete,
  MarkMatchingNotificationsAsRead,
  RefreshChatroomMessages,
  RemoveDiscussionParticipant,
  RemoveSelectedDirectChatroom,
  SendVillageMessage,
  SetSelectedPost,
  SilentlyUpdateSelectedPost,
  UpdatePostReadReceipts,
} from './app.actions';

import { VillageMessageService } from '../services/village-message.service';
import { DiscussionStateModel } from '../models/village-chatroom.model';
import { getParticipantTokens, StateUtilities } from './state-utilities';
import { VillageState } from './village.state';
import {
  ChatMessage,
  Reaction,
  RecipientToken,
} from '../models/chat-message.model';
import {
  Connected,
  Disconnect,
  Emitted,
  NgxsFirestoreConnect,
  StreamConnected,
  StreamEmitted,
} from '@ngxs-labs/firestore-plugin';
import { VillagerState } from './villager.state';
import { ReadReceipt } from '../models/read-receipt.model';
import * as moment from 'moment';
import { UtilitiesService } from '../services/utilities.service';
import { PushNotificationService } from '../services/push-notification.service';
import { AnalyticsService } from '../analytics.service';
import { LoadingController } from '@ionic/angular';

@State<DiscussionStateModel>({
  name: 'villageChatrooms',
  defaults: {
    selectedPost: null,
    selectedPostChatroomMessages: [],
    postSetLocation: null,
  },
})
@Injectable()
export class DiscussionState implements NgxsAfterBootstrap {
  loading: HTMLIonLoadingElement;
  component = 'discussion-state';
  constructor(
    private messageSvc: VillageMessageService,
    private utilities: StateUtilities,
    private ngxsFirestoreConnect: NgxsFirestoreConnect,
    private store: Store,
    private utils: UtilitiesService,
    private pushSvc: PushNotificationService,
    private analytics: AnalyticsService,
    private loadingCtrl: LoadingController
  ) {}

  ngxsOnInit() {
    this.ngxsFirestoreConnect.connect(FetchChatroomMessages, {
      to: () => this.messageSvc.collection$(),
      cancelPrevious: true,
    });
  }

  ngxsAfterBootstrap(ctx: StateContext<DiscussionStateModel>) {
    const selectedPost = ctx.getState().selectedPost;
    if (selectedPost) {
      ctx.dispatch(new SetSelectedPost({ post: selectedPost, location: null }));
    }
  }

  @Selector()
  static selectedPost(state: DiscussionStateModel): GenericPost {
    return state.selectedPost;
  }

  @Selector()
  static postSetLocation(state: DiscussionStateModel): string {
    return state.postSetLocation;
  }

  @Selector()
  static selectedPostChatroomMessages(
    state: DiscussionStateModel
  ): ChatMessage[] {
    return state.selectedPostChatroomMessages;
  }

  @Selector()
  static isSelectedPost(
    state: DiscussionStateModel
  ): (postId: string) => boolean {
    return (postId: string) => {
      if (!state.selectedPost) return false;
      return postId === state.selectedPost._UID;
    };
  }

  @Action(StreamConnected(FetchChatroomMessages))
  messageStreamConnected(
    ctx: StateContext<DiscussionStateModel>,
    { action }: Connected<FetchChatroomMessages>
  ) {
    // console.log('[Village Chatroom State] Message Stream connected');
  }

  @Action(StreamEmitted(FetchChatroomMessages))
  async messageStreamEmitted(
    ctx: StateContext<DiscussionStateModel>,
    { action, payload }: Emitted<FetchChatroomMessages, ChatMessage[]>
  ) {
    console.log(
      '[DEBUG] [Village Chatroom State] received chat messages: ',
      payload.length
      // payload.map((x) => x._UID)
    );

    // mark new incoming messages as read
    const { selectedPost } = ctx.getState();
    if (selectedPost) {
      ctx.dispatch(
        new UpdatePostReadReceipts({
          post: selectedPost,
        })
      );
    }

    const messages = payload.sort((a, b) => a._CREATED_AT - b._CREATED_AT);
    messages.forEach((x, idx) => {
      if (idx > 0) {
        const previousMessage = messages[idx - 1];
        if (previousMessage._CREATOR_UID !== x._CREATOR_UID) {
          x.SHOW_TIMESTAMP = true;
          x.SHOW_AVATAR = true;
        } else if (
          moment(previousMessage._CREATED_AT).isAfter(
            moment(x._CREATED_AT).subtract(15, 'minutes')
          )
        ) {
          x.SHOW_TIMESTAMP = false;
          x.SHOW_AVATAR = false;
        } else {
          x.SHOW_TIMESTAMP = true;
          x.SHOW_AVATAR = true;
        }
      } else {
        x.SHOW_TIMESTAMP = true;
        x.SHOW_AVATAR = true;
      }
    });
    ctx.patchState({
      selectedPostChatroomMessages: messages,
    });

    if (this.loading) {
      await this.loading.dismiss();
      this.loading = null;
    }
  }

  @Action(SilentlyUpdateSelectedPost)
  silentlySetSelectedPost(
    ctx: StateContext<DiscussionStateModel>,
    action: SilentlyUpdateSelectedPost
  ) {
    ctx.patchState({
      selectedPost: action.payload.post,
    });
  }

  @Action(SetSelectedPost)
  async setSelectedPost(
    ctx: StateContext<DiscussionStateModel>,
    action: SetSelectedPost
  ) {
    // clear chat messages from previous chatroom
    await ctx.dispatch(new ClearVillageChatroom()).toPromise();
    // also clear any active DMs for now
    this.store.dispatch(new RemoveSelectedDirectChatroom());

    const { post, location } = action.payload;

    this.pushSvc.clearNotificationsByContentId(post._UID);

    // set the message subcollection path
    const currentVillageUid = this.store.selectSnapshot(VillageState.uid);
    if (currentVillageUid) {
      this.messageSvc.setVillageId(currentVillageUid);

      switch (post._TYPE) {
        case 'SHARE':
          this.messageSvc.setCollection('SHARES');
          break;
        case 'HANGOUT':
          this.messageSvc.setCollection('HANGOUTS');
          break;
        case 'EXCHANGE':
          this.messageSvc.setCollection('EXCHANGE');
          break;
        case 'SUPPORT':
          this.messageSvc.setCollection('SUPPORT_REQUESTS');
          break;
        case 'CONFLICT':
          this.messageSvc.setCollection('CONFLICTS');
          break;
        case 'ANNOUNCEMENT':
          this.messageSvc.setCollection('ANNOUNCEMENTS');
          break;
        case 'LIST':
          this.messageSvc.setCollection('LISTS');
          break;
        default:
          alert('Error. Cannot find matching type');
          break;
      }
      this.messageSvc.setChatroomId(post._UID);

      // set local state
      ctx.patchState({
        selectedPost: post,
        postSetLocation: location,
      });

      console.log('[Discussion State] Set selected post');

      // fetch the messages
      ctx.dispatch(new FetchChatroomMessages());

      ctx.dispatch(new UpdatePostReadReceipts({ post }));
    } else {
      this.analytics.logError(
        'error_setting_selected_discussion',
        this.component,
        new Error('no village set')
      );
      alert('Error: no village set');
    }
  }

  @Action(RefreshChatroomMessages)
  refreshMessages(ctx: StateContext<DiscussionStateModel>) {
    ctx.dispatch(new FetchChatroomMessages());
  }

  @Action(UpdatePostReadReceipts)
  async updateReadReceipts(ctx, action: UpdatePostReadReceipts) {
    const { post } = action.payload;
    const { _UID, _TYPE, PARTICIPANT_READ_RECEIPTS, MESSAGE_COUNT } = post;

    // mark all associated in-app notifications as read
    this.store.dispatch(
      new MarkMatchingNotificationsAsRead({ contentId: _UID })
    );

    const currentVillagerId = this.store.selectSnapshot(VillagerState.uid);
    let idx = PARTICIPANT_READ_RECEIPTS.findIndex(
      (x) => x.UID === currentVillagerId
    );
    let currentReadReceipt: ReadReceipt;
    if (idx > -1) {
      currentReadReceipt = {
        ...PARTICIPANT_READ_RECEIPTS[idx],
      };
    } else {
      currentReadReceipt = {
        UID: currentVillagerId,
        LAST_READ_MESSAGE_COUNT: 0,
      };
    }

    console.log(
      '[Village Chatroom] have read receipt: ',
      currentReadReceipt,
      post
    );

    if (MESSAGE_COUNT > currentReadReceipt.LAST_READ_MESSAGE_COUNT) {
      // update the chatroom doc
      currentReadReceipt.LAST_READ_MESSAGE_COUNT = MESSAGE_COUNT;
      const updatedReadReceipts = [...PARTICIPANT_READ_RECEIPTS];
      if (idx > -1) {
        updatedReadReceipts[idx] = currentReadReceipt;
      } else {
        updatedReadReceipts.push(currentReadReceipt);
      }

      return this.utilities.updateReadReceipts(
        _UID,
        _TYPE,
        updatedReadReceipts
      );
    }
  }

  @Action(DiscussionReact)
  async react(
    ctx: StateContext<DiscussionStateModel>,
    action: DiscussionReact
  ) {
    const { message, emoji, villagerUid } = action.payload;

    let reactions = [...message.REACTIONS];

    if (reactions.length > 0) {
      console.log('checking existing reactions');
      const index = reactions.findIndex(
        (x) => x.VILLAGER_UID === villagerUid && x.EMOJI === emoji
      );

      if (index > -1) {
        console.log('removing reaction');
        reactions.splice(index, 1);
      } else {
        console.log('adding reaction');
        let reaction: Reaction = {
          EMOJI: emoji,
          VILLAGER_UID: villagerUid,
        };
        reactions.push(reaction);
      }
    } else {
      console.log('adding first reaction');
      let reaction: Reaction = {
        EMOJI: emoji,
        VILLAGER_UID: villagerUid,
      };
      reactions.push(reaction);
    }

    console.log(`updating ${message._UID} reactions with: `, reactions);
    return this.messageSvc.updateWithoutConverter(message._UID, {
      REACTIONS: reactions,
    });
  }

  @Action(RemoveDiscussionParticipant)
  async removeParticipant(
    ctx: StateContext<DiscussionStateModel>,
    action: RemoveDiscussionParticipant
  ) {
    const { villagerId, post } = action.payload;
    return this.utilities.removeParticipantFromDiscussion(post, villagerId);
  }

  @Action(DeleteVillageMessage)
  deleteMessage(
    ctx: StateContext<DiscussionStateModel>,
    action: DeleteVillageMessage
  ) {
    const { messageId } = action.payload;
    return this.messageSvc.updateWithoutConverter(messageId, {
      DELETED: true,
    });
  }

  @Action(SendVillageMessage)
  async sendMessage(
    ctx: StateContext<DiscussionStateModel>,
    action: SendVillageMessage
  ) {
    const { message, villageId } = action.payload;

    this.messageSvc.setVillageId(villageId);

    const villagers = this.store.selectSnapshot(VillagerState.allVillagersMap);
    const recipientsAndMentionUids = [
      ...message._RECIPIENT_UIDS,
      ...message._MENTION_UIDS,
    ];

    message._PARTICIPANT_TOKENS = getParticipantTokens(
      recipientsAndMentionUids,
      villagers,
      villageId
    );
    message._BODY = this.utils.parseLinksFromText(message._BODY);

    console.log('[Discussion State] New message to send: ', message);

    // 1. add sender to chatroom PARTICIPANT_UIDS arrays
    const { selectedPost } = ctx.getState();
    await this.utilities.updateParticipants(selectedPost, message._CREATOR_UID);

    // this.store.dispatch(new AddPostToParticipated({ post: selectedPost}));

    // 3. Update Message Count
    await this.utilities.incrementMessageCount(selectedPost).toPromise();

    // 4. Update UPDATE_AT timestamp
    await this.utilities.setUpdatedAtTimestamp(selectedPost);

    console.log('[Discussion State] post before updates: ', selectedPost);
    let updatedPost = ctx.getState().selectedPost;
    console.log('[Discussion State] post after updates: ', updatedPost);
    // update senders' read receipt so they arent notified of their own message
    ctx.dispatch(new UpdatePostReadReceipts({ post: updatedPost }));

    // 5. write message to db
    return this.messageSvc.create$(message);
  }

  @Action(FetchChatroomMessages)
  async fetchChatroomMessages(
    ctx: StateContext<DiscussionStateModel>,
    action: FetchChatroomMessages
  ) {
    await this.presentLoading('Loading Messages');
  }

  @Action(DisconnectDiscussionChat)
  disconnectChat(ctx: StateContext<DiscussionStateModel>) {
    this.store.dispatch(new Disconnect(FetchChatroomMessages));
    ctx.patchState({
      selectedPostChatroomMessages: [],
    });
  }

  @Action(ClearVillageChatroom)
  clear(ctx: StateContext<DiscussionStateModel>) {
    this.store.dispatch(new Disconnect(FetchChatroomMessages));
    ctx.setState({
      selectedPost: null,
      selectedPostChatroomMessages: [],
      postSetLocation: null,
    });
  }

  @Action(FetchMessagesComplete)
  complete() {}

  async presentLoading(message: string = '') {
    if (!this.loading) {
      this.loading = await this.loadingCtrl.create({
        duration: 3000,
        message,
        backdropDismiss: true,
      });
      return this.loading.present();
    }
  }
}
