import { Tutorial, TutorialState} from './state/tutorial.state';
import { Component, NgZone, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { setUser } from '@sentry/angular-ivy';
import {
  AlertController,
  LoadingController,
  MenuController,
  ModalController,
  NavController,
  Platform,
  ToastController,
} from '@ionic/angular';
import { StreamEmitted } from '@ngxs-labs/firestore-plugin';
import {
  ActionCompletion,
  Actions,
  ofActionCompleted,
  ofActionDispatched,
  Select,
  Store,
} from '@ngxs/store';
import { JoinedVillage, Villager } from './models/villager.model';
import { combineLatest, Observable } from 'rxjs';
import { BackgroundService } from './services/background.service';
import { PushNotificationService } from './services/push-notification.service';
import {
  GetFellowVillagers,
  GetVillage,
  GetVillager,
  Logout,
  AppToggleIsMobile,
  GetInitialVillageData,
  GetVillageBulletinBoard,
  AppInitialDataLoaded,
  FetchDirectChatrooms,
  FetchShares,
  FetchAnnouncements,
  FetchHangouts,
  FetchSharedLists,
  FetchSupportRequests,
  FetchConflicts,
  FetchExchanges,
  FetchVillagerNotifications,
  SetStatusOnline,
  SwitchVillage,
} from './state/app.actions';
import { AuthState } from './state/auth.state';
import { VillagerState } from './state/villager.state';
import { SplashScreen } from '@capacitor/splash-screen';
import { environment } from 'src/environments/environment';
import { AnalyticsService } from './analytics.service';
import { OfflineService } from './services/offline.service';
import { AppState } from './state/app.state';
import {
  debounceTime,
  distinctUntilChanged,
  distinctUntilKeyChanged,
  tap,
} from 'rxjs/operators';
import { CourtyardActions } from './state/courtyard.actions';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { VillageState } from './state/village.state';
import { Village } from './models/village.model';
import { ThemeService } from './services/theme.service';
import { AngularFireFunctions } from '@angular/fire/functions';
import { ImageService } from './services/images.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  loading: HTMLIonLoadingElement = null;
  alert: HTMLIonAlertElement = null;
  authRoutes = ['/', '/signup', '/login'];
  modal: HTMLIonModalElement;
  isToastPresent: boolean;
  appName: string;
  theme: 'revillager' | 'northern' | 'southern' = 'revillager';
  mode: 'light' | 'dark' = 'light';
  thumbnailUrl$: Observable<string>;
  villagePages = [
    {
      title: 'Home',
      url: '/pages/home',
      icon: 'home',
    },
    {
      title: 'Board',
      url: '/pages/board',
      icon: 'grid',
    },
    {
      title: 'Members',
      url: 'members',
      icon: 'people',
    },
    {
      title: 'Circles',
      url: 'circles',
      icon: 'people-circle',
    },
  ];

  memberPages = [
    {
      title: 'Direct Messages',
      url: '/pages/messages',
      icon: 'paper-plane',
    },
  ];

  @Select(VillagerState.currentVillager) villager$: Observable<Villager>;
  @Select(VillageState.currentVillage) village$: Observable<Village>;
  @Select(AppState.isOffline) isOffline$: Observable<boolean>;
  @Select(AuthState.isAuthenticated) isAuthenticated$: Observable<boolean>;
  @Select(VillageState.showSwitchVillage)
  showSwitchVillage$: Observable<boolean>;
  @Select(VillageState.villageName) villageName$: Observable<string>;
  @Select(VillageState.uid) villageId$: Observable<string>;
  @Select(AppState.isMobile) isMobile$: Observable<boolean>;
  @Select(VillageState.theme) theme$: Observable<
    'revillager' | 'northern' | 'southern'
  >;
  @Select(TutorialState.videoOverviewSeen) videoOverviewSeen$: Observable<boolean>;

  initialLoadDone$ = combineLatest([
    this.actions$.pipe(ofActionCompleted(GetInitialVillageData)),
    this.actions$.pipe(ofActionCompleted(FetchAnnouncements)),
    this.actions$.pipe(ofActionCompleted(FetchSharedLists)),
    this.actions$.pipe(ofActionCompleted(CourtyardActions.FetchAll)),
  ]).pipe(
    tap(() => {
      this.hideLoading();
    })
  );

  boardLoadDone$ = combineLatest([
    this.actions$.pipe(ofActionCompleted(GetVillageBulletinBoard)),
    this.actions$.pipe(ofActionCompleted(FetchShares)),
    this.actions$.pipe(ofActionCompleted(FetchHangouts)),
    this.actions$.pipe(ofActionCompleted(FetchSupportRequests)),
    this.actions$.pipe(ofActionCompleted(FetchExchanges)),
    this.actions$.pipe(ofActionCompleted(FetchConflicts)),
  ]).pipe(
    tap(() => {
      this.hideLoading();
    })
  );

  constructor(
    private store: Store,
    private loadingCtrl: LoadingController,
    private actions$: Actions,
    private platform: Platform,
    private router: Router,
    private backgroundSvc: BackgroundService,
    private pushSvc: PushNotificationService,
    private analytics: AnalyticsService,
    private modalCtrl: ModalController,
    private navCtrl: NavController,
    private toastCtrl: ToastController,
    private offlineSvc: OfflineService,
    private alertCtrl: AlertController,
    private zone: NgZone,
    private menuCtrl: MenuController,
    private themeSvc: ThemeService,
    private fns: AngularFireFunctions,
    private imageSvc: ImageService
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.toastCtrl.create({ animated: false }).then((t) => {
      t.present();
      t.dismiss();
    });

    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        // Example url: https://beerswift.app/tabs/tab2
        // slug = /tabs/tab2
        console.log('[DEEP LINK] FULL URL: ', event.url);
        // const slug = event.url.split('.com').pop();

        const queryParams = event.url.split('?').pop();
        if (queryParams) {
          const params = new URLSearchParams(queryParams);
          const encodedInvite = params.get('code');

          if (encodedInvite) {
            // {inviteCode: string, expires: number}
            const decodedInvite = JSON.parse(atob(encodedInvite));
            const { inviteCode } = decodedInvite; // TODO handle expires
            const authd = this.store.selectSnapshot(AuthState.isAuthenticated);
            const villagerId = this.store.selectSnapshot(VillagerState.uid);
            if (authd && villagerId) {
              // handle the invite code here
              this.confirmAcceptInvite(inviteCode, villagerId);
            } else {
              this.router.navigateByUrl(`/signup/${inviteCode}`);
            }
          }
        }

        // else {
        //   this.router.navigateByUrl(slug);
        // }
        // If no match, do nothing - let regular routing
        // logic take over
      });
    });
  }

  ngOnInit() {
    this.appName = environment.appName;
    this.menuCtrl.enable(false);

    this.isAuthenticated$.subscribe((auth) => {
      this.menuCtrl.enable(auth);
    });

    console.log(
      '[App] Connected to Database: ',
      environment.firebase.projectId
    );

    this.initialLoadDone$.subscribe();
    this.boardLoadDone$.subscribe();

    this.theme$.subscribe((theme) => {
      this.theme = theme;
      this.themeSvc.setTheme(theme);
    });

    if (window && window.matchMedia) {
      window
        .matchMedia('(prefers-color-scheme: dark)')
        .addEventListener('change', (event) => {
          this.mode = event.matches ? 'dark' : 'light';
          this.themeSvc.setTheme(this.theme);
        });
    }

    // this.store.dispatch(
    //   new AppToggleIsMobile({ isMobile: this.platform.is('mobile') })
    // );
    const width = this.platform.width();
    if (width < 600) {
      this.store.dispatch(new AppToggleIsMobile({ isMobile: true }));
    } else {
      this.store.dispatch(new AppToggleIsMobile({ isMobile: false }));
    }

    this.platform.resize.subscribe(async () => {
      const resizedWidth = this.platform.width();
      if (resizedWidth < 600) {
        this.store.dispatch(new AppToggleIsMobile({ isMobile: true }));
      } else {
        this.store.dispatch(new AppToggleIsMobile({ isMobile: false }));
      }
    });

    SplashScreen.show({
      showDuration: 3000,
      autoHide: true,
    });

    this.actions$
      .pipe(ofActionDispatched(GetInitialVillageData))
      .subscribe(async () => {
        // await this.presentLoading('Getting Chats');
      });

    this.actions$
      .pipe(ofActionCompleted(GetInitialVillageData))
      .subscribe(() => {});

    this.actions$
      .pipe(ofActionDispatched(FetchDirectChatrooms))
      .subscribe(async () => {
        await this.presentLoading('Getting Messages');
      });

    this.actions$
      .pipe(ofActionCompleted(FetchDirectChatrooms))
      .subscribe(() => {
        this.hideLoading();
      });

    this.actions$
      .pipe(ofActionDispatched(GetVillageBulletinBoard))
      .subscribe(async () => {
        await this.presentLoading('Getting Posts');
      });

    this.actions$
      .pipe(ofActionCompleted(StreamEmitted(GetVillager)), debounceTime(100))
      .subscribe((completion: ActionCompletion) => {
        if (this.store.selectSnapshot(AppState.initLoad)) return;
        if (!this.store.selectSnapshot(AuthState.isAuthenticated)) return;

        const villager: Villager = completion.action.payload;

        // set sentry user context
        if (villager && villager._UID && villager._EMAIL) {
          setUser({ id: villager._UID, email: villager._EMAIL });
        }

        if (villager && villager._CREATED_AT) {
          if (villager.VILLAGE) {
            // WARNING do not update the villager in here
            // it will cause inf loop before initLoad set to true
            const joinedVillage: JoinedVillage = villager.VILLAGES.find(
              (x) => x.UID === villager.VILLAGE
            );
            if (joinedVillage) {
              const villagerCircleIds: string[] = joinedVillage.CIRCLE_UIDS;
              this.fetchAppData(villager, villagerCircleIds);
            } else {
              this.signOutError('Missing Data. Please log back in.');
            }
          } else {
            // handle villager doesnt have a village yet
            this.missingVillage();
          }
        } else {
          // villager doc is fucked
          this.signOutError('Could not connect to server. Please log back in');
        }
      });

    this.village$
    // .pipe(distinctUntilKeyChanged('_UID'))
    .subscribe((village) => {
      if (village) {
        const idx = this.villagePages.findIndex((x) => x.title === 'Village');
        if (idx > -1) {
          this.villagePages.splice(idx, 1);
        }
        this.villagePages.push({
          title: 'Village',
          url: `/village/${village._UID}`,
          icon: 'bonfire',
        });
      }
    });

    this.villager$
      .pipe(distinctUntilKeyChanged('_UID'))
      .subscribe((villager) => {
        if (villager) {
          const idx = this.memberPages.findIndex((x) => x.title === 'Profile');
          if (idx > -1) {
            this.memberPages.splice(idx, 1);
          }
          this.memberPages.push({
            title: 'Profile',
            url: `/pages/profile/${villager._UID}`,
            icon: 'person-circle',
          });
        }
      });

    combineLatest([this.villageId$, this.villageName$]).subscribe(
      ([id, name]) => {
        if (id && name) {
          this.thumbnailUrl$ = this.imageSvc.getVillagePfp$(id, name, '256');
        }
      }
    );

    this.platform.ready().then(async () => {
      SplashScreen.hide();
      this.backgroundSvc.init();
      this.pushSvc.init();
      this.analytics.init();
      this.offlineSvc.init();

      const cachedAuthUid = this.store.selectSnapshot(AuthState.uid);

      if (cachedAuthUid) {
        this.fetchExistingUser(cachedAuthUid);
      }

      this.isOffline$.pipe(debounceTime(5000)).subscribe(async (isOffline) => {
        if (isOffline === undefined) return false;
        console.log('App sees status offline: ', isOffline);
        if (isOffline && !this.isToastPresent) {
          this.presentOfflineToast();
        } else if (!isOffline && this.isToastPresent) {
          this.toastCtrl.dismiss();
        }
      });
    });
  }

  async fetchExistingUser(cachedAuthUid) {
    console.log('[App] have cached uid: ', cachedAuthUid);
    // await this.presentLoading('Loading');
    const currentUrl = this.router.url;
    if (this.authRoutes.includes(currentUrl)) {
      this.navCtrl.setDirection('root');
      this.router.navigateByUrl('pages/home');
    }
    await this.store
      .dispatch(new GetVillager({ uid: cachedAuthUid }))
      .toPromise();

    if (this.loading) {
      await this.loading.dismiss();
      this.loading = null;
    }
  }

  fetchAppData(villager: Villager, villagerCircleIds: string[]) {
    const uid = villager.VILLAGE;
    this.store
      .dispatch([
        new GetVillage({ uid }),
        new GetInitialVillageData({ uid, villagerCircleIds }),
        new GetFellowVillagers({ villageUid: uid }),
        new FetchVillagerNotifications({
          villagerId: villager._UID,
          villageName: 'All Villages',
        }),
      ])
      .subscribe(() => {
        // WARNING: anything that updates the villager doc needs to put after initial data loaded
        // otherwise it will cause inf loop above with Emitted(Villager)
        this.store.dispatch(new AppInitialDataLoaded());
        this.updateFcmToken(villager);
        this.store.dispatch(new SetStatusOnline());
      });
  }

  updateFcmToken(villager: Villager) {
    if (villager.NOTIFICATIONS_ALLOWED) {
      this.pushSvc.register();
    } else {
      console.log(
        'not calling refresh. villager doesnt have notifications enabled'
      );
    }
  }

  goToVideoOnboarding() {
    const villageId = this.store.selectSnapshot(VillageState.uid);
    this.router.navigateByUrl(`/village/${villageId}`);
    this.store.dispatch(new Tutorial.VideoOverviewSeen({seen: true}));
    this.menuCtrl.close();
  }

  async presentLoading(message: string = '') {
    const existing = await this.loadingCtrl.getTop();
    if (existing) existing.dismiss();
    if (!this.loading) {
      this.loading = await this.loadingCtrl.create({
        duration: 30000,
        message,
        backdropDismiss: true,
      });
      return this.loading.present();
    }
  }

  async presentOfflineToast() {
    const toast = await this.toastCtrl.create({
      duration: 4000,
      message:
        '<ion-icon name="alert-circle-outline"></ion-icon> No Internet Connection.',
      position: 'bottom',
      color: 'danger',
    });
    toast.onDidDismiss().then(() => (this.isToastPresent = false));
    this.isToastPresent = true;
    return await toast.present();
  }

  private hideLoading() {
    setTimeout(() => {
      if (this.loading) {
        this.loading.dismiss();
        this.loading = null;
      }
    }, 500);
  }

  async missingVillage() {
    if (this.alert) return false;
    this.alert = await this.alertCtrl.create({
      header: 'Oops!',
      message:
        'You are not in a village. If this is a mistake, please try again. Otherwise you can create or join a village',
      buttons: [
        {
          text: 'Try Again',
          handler: () => {
            const cachedAuthUid = this.store.selectSnapshot(AuthState.uid);
            if (cachedAuthUid) {
              this.fetchExistingUser(cachedAuthUid);
            }
            this.alert = null;
          },
        },
        {
          text: 'Create Village',
          handler: () => {
            this.router.navigateByUrl('create-village');
            this.alert = null;
          },
        },
      ],
    });

    await this.alert.present();
  }

  async signOutError(message: string) {
    if (this.alert) return false;
    this.alert = await this.alertCtrl.create({
      header: 'Error',
      message,
      buttons: [
        {
          text: 'Logout',
          role: 'destructive',
          handler: () => {
            this.store.dispatch(new Logout());
            this.alert = null;
          },
        },
        {
          text: 'Retry',
          handler: () => {
            const cachedAuthUid = this.store.selectSnapshot(AuthState.uid);
            if (cachedAuthUid) {
              this.fetchExistingUser(cachedAuthUid);
            }
            this.alert = null;
          },
        },
      ],
    });

    await this.alert.present();
  }

  async confirmAcceptInvite(inviteCode: string, villagerId: string) {
    this.alert = await this.alertCtrl.create({
      header: 'Accept Invite',
      message: `Do you want to join the village invite code ${inviteCode}?`,
      buttons: [
        {
          text: 'No',
          role: 'cancel',
        },
        {
          text: 'Yes',
          handler: async () => {
            await this.presentLoading('Joining Village');
            const { villageId } = await this.fns
              .httpsCallable('village-joinVillageByInviteCode')({
                inviteCode,
                villagerId,
                circles: [],
              })
              .toPromise();
            if (villageId) {
              const toast = await this.toastCtrl.create({
                message: 'Successfully joined village',
                duration: 10000,
                color: 'primary',
                position: 'top',
                buttons: ['Done'],
              });
              await toast.present();
              this.store
                .dispatch(
                  new SwitchVillage({
                    villagerUid: villagerId,
                    villageUid: villageId,
                  })
                )
                .subscribe(() => {
                  if (this.loading) {
                    this.loading.dismiss();
                    this.loading = null;
                  }
                });
            } else {
              if (this.loading) {
                this.loading.dismiss();
                this.loading = null;
              }
              alert(
                'Could not find village to join. Please try again or with a different invite link.'
              );
            }
            this.alert.dismiss();
            if (this.loading) {
              this.loading.dismiss();
              this.loading = null;
            }
          },
        },
      ],
    });

    await this.alert.present();
  }
}
