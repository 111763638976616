import { Injectable } from "@angular/core";
import { AngularFireFunctions } from "@angular/fire/functions";

@Injectable({
  providedIn: 'root',
})
export class InviteCodeService {
  constructor(private fns: AngularFireFunctions,) {}

  async generateCode(length) {
    let inviteCode = '';
    let exists = true;
    while (exists) {

      inviteCode = this.makeCode(length);
      console.log('checking code: ', inviteCode);
      ({ exists } = await this.fns
        .httpsCallable('invitation-checkInviteCode')({
          inviteCode,
        })
        .toPromise());
    }
    return inviteCode;
  }

  private makeCode(length) {
    let result = '';
    const characters = 'ABCDEFGHJKLMNPQRSTUVWXYZ23456789'; // removed i, 1, 0 and O for confusion sake
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
}
