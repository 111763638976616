import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';
import {
  AlertController,
  ModalController,
  LoadingController,
} from '@ionic/angular';
import { Store } from '@ngxs/store';

import { AnalyticsService } from 'src/app/analytics.service';
import { Village } from 'src/app/models/village.model';
import { Villager } from 'src/app/models/villager.model';
import { JoinVillage } from 'src/app/state/app.actions';
import { VillagerState } from 'src/app/state/villager.state';

@Component({
  selector: 'app-join-village-button',
  templateUrl: './join-village-button.component.html',
  styleUrls: ['./join-village-button.component.scss'],
})
export class JoinVillageButtonComponent implements OnInit {
  verifying = false;
  verified = false;
  successMessage = null;
  saving = false;
  loading: any;
  component = 'join-village-button-component';

  @Input() iconOnly: boolean = false;
  @Output() done: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private fns: AngularFireFunctions,
    private store: Store,
    private alertCtrl: AlertController,
    public modalCtrl: ModalController,
    private loadingCtrl: LoadingController,
    private analytics: AnalyticsService
  ) {}

  ngOnInit() {}

  async confirmInvitation(inviteCode: string) {
    this.analytics.logEvent('select_or_join_confim_invite_clicked', {});
    this.presentLoading();
    this.verifying = true;
    this.successMessage = null;
    try {
      const { exists } = await this.fns
        .httpsCallable('invitation-checkInviteCode')({
          inviteCode,
        })
        .toPromise();

      if (exists) {
        this.successMessage = 'Great! We found your village.';
        this.verifying = false;
        this.verified = true;

        const village: Village = await this.fns
          .httpsCallable('invitation-getVillageFromInviteCode')({
            inviteCode,
          })
          .toPromise();
        console.log('[ONBOARD VILLAGE] received village: ', village);

        const villager: Villager = this.store.selectSnapshot(
          VillagerState.currentVillager
        );

        if (
          villager.VILLAGES &&
          !villager.VILLAGES_UIDS.includes(village._UID)
        ) {
          await this.store
            .dispatch(
              new JoinVillage({
                villager: villager,
                village: village,
                updateVillageCircles: true
              })

            )
            .toPromise();

          this.showSuccessAlert(village.NAME);
          this.done.emit();
          if (this.loading) {
            this.loading.dismiss();
            this.loading = null;
          }

          // this.switchVillageAndUpdateCircles(village, villager);
        } else {
          this.showAlreadyJoinedErrorAlert();
          if (this.loading) {
            this.loading.dismiss();
            this.loading = null;
          }
        }
      } else {
        this.verifying = false;
        if (this.loading) {
          this.loading.dismiss();
          this.loading = null;
        }
      }
    } catch (e) {
      this.analytics.logEvent('select_or_join_confirm_invite_error', {
        error: e,
      });
      console.error(e);
      this.verifying = false;
    }
  }

  async showJoinCodeAlert() {
    const alert = await this.alertCtrl.create({
      header: 'Enter Your Invite Code',
      cssClass: 'darker-backdrop',
      message: `Lookup a village to join by the invite code someone in the village sent you.`,
      inputs: [
        {
          name: 'inviteCode',
          type: 'text',
          placeholder: 'Invite Code',
        },
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'danger',
          handler: () => {
            console.log('Confirm Cancel');
          },
        },
        {
          text: 'Ok',
          handler: (data) => {
            console.log('Lookup invite code with : ', data.inviteCode);
            if (data.inviteCode) this.confirmInvitation(data.inviteCode);
          },
        },
      ],
    });

    await alert.present();
  }

  async showSuccessAlert(villageName: string) {
    const alert = await this.alertCtrl.create({
      header: `Welcome to ${villageName}!`,
      message: `If you joined this village by accident, you can leave the village in your profile settings.`,
      buttons: ['OK'],
    });

    await alert.present();
  }

  async showErrorAlert() {
    const alert = await this.alertCtrl.create({
      header: 'Error',
      message:
        'We ran into an error changing your village. Please check your invite code and try again.',
      buttons: ['OK'],
    });

    await alert.present();
  }

  async presentLoading() {
    if (!this.loading) {
      this.loading = await this.loadingCtrl.create({
        // message: 'Communicating through space & time...',
        duration: 20000,
        backdropDismiss: true,
      });
      return this.loading.present();
    }
  }

  async showAlreadyJoinedErrorAlert() {
    const alert = await this.alertCtrl.create({
      header: 'Error',
      message: 'We noticed you are already apart of this Village!',
      buttons: ['OK'],
    });

    await alert.present();
  }
}
