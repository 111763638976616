import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import {
  AlertController,
  LoadingController,
  ModalController,
  Platform,
  ToastController,
} from '@ionic/angular';
import { Select, Store } from '@ngxs/store';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import { Villager } from 'src/app/models/villager.model';
import moment from 'moment';
import { Observable } from 'rxjs';
import { AnalyticsService } from 'src/app/analytics.service';
import { EVENT_KARMA } from 'src/app/app.config';
import { Hangout, HangoutIntent } from 'src/app/models/hangout.model';
import { HangoutPromptsService } from 'src/app/services/hangout-prompts.service';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { UpdateHangout, CreateHangout } from 'src/app/state/app.actions';
import { VillageState } from 'src/app/state/village.state';
import { VillagerState } from 'src/app/state/villager.state';
import { JoinedCircle } from 'src/app/models/circle.model';
import { AppState } from 'src/app/state/app.state';
import { ReadReceipt } from 'src/app/models/read-receipt.model';
import { StoredDocument } from 'src/app/models/post-core.model';
import { PostFileUploadComponent } from 'src/app/shared/post-file-upload/post-file-upload.component';
import { RecipientNotificationSettings } from 'src/app/models/notification-settings';
import { CreatePostHelpersService } from 'src/app/services/create-post-helpers.service';
import { PostMentionableInputComponent } from 'src/app/shared/post-mentionable-input/post-mentionable-input.component';
import { VillagerActions } from 'src/app/state/villager.actions';

interface AvailableSpot {
  value: number;
  name: string | number;
}

@Component({
  selector: 'app-create-hangout-modal',
  templateUrl: './create-hangout-modal.page.html',
  styleUrls: ['./create-hangout-modal.page.scss'],
})
export class CreateHangoutModalPage implements OnInit, AfterViewInit {
  villageEventForm = new FormGroup({
    title: new FormControl('', [Validators.required]),
    location: new FormControl(''),
    startDateTime: new FormControl(''),
    endDateTime: new FormControl(''),
    capacity: new FormControl('', [Validators.required]),
  });
  creator: Villager;
  loading: any;
  minFutureDate = moment().subtract(12, 'hours').toISOString(); // this allows today to show up in date picker
  maxFutureDate = moment().add(10, 'years').toISOString();
  minuteValues = '0, 5, 10, 15, 20, 25, 30, 45, 40, 45, 50, 55';
  showEndTime = false;
  showStartTime = false;
  availableSpots: AvailableSpot[] = [
    {
      value: 0,
      name: 'Everyone',
    },
  ];

  topics: string[] = [];
  suggestedPrompts: string[] = [];
  assignedCircles: JoinedCircle[] = [];
  assignedOrganizers: HangoutIntent[] = [];

  EVENT_KARMA = EVENT_KARMA;

  component = 'create-hangout-modal';
  placeholderText = `Write a brief description...`;

  mentionables: string[] = [];
  isMobile = false;

  showFeeling = false;
  showPhotos = false;
  showTopics = false;

  // for existing files on post
  tmpImageUrls: string[] = [];
  tmpDocUrls: StoredDocument[] = [];

  compareWith(o1: any, o2: any) {
    return o1 && o2 ? o1.value === o2.value : o1 === o2;
  }

  get title() {
    return this.villageEventForm.get('title');
  }

  get location() {
    return this.villageEventForm.get('location');
  }

  get startDateTime() {
    return this.villageEventForm.get('startDateTime');
  }

  get endDateTime() {
    return this.villageEventForm.get('endDateTime');
  }

  get capacity() {
    return this.villageEventForm.get('capacity');
  }

  @Select(VillageState.villageName) villageName$: Observable<string>;
  @Select(VillageState.showKarma) showKarma$: Observable<boolean>;
  @Select(AppState.postCirclesAssigned) postCirclesAssigned$: Observable<
    JoinedCircle[]
  >;

  @Input() village: string;
  @Input() type: string;
  @Input() hangout: Hangout;
  @Input() description: string;
  @Input() edit = false;

  @ViewChild(PostMentionableInputComponent)
  mentionInput: PostMentionableInputComponent;
  @ViewChild(PostFileUploadComponent) fileUpload: PostFileUploadComponent;

  constructor(
    public modalCtrl: ModalController,
    private toastCtrl: ToastController,
    private alertCtrl: AlertController,
    private loadingCtrl: LoadingController,
    private store: Store,
    private utils: UtilitiesService,
    private analytics: AnalyticsService,
    private promptSvc: HangoutPromptsService,
    private helpers: CreatePostHelpersService,
    private platform: Platform
  ) {}

  ngAfterViewInit(): void {
    if (this.edit === true) {
      console.log('edit hangout: ', this.hangout._UID);
      this.mentionInput.setBody(
        this.utils.removeExistingTags(this.hangout.BODY),
        this.hangout._MENTION_UIDS
      );
    } else {
      if (this.description) {
        this.mentionInput.setBody(
          this.utils.removeExistingTags(this.description),
          []
        );
      } else {
        console.log('foobiz: ', this.mentionInput);
        this.mentionInput.setBody('', []);
      }
    }
  }

  ngOnInit() {
    this.isMobile = this.platform.is('mobile');
    this.suggestedPrompts = this.promptSvc.getHangoutPrompts(2);
    const allVillagers = this.store.selectSnapshot(VillagerState.allVillagers);
    const temp = [...Array(allVillagers.length - 1).keys()];
    temp.forEach((x) => {
      if (x === 0) {
        return;
      }
      if (x !== 1) {
        this.availableSpots.push({
          value: x,
          name: x,
        });
      }
    });

    this.postCirclesAssigned$.subscribe((assignedCircles: JoinedCircle[]) => {
      this.assignedCircles = assignedCircles;
    });

    if (this.edit === true) {
      console.log('DEBUG editing hangout: ', this.hangout);
      this.topics = [...this.hangout.TOPICS];
      this.title.setValue(this.hangout.TITLE);
      let capacityFormPatch = this.availableSpots.find(
        (x) => x.value === this.hangout.CAPACITY
      );
      if (!capacityFormPatch) {
        capacityFormPatch = {
          value: this.hangout.CAPACITY,
          name: this.hangout.CAPACITY,
        };
        this.availableSpots.push({
          value: this.hangout.CAPACITY,
          name: this.hangout.CAPACITY,
        });
      }
      this.capacity.setValue(capacityFormPatch);
      // this.tempUrls = [...this.hangout.IMAGE_PATHS];
      this.tmpImageUrls = [...this.hangout.IMAGE_PATHS];
      this.tmpDocUrls = [...this.hangout.DOCUMENT_PATHS];

      if (this.hangout.START_DATETIME) {
        this.showStartTime = true;
        this.startDateTime.setValue(
          moment(this.hangout.START_DATETIME).toISOString(true)
        );
      } else this.showStartTime = false;

      if (this.hangout.END_DATETIME) {
        this.showEndTime = true;
        this.endDateTime.setValue(
          moment(this.hangout.END_DATETIME).toISOString(true)
        );
      } else this.showEndTime = false;
      if (this.hangout.LOCATION)
        this.location.setValue(
          this.utils.removeExistingTags(this.hangout.LOCATION)
        );
      this.assignedCircles = [...this.hangout.CIRCLES];
      this.assignedOrganizers = this.hangout.ORGANIZERS;
    } else {
      if (this.type === 'Duo') {
        this.capacity.setValue({ value: 2, name: 2 });
        this.availableSpots.push({ value: 2, name: 2 });
      } else if (this.type === 'Trio') {
        this.capacity.setValue({ value: 3, name: 3 });
        this.availableSpots.push({ value: 3, name: 3 });
      } else {
        this.capacity.setValue({ value: 0, name: 'Everyone' });
      }

      if (this.type) {
        this.title.setValue(``);
      }
    }

    this.loadMentionables();
  }

  toggleShowEndTime() {
    this.showEndTime = !this.showEndTime;
    if (this.showEndTime && this.startDateTime.value) {
      this.endDateTime.setValue(
        moment(this.startDateTime.value).add(1, 'hour').toISOString(true)
      );
      // make sure to mark as dirty because the event doesn't fire if you only
      // select to add the time and use the default value we populate
      this.villageEventForm.markAsDirty();
    }
  }

  toggleShowStartTime() {
    this.showStartTime = !this.showStartTime;
    if (this.showStartTime) {
      this.startDateTime.setValue(moment().toISOString(true));
      // make sure to mark as dirty because the event doesn't fire if you only
      // select to add the time and use the default value we populate
      this.villageEventForm.markAsDirty();
    }
  }

  onSetTopics(topics: string[]) {
    this.topics = topics;
  }

  onSetOrganizers(organizers: HangoutIntent[]) {
    this.assignedOrganizers = organizers;
    console.log('[DEBUG] Hangout modal has organizer to store: ', organizers);
  }

  handleDeletePhoto(event, index: number) {
    this.tmpImageUrls.splice(index, 1);
  }

  handleDeleteDocument(index: number) {
    this.tmpDocUrls.splice(index, 1);
  }

  async createHangout() {
    const { BODY } = this.mentionInput.parseMentionsAndBody();
    if (!BODY || BODY.trim().length === 0) {
      this.missingDataError(
        'Missing description. Please add a description and try again.'
      );
    } else {
      try {
        let photoPaths = [];
        let documentPaths = [];
        if (this.fileUpload) {
          photoPaths = await this.fileUpload.uploadPhotos();
          documentPaths = await this.fileUpload.uploadDocuments();
        }
        this.save(photoPaths, documentPaths);
      } catch (e) {
        this.analytics.logEvent('hangout_create_error', {
          error: e,
        });
        alert('Error creating post');
      }
    }
  }

  async updateHangout() {
    await this.presentLoading();
    this.update();
  }

  async update() {
    if (
      this.capacity.value.value > 0 &&
      this.assignedOrganizers.length > this.capacity.value.value
    ) {
      this.showCapacityToLowAlert();
      return;
    }
    this.analytics.logEvent('hangout_update', {
      hangoutId: this.hangout._UID,
    });

    let newPhotoPaths = [];
    let newDocumentPaths: StoredDocument[] = [];
    try {
      if (this.fileUpload) {
        newPhotoPaths = await this.fileUpload.uploadPhotos();
        newDocumentPaths = await this.fileUpload.uploadDocuments();
      }
    } catch (e) {
      alert('Error updating photos & documents');
      this.analytics.logError('error_updating_post_files', this.component, e);
    }
    let allImages = [...this.tmpImageUrls];
    let allDocuments = [...this.tmpDocUrls];
    if (newPhotoPaths.length > 0) {
      allImages = this.tmpImageUrls.concat(newPhotoPaths);
    }
    if (newDocumentPaths.length > 0) {
      allDocuments = this.tmpDocUrls.concat(newDocumentPaths);
    }

    const CIRCLE_UIDS = this.assignedCircles.map((x) => x.UID);
    const RECIPIENT_NOTIFICATION_SETTINGS: RecipientNotificationSettings =
      this.helpers.getNotificationRecipientsForCircle(CIRCLE_UIDS);

    const hangout: Hangout = {
      ...this.hangout,
      TITLE: this.title.value,
      LOCATION: this.utils.parseLinksFromText(this.location.value),
      CAPACITY: this.capacity.value.value,
      IMAGE_PATHS: [...allImages],
      DOCUMENT_PATHS: [...allDocuments],
      UPDATED_AT: firebase.default.firestore.FieldValue.serverTimestamp(),
      TOPICS: this.topics,
      RECIPIENT_NOTIFICATION_SETTINGS,
      CIRCLES: this.assignedCircles,
      CIRCLE_UIDS,
    };

    const { _MENTION_UIDS, BODY } = this.mentionInput.parseMentionsAndBody();
    hangout._MENTION_UIDS = _MENTION_UIDS;
    hangout.BODY = this.utils.parseLinksFromText(BODY);

    if (this.showStartTime) {
      hangout.START_DATETIME = moment(this.startDateTime.value).toISOString(
        true
      );
      if (this.showEndTime) {
        hangout.END_DATETIME = moment(this.endDateTime.value).toISOString(true);

        if (
          moment(hangout.START_DATETIME).isSameOrAfter(
            moment(hangout.END_DATETIME)
          )
        ) {
          this.showDateTimeAlert();
          if (this.loading) {
            this.loading.dismiss();
            this.loading = null;
          }
          return false; // stop update execution
        }
      }
    }

    this.store.dispatch(new UpdateHangout({ post: hangout })).subscribe(
      () => {
        this.analytics.logEvent('hangout_update_success', {});
        this.presentToast('Successfully updated your hangout.', false);
        this.villageEventForm.reset();
        this.modalCtrl.dismiss({ action: null });
        this.loading.dismiss();
        this.loading = null;
      },
      (err) => {
        this.analytics.logEvent('hangout_update_error', {
          hangoutId: this.hangout._UID,
          error: err,
        });
        console.error(err);
        this.showErrorAlert();
        this.loading.dismiss();
        this.loading = null;
      }
    );
  }

  async save(imagePaths: string[], documentPaths: StoredDocument[]) {
    // console.log(`Comparing organizers: ${this.assignedOrganizers.length} to capacity: ${this.capacity.value.value}`)
    // allowing the creator + 2 organizers to be allowed for dyads
    // because in theory the organizer isnt going
    if (
      this.capacity.value.value > 0 &&
      this.assignedOrganizers.length > this.capacity.value.value
    ) {
      this.showCapacityToLowAlert();
      return;
    }
    this.analytics.logEvent('hangout_save_new', {});
    await this.presentLoading();
    const parsedLocation = this.utils.parseLinksFromText(this.location.value);
    const uid = this.store.selectSnapshot(VillagerState.uid);
    const firstName = this.store.selectSnapshot(VillagerState.firstName);
    const lastName = this.store.selectSnapshot(VillagerState.lastName);
    const currentVillager: HangoutIntent = {
      FIRST_NAME: firstName,
      LAST_NAME: lastName,
      UID: uid,
    };

    // Add Assigned Organizers To List Of GOING
    const organizers: HangoutIntent[] = [];
    const going: HangoutIntent[] = [];
    const participantReceipts: ReadReceipt[] = [];
    if (this.assignedOrganizers.length > 0) {
      // const allVillagers = this.store.selectSnapshot(
      //   VillagerState.allVillagers
      // );
      this.assignedOrganizers.forEach((intent: HangoutIntent) => {
        organizers.push(intent);
        going.push(intent);
        const receipt: ReadReceipt = {
          UID: intent.UID,
          LAST_READ_MESSAGE_COUNT: 0,
        };
        participantReceipts.push(receipt);
      });
    } else {
      // the creator is only defaulting to going if no organizer is assigned
      going.push(currentVillager);
    }

    participantReceipts.push({ UID: uid, LAST_READ_MESSAGE_COUNT: 0 });

    const CIRCLE_UIDS = this.assignedCircles.map((x) => x.UID);
    const RECIPIENT_NOTIFICATION_SETTINGS: RecipientNotificationSettings =
      this.helpers.getNotificationRecipientsForCircle(CIRCLE_UIDS);

    const hangout: Hangout = {
      _CREATOR_UID: uid,
      _CREATOR_FIRST_NAME: firstName,
      _CREATOR_LAST_NAME: lastName,
      _SERVER_TIMESTAMP:
        firebase.default.firestore.FieldValue.serverTimestamp(),
      _CREATED_AT: new Date(),
      _TYPE: 'HANGOUT',
      REPORTED_BY: [],
      UPDATED_AT: firebase.default.firestore.FieldValue.serverTimestamp(),
      IMAGE_PATHS: imagePaths,
      DOCUMENT_PATHS: documentPaths,
      TITLE: this.title.value,
      BODY: null,
      LOCATION: parsedLocation,
      CAPACITY: this.capacity.value.value,
      GOING: going,
      MAYBE: [], // DEPRECATED
      PARTICIPANT_UIDS: [uid],
      PARTICIPANT_READ_RECEIPTS: participantReceipts,
      RECIPIENT_NOTIFICATION_SETTINGS,
      MESSAGE_COUNT: 0,
      TOPICS: this.topics,
      SUGGESTED_PROMPTS: this.suggestedPrompts,
      ARCHIVED: false,
      CIRCLES: this.assignedCircles,
      CIRCLE_UIDS,
      ORGANIZERS: organizers,
      _MENTION_UIDS: [],
    };

    const { _MENTION_UIDS, BODY } = this.mentionInput.parseMentionsAndBody();
    hangout._MENTION_UIDS = _MENTION_UIDS;
    hangout.BODY = this.utils.parseLinksFromText(BODY);

    // START TIME HANDLED OPTIONALLY
    if (this.showStartTime) {
      hangout.START_DATETIME = moment(this.startDateTime.value).toISOString(
        true
      );
      if (this.showEndTime) {
        hangout.END_DATETIME = moment(this.endDateTime.value).toISOString(true);

        if (
          moment(hangout.START_DATETIME).isSameOrAfter(
            moment(hangout.END_DATETIME)
          )
        ) {
          this.showDateTimeAlert();
          if (this.loading) {
            this.loading.dismiss();
            this.loading = null;
          }
          return false; // stop save execution
        }
      }
    }

    this.store.dispatch(new CreateHangout({ hangout })).subscribe(
      (result) => {
        this.store.dispatch(
          new VillagerActions.MarkOnboardedTaskComplete({
            villagerId: uid,
            field: 'HAS_CREATED_EVENT',
          })
        );
        this.analytics.logEvent('hangout_save_new_success', {});
        this.presentToast('Successfully created your hangout.', true);
        this.villageEventForm.reset();
        this.modalCtrl.dismiss({ action: 'create-close' });
        this.loading.dismiss();
        this.loading = null;
      },
      (err) => {
        this.analytics.logEvent('hangout_save_new_error', {
          error: err,
        });
        console.error(err);
        this.showErrorAlert();
        this.loading.dismiss();
        this.loading = null;
      }
    );
  }

  async showDeletePhotoConfirm() {
    const alert = await this.alertCtrl.create({
      header: 'Careful!',
      message: `Are you sure you want to delete images?`,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'dark',
          handler: () => {},
        },
        {
          text: 'Delete',
          role: 'delete',
          cssClass: 'danger',
          handler: () => {
            // this.presentLoading();
            this.update();
          },
        },
      ],
    });

    await alert.present();
  }

  async presentToast(header: string, update = false) {
    let message;
    if (update) {
      message = `You earned ${EVENT_KARMA} karma!`;
    }
    const toast = await this.toastCtrl.create({
      header,
      message,
      color: 'primary',
      duration: 3000,
      position: 'top',
    });
    toast.present();
  }
  async presentLoading() {
    if (!this.loading) {
      this.loading = await this.loadingCtrl.create({
        duration: 15000,
        backdropDismiss: true,
      });
      return this.loading.present();
    }
  }

  async showDateTimeAlert() {
    const alert = await this.alertCtrl.create({
      header: 'Error',
      message: 'The start time of your hangout must be before the end time.',
      buttons: ['OK'],
    });

    await alert.present();
  }

  async showCapacityToLowAlert() {
    const alert = await this.alertCtrl.create({
      header: 'Error',
      message: `The capacity for your event is not high enough to include this many organizers.
        Either raise the capacity or assign less organizers.`,
      buttons: ['OK'],
    });

    await alert.present();
  }

  async showErrorAlert() {
    const alert = await this.alertCtrl.create({
      header: 'Error',
      message:
        'We ran into an error creating the post. Please check that everything is filled out and try again.',
      buttons: ['OK'],
    });

    await alert.present();
  }

  async missingDataError(message: string) {
    const alert = await this.alertCtrl.create({
      header: 'Error',
      message,
      buttons: ['OK'],
    });

    await alert.present();
  }

  async loadMentionables() {
    let tmpMentionables = [];

    const allUnblockedVillagers = this.store.selectSnapshot(
      VillagerState.allUnblockedVillagersExcludeCurrent
    );
    tmpMentionables = [
      ...allUnblockedVillagers.map((villager) => villager._UID),
    ];

    this.mentionables = [...new Set(tmpMentionables)];
    console.log('[POST BODY INPUT] Mentionables: ', this.mentionables);
  }
}
