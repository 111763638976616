import { PostCore} from './post-core.model';

export interface PostInteraction {
  CREATOR_UID: string;
  CREATOR_NAME: string;
}

export const EMOTIONS = [
  {
    emotion: 'happy',
    icon: '😊'
  },
  {
    emotion: 'sad',
    icon: '😥'
  },
  {
    emotion: 'angry',
    icon: '😡'
  },
  {
    emotion: 'anxious',
    icon: '😣'
  },
  {
    emotion: 'excited',
    icon: '🥳'
  },
  {
    emotion: 'lonely',
    icon: '😔'
  },
  {
    emotion: 'grateful',
    icon: '🤗'
  },
  {
    emotion: 'afraid',
    icon: '😨'
  },
  {
    emotion: 'other',
    icon: '🤯'
  }
];

export interface SupportRequest extends PostCore {
  _EMOTION: string;
  CELEBRATIONS: PostInteraction[];
  // HEARINGS: PostInteraction[];
  // MESSAGE_COUNT: number;
  SUPPORTERS: PostInteraction[]; // user UID
  // ANONYMOUS?: boolean;
  // MEMBERS: string[]; // list of user IDs. Does NOT include the CREATOR ID (for points reasons)
  // RESOLVED?: boolean;
  // RESOLVED_AT?: Date | any; // firebase.firestore.FieldValue.serverTimestamp()
  // PENDING?: boolean;
  // ENDED?: boolean;
}

export interface SupportRequestStateModel {
  supportRequests: SupportRequest[]
}
