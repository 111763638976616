import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import {
  AlertController,
  GestureController,
  LoadingController,
  Platform,
} from '@ionic/angular';
import { Actions, ofActionCompleted, Select, Store } from '@ngxs/store';
import firebase from 'firebase';
import { combineLatest, from, Observable, Subscription } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { AnalyticsService } from 'src/app/analytics.service';
import { ChatMessage } from 'src/app/models/chat-message.model';
import { CourtyardActions } from 'src/app/state/courtyard.actions';
import { VillageState } from 'src/app/state/village.state';
import { VillagerState } from 'src/app/state/villager.state';
import 'firebase/firestore';
import { GenericChatroom, GenericPost } from 'src/app/models/post-core.model';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import {
  ClearReplyMessage,
  DmToggleInputHasPhoto,
  SendDirectChatMessage,
  SendVillageMessage,
} from 'src/app/state/app.actions';

import { Directory, FileInfo, Filesystem } from '@capacitor/filesystem';
import { RecordingData, VoiceRecorder } from 'capacitor-voice-recorder';
import { Haptics, ImpactStyle } from '@capacitor/haptics';
import { AudioService } from 'src/app/services/audio.service';

import { QuickReply } from 'src/app/models/share.model';
import { QuickReplyService } from 'src/app/services/quick-reply.service';
import { MentionifyDirective } from 'src/app/directives/mentionify/mentionify.directive';
import { Villager } from 'src/app/models/villager.model';
import { MENTIONIFY_CLASSNAME, MENTIONIFY_HIDDEN } from 'src/app/app.config';
import { Courtyard } from 'src/app/models/courtyard.model';
import { CircleState } from 'src/app/state/circle.state';
import {
  DirectChatroom,
  DirectParticipant,
} from 'src/app/models/direct-chatroom.model';
import { MessageReplyState } from 'src/app/state/message-reply.state';
import { VillagerActions } from 'src/app/state/villager.actions';

@Component({
  selector: 'app-chat-input',
  templateUrl: './chat-input.component.html',
  styleUrls: ['./chat-input.component.scss'],
})
export class ChatInputComponent implements OnInit, AfterViewInit {
  messageBody = '';
  autoFocus = false;
  placeholderText = `Type message, @ to mention, or press & hold mic for voice note`;
  sending = false;
  loading: HTMLIonLoadingElement = null;

  // for quick replies
  replies: QuickReply[] = [];

  // for picture uploads
  files: File[] = [];
  localUrls: any[] = [];
  uploads: any[];
  uploadPercent$: Observable<number>;
  uploadPercent: number;
  downloadURLs = [];
  uploading = false;
  tempUrls: string[] = [];
  isNative = false;
  isMobile = false;
  base64prefix = 'data:image/jpeg;base64,';

  // Voice Notes
  recording = false;
  storedFiles: FileInfo[] = [];
  durationDisplay = '';
  duration = -1;
  recordedDuration = 0;

  mentionables: string[] = [];

  reply: ChatMessage;
  @Select(MessageReplyState.replyMessage) reply$: Observable<ChatMessage>;

  @Input() chatroom: GenericChatroom;
  @Input() type: 'post' | 'courtyard' | 'dm';
  @Output() hasMedia: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('recordbtn', { read: ElementRef }) recordbtn: ElementRef;
  @ViewChild('messageInput') msgEl: { nativeElement: HTMLDivElement };
  @ViewChild(MentionifyDirective) mentionify: MentionifyDirective;
  constructor(
    private store: Store,
    private analytics: AnalyticsService,
    private bucket: AngularFireStorage,
    private platform: Platform,
    private actions$: Actions,
    private loadingCtrl: LoadingController,
    private qrs: QuickReplyService,
    private gesture: GestureController,
    private audioSvc: AudioService,
    private alertCtrl: AlertController
  ) {}

  ngOnInit() {
    this.isNative = this.platform.is('capacitor');
    this.isMobile = this.platform.is('mobile');

    if (this.type === 'post') this.replies = this.getRepliesForPost();

    this.reply$.subscribe((reply) => (this.reply = reply));

    this.actions$
      .pipe(
        ofActionCompleted(
          CourtyardActions.SendMessage,
          SendDirectChatMessage,
          SendVillageMessage
        )
      )
      .subscribe(() => {
        this.msgEl.nativeElement.innerHTML = '';
      });

    this.loadFiles();

    if (this.store.selectSnapshot(CircleState.allCircles).length > 0) {
      this.loadMentionables();
    } else {
      this.store.select(CircleState.allCircles).subscribe((circles) => {
        if (circles && circles.length > 0) {
          this.loadMentionables();
        }
      });

      // this.actions$
      //   .pipe(ofActionCompleted(StreamEmitted(CircleActions.FetchAll)))
      //   .subscribe(() => {

      //     console.log(
      //       '[CHAT INPUT] Fetching mentionables after circles loaded'
      //     );

      //   });
    }
  }

  ngAfterViewInit(): void {
    const longPress = this.gesture.create(
      {
        el: this.recordbtn.nativeElement,
        threshold: 0,
        gestureName: 'long-press-record',
        onStart: (event) => {
          VoiceRecorder.hasAudioRecordingPermission().then(
            ({ value }) => {
              if (value) {
                this.audioSvc.killAnyExistingAudio();
                this.recordedDuration = 0;
                Haptics.impact({ style: ImpactStyle.Medium });
                this.startRecording();
                this.calculateDuration();
              } else {
                VoiceRecorder.requestAudioRecordingPermission();
              }
            },
            (err) => {
              console.error('Error no permission: ', err);
              VoiceRecorder.requestAudioRecordingPermission();
            }
          );
        },
        onEnd: (event) => {
          setTimeout(() => {
            this.recordedDuration = this.duration;
            if (this.recordedDuration < 1) {
              this.showRecordingTutorialAlert();
              this.cancelRecording();
            } else {
              Haptics.impact({ style: ImpactStyle.Medium });
              this.stopRecording();
            }
          }, 500);
        },
      },
      true
    );

    longPress.enable();
  }

  getRepliesForPost(): QuickReply[] {
    return this.qrs.getQuickReplies(this.chatroom as GenericPost);
  }

  autofillMessage(message: string) {
    this.msgEl.nativeElement.innerHTML = message;
  }

  removeThumbnail(idx: number) {
    this.files.splice(idx, 1);
    this.localUrls.splice(idx, 1);
    // some components use emit, others use global state
    this.hasMedia.emit(false);
    this.store.dispatch(new DmToggleInputHasPhoto({ hasPhoto: false }));
  }

  fileChangeEvent(e) {
    if (this.files.length > 0) {
      alert(
        'Only 1 file per message for now. If you want to share more photos, please use a post!'
      );
    } else {
      if (e.target.files && e.target.files[0]) {
        // 1. Store the file reference for the uploader
        this.files.push(e.target.files[0]);

        // 2. Read the file in so we can render it's thumbnail
        const reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]); // read file as data url
        reader.onload = (event) => {
          // called once readAsDataURL is completed
          this.localUrls.push(event.target.result);
        };
        // some components use emit, others use global state
        this.hasMedia.emit(true);
        this.store.dispatch(new DmToggleInputHasPhoto({ hasPhoto: true }));
      }
    }
  }

  async loadMentionables() {
    let tmpMentionables = [];
    // console.log('[MENTIONABLES] building mentionables off: ', this.type)
    switch (this.type) {
      case 'courtyard':
        // get villagers in the circle the courtyard is in
        const { CIRCLE_UID } = this.chatroom as Courtyard;
        const circles = this.store.selectSnapshot(CircleState.allCircles);
        const circle = circles.find((x) => x._UID === CIRCLE_UID);
        tmpMentionables = [...circle.VILLAGER_UIDS, ...circle.ADMIN_UIDS];
        // console.log('[MENTIONABLES] TEMP COURTYARD mentionables: ', tmpMentionables)
        break;
      case 'dm':
        // get the villagers in the PARTICIPANTS
        const { PARTICIPANT_UIDS } = this.chatroom as DirectChatroom;
        tmpMentionables = [...PARTICIPANT_UIDS];
        // console.log('[MENTIONABLES] TEMP DM mentionables: ', tmpMentionables)
        break;

      case 'post':
        // get the villagers in all the circles the post was shared in
        const { CIRCLE_UIDS } = this.chatroom as GenericPost;
        const allCircles = this.store.selectSnapshot(CircleState.allCircles);
        const matchingCircles = allCircles.filter((x) =>
          CIRCLE_UIDS.includes(x._UID)
        );
        tmpMentionables = matchingCircles.reduce(
          (acc, circleReduce) => [
            ...acc,
            ...circleReduce.VILLAGER_UIDS,
            ...circleReduce.ADMIN_UIDS,
          ],
          []
        );
        // console.log('[MENTIONABLES] TEMP POST mentionables: ', tmpMentionables)
        break;
    }

    this.mentionables = [...new Set(tmpMentionables)];
  }

  async loadFiles() {
    Filesystem.readdir({
      path: '',
      directory: Directory.Data,
    }).then((result) => {
      if (result && result.files.length >= 0) {
        this.storedFiles = result.files.filter((x) => x.uri.match(/.wav/g));
        if (this.storedFiles.length > 0) {
          this.hasMedia.emit(true);
        } else {
          this.hasMedia.emit(false);
          this.placeholderText = `Type message, @ to mention, or press & hold mic for voice note`;
        }
      } else {
        this.hasMedia.emit(false);
        this.placeholderText = `Type message, @ to mention, or press & hold mic for voice note`;
      }
    });
  }

  startRecording() {
    if (this.recording) {
      return;
    }
    this.recording = true;

    try {
      VoiceRecorder.startRecording();
    } catch (e) {
      this.showErrorAlert('Error recording audio');
      this.audioSvc.killAnyExistingAudio();
    }
  }

  stopRecording() {
    this.placeholderText = `Type message, @ to mention, or press & hold mic for voice note`;
    if (!this.recording) {
      return;
    }
    VoiceRecorder.stopRecording().then(
      async (result: RecordingData) => {
        this.recording = false;
        this.duration = -1;
        this.durationDisplay = '';
        this.messageBody = '';
        if (result.value && result.value.recordDataBase64) {
          const recordData = result.value.recordDataBase64;
          this.placeholderText = `Tap arrow to send voice message`;
          const fileName = new Date().getTime() + '.wav';
          await Filesystem.writeFile({
            path: fileName,
            directory: Directory.Data,
            data: recordData,
          });
          this.loadFiles();
        }
      },
      (e) => {
        this.audioSvc.killAnyExistingAudio();
      }
    );
  }

  cancelRecording() {
    if (!this.recording) {
      return;
    }
    VoiceRecorder.stopRecording().then(
      async (result: RecordingData) => {
        this.recording = false;
        this.duration = -1;
        this.durationDisplay = '';
        this.messageBody = '';
        this.placeholderText = `Tap arrow to send voice message`;
      },
      (e) => {
        console.error('Error cancelling audio: ', e);
        this.audioSvc.killAnyExistingAudio();
      }
    );
  }

  async deleteRecording(fileBlob: any) {
    console.log('Deleting file: ', fileBlob);
    await Filesystem.deleteFile({
      path: fileBlob.name,
      directory: Directory.Data,
    });
    this.loadFiles();
  }

  async playFile(fileName: string) {
    // this is great but lets refactor this to use audio svc with play/pause functionality
    // const audioFile = await Filesystem.readFile({
    //   path: fileName,
    //   directory: Directory.Data,
    // });
    // console.log('[recorder] playing file: ', audioFile);
    // const base64Sound = audioFile.data;
    // const audioRef = new Audio(`data:audio/aac;base64,${base64Sound}`);
    // audioRef.oncanplaythrough = () => audioRef.play();
    // audioRef.load();
  }

  calculateDuration() {
    if (!this.recording) {
      this.duration = -1;
      this.durationDisplay = '';
      return;
    }

    this.duration += 1;
    const minutes = Math.floor(this.duration / 60);
    const seconds = (this.duration % 60).toString().padStart(2, '0');
    this.durationDisplay = `${minutes}:${seconds}`;
    this.placeholderText = `Keep holding while recording voice note`;
    setTimeout(() => {
      this.calculateDuration();
    }, 1000);
  }

  async phonePhoto() {
    if (this.localUrls.length > 0) {
      alert(
        'Only 1 image per message for now. If you want to share more photos, please use a post!'
      );
    } else {
      const image = await Camera.getPhoto({
        quality: 90,
        allowEditing: false,
        resultType: CameraResultType.Base64,
        source: CameraSource.Photos,
      });
      if (image) {
        const { format, base64String } = image;
        // console.log(
        //   '[CHAT INPUT] has base64 image str: ',
        //   base64String.substring(0, 100)
        // );
        this.localUrls.push(`${this.base64prefix}${base64String}`);
        // some components use emit, others use global state
        this.hasMedia.emit(true);
        this.store.dispatch(new DmToggleInputHasPhoto({ hasPhoto: true }));
      }
    }
  }

  uploadDesktopImages(): Promise<string[]> {
    return new Promise(async (resolve, reject) => {
      await this.presentLoading();
      const thumbPaths = [];
      this.uploading = true;
      this.uploads = [];
      const villageId = this.store.selectSnapshot(VillageState.uid);

      const allPercentage: Observable<number>[] = [];
      const downloadUrls$ = this.files.map((file) => {
        const ts = new Date().getTime().toString();
        const filePath = `villages/${villageId}/message_photos/${ts}_${file.name}`;
        const thumbPath = `villages/${villageId}/message_photos/thumbs/${ts}_${file.name}`;
        const metadata = {
          contentType: 'image/jpeg',
          cacheControl: 'public, max-age=86400',
        };
        thumbPaths.push(thumbPath.replace(/\.[^/.]+$/, '')); // remove the extension
        const fileRef = this.bucket.ref(filePath);
        const task = this.bucket.upload(filePath, file, metadata);

        const _percentage$ = task.percentageChanges();
        allPercentage.push(_percentage$);

        // observe percentage changes
        this.uploadPercent$ = task.percentageChanges();
        this.uploadPercent$.subscribe((x) => (this.uploadPercent = x));

        // get notified when the download URL is available
        return task.snapshotChanges().pipe(
          filter((status) => status.state === firebase.storage.TaskState.SUCCESS),
          switchMap(() => from(fileRef.getDownloadURL()))
        );
      });

      combineLatest([...downloadUrls$]).subscribe(
        (urls) => {
          this.downloadURLs = urls;
          this.uploading = false;
          resolve(thumbPaths);
        },
        (err) => {
          this.analytics.logEvent('share_image_upload_error', {
            error: err,
          });
          this.uploading = false;
          reject(err);
        }
      );
    });
  }

  uploadNativeImages(): Promise<string[]> {
    return new Promise(async (resolve, reject) => {
      await this.presentLoading();
      const thumbPaths = [];
      this.uploading = true;
      this.uploads = [];
      const villageId = this.store.selectSnapshot(VillageState.uid);
      const villagerId = this.store.selectSnapshot(VillagerState.uid);

      const allPercentage: Observable<number>[] = [];
      const downloadUrls$ = this.localUrls.map((base64image) => {
        const ts = new Date().getTime().toString();
        const filePath = `villages/${villageId}/message_photos/${ts}_${villagerId}.jpg`;
        const thumbPath = `villages/${villageId}/message_photos/thumbs/${ts}_${villagerId}`;
        const metadata = {
          contentType: 'image/jpeg',
          cacheControl: 'public, max-age=86400',
        };
        thumbPaths.push(thumbPath);
        const fileRef = this.bucket.ref(filePath);
        const croppedBase64 = base64image.split(this.base64prefix)[1];
        const task = fileRef.putString(croppedBase64, 'base64', metadata);

        const _percentage$ = task.percentageChanges();
        allPercentage.push(_percentage$);

        // observe percentage changes
        this.uploadPercent$ = task.percentageChanges();
        this.uploadPercent$.subscribe((x) => (this.uploadPercent = x));

        // get notified when the download URL is available
        return task.snapshotChanges().pipe(
          filter((status) => status.state === firebase.storage.TaskState.SUCCESS),
          switchMap(() => from(fileRef.getDownloadURL()))
        );
      });

      combineLatest([...downloadUrls$]).subscribe(
        (urls) => {
          this.analytics.logEvent('share_image_upload_success', {});
          this.downloadURLs = urls;
          this.uploading = false;
          resolve(thumbPaths);
        },
        (err) => {
          this.analytics.logEvent('share_image_upload_error', {
            error: err,
          });
          this.uploading = false;
          console.error(err);
          reject(err);
        }
      );
    });
  }

  @HostListener('window:keydown.enter', ['$event'])
  enterKeyDown(e: Event) {
    // on desktop, we want to prevent enter from adding a new line to the message
    // because the enter key sends the message. new lines are added on desktop
    // via shift+enter
    if (!this.isMobile && !this.mentionify.popupRef) {
      e.preventDefault();
      this.addMessage();
    }
  }

  transformMentionifyNodes(clone: HTMLElement) {
    Array.from(clone.children).forEach((el: HTMLElement) => {
      const elChildren = Array.from(el.children || []);
      if (elChildren.length) {
        if (el.className === MENTIONIFY_CLASSNAME) {
          elChildren.forEach((hidEl) => hidEl.remove());
          el.innerText = `\uFFF9@${el.innerText}\uFFFB`;
        } else {
          this.transformMentionifyNodes(el);
        }
      }
    });
  }

  async addMessage() {
    // can put this back in if we want. needs alert
    // this.msgEl.nativeElement.innerText.length > 900 ||
    if (
      this.sending ||
      (!this.msgEl.nativeElement.innerHTML &&
        !this.localUrls.length &&
        this.storedFiles.length === 0)
    ) {
      return;
    }

    this.sending = true;
    const uid = this.store.selectSnapshot(VillagerState.uid);
    const firstName = this.store.selectSnapshot(VillagerState.firstName);
    const lastName = this.store.selectSnapshot(VillagerState.lastName);
    const { PARTICIPANT_UIDS, PARTICIPANTS } = this.chatroom;
    let MUTED_UIDS = [];
    switch (this.type) {
      case 'courtyard':
      case 'dm':
        MUTED_UIDS = (this.chatroom as DirectChatroom | Courtyard).MUTED_UIDS;
        break;
    }
    // const acceptedParticipants =
    //   this.type === 'dm' && PARTICIPANTS
    //     ? (PARTICIPANTS as DirectParticipant[]).reduce(
    //         (acc, x) => (x.ACCEPTED ? [...acc, x.UID] : acc),
    //         [] as string[]
    //       )
    //     : PARTICIPANT_UIDS;

    const acceptedParticipants = PARTICIPANT_UIDS;

    const newMessage: ChatMessage = {
      _CREATOR_UID: uid,
      _CREATOR_FIRST_NAME: firstName,
      _CREATOR_LAST_NAME: lastName,
      _BODY: null,
      _SERVER_TIMESTAMP: firebase.firestore.FieldValue.serverTimestamp(),
      _CREATED_AT: new Date(),
      _RECIPIENT_UIDS: acceptedParticipants.filter(
        (x) => !MUTED_UIDS.includes(x) && x !== uid
      ),
      _PARTICIPANT_TOKENS: [], // this is populated in action handler
      _HAS_PHOTO: false,
      _MENTION_UIDS: [],
      _HAS_VOICE_NOTE: false,
      // _TYPE: null
    };

    if (this.msgEl.nativeElement.innerHTML) {
      const hiddenElements = document.getElementsByClassName(MENTIONIFY_HIDDEN);
      const mentionsJson: Partial<Villager>[] = Array.from(hiddenElements)
        .map((e) => JSON.parse(e.innerHTML))
        .filter(Boolean);
      newMessage._MENTION_UIDS = mentionsJson.map((i) => i._UID);

      const clone = this.msgEl.nativeElement.cloneNode(true) as HTMLDivElement;
      clone.innerHTML = clone.innerHTML.replace(
        /(<br>|<div><br><\/div>?)/g,
        '\n'
      );
      this.transformMentionifyNodes(clone);
      newMessage._BODY = clone.innerText;
    }

    try {
      const villageId = this.store.selectSnapshot(VillageState.uid);
      if (this.storedFiles.length > 0) {
        const voiceNoteUrl = await this.uploadVoiceNotes();
        newMessage._HAS_VOICE_NOTE = true;
        newMessage._VOICE_NOTE_URL = voiceNoteUrl;
        newMessage._VOICE_NOTE_DURATION = this.recordedDuration;
        this.deleteRecording(this.storedFiles[0]);
      }

      if (this.localUrls.length > 0) {
        let imageUrls;
        if (this.isNative) {
          imageUrls = await this.uploadNativeImages();
        } else {
          imageUrls = await this.uploadDesktopImages();
        }

        newMessage._IMAGE_URLS = imageUrls;
        newMessage._HAS_PHOTO = true;
      }

      if (this.reply) {
        newMessage._REPLIED_TO_UID = this.reply._UID;
        if (this.reply._BODY !== 'null') {
          newMessage._REPLIED_TO_BODY = this.reply._BODY;
        }
        newMessage._REPLIED_TO_CREATOR_UID = this.reply._CREATOR_UID;
        newMessage._REPLIED_TO_MENTION_UIDS = this.reply._MENTION_UIDS;
        if (this.reply._HAS_VOICE_NOTE) {
          newMessage._REPLIED_TO_VOICE_NOTE = true;
          newMessage._REPLIED_TO_VOICE_NOTE_URL = this.reply._VOICE_NOTE_URL;
          newMessage._REPLIED_TO_VOICE_NOTE_DURATION =
            this.reply._VOICE_NOTE_DURATION;
        }
        if (this.reply._HAS_PHOTO) {
          newMessage._REPLIED_TO_IMAGE = this.reply._HAS_PHOTO;
          newMessage._REPLIED_TO_IMAGE_URLS = this.reply._IMAGE_URLS;
        }
      }

      let action;
      let actionField;
      switch (this.type) {
        case 'courtyard':
          action = new CourtyardActions.SendMessage({
            message: newMessage,
            villageId,
          });
          actionField = 'HAS_SENT_CHANNEL_MESSAGE';
          break;
        case 'post':
          action = new SendVillageMessage({ message: newMessage, villageId });
          actionField = 'HAS_SENT_POST_MESSAGE';
          break;
        case 'dm':
          action = new SendDirectChatMessage({
            message: newMessage,
            villageId,
          });
          actionField = 'HAS_SENT_DM';
          break;
        default:
          alert('Internal Error. No matching type for input message');
      }

      this.store.dispatch(action).subscribe(
        () => {
          this.analytics.logEvent(
            `${this.type}_chatroom_add_message_success`,
            {}
          );
          this.localUrls = [];
          this.files = [];
          this.reply = null;
          this.store.dispatch(new ClearReplyMessage());
          this.cleanUp();
          this.store.dispatch(
            new VillagerActions.MarkOnboardedTaskComplete({
              villagerId: uid,
              field: actionField,
            })
          );
        },
        (err) => {
          this.analytics.logEvent(`${this.type}_chatroom_add_message_error`, {
            error: err,
          });
          this.cleanUp();
          console.error(err);

          alert('Error sending message');
        }
      );
    } catch (e) {
      console.error('Error saving photos OR sending message: ', e);
      this.cleanUp();
      alert('Error sending message');
    }
  }

  uploadVoiceNotes(): Promise<string> {
    return new Promise(async (resolve, reject) => {
      await this.presentLoading();
      let filePath;
      this.uploading = true;
      this.uploads = [];
      const villageId = this.store.selectSnapshot(VillageState.uid);
      const villagerId = this.store.selectSnapshot(VillagerState.uid);

      const allPercentage: Observable<number>[] = [];
      const downloadUrls$ = this.storedFiles.map(async (fileName) => {
        // Get the file from local filesystem
        const audioFile = await Filesystem.readFile({
          path: fileName.name,
          directory: Directory.Data,
        });

        const base64Sound = audioFile.data;

        const ts = new Date().getTime().toString();
        filePath = `villages/${villageId}/voice_notes/${ts}_${villagerId}.wav`; // needs .jpg for resizer to append filename correctly
        const metadata = {
          contentType: 'audio/wav',
          cacheControl: 'public, max-age=86400',
        };
        const fileRef = this.bucket.ref(filePath);
        const task = fileRef.putString(base64Sound, 'base64', metadata);

        const _percentage$ = task.percentageChanges();
        allPercentage.push(_percentage$);

        // observe percentage changes
        this.uploadPercent$ = task.percentageChanges();
        this.uploadPercent$.subscribe((x) => (this.uploadPercent = x));

        // get notified when the download URL is available
        return task.snapshotChanges().pipe(
          filter((status) => status.state === firebase.storage.TaskState.SUCCESS),
          switchMap(() => from(fileRef.getDownloadURL()))
        );
      });

      combineLatest([...downloadUrls$]).subscribe(
        (urls) => {
          this.analytics.logEvent('share_voice_note_upload_success', {});
          this.downloadURLs = urls;
          this.uploading = false;
          this.cleanUp();
          resolve(filePath);
        },
        (err) => {
          this.analytics.logEvent('share_voice_note_upload_error', {
            error: err,
          });
          this.uploading = false;
          this.cleanUp();
          console.error(err);
          reject(err);
        }
      );
    });
  }

  cleanUp() {
    this.hasMedia.emit(false);
    this.store.dispatch(new DmToggleInputHasPhoto({ hasPhoto: false }));
    this.sending = false;
    if (this.loading) {
      this.loading.dismiss();
      this.loading = null;
    }
  }

  async presentLoading() {
    if (!this.loading) {
      this.loading = await this.loadingCtrl.create({
        duration: 30000,
        message: `Uploading Content`,
        backdropDismiss: true,
      });
      return this.loading.present();
    }
  }

  async showRecordingTutorialAlert() {
    const alert = await this.alertCtrl.create({
      // header: `Tip`,
      message:
        'Press and hold the microphone duration to record your voice note.',
      buttons: [
        {
          text: 'Ok',
          handler: () => {
            this.stopRecording();
            this.audioSvc.killAnyExistingAudio();
          },
        },
      ],
    });

    await alert.present();
  }

  async showErrorAlert(e) {
    const alert = await this.alertCtrl.create({
      header: `Error`,
      message: e,
      buttons: ['Ok'],
    });

    await alert.present();
  }
}
