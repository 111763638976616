import { Injectable } from '@angular/core';
import { NgxsFirestore } from '@ngxs-labs/firestore-plugin';
import { DirectChatroom } from '../models/direct-chatroom.model';
import * as firebase from 'firebase';
import 'firebase/firestore';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DirectChatroomService extends NgxsFirestore<DirectChatroom> {
  idField = '_UID';
  path = 'DIRECT_CHATROOMS';

  /**
   * @deprecated
   */
  updateIfExists(id, data) {
    return of(
      this.adapter.firestore
        .doc(`${this.path}/${id}`)
        .ref.withConverter(this.converter)
        .update(data)
    );
  }

  updateWithoutConverter(id: string, update: Partial<DirectChatroom>) {
    return of(this.adapter.firestore.doc(`${this.path}/${id}`).update(update));
  }

  converter: firebase.default.firestore.FirestoreDataConverter<DirectChatroom> =
    {
      toFirestore: (value: DirectChatroom) => {
        return value;
      },
      fromFirestore: (snapshot, options) => {
        let data = snapshot.data(options) as DirectChatroom;
        if (snapshot.metadata.hasPendingWrites) {
          const ts = firebase.default.firestore.Timestamp.now();
          data = {
            ...data,
            _CREATED_AT: ts.toDate(),
            UPDATED_AT: ts.toDate(),
          };
        } else {
          data = {
            ...data,
            _CREATED_AT: data._SERVER_TIMESTAMP.toDate(),
            UPDATED_AT: data.UPDATED_AT.toDate(),
          };
        }

        // patch the ACCEPTED & REJECTED, defaulting to accepted for all old chatrooms
        data.PARTICIPANTS = data.PARTICIPANTS.map((x) => {
          if (!x.hasOwnProperty('ACCEPTED')) {
            x.ACCEPTED = true;
          }

          if (!x.hasOwnProperty('REJECTED')) {
            x.REJECTED = false;
          }

          return x;
        });

        if (!data.hasOwnProperty('MUTED_UIDS')) {
          data.MUTED_UIDS = [];
        }

        return data;
      },
    };
}
