<ion-progress-bar [value]="playback"></ion-progress-bar>

<div
  *ngIf="voiceNoteUrl$ | async as url; else loading"
  class="message-body voice-note-wrapper"
>
  <!-- <p>voiceServiceVoiceNote: {{voiceServiceVoiceNote?.substring(0, 50)}}</p>
<p>currentTime: {{currentTime}}</p>
<p>duration: {{duration}}</p>
<p>globalUrl: {{globalUrl?.substring(0, 50)}}</p><p></p> -->
  <div class="duration-text">
    <span class="duration-note">Voice Note</span>&nbsp;
    <span *ngIf="currentTime">{{ currentTime * 1000 | date: 'm:ss' }}/</span
    >{{ duration * 1000 | date: 'm:ss' }}
  </div>
  <!-- <p>is current playing: {{isCurrentPlaying}}</p> -->
  <!-- playback: {{ playback }}
  currentTime: {{ currentTime}} -->
  <!-- <ion-item>
    <ion-text *ngIf="(audioLoading$ | async) as loading">
      Loading: {{loading}}
    </ion-text>
  </ion-item> -->
  <ion-button
    (click)="togglePlayback(url); $event.stopPropagation()"
    class="control-button"
    slot="end"
    fill="clear"
  >
    <ion-icon *ngIf="hasEnded" color="primary" name="refresh-circle"></ion-icon>
    <ion-spinner
      *ngIf="isLoading === true; else showControls"
      color="primary"
    ></ion-spinner>
    <ng-template #showControls>
      <ion-icon
        *ngIf="!isPlaying && !hasEnded"
        color="primary"
        name="play-circle"
      ></ion-icon>
      <ion-icon
        *ngIf="isPlaying && !hasEnded"
        color="dark"
        name="pause-circle"
      ></ion-icon>
    </ng-template>
  </ion-button>
</div>

<ng-template #loading>
  <ion-skeleton-text
    animated
    style="width: 250px; height: 25px; border-radius: 10px"
  ></ion-skeleton-text>
</ng-template>

<ion-text
  *ngIf="messageBody && messageBody !== 'null'"
  [innerHtml]="messageBody | safehtml"
></ion-text>
