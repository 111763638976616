import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { PopoverController } from '@ionic/angular';
import { Select, Store } from '@ngxs/store';
import { combineLatest, Observable } from 'rxjs';
import { Circle, JoinedCircle } from 'src/app/models/circle.model';
import { AppSetPostCirclesAssigned } from 'src/app/state/app.actions';
import { CircleState } from 'src/app/state/circle.state';
import { VillageState } from 'src/app/state/village.state';
import { VillagerState } from 'src/app/state/villager.state';
import { PostCircleExplainPopoverComponent } from '../post-circle-explain-popover/post-circle-explain-popover.component';

@Component({
  selector: 'app-post-circle-assignment',
  templateUrl: './post-circle-assignment.component.html',
  styleUrls: ['./post-circle-assignment.component.scss'],
})
export class PostCircleAssignmentComponent implements OnInit {
  circleForm = new FormGroup({
    assignedCircles: new FormControl(''),
  });

  defaultCircle: JoinedCircle = {
    NAME: 'Members',
    UID: 'MEMBERS',
  };

  mappedCircles: JoinedCircle[] = [];

  get assignedCircles() {
    return this.circleForm.get('assignedCircles');
  }

  @Select(VillageState.villageName) villageName$: Observable<string>;
  @Select(VillagerState.currentVillagerCircles) villagerCircles$: Observable<
    JoinedCircle[]
  >;
  @Select(CircleState.allCircles) allCircles$: Observable<Circle[]>;

  @Input() alreadyAssignedCircles: JoinedCircle[] = [];
  constructor(private store: Store, private popoverCtrl: PopoverController) {}

  ngOnInit() {
    combineLatest([this.villagerCircles$, this.allCircles$]).subscribe(
      ([villagerCircles, allCircles]) => {
        this.mappedCircles = [];
        villagerCircles.forEach((joinedCircle) => {
          const mappedCircle = allCircles.find(
            (x) => x._UID == joinedCircle.UID
          );
          this.mappedCircles.push({
            UID: mappedCircle._UID,
            NAME: mappedCircle.NAME,
          });
        });
        console.log('[DEBUG] Circles to display in select: ', this.mappedCircles)
      }
    );

    this.assignedCircles.valueChanges.subscribe((value: JoinedCircle[]) => {
      console.log('Emitting assigned circles: ', value);
      this.store.dispatch(
        new AppSetPostCirclesAssigned({ assignedCircles: value })
      );
      // this.assignCircles.emit(value);
    });

    this.villagerCircles$.subscribe((circles) => {
      if (this.alreadyAssignedCircles.length > 0) {
        this.assignedCircles.setValue(this.alreadyAssignedCircles);
      } else {
        this.assignedCircles.setValue(this.defaultCircle, { emitEvent: false });
        setTimeout(
          () =>
            this.store.dispatch(
              new AppSetPostCirclesAssigned({
                assignedCircles: [this.defaultCircle],
              })
            ),
          300
        );
      }
    });
  }

  compareWith(o1: JoinedCircle, o2: JoinedCircle | JoinedCircle[]) {
    if (!o1 || !o2) {
      return o1 === o2;
    }

    if (Array.isArray(o2)) {
      return o2.some((u: JoinedCircle) => u.UID === o1.UID);
    }

    return o1.UID === o2.UID;
  }

  async showHelper(e) {
    const popover = await this.popoverCtrl.create({
      component: PostCircleExplainPopoverComponent,
      event: e
    })

    await popover.present()
  }

}
