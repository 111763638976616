<div class="message-input-container">
  <ion-grid>
    <!-- <ion-row *ngIf="replies.length > 0">
      <div class="scroll-row">
        <ion-chip
          *ngFor="let quickReply of replies"
          color="{{ quickReply.COLOR }}"
          outline="{{ quickReply.COLOR }}"
          (click)="autofillMessage(quickReply.MESSAGE)"
        >
          <ion-label>{{ quickReply.MESSAGE }}</ion-label>
        </ion-chip>
      </div>
    </ion-row> -->
    <ion-row *ngIf="localUrls.length > 0">
      <ion-col>
        <div class="thumbnail-container">
          <div
            *ngFor="let url of localUrls; let i = index"
            class="thumbnail-wrapper"
            (click)="removeThumbnail(i)"
          >
            <ion-icon color="#fff" name="close-circle"></ion-icon>
            <img [src]="url" />
          </div>
        </div>
      </ion-col>
    </ion-row>
    <ion-row *ngIf="recording">
      <ion-col>
        <ion-item lines="none">
          <ion-label style="text-align: right">
            <ion-spinner
              style="margin-bottom: -7px; margin-right: 5px; font-size: 30px"
              name="dots"
            ></ion-spinner>
            <strong>{{ durationDisplay }}</strong>
          </ion-label>
        </ion-item>
      </ion-col>
    </ion-row>
    <ion-row *ngIf="storedFiles.length > 0">
      <ion-col>
        <div class="recording-container">
          <ion-list>
            <ion-item button *ngFor="let f of storedFiles" detail="false">
              <ion-icon (click)="playFile(f.name)" name="radio"></ion-icon
              >&nbsp;Voice Note Recorded
              <ion-button
                slot="end"
                color="danger"
                (click)="deleteRecording(f)"
                fill="clear"
              >
                <ion-icon name="close-circle"></ion-icon>
              </ion-button>
            </ion-item>
          </ion-list>
        </div>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="12">
        <div class="photo-button-wrapper">
          <ion-button
            size="small"
            *ngIf="!isNative; else nativePhoto"
            fill="clear"
          >
            <label class="custom-file-upload">
              <ion-icon style="font-size: 24px" name="image-outline">
              </ion-icon>
              <input
                type="file"
                accept="image/*"
                (change)="fileChangeEvent($event)"
              />
            </label>
          </ion-button>
          <ng-template #nativePhoto>
            <ion-button (click)="phonePhoto()" fill="clear">
              <ion-icon
                style="font-size: 24px"
                slot="icon-only"
                name="image-outline"
              >
              </ion-icon>
            </ion-button>
          </ng-template>
        </div>
        <div class="text-input-wrapper">
          <div>
            <app-message-reply-preview
              *ngIf="reply"
              [replyMessage]="reply"
            >
            </app-message-reply-preview>
          </div>
          <!-- enterkeyhint="enter" -->
          <div
            #messageInput
            class="textarea"
            appMentionify
            [mentionables]="mentionables"
            [type]="type"
            contenteditable
            [attr.placeholder]="placeholderText"
            autocapitalize="on"
          ></div>

          <div class="send-button">
            <!-- recording button -->
            <ion-button
              [ngClass]="
                localUrls.length == 0 &&
                (this.msgEl?.nativeElement.innerText.length <= 0 ||
                  duration > -1) &&
                this.storedFiles.length == 0
                  ? 'ion-show'
                  : 'ion-hide'
              "
              [disabled]="sending"
              fill="clear"
              #recordbtn
            >
              <ion-icon
                name="mic-circle"
                color="primary"
                slot="icon-only"
              ></ion-icon>
              <ion-spinner
                *ngIf="sending"
                name="lines-small"
                color="primary"
              ></ion-spinner>
            </ion-button>

            <!-- send message button -->
            <ion-button
              *ngIf="
                (this.msgEl?.nativeElement.innerText.length > 0 &&
                  duration == -1) ||
                localUrls.length > 0 ||
                storedFiles.length > 0
              "
              [disabled]="sending"
              fill="clear"
              (click)="addMessage()"
            >
              <ion-label *ngIf="sending && uploadPercent$ | async as percent">
                {{ percent | number: '1.0-0' }}%
              </ion-label>
              <ion-icon
                *ngIf="!sending"
                color="primary"
                slot="icon-only"
                name="arrow-up-circle"
              ></ion-icon>
              <ion-spinner
                *ngIf="sending"
                name="lines-small"
                color="primary"
              ></ion-spinner>
            </ion-button>
          </div>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</div>
