import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  ElementRef,
  HostListener,
  Injector,
  Input,
  OnInit,
} from '@angular/core';
import { Platform } from '@ionic/angular';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { MENTIONIFY_CLASSNAME, MENTIONIFY_HIDDEN } from 'src/app/app.config';
import { Villager } from 'src/app/models/villager.model';
import { MemberMentionPopupComponent } from 'src/app/shared/member-mention-popup/member-mention-popup.component';
import { DirectChatroomState } from 'src/app/state/direct-chatroom.state';
import { VillagerState } from 'src/app/state/villager.state';

const HTML_AT = `<span id="at">@</span>`;

@Directive({
  selector: '[appMentionify]',
  exportAs: 'appMentionify',
})
export class MentionifyDirective implements OnInit {
  popupRef: ComponentRef<MemberMentionPopupComponent> = null;
  partialMention = '';
  inputEl: HTMLDivElement = null;

  isAndroid = false;

  @Select(DirectChatroomState.chatroomVillagers) chatroomVillagers$: Observable<
    Villager[]
  >;
  @Select(VillagerState.fellowVillagers) fellowVillagers$: Observable<
    Villager[]
  >;

  @Input() mentionables: string[];
  @Input() type: 'post' | 'courtyard' | 'dm';
  @Input() shouldDisplayAbove: boolean = true;

  constructor(
    private injector: Injector,
    private resolver: ComponentFactoryResolver,
    private app: ApplicationRef,
    private store: Store,
    private nativeElement: ElementRef,
    private platform: Platform
  ) {
    this.inputEl = nativeElement.nativeElement;
  }

  ngOnInit(): void {

    if (this.platform.is('android')) {
      this.isAndroid = true;
    } else this.isAndroid = false;
  }

  @HostListener('document:keydown.escape', ['$event'])
  onEscapeKeyboardEvent() {
    if (this.popupRef) {
      this.deleteMention(
        window.getSelection().getRangeAt(0),
        document.getElementById('at')
      );
    }
  }

  @HostListener('document:keydown.tab', ['$event'])
  @HostListener('document:keydown.enter', ['$event'])
  onEnterKeyboardEvent(ev: InputEvent) {
    if (this.popupRef) {
      ev.preventDefault();
      ev.stopPropagation();
      this.onKeyDown(ev);
    }
  }

  @HostListener(':input', ['$event'])
  onKeyDown(event: InputEvent) {
    if (event.inputType === 'deleteSoftLineBackward') {
      /**
       * BUG: when a mention is the first thing in the input and it gets
       * deleted with a cmd+enter, the mention styles appear on next typed char
       */
      this.inputEl.innerHTML = '';
      return;
    }
    const selection = window.getSelection();

    const focusNode = selection.focusNode as HTMLSpanElement;
    const parentNode = selection.focusNode.parentNode as HTMLSpanElement;
    const isEditingMention = (
      focusNode.className + parentNode.className
    ).includes(MENTIONIFY_CLASSNAME);
    if (isEditingMention) {
      // delete mention if any part of it is modified
      const focusNode = selection.focusNode as HTMLSpanElement;
      const parentNode = selection.focusNode.parentNode as HTMLSpanElement;
      const mentionifyNode =
        focusNode.className === MENTIONIFY_CLASSNAME ? focusNode : parentNode;
      this.deleteMention(selection.getRangeAt(0), mentionifyNode);
    }

    if (this.popupRef) {
      const isFirstChar = this.inputEl.innerText[0] === '@';
      const match = this.inputEl.innerText.match(/\s@(.*)/g);
      if (!isFirstChar && !match) {
        this.destroyPopup();
        return;
      }

      const isSpace = (event.data || '').search(/\s/) >= 0;
      const isSelect = ['Enter', 'Tab'].includes((event as any).key);

      const matchedVillagerCount = this.popupRef.instance.villagers.length;
      if (isSpace || isSelect) {
        if (!matchedVillagerCount) {
          /**
           * remove the partial mention when whitespace entered and no matches.
           */
          const currentNodeIndex = Array.from(
            this.inputEl.childNodes
          ).findIndex((node) => node === focusNode || node === parentNode);
          this.inputEl.innerHTML = this.inputEl.innerHTML.replace(
            /<span id="at">.*<\/span>/,
            `\uFEFF@${this.partialMention}&nbsp;`
          );
          const currentNode = this.inputEl.childNodes[currentNodeIndex - 1];
          selection.setPosition(currentNode, currentNode.textContent.length);
          this.destroyPopup();
        } else if (isSelect || matchedVillagerCount === 1) {
          this.popupRef.instance.setVillager(
            this.popupRef.instance.villagers[
              this.popupRef.instance.selectedIndex
            ]
          );
        }
      }

      const node = focusNode.className === 'at' ? focusNode : parentNode;
      this.partialMention = node.innerText.replace('@', '').toLowerCase();
      this.setPopupVillagers();
    } else if (event.data === '@') {
      const isFirstChar = this.inputEl.innerText.length === 1;
      if (!isFirstChar && this.inputEl.innerText.search(/\s@/) === -1) {
        return;
      }

      setTimeout(() => {
        this.createPopup(selection);
      }, 30);
    }

    /**
     * This is here to handle the case where, on iOS, you delete all the text
     * but a single <br> is left which is considered to have length 1
     * this helps make the recording button visible again
     */
    if (
      event.inputType === 'deleteContentBackward' &&
      this.inputEl.innerHTML === '<br>'
    ) {
      this.inputEl.innerHTML = '';
    }
  }

  deleteMention(range: Range, node: Node) {
    this.destroyPopup();
    range.selectNode(node);
    range.deleteContents();
  }

  createPopup(selection: Selection) {
    if (this.popupRef) {
      return false;
    }

    this.inputEl.innerHTML = this.inputEl.innerHTML.replace(
      /\uFEFF@|@/g,
      (match) => (match.length === 1 ? HTML_AT : match)
    );
    selection.setPosition(document.getElementById('at'), 1);

    const factory = this.resolver.resolveComponentFactory(
      MemberMentionPopupComponent
    );
    const popupNode = document.createElement('div');
    this.inputEl.parentNode.appendChild(popupNode);
    this.popupRef = factory.create(this.injector, [], popupNode);
    this.popupRef.instance.shouldDisplayAbove = this.shouldDisplayAbove;

    this.setPopupVillagers();
    this.popupRef.instance.setVillager = (v: Villager) => {
      const { _UID, FIRST_NAME, LAST_NAME } = v;
      const mentionJSON = JSON.stringify({ _UID, FIRST_NAME, LAST_NAME });

      const ts = new Date().getTime();
      this.inputEl.innerHTML = this.inputEl.innerHTML.replace(
        /<span id="at">.*<\/span>/,
        `<span id="mention-${ts}" class="${MENTIONIFY_CLASSNAME}">${FIRST_NAME} ${LAST_NAME}<span class="${MENTIONIFY_HIDDEN}">${mentionJSON}</span></span>&nbsp;`
      );

      const mentionNode = document.getElementById(`mention-${ts}`);
      selection.setPosition(mentionNode.nextSibling, 1);

      this.destroyPopup();
    };

    this.app.attachView(this.popupRef.hostView);
  }

  async setPopupVillagers() {
    let villagers$;
    switch (this.type) {
      case 'dm':
        villagers$ = this.chatroomVillagers$;
        break;
      default:
        villagers$ = this.fellowVillagers$;
        break;
    }
    const mentionChars = this.partialMention.replace(/\s/, '');

    villagers$.subscribe((villagers) => {
      if (!this.popupRef) return;
      this.popupRef.instance.villagers = villagers.filter(
        (v) =>
          this.mentionables.includes(v._UID) &&
          `${v.FIRST_NAME}${v.LAST_NAME}`.toLowerCase().includes(mentionChars)
      );
    });
  }

  destroyPopup() {
    if (this.popupRef) {
      this.popupRef.destroy();
    }
    this.popupRef = null;
    this.partialMention = '';
  }
}
