import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AnalyticsService } from 'src/app/analytics.service';
import { Circle } from 'src/app/models/circle.model';
import {
  ClientPostType,
  GenericPost,
  PostTypes,
} from 'src/app/models/post-core.model';
import {
  ClearVillageChatroom,
  RemoveDiscussionParticipant,
} from 'src/app/state/app.actions';
import { CircleState } from 'src/app/state/circle.state';
import { VillagerState } from 'src/app/state/villager.state';

@Component({
  selector: 'app-post-dropdown-details',
  templateUrl: './post-dropdown-details.component.html',
  styleUrls: ['./post-dropdown-details.component.scss'],
})
export class PostDropdownDetailsComponent implements OnInit {
  currentVillagerId: string;
  postTypes = PostTypes;
  chipInfo: ClientPostType;
  isCurrentVillager$: Observable<boolean>;

  @Select(VillagerState.uid) villagerId$: Observable<string>;
  @Select(CircleState.allCircles) circles$: Observable<Circle[]>;
  @Input() post: GenericPost;
  @Output() done: EventEmitter<any>  = new EventEmitter<any>();

  constructor(
    private store: Store,
    private analytics: AnalyticsService,
    // private alertCtrl: AlertController
  ) {}

  ngOnInit() {
    this.chipInfo = this.postTypes.find((x) => x.type === this.post._TYPE);
    this.villagerId$.subscribe((x) => (this.currentVillagerId = x));

    this.isCurrentVillager$ = this.store
      .select(VillagerState.isCurrentVillager)
      .pipe(map((filterFn) => filterFn(this.post._CREATOR_UID)));
  }

  leaveDiscussion() {
    // this.showLeaveAlert();
    this.store.dispatch(
      new RemoveDiscussionParticipant({
        villagerId: this.currentVillagerId,
        post: this.post,
      })
    );
    this.analytics.logEvent('discussion_leave_success', {});
    this.done.emit();
    this.closeSelectedPost();
  }

  closeSelectedPost() {
    this.store.dispatch(new ClearVillageChatroom());
  }

  // async showLeaveAlert() {
  //   const alert = await this.alertCtrl.create({
  //     message: 'You can always rejoin at any time',
  //     buttons: ['Got it!'],
  //   });

  //   await alert.present();
  // }
}
