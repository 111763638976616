<div class="text-input-wrapper">
  <div
    (focus)="handleFocus($event)"
    #messageInput
    class="textarea"
    appMentionify
    [mentionables]="mentionables"
    [shouldDisplayAbove]="shouldDisplayAbove"
    [type]="'post'"
    contenteditable
    [attr.placeholder]="placeholderText"
    autocapitalize="on"
  ></div>
</div>
