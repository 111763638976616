<ion-header class="ion-no-border" translucent="false">
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button (click)="modalCtrl.dismiss({action: 'regular-close'})">
        <ion-icon color="dark" slot="icon-only" name="close-circle"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>Switch Villages</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content [fullscreen]="true">
  <app-select-or-join-village
    [villager]="villager"
    [showBorder]="false"
  ></app-select-or-join-village>

  <div style="height: 20vh"></div>
</ion-content>
