import { Component, OnInit } from '@angular/core';
import {
  AlertController,
  ModalController,
  PopoverController,
} from '@ionic/angular';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CreateCourtyardModalPage } from 'src/app/modals/create-courtyard-modal/create-courtyard-modal.page';
import { Courtyard } from 'src/app/models/courtyard.model';
import { FilterAppNotifications } from 'src/app/state/app.actions';
import { CircleState } from 'src/app/state/circle.state';
import { CourtyardActions } from 'src/app/state/courtyard.actions';
import { CourtyardState } from 'src/app/state/courtyard.state';
import { VillageState } from 'src/app/state/village.state';
import { VillagerState } from 'src/app/state/villager.state';

@Component({
  selector: 'app-courtyard-details-popover',
  templateUrl: './courtyard-details-popover.component.html',
  styleUrls: ['./courtyard-details-popover.component.scss'],
})
export class CourtyardDetailsPopoverComponent implements OnInit {
  courtyard: Courtyard;
  isSteward$: Observable<boolean>;
  canManageCourtyards$: Observable<boolean>;
  isMuted: boolean;
  loading = false;
  isCircleAdmin$: Observable<boolean>;

  @Select(VillageState.uid) villageUid$: Observable<string>;
  @Select(VillagerState.uid) villagerUid$: Observable<string>;
  constructor(
    private store: Store,
    private popoverCtrl: PopoverController,
    private modalCtrl: ModalController,
    private alertCtrl: AlertController
  ) {}

  ngOnInit() {
    this.courtyard = this.store.selectSnapshot(
      CourtyardState.selectedCourtyard
    );
    const { _UID: villagerUid } = this.store.selectSnapshot(
      VillagerState.currentVillager
    );
    this.isMuted = this.courtyard.MUTED_UIDS.includes(villagerUid);

    this.villageUid$.subscribe((id) => {
      this.isSteward$ = this.store
        .select(VillagerState.currentVillagerIsStewardInVillage)
        .pipe(map((filterFn) => filterFn(id)));
    });

    this.villagerUid$.subscribe((villagerId) => {
      this.canManageCourtyards$ = this.store
      .select(VillageState.canManageCourtyards)
      .pipe(map((filterFn) => filterFn(villagerId)))

      this.isCircleAdmin$ = this.store
      .select(CircleState.isCircleAdmin)
      .pipe(map((filterFn) => filterFn(villagerId, this.courtyard.CIRCLE_UID)))

      this.isCircleAdmin$.subscribe((x) => {
        console.log('circle admin: ', x)
      })
    })
  }

  async edit() {
    this.popoverCtrl.dismiss();
    const modal = await this.modalCtrl.create({
      component: CreateCourtyardModalPage,
      componentProps: {
        edit: true,
        courtyard: this.courtyard,
      },
    });

    return await modal.present();
  }

  toggleNotificationsMuted() {
    const { _UID: uid } = this.store.selectSnapshot(
      VillagerState.currentVillager
    );
    if (!this.loading && uid) {
      this.loading = true;

      this.store
        .dispatch(new CourtyardActions.ToggleMutedId({ uid }))
        .subscribe(() => {
          this.loading = false;
          this.isMuted = !this.isMuted;
        });
    }
  }

  async showDeleteWarning() {
    const alert = await this.alertCtrl.create({
      header: 'Careful!',
      message:
        'Are you sure you want to delete this courtyard? It cannot be restored.',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Delete',
          role: 'destructive',
          handler: () => this.delete(),
        },
      ],
    });

    await alert.present();
  }

  delete() {
    console.log('Deleting Courtyard');
    this.store
      .dispatch(
        new CourtyardActions.Delete({ courtyardId: this.courtyard._UID })
      )
      .subscribe(
        async () => {
          const modal = await this.modalCtrl.getTop();
          if (modal) {
            modal.dismiss();
          }
          this.popoverCtrl.dismiss();
        },
        (err) => alert('Error deleting')
      );
  }
}
