<!-- style="background-color: var(--ion-color-primary)" -->
<ion-grid>
  <ion-row>
    <ion-col>
      <div style="position: relative">
        <ion-button
          class="remove-reply-button"
          slot="end"
          color="dark"
          (click)="deleteReply()"
          fill="clear"
        >
          <ion-icon name="close-circle"></ion-icon>
        </ion-button>

      <ion-text
        size="small"
        style="
          font-weight: bold;
          font-size: 12px;
          display: block;
          color: var(--ion-color-dark-tint);
        "
      >
        <span *ngIf="replyMessage._HAS_VOICE_NOTE">Voice Note from</span
        ><span *ngIf="replyMessage._HAS_PHOTO">Photo from</span>
        {{ replyMessage._CREATOR_FIRST_NAME }}
        {{ replyMessage._CREATOR_LAST_NAME }}</ion-text
      >
    </div>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col>
      <div class="reply-container">
        <!-- MESSAGE REPLY -->
        <ion-text
          style="user-select: none"
          *ngIf="replyMessage._BODY !== 'null'"
          [innerHtml]="
            replyMessage._BODY
              | mentionified: replyMessage._MENTION_UIDS
              | safehtml
          "
        >
        </ion-text>
        <!-- VOICE NOTE REPLY -->
        <ion-text
          style="user-select: none"
          *ngIf="replyMessage._HAS_VOICE_NOTE"
        >
          Voice note
          {{ replyMessage._VOICE_NOTE_DURATION * 1000 | date: 'm:ss' }}
        </ion-text>
        <!-- IMAGE REPLY -->
        <div *ngFor="let url of replyMessage._IMAGE_URLS" style="width: 140px ">
          <app-chat-image [url]="url" [message]="replyMessage"></app-chat-image>
        </div>
      </div>
    </ion-col>
  </ion-row>
</ion-grid>
