<div [ngClass]="sideMenu ? 'sideMenu' : ''">
  <ion-button fill="clear" expand="block" (click)="joinOrSwitchVillage()">
    <ion-icon
      [color]="color"
      [slot]="header ? 'end' : 'icon-only'"
      name="swap-horizontal"
    ></ion-icon>
    <ion-icon name="chevron-expand-outline"></ion-icon>
    <ion-label [color]="color" *ngIf="header"
      ><span
        *ngIf="villageLabel$ | async as label"
        style="text-transform: capitalize"
      >
        Switch {{ label }}s
      </span>
    </ion-label>
  </ion-button>

  <span *ngIf="showLabel">
    <ion-chip
      [color]="sideMenu ? 'light' : 'primary'"
      *ngIf="(proAccess$ | async) === false"
    >
      <ion-label>COMMUNITY</ion-label>
    </ion-chip>

    <ion-chip
      [color]="sideMenu ? 'light' : 'primary'"
      *ngIf="(proAccess$ | async) === true"
    >
      <ion-label>COMMUNITY +</ion-label>
    </ion-chip>
  </span>
</div>
