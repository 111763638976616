import { Injectable } from '@angular/core';
import { NgxsFirestore } from '@ngxs-labs/firestore-plugin';
import { ChatMessage } from 'src/app/models/chat-message.model';
import * as firebase from 'firebase';
import 'firebase/firestore';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class VillageMessageService extends NgxsFirestore<ChatMessage> {
  idField = '_UID';

  protected get path() {
    return `VILLAGES/${this.villageId}/${this.collection}/${this.chatroomId}/MESSAGES`;
  }

  private VILLAGE_UID;
  private COLLECTION;
  private CHATROOM_UID;

  public setVillageId(villageId) {
    this.VILLAGE_UID = villageId;
  }

  public setCollection(collection) {
    this.COLLECTION = collection;
  }

  public setChatroomId(uid) {
    this.CHATROOM_UID = uid;
  }

  protected get villageId() {
    return this.VILLAGE_UID;
  }

  protected get collection() {
    return this.COLLECTION;
  }

  protected get chatroomId() {
    return this.CHATROOM_UID;
  }

  public test() {
    console.log(`PATH TO PUT: ${this.path}`);
  }

  /**
   * @deprecated
   */
  updateIfExists(id, data) {
    return of(
      this.adapter.firestore
        .doc(`${this.path}/${id}`)
        .ref.withConverter(this.converter)
        .update(data)
    );
  }

  updateWithoutConverter(id: string, update: Partial<ChatMessage>) {
    return of(this.adapter.firestore.doc(`${this.path}/${id}`).update(update));
  }

  converter: firebase.default.firestore.FirestoreDataConverter<ChatMessage> = {
    toFirestore: (value: any) => {
      let db = { ...value };

      if (value.hasOwnProperty('_BODY')) {
        console.log(
          '[Village Message Service] encoded message: ',
          encodeURI(value._BODY)
        );
        db = { ...value, _BODY: encodeURI(value._BODY) };
      }
      return db;
    },
    fromFirestore: (snapshot, options) => {
      let data = snapshot.data(options) as ChatMessage;

      if (snapshot.metadata.hasPendingWrites) {
        const ts = firebase.default.firestore.Timestamp.now();
        data = {
          ...data,
          _BODY: decodeURI(data._BODY),
          _CREATED_AT: ts.toDate(),
        };
      } else {
        data = {
          ...data,
          _BODY: decodeURI(data._BODY),
          _CREATED_AT: data._SERVER_TIMESTAMP.toDate(),
        };
      }

      if (!data.hasOwnProperty('_HAS_PHOTO')) {
        data = {
          ...data,
          _HAS_PHOTO: false,
        };
      }

      if (!data.hasOwnProperty('REACTIONS')) {
        data = {
          ...data,
          REACTIONS: [],
        };
      }

      return data;
    },
  };
}
