import { Injectable } from '@angular/core';
import { Action, NgxsOnInit, Selector, State, StateContext } from '@ngxs/store';
import { ChatMessage } from '../models/chat-message.model';
import { ClearReplyMessage, SetReplyMessage } from './app.actions';

export interface MessageReplyStateModel {
  replyMessage: ChatMessage;
}

@State<MessageReplyStateModel>({
  name: 'messageReply',
  defaults: {
    replyMessage: null,
  },
})
@Injectable()
export class MessageReplyState implements NgxsOnInit {
  ngxsOnInit() {}

  @Selector()
  static replyMessage(state: MessageReplyStateModel): ChatMessage {
    return state.replyMessage;
  }

  @Action(SetReplyMessage)
  setReplyMessage(
    ctx: StateContext<MessageReplyStateModel>,
    action: SetReplyMessage
  ) {
    ctx.patchState({
      replyMessage: action.payload.message,
    })
  }

  @Action(ClearReplyMessage)
  clearReplyMessage(ctx: StateContext<MessageReplyStateModel>, action: ClearReplyMessage) {
    ctx.setState({
      replyMessage: null,
    })
  }
}
