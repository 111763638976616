import { Injectable } from '@angular/core';
import {
  Connected,
  Emitted,
  NgxsFirestoreConnect,
  StreamConnected,
  StreamEmitted,
} from '@ngxs-labs/firestore-plugin';
import {
  Action,
  NgxsAfterBootstrap,
  NgxsOnInit,
  Selector,
  State,
  StateContext,
  Store,
} from '@ngxs/store';
import { tap } from 'rxjs/operators';
import {
  ConflictResolution,
  ConflictStateModel,
} from '../models/conflict-resolution.model';
import { ConflictResolutionService } from '../services/conflict-resolution.service';
import {
  ClearConflicts,
  CreateConflict,
  DeleteConflict,
  FetchConflicts,
  SilentlyUpdateSelectedPost,
  UpdateConflict,
  UpdateVillageTopics,
} from './app.actions';

import { StateUtilities } from './state-utilities';
import { DiscussionState } from './discussion.state';

@State<ConflictStateModel>({
  name: 'conflicts',
  defaults: {
    conflicts: [],
  },
})
@Injectable()
export class ConflictState implements NgxsOnInit, NgxsAfterBootstrap {
  constructor(
    private conflictSvc: ConflictResolutionService,
    private ngxsFirestoreConnect: NgxsFirestoreConnect,
    private utilities: StateUtilities,
    private store: Store
  ) {}

  ngxsOnInit(ctx: StateContext<ConflictStateModel>) {
    this.ngxsFirestoreConnect.connect(FetchConflicts, {
      to: (action) =>
        this.conflictSvc.collection$((ref) =>
          ref.where(
            'CIRCLE_UIDS',
            'array-contains-any',
            action.payload.villagerCircleIds
          )
        ),
      cancelPrevious: true,
    });
  }

  ngxsAfterBootstrap(ctx: StateContext<ConflictStateModel>) {
    // ctx.dispatch();
  }

  @Selector()
  static allConflicts(state: ConflictStateModel): ConflictResolution[] {
    return state.conflicts;
  }

  @Action(FetchConflicts)
  startConflictListener(
    ctx: StateContext<ConflictStateModel>,
    action: FetchConflicts
  ) {
    const { villageUID } = action.payload;
    this.conflictSvc.setVillageId(villageUID);
  }

  @Action(StreamConnected(FetchConflicts))
  streamConnected(
    ctx: StateContext<ConflictStateModel>,
    { action }: Connected<FetchConflicts>
  ) {
    console.log('[Conflict State] Stream connected');
  }

  @Action(StreamEmitted(FetchConflicts))
  streamEmitted(
    ctx: StateContext<ConflictStateModel>,
    { action, payload }: Emitted<FetchConflicts, ConflictResolution[]>
  ) {
    const selectedChatroom = this.store.selectSnapshot(
      DiscussionState.selectedPost
    );
    if (payload && selectedChatroom) {
      const matchingPost = payload.find(
        (x) => x._UID === selectedChatroom._UID
      );
      if (matchingPost) {
        this.store.dispatch(
          new SilentlyUpdateSelectedPost({ post: matchingPost })
        );
      }
    }

    ctx.patchState({
      conflicts: payload,
    });
  }

  @Action(CreateConflict)
  createConflict(
    ctx: StateContext<ConflictStateModel>,
    action: CreateConflict
  ) {
    const { post } = action.payload;
    this.store.dispatch(new UpdateVillageTopics({ topics: post.TOPICS }));

    return this.conflictSvc.create$(post).pipe(
      tap(() => {
        console.log('[Conflict State] successfully created post');
      })
    );
  }

  @Action(ClearConflicts)
  clear(ctx: StateContext<ConflictStateModel>) {
    ctx.setState({
      conflicts: [],
    });
  }

  @Action(UpdateConflict)
  updateConflict(ctx, action: UpdateConflict) {
    const { post } = action.payload;
    const { _UID } = post;
    if (!_UID) {
      console.error('Missing Id: ', post);
      alert('Error Updating Conflict Resolution');
      return false;
    }
    this.store.dispatch(new UpdateVillageTopics({ topics: post.TOPICS }));
    return this.conflictSvc.update$(_UID, post);
  }

  @Action(DeleteConflict)
  deleteConflict(ctx, action: DeleteConflict) {
    const { post } = action.payload;
    return this.conflictSvc.delete$(post._UID);
  }
}
