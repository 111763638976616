import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { JoinedCircle } from '../models/circle.model';
import { AudioService } from '../services/audio.service';
import {
  AppToggleIsMobile,
  SetSearchTerm,
  SetSearchVisibility,
  SetStatusOffline,
  SetStatusOnline,
  ToggleAppBackground,
  AppToggleIsOffline,
  AppInitialDataLoaded,
  ClearInitialAppDataLoaded,
  AppBoardDataLoaded,
  ClearAppDataBoard,
  AppDMsLoaded,
  ClearDMsLoaded,
  ClearSearchTerm,
  AppResetLoadedStates,
  SetCircleFilter,
  AppClearPostCircles,
  AppSetPostCirclesAssigned,
  SetVillagerSearchVisibility,
  SetVillagerSearchTerm,
  SetVillagerCircleFilter,
  ClearVillagerSearchTerm,
  DmToggleInputHasPhoto,
  SetVoiceNoteUrl,
  SetVoiceNoteCurrentTime,
  VoiceNoteEnded,
  VoiceNoteIsLoading,
} from './app.actions';

export interface AppStateModel {
  background: boolean;
  searchVisibility: boolean;
  searchTerm: string;
  circleSearchFilter: string;
  villagerSearchVisibility: boolean;
  villagerSearchTerm: string;
  villagerCircleSearchId: string;
  isMobile: boolean;
  isOffline: boolean;
  initialDataLoaded: boolean;
  boardDataLoaded: boolean;
  dmsLoaded: boolean;
  feedBuilt: boolean;
  postCirclesAssigned: JoinedCircle[];
  dmInputHasPhoto: boolean;
  audioIsLoading: boolean;
  audioIsPlaying: boolean;
  voiceNoteUrl: string;
  voiceNoteCurrentTime: number;
  voiceNoteEnded: string;
}

@State<AppStateModel>({
  name: 'app',
  defaults: {
    background: false,
    searchVisibility: false,
    villagerSearchVisibility: false,
    searchTerm: null,
    villagerSearchTerm: null,
    villagerCircleSearchId: null,
    circleSearchFilter: null,
    isMobile: true,
    isOffline: false,
    initialDataLoaded: false,
    boardDataLoaded: false,
    dmsLoaded: false,
    feedBuilt: false,
    postCirclesAssigned: [],
    dmInputHasPhoto: false,
    audioIsLoading: false,
    audioIsPlaying: false,
    voiceNoteUrl: null,
    voiceNoteCurrentTime: null,
    voiceNoteEnded: null,
  },
})
@Injectable()
export class AppState {
  constructor(private audioSvc: AudioService) {}

  @Selector()
  static appBackground(state: AppStateModel): boolean {
    return state.background;
  }

  @Selector()
  static searchVisibility(state: AppStateModel): boolean {
    return state.searchVisibility;
  }

  @Selector()
  static searchTerm(state: AppStateModel): string {
    return state.searchTerm;
  }

  @Selector()
  static circleFilter(state: AppStateModel): string {
    return state.circleSearchFilter;
  }

  @Selector()
  static villagerSearchVisibility(state: AppStateModel): boolean {
    return state.villagerSearchVisibility;
  }

  @Selector()
  static villagerSearchTerm(state: AppStateModel): string {
    return state.villagerSearchTerm;
  }

  @Selector()
  static villagerCircleFilter(state: AppStateModel): string {
    return state.villagerCircleSearchId;
  }

  @Selector()
  static isMobile(state: AppStateModel): boolean {
    return state.isMobile;
  }

  @Selector()
  static isOffline(state: AppStateModel): boolean {
    return state.isOffline;
  }

  @Selector()
  static initLoad(state: AppStateModel): boolean {
    return state.initialDataLoaded;
  }

  @Selector()
  static boardLoad(state: AppStateModel): boolean {
    return state.boardDataLoaded;
  }

  @Selector()
  static dmsLoaded(state: AppStateModel): boolean {
    return state.dmsLoaded;
  }

  @Selector()
  static feedBuilt(state: AppStateModel): boolean {
    return state.feedBuilt;
  }

  @Selector()
  static postCirclesAssigned(state: AppStateModel): JoinedCircle[] {
    return state.postCirclesAssigned;
  }

  @Selector()
  static dmInputHasPhoto(state: AppStateModel): boolean {
    return state.dmInputHasPhoto;
  }

  @Selector()
  static audioIsLoading(state: AppStateModel): boolean {
    return state.audioIsLoading;
  }

  @Selector()
  static voiceNoteUrl(state: AppStateModel): string {
    return state.voiceNoteUrl;
  }

  @Selector()
  static voiceNoteCurrentTime(state: AppStateModel): number {
    return state.voiceNoteCurrentTime;
  }

  @Selector()
  static voiceNoteEnded(state: AppStateModel): string {
    return state.voiceNoteEnded;
  }

  @Action(DmToggleInputHasPhoto)
  toggleDmPhasPhoto(
    ctx: StateContext<AppStateModel>,
    action: DmToggleInputHasPhoto
  ) {
    ctx.patchState({
      dmInputHasPhoto: action.payload.hasPhoto,
    });
  }

  @Action(AppToggleIsOffline)
  toggleOffline(ctx: StateContext<AppStateModel>, action: AppToggleIsOffline) {
    ctx.patchState({
      isOffline: action.payload.isOffline,
    });
  }

  @Action(AppInitialDataLoaded)
  initialDataLoaded(ctx: StateContext<AppStateModel>) {
    ctx.patchState({
      initialDataLoaded: true,
    });
  }

  @Action(ClearInitialAppDataLoaded)
  removeDataLoaded(ctx: StateContext<AppStateModel>) {
    ctx.patchState({
      initialDataLoaded: false,
    });
  }

  @Action(AppBoardDataLoaded)
  initialBoardLoaded(ctx: StateContext<AppStateModel>) {
    ctx.patchState({
      boardDataLoaded: true,
    });
  }

  @Action(ClearAppDataBoard)
  clearBoardLoad(ctx: StateContext<AppStateModel>) {
    ctx.patchState({
      boardDataLoaded: false,
    });
  }

  @Action(AppDMsLoaded)
  dmsLoaded(ctx: StateContext<AppStateModel>) {
    ctx.patchState({
      dmsLoaded: true,
    });
  }

  @Action(ClearDMsLoaded)
  clearDmsLoaded(ctx: StateContext<AppStateModel>) {
    ctx.patchState({
      dmsLoaded: false,
    });
  }

  @Action(AppResetLoadedStates)
  resetLoadedStates(ctx: StateContext<AppStateModel>) {
    ctx.patchState({
      dmsLoaded: false,
      boardDataLoaded: false,
      initialDataLoaded: false,
    });
  }

  @Action(AppToggleIsMobile)
  toggleMobile(ctx: StateContext<AppStateModel>, action: AppToggleIsMobile) {
    ctx.patchState({
      isMobile: action.payload.isMobile,
    });
  }

  @Action(ToggleAppBackground)
  ToggleAppBackground(
    ctx: StateContext<AppStateModel>,
    action: ToggleAppBackground
  ) {
    const { background } = action.payload;

    if (background) {
      ctx.dispatch(new SetStatusOffline());
      this.audioSvc.killAnyExistingAudio();
    } else {
      console.log('[App] Setting villager to online');
      ctx.dispatch(new SetStatusOnline());
    }
    ctx.patchState({
      background,
    });
  }

  @Action(SetSearchVisibility)
  setSearchVisibility(
    ctx: StateContext<AppStateModel>,
    action: SetSearchVisibility
  ) {
    ctx.patchState({
      searchVisibility: action.payload.showSearch,
    });
  }

  @Action(SetSearchTerm)
  setSearchTerm(ctx: StateContext<AppStateModel>, action: SetSearchTerm) {
    ctx.patchState({
      searchTerm: action.payload.searchTerm,
    });
  }

  @Action(SetCircleFilter)
  setCircleFilter(ctx: StateContext<AppStateModel>, action: SetCircleFilter) {
    ctx.patchState({
      circleSearchFilter: action.payload.circleId,
    });
  }

  @Action(ClearSearchTerm)
  clearSearchTerm(ctx: StateContext<AppStateModel>, action: ClearSearchTerm) {
    ctx.patchState({
      searchTerm: null,
      circleSearchFilter: null,
    });
  }

  @Action(AppSetPostCirclesAssigned)
  assignPostCircle(
    ctx: StateContext<AppStateModel>,
    action: AppSetPostCirclesAssigned
  ) {
    ctx.patchState({
      postCirclesAssigned: action.payload.assignedCircles,
    });
  }

  @Action(AppClearPostCircles)
  clearPostAssignedCircle(ctx: StateContext<AppStateModel>) {
    ctx.patchState({
      postCirclesAssigned: [],
    });
  }

  @Action(SetVillagerSearchVisibility)
  setVillagerSearchVisibility(
    ctx: StateContext<AppStateModel>,
    action: SetVillagerSearchVisibility
  ) {
    ctx.patchState({
      villagerSearchVisibility: action.payload.showSearch,
    });
  }

  @Action(SetVillagerSearchTerm)
  setVillagerSearchTerm(
    ctx: StateContext<AppStateModel>,
    action: SetVillagerSearchTerm
  ) {
    ctx.patchState({
      villagerSearchTerm: action.payload.searchTerm,
    });
  }

  @Action(SetVillagerCircleFilter)
  setVillagerCircleFilter(
    ctx: StateContext<AppStateModel>,
    action: SetVillagerCircleFilter
  ) {
    ctx.patchState({
      villagerCircleSearchId: action.payload.circleId,
    });
  }

  @Action(ClearVillagerSearchTerm)
  clearVillagerSearchTerm(
    ctx: StateContext<AppStateModel>,
    action: ClearVillagerSearchTerm
  ) {
    ctx.patchState({
      searchTerm: null,
      circleSearchFilter: null,
    });
  }

  @Action(SetVoiceNoteUrl)
  setVoiceNoteUrl(
    ctx: StateContext<AppStateModel>,
    { payload: { voiceNoteUrl } }: SetVoiceNoteUrl
  ) {
    ctx.patchState({
      voiceNoteUrl,
      voiceNoteCurrentTime: 0,
    });
  }

  @Action(SetVoiceNoteCurrentTime)
  setVoiceNoteCurrentTime(
    ctx: StateContext<AppStateModel>,
    { payload: { currentTime } }: SetVoiceNoteCurrentTime
  ) {
    ctx.patchState({
      voiceNoteCurrentTime: currentTime,
    });
  }

  @Action(VoiceNoteEnded)
  voiceNoteEnded(
    ctx: StateContext<AppStateModel>,
    { payload: { voiceNoteUrl } }: VoiceNoteEnded
  ) {

    if(voiceNoteUrl) {
        // TODO mark voice note as listened to here
    }
    ctx.patchState({
      voiceNoteEnded: voiceNoteUrl,
    });
  }

  @Action(VoiceNoteIsLoading)
  voiceNoteIsLoading(
    ctx: StateContext<AppStateModel>,
    { payload: { loading } }: VoiceNoteIsLoading
  ) {

    ctx.patchState({
      audioIsLoading: loading,
    });
  }
}
