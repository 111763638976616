import { Component, Input, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { Store } from '@ngxs/store';
import { debounceTime, map } from 'rxjs/operators';
import { GenericPost } from 'src/app/models/post-core.model';
import { SharedList, SharedListItem } from 'src/app/models/shared-list.model';
import { SetSelectedPost, UpdateSharedList } from 'src/app/state/app.actions';
import { SharedListState } from 'src/app/state/shared-list.state';
import { VillagerState } from 'src/app/state/villager.state';

@Component({
  selector: 'app-shared-list-body',
  templateUrl: './shared-list-body.component.html',
  styleUrls: ['./shared-list-body.component.scss'],
})
export class SharedListBodyComponent implements OnInit {
  newItem: string;
  sharedList: SharedList;
  filteredItems: SharedListItem[];
  showCompleted = false;

  @Input() post: GenericPost;
  @Input() showFullBody = true;
  constructor(
    private store: Store,
    private angularFire: AngularFirestore,
    private router: Router,
    private modalCtrl: ModalController
  ) {}

  ngOnInit() {
    this.sharedList = this.post as SharedList;
    this.filterItems();
    console.log('Shared List: ', this.sharedList);

    this.store
      .select(SharedListState.getListById)
      .pipe(
        debounceTime(100),
        map((filterFn) => filterFn(this.post._UID))
      )
      .subscribe((post) => {
        if (post) {
          this.sharedList = post as SharedList;
          this.filterItems();
        }
      });
  }

  toggleCompleted() {
    this.showCompleted = !this.showCompleted;
    this.filterItems();
  }

  filterItems() {
    if (!this.showCompleted) {
      this.filteredItems = [
        ...this.sharedList.ITEMS.filter((x) => !x.COMPLETED),
      ];
    } else {
      this.filteredItems = [...this.sharedList.ITEMS];
    }
    // setTimeout(() => {
      this.filteredItems.sort(
        (a, b) => Number(b.COMPLETED) - Number(a.COMPLETED)
      );
    // }, 100);
  }

  goToDetails() {
    this.store.dispatch(
      new SetSelectedPost({ post: this.post, location: 'DETAILS' })
    );
    this.router.navigateByUrl(`pages/post-details`);
  }

  async clickBody(ev: Event) {
    const target = ev.target as HTMLElement;
    if (target?.className === 'mentionified') {
      const uid = target.getAttribute('data-mention-uid');
      if (uid) {
        const existing = await this.modalCtrl.getTop();
        if (existing) await this.modalCtrl.dismiss();
        this.router.navigateByUrl(`member/${uid}`);
      }
    }
  }

  toggleCompletion(item: SharedListItem) {
    const villagerId = this.store.selectSnapshot(VillagerState.uid);
    const idx = this.sharedList.ITEMS.findIndex(x => x.UID === item.UID)
    item = {
      ...item,
      COMPLETED: !item.COMPLETED,
      COMPLETED_BY: villagerId,
    };

    const updatedList = [...this.sharedList.ITEMS];
    updatedList[idx] = item;

    setTimeout(() => {
      this.store.dispatch(
        new UpdateSharedList({
          postId: this.sharedList._UID,
          partialPost: { ITEMS: updatedList },
        })
      );
    }, 1000);
  }

  addItem() {
    const villagerId = this.store.selectSnapshot(VillagerState.uid);
    console.log('Adding item: ', this.newItem);
    const itemToAdd: SharedListItem = {
      NAME: this.newItem,
      COMPLETED: false,
      ADDED_BY: villagerId,
      COMPLETED_BY: '',
      ARCHIVED: false,
      UID: this.angularFire.createId(),
    };

    const updatedList = [...this.sharedList.ITEMS, itemToAdd];

    this.store.dispatch(
      new UpdateSharedList({
        postId: this.sharedList._UID,
        partialPost: { ITEMS: updatedList },
      })
    );

    this.newItem = null;
  }

  deleteItem(idx: number) {
    const updatedList = [...this.sharedList.ITEMS];
    updatedList.splice(idx, 1);
    this.store.dispatch(
      new UpdateSharedList({
        postId: this.sharedList._UID,
        partialPost: { ITEMS: updatedList },
      })
    );
  }
}
function debouceTime(
  arg0: number
): import('rxjs').OperatorFunction<(listId: string) => SharedList, unknown> {
  throw new Error('Function not implemented.');
}
