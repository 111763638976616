<ion-app>
  <ion-split-pane contentId="main-content">
    <ion-menu contentId="main-content">
      <ion-header
        [translucent]="false"
        class="ion-no-border"
        *ngIf="isMobile$ | async"
        slot="start"
      >
        <ion-toolbar>
          <ion-buttons>
            <ion-menu-toggle>
              <ion-button>
                <ion-icon name="close-outline"></ion-icon>
              </ion-button>
            </ion-menu-toggle>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <ion-item
          style="
            margin-top: 50px;
            border-top: 1px solid #fff;
            padding-top: 30px;
          "
          lines="none"
          *ngIf="villageName$ | async as villageName"
        >
          <ion-avatar *ngIf="thumbnailUrl$ | async as url" slot="start">
            <img [src]="url" />
          </ion-avatar>
          <ion-label
            style="font-weight: 600; font-size: 1.2rem; margin-left: 10px"
            >{{ villageName }}</ion-label
          >
        </ion-item>
        <!--  -->
        <ion-button
          *ngIf="(videoOverviewSeen$ | async) === false"
          expand="block"
          color="light"
          (click)="goToVideoOnboarding()"
          style="margin: 20px 10px;"
        >
          <ion-icon slot="start" name="sparkles-outline"></ion-icon>
          <ion-label>Get Start here!</ion-label>
        </ion-button>
        <ion-list lines="none" style="margin-top: 10px">
          <!-- <ion-list-header> {{ appName }} </ion-list-header> -->

          <ion-menu-toggle
            autoHide="false"
            *ngFor="let p of villagePages; let i = index"
          >
            <ion-item
              [routerLink]="p.url"
              routerLinkActive="selected"
              routerDirection="root"
              detail="false"
            >
              <ion-icon slot="start" [name]="p.icon + '-outline'"></ion-icon>
              <ion-label>
                {{ p.title }}
              </ion-label>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>
        <div style="margin-top: 20px">
          <app-switch-village-header-button
            *ngIf="(showSwitchVillage$ | async) === true"
            [header]="true"
            [color]="'dark'"
            [showLabel]="false"
            [sideMenu]="true"
          ></app-switch-village-header-button>
        </div>
        <ion-list
          style="margin-top: 50px; border-top: 1px solid #fff"
          lines="none"
        >
          <ion-menu-toggle
            autoHide="false"
            *ngFor="let p of memberPages; let i = index"
          >
            <ion-item
              [routerLink]="p.url"
              routerLinkActive="selected"
              routerDirection="root"
              detail="false"
            >
              <ion-icon slot="start" [name]="p.icon + '-outline'"></ion-icon>
              <ion-label>
                {{ p.title }}
              </ion-label>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>
      </ion-content>
      <ion-footer style="margin-bottom: 10px; border-top: 1px solid #fff">
        <ion-item lines="none">
          <ion-icon slot="start" name="revillager"></ion-icon>
          <ion-label style="font-size: 0.9em"
            >Connected by reVillager</ion-label
          >
        </ion-item>
      </ion-footer>
    </ion-menu>

    <ion-router-outlet id="main-content"></ion-router-outlet>
  </ion-split-pane>
</ion-app>
