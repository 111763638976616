import { AngularFireAuth } from '@angular/fire/auth';
import { State, Selector, Action, StateContext, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { AuthStateModel } from '../models/auth.model';
import { from } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import {
  ClearAllPosts,
  ClearShares,
  ClearConflicts,
  ClearExchanges,
  ClearHangouts,
  ClearSupportRequests,
  ClearVillage,
  ClearVillager,
  FetchShares,
  FetchChatroomMessages,
  FetchConflicts,
  FetchDirectChatroomMessages,
  FetchDirectChatrooms,
  FetchExchanges,
  FetchHangouts,
  FetchSupportRequests,
  FetchVillagerNotifications,
  GetFellowVillagers,
  GetVillage,
  GetVillager,
  Login,
  Logout,
  Signup,
  ClearAnnouncements,
  FetchAnnouncements,
  FetchSharedLists,
  ClearSharedList,
  ClearInitialAppDataLoaded,
  ClearAppDataBoard,
  ClearDMsLoaded,
} from './app.actions';
import { AlertController } from '@ionic/angular';
import { Disconnect } from '@ngxs-labs/firestore-plugin';
import { CourtyardActions } from './courtyard.actions';
import { CircleActions } from './circle.actions';

@State<AuthStateModel>({
  name: 'auth',
  defaults: {
    email: null,
    uid: null,
  },
})
@Injectable()
export class AuthState {
  constructor(
    private auth: AngularFireAuth,
    private router: Router,
    private store: Store,
    private alertCtrl: AlertController
  ) {}

  @Selector()
  static authUser(state: AuthStateModel): AuthStateModel {
    return state;
  }

  @Selector()
  static uid(state: AuthStateModel): string {
    return state.uid;
  }

  @Selector()
  static isAuthenticated(state: AuthStateModel): boolean {
    // TODO connect this this.auth.user (which is an observable. need to flatMap?)
    return !!state.uid;
  }

  @Action(Login)
  login(ctx: StateContext<AuthStateModel>, action: Login) {
    return from(
      this.auth.signInWithEmailAndPassword(
        action.payload.email,
        action.payload.password
      )
    ).pipe(
      tap((result) => {
        console.log('[Auth State] Login result: ', result);
        const { email, uid } = result.user;
        ctx.patchState({
          email,
          uid,
        });
        this.store.dispatch(new GetVillager({ uid }));
      })
    );
  }

  @Action(Logout)
  logout(ctx: StateContext<AuthStateModel>) {
    this.store.dispatch([
      // TODO complete pause of notifications
      // new RemoveFCMToken(),
      new Disconnect(GetVillage),
      new Disconnect(GetVillager),
      new Disconnect(GetFellowVillagers),
      new Disconnect(FetchShares),
      new Disconnect(FetchHangouts),
      new Disconnect(FetchAnnouncements),
      new Disconnect(FetchSupportRequests),
      new Disconnect(FetchExchanges),
      new Disconnect(FetchConflicts),
      new Disconnect(FetchSharedLists),
      new Disconnect(FetchDirectChatrooms),
      new Disconnect(FetchDirectChatroomMessages),
      new Disconnect(FetchVillagerNotifications),
      new Disconnect(FetchChatroomMessages),
      new Disconnect(CourtyardActions.FetchAll),
      // new Disconnect(CourtyardActions.FetchMessages),
      new Disconnect(CircleActions.FetchAll),
    ]);

    this.store
      .dispatch([
        new ClearSupportRequests(),
        new ClearHangouts(),
        new ClearShares(),
        new ClearExchanges(),
        new ClearConflicts(),
        new ClearSharedList(),
        new ClearAnnouncements(),
        new ClearAllPosts(),
        new ClearVillage(),
        new ClearVillager(),
        new ClearInitialAppDataLoaded(),
        new ClearAppDataBoard(),
        new ClearDMsLoaded(),
        new CourtyardActions.Clear(),
        new CircleActions.Clear(),
      ])
      .subscribe(
        () => {
          ctx.setState({
            email: null,
            uid: null,
          });
          this.store.dispatch(new ClearAllPosts());
          this.auth.signOut();
          this.router.navigateByUrl('/login');
        },
        (err) => {
          console.error(
            'Error logging villager out. Handle error and let user know'
          );
        }
      );
  }

  @Action(Signup)
  signUp(ctx: StateContext<AuthStateModel>, action: Signup) {
    ctx.setState({
      uid: action.payload.uid,
      email: action.payload.email,
    });
  }

  async showErrorAlert() {
    const alert = await this.alertCtrl.create({
      header: 'Error',
      message: `It looks like your email is already in use. Please try a different email address.`,
      buttons: ['OK'],
    });

    await alert.present();
  }
}
