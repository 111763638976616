import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AlertController, IonContent, ModalController } from '@ionic/angular';
import { Actions, ofActionCompleted, Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { GenericPost } from 'src/app/models/post-core.model';
import { VillagerState } from 'src/app/state/villager.state';
import {
  DisconnectDiscussionChat,
  FetchChatroomMessages,
  RefreshChatroomMessages,
  RemoveDiscussionParticipant,
  SendVillageMessage,
} from 'src/app/state/app.actions';
import {
  slideInDownOnEnterAnimation,
  slideOutUpOnLeaveAnimation,
  rotateInOnEnterAnimation,
} from 'angular-animations';
import { DiscussionState } from 'src/app/state/discussion.state';
import { ChatMessage } from 'src/app/models/chat-message.model';
import { AnalyticsService } from 'src/app/analytics.service';
import { first, map, skipWhile, take } from 'rxjs/operators';

@Component({
  selector: 'app-village-chatroom-modal',
  templateUrl: './village-chatroom-modal.page.html',
  styleUrls: ['./village-chatroom-modal.page.scss'],
  animations: [
    slideInDownOnEnterAnimation(),
    slideOutUpOnLeaveAnimation(),
    rotateInOnEnterAnimation(),
  ],
})
export class VillageChatroomModalPage implements OnInit {
  autoFocus = false;
  placeholderText = `Share a message with your village..`;
  showInfo = true;
  sending = false;
  postSub: Subscription;

  isCurrentVillager$: Observable<boolean>;
  villagerId: string;
  viewLoaded = false;
  newMessageHasImages = false;
  scrollTimeout: any;

  @Select(DiscussionState.selectedPost) post$: Observable<GenericPost>;
  @Select(DiscussionState.selectedPostChatroomMessages)
  messages$: Observable<ChatMessage[]>;

  @Input() post: GenericPost;
  @ViewChild('content') content: IonContent;

  constructor(
    private modalCtrl: ModalController,
    public alertCtrl: AlertController,
    public store: Store,
    private actions$: Actions,
    public analytics: AnalyticsService
  ) {}

  ngOnInit() {
    this.villagerId = this.store.selectSnapshot(VillagerState.uid);

    // only autoscroll to the bottom after the first value
    this.post$
      .pipe(
        skipWhile((post) => post === undefined),
        first()
      )
      .subscribe((post) => {
        if (post) {
          this.isCurrentVillager$ = this.store
            .select(VillagerState.isCurrentVillager)
            .pipe(map((filterFn) => filterFn(post._CREATOR_UID)));
        }
        this.scrollToBottom();
      });

    this.actions$
      .pipe(ofActionCompleted(SendVillageMessage, FetchChatroomMessages))
      .subscribe(() => {
        this.scrollToBottom();
      });
  }

  ionViewDidEnter() {
    this.viewLoaded = true;
  }

  leaveDiscussion() {
    // this.showLeaveAlert();
    this.store.dispatch(
      new RemoveDiscussionParticipant({
        villagerId: this.villagerId,
        post: this.post,
      })
    );
    this.analytics.logEvent('discussion_leave_success', {});
    this.close();
  }

  refreshChatMessages() {
    this.store.dispatch(new RefreshChatroomMessages());
  }

  scrollToBottom() {
    if (this.scrollTimeout) {
      clearTimeout(this.scrollTimeout);
    }
    this.scrollTimeout = setTimeout(() => {
      if (this.content) this.content.scrollToBottom(300);
    }, 200);
  }

  onImageChange(hasPhotos: boolean) {
    console.log('Parent has image change: ', hasPhotos);
    this.newMessageHasImages = hasPhotos;
  }

  onLeave() {
    this.close();
  }

  close() {
    this.showInfo = false;
    if (this.postSub) {
      this.postSub.unsubscribe();
    }
    this.store.dispatch(new DisconnectDiscussionChat());
    this.modalCtrl.dismiss();
  }

  trackByFn(idx: number, message: ChatMessage) {
    return message._UID;
  }

  toggleInfo() {
    this.showInfo = !this.showInfo;
  }
}
