import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import {
  ModalController,
  ToastController,
  AlertController,
  LoadingController,
} from '@ionic/angular';
import { Select, Store } from '@ngxs/store';
import * as firebase from 'firebase';

import { Observable } from 'rxjs';
import { AnalyticsService } from 'src/app/analytics.service';
import { Announcement } from 'src/app/models/announcement.model';
import { JoinedCircle } from 'src/app/models/circle.model';
import { RecipientNotificationSettings } from 'src/app/models/notification-settings';
import { StoredDocument } from 'src/app/models/post-core.model';
import { ReadReceipt } from 'src/app/models/read-receipt.model';
import { EMOTIONS } from 'src/app/models/support-request.model';
import { Villager } from 'src/app/models/villager.model';
import { CreatePostHelpersService } from 'src/app/services/create-post-helpers.service';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { PostFileUploadComponent } from 'src/app/shared/post-file-upload/post-file-upload.component';
import { PostMentionableInputComponent } from 'src/app/shared/post-mentionable-input/post-mentionable-input.component';
import {
  UpdateAnnouncement,
  CreateAnnouncement,
} from 'src/app/state/app.actions';
import { AppState } from 'src/app/state/app.state';
import { VillageState } from 'src/app/state/village.state';
import { VillagerActions } from 'src/app/state/villager.actions';
import { VillagerState } from 'src/app/state/villager.state';

@Component({
  selector: 'app-create-announcement-modal',
  templateUrl: './create-announcement-modal.page.html',
  styleUrls: ['./create-announcement-modal.page.scss'],
})
export class CreateAnnouncementModalPage implements OnInit, AfterViewInit {
  announcementForm = new FormGroup({
    title: new FormControl('', [Validators.required]),
  });
  creator: Villager;
  loading: any;
  emotions = EMOTIONS;
  selectedEmotion = null;
  component = 'create-announcement-modal';
  placeholderText = 'Share your announcement...';

  showFeeling = false;
  showPhotos = false;
  showTopics = false;

  // for existing files on post
  tmpImageUrls: string[] = [];
  tmpDocUrls: StoredDocument[] = [];

  topics: string[] = [];
  assignedCircles: JoinedCircle[] = [];

  mentionables: string[] = [];

  get title() {
    return this.announcementForm.get('title');
  }

  @Select(VillageState.showKarma) showKarma$: Observable<boolean>;
  @Select(VillageState.villageName) villageName$: Observable<string>;
  @Select(AppState.postCirclesAssigned) postCirclesAssigned$: Observable<
    JoinedCircle[]
  >;

  @Input() village: string;
  @Input() edit = false;
  @Input() announcement: Announcement;
  @Input() description?: string;

  @ViewChild(PostMentionableInputComponent)
  mentionInput: PostMentionableInputComponent;
  @ViewChild(PostFileUploadComponent) fileUpload: PostFileUploadComponent;
  constructor(
    public modalCtrl: ModalController,
    private store: Store,
    private toastCtrl: ToastController,
    private alertCtrl: AlertController,
    private loadingCtrl: LoadingController,
    private utils: UtilitiesService,
    private analytics: AnalyticsService,
    private helpers: CreatePostHelpersService
  ) {}

  ngAfterViewInit(): void {
    if (this.edit === true) {
      console.log('edit post: ', this.announcement._UID);
      this.mentionInput.setBody(this.utils.removeExistingTags(this.announcement.BODY), this.announcement._MENTION_UIDS);
    } else {
      if (this.description) {
        this.mentionInput.setBody(this.utils.removeExistingTags(this.description), []);
      }
    }
  }

  ngOnInit() {
    this.postCirclesAssigned$.subscribe((assignedCircles: JoinedCircle[]) => {
      this.assignedCircles = assignedCircles;
    });

    if (this.edit === true) {
      console.log('edit is true');
      if (this.announcement.TITLE) this.title.setValue(this.announcement.TITLE);
      if (this.announcement.FEELING)
        this.selectedEmotion = this.announcement.FEELING;
      this.tmpImageUrls = [...this.announcement.IMAGE_PATHS];
      this.tmpDocUrls = [...this.announcement.DOCUMENT_PATHS];
      this.assignedCircles = [...this.announcement.CIRCLES];
    }

    this.loadMentionables();
  }

  onSetTopics(topics: string[]) {
    this.topics = topics;
  }

  handleDeletePhoto(event, index: number) {
    this.tmpImageUrls.splice(index, 1);
  }

  handleDeleteDocument(index: number) {
    this.tmpDocUrls.splice(index, 1);
  }

  async createAnnouncement() {
    const { BODY } = this.mentionInput.parseMentionsAndBody();
    if (!BODY || BODY.trim().length === 0) {
      this.missingDataError(
        'Missing description. Please add a description and try again.'
      );
    } else {
      try {
        let photoPaths = [];
        let documentPaths = [];
        if (this.fileUpload) {
          photoPaths = await this.fileUpload.uploadPhotos();
          documentPaths = await this.fileUpload.uploadDocuments();
        }
        this.saveAnnouncement(photoPaths, documentPaths);
      } catch (e) {
        this.analytics.logEvent('announcement_create_error', {
          error: e,
        });
        alert('Error creating post');
      }
    }
  }

  async updateAnnouncement() {
    await this.presentLoading();
    this.update();
  }

  async update() {
    await this.presentLoading();
    this.analytics.logEvent('announcement_update', {
      announcementId: this.announcement._UID,
    });
    let newPhotoPaths = [];
    let newDocumentPaths: StoredDocument[] = [];
    try {
      if (this.fileUpload) {
        newPhotoPaths = await this.fileUpload.uploadPhotos();
        newDocumentPaths = await this.fileUpload.uploadDocuments();
      }
    } catch (e) {
      alert('Error updating photos & documents');
      this.analytics.logError('error_updating_post_files', this.component, e);
    }
    let allImages = [...this.tmpImageUrls];
    let allDocuments = [...this.tmpDocUrls];
    if (newPhotoPaths.length > 0) {
      allImages = this.tmpImageUrls.concat(newPhotoPaths);
    }
    if (newDocumentPaths.length > 0) {
      allDocuments = this.tmpDocUrls.concat(newDocumentPaths);
    }

    const CIRCLE_UIDS = this.assignedCircles.map((x) => x.UID);

    const villageId = this.store.selectSnapshot(
      VillagerState.currentVillager
    ).VILLAGE;
    const fellowVillagers = this.store.selectSnapshot(
      VillagerState.fellowVillagers
    );
    const RECIPIENT_NOTIFICATION_SETTINGS: RecipientNotificationSettings =
      this.helpers.getNotificationRecipientsForCircle(CIRCLE_UIDS);

    let announcement: Announcement = {
      ...this.announcement,
      TITLE: this.title.value,
      FEELING: this.selectedEmotion,
      IMAGE_PATHS: allImages,
      DOCUMENT_PATHS: allDocuments,
      TOPICS: this.topics,
      RECIPIENT_NOTIFICATION_SETTINGS,
      CIRCLES: this.assignedCircles,
      CIRCLE_UIDS,
    };

    const { _MENTION_UIDS, BODY } = this.mentionInput.parseMentionsAndBody();
    announcement._MENTION_UIDS = _MENTION_UIDS;
    announcement.BODY = this.utils.parseLinksFromText(BODY);

    this.store.dispatch(new UpdateAnnouncement({ announcement })).subscribe(
      () => {
        this.analytics.logEvent('announcement_update_success', {
          userId: this.store.selectSnapshot(VillagerState.uid),
          villageId: this.store.selectSnapshot(VillageState.uid),
        });
        this.presentToast('Successfully updated announcement.');
        this.announcementForm.reset();
        this.modalCtrl.dismiss({ action: null });
        this.loading.dismiss();
        this.loading = null;
      },
      (err) => {
        this.analytics.logEvent('announcement_update_error', {
          userId: this.store.selectSnapshot(VillagerState.uid),
          villageId: this.store.selectSnapshot(VillageState.uid),
          announcementId: this.announcement._UID,
          error: err,
        });
        console.error(err);
        this.showErrorAlert();
        this.loading.dismiss();
        this.loading = null;
      }
    );
  }

  async saveAnnouncement(
    photoPaths: string[],
    documentPaths: StoredDocument[]
  ) {
    this.analytics.logEvent('announcement_save_new', {});

    this.presentLoading();
    const villagerId = this.store.selectSnapshot(VillagerState.uid);
    const firstName = this.store.selectSnapshot(VillagerState.firstName);
    const lastName = this.store.selectSnapshot(VillagerState.lastName);
    const allVillageVillagersUids = this.store
      .selectSnapshot(VillagerState.allVillagers)
      .map((x) => x._UID);
    const emptyReadReceipts: ReadReceipt[] = allVillageVillagersUids.map(
      (UID) => ({ UID, LAST_READ_MESSAGE_COUNT: 0 })
    );
    const CIRCLE_UIDS = this.assignedCircles.map((x) => x.UID);
    const RECIPIENT_NOTIFICATION_SETTINGS: RecipientNotificationSettings =
      this.helpers.getNotificationRecipientsForCircle(CIRCLE_UIDS);

    const announcement: Announcement = {
      _CREATOR_UID: villagerId,
      _CREATOR_FIRST_NAME: firstName,
      _CREATOR_LAST_NAME: lastName,
      _SERVER_TIMESTAMP:
        firebase.default.firestore.FieldValue.serverTimestamp(),
      _CREATED_AT: new Date(),
      _TYPE: 'ANNOUNCEMENT',
      TITLE: this.title.value,
      BODY: null,
      FEELING: this.selectedEmotion,
      REPORTED_BY: [],
      PARTICIPANT_UIDS: allVillageVillagersUids,
      PARTICIPANT_READ_RECEIPTS: emptyReadReceipts,
      RECIPIENT_NOTIFICATION_SETTINGS,
      CELEBRATIONS: [],
      HEARINGS: [],
      MESSAGE_COUNT: 0,
      UPDATED_AT: firebase.default.firestore.FieldValue.serverTimestamp(),
      IMAGE_PATHS: photoPaths,
      DOCUMENT_PATHS: documentPaths,
      TOPICS: this.topics,
      ARCHIVED: false,
      CIRCLES: this.assignedCircles,
      CIRCLE_UIDS,
      _MENTION_UIDS: []
    };

    const { _MENTION_UIDS, BODY } = this.mentionInput.parseMentionsAndBody();
    announcement._MENTION_UIDS = _MENTION_UIDS;
    announcement.BODY = this.utils.parseLinksFromText(BODY);

    this.store.dispatch(new CreateAnnouncement({ announcement })).subscribe(
      (result) => {
        this.store.dispatch(new VillagerActions.MarkOnboardedTaskComplete({ villagerId, field: "HAS_CREATED_POST" }))
        this.analytics.logEvent('announcement_save_new_success', {
          userId: this.store.selectSnapshot(VillagerState.uid),
          villageId: this.store.selectSnapshot(VillageState.uid),
        });
        this.presentToast('Successfully created announcement.');
        this.announcementForm.reset();
        this.modalCtrl.dismiss({ action: 'create-close' });
        this.loading.dismiss();
        this.loading = null;
      },
      (err) => {
        this.analytics.logEvent('announcement_save_new_error', {
          userId: this.store.selectSnapshot(VillagerState.uid),
          villageId: this.store.selectSnapshot(VillageState.uid),
          error: err,
        });
        console.error(err);
        this.showErrorAlert();
        this.loading.dismiss();
        this.loading = null;
      }
    );
  }

  selectEmotion(event: { emotion: string; icon: string }) {
    if (!this.selectedEmotion) {
      this.selectedEmotion = event.emotion;
    } else if (this.selectedEmotion === event.emotion) {
      this.selectedEmotion = null;
    } else {
      this.selectedEmotion = event.emotion;
    }
  }

  async presentToast(message: string) {
    const toast = await this.toastCtrl.create({
      header: message,
      message: `You posted the announcement!`,
      color: 'primary',
      duration: 3000,
      position: 'top',
    });
    toast.present();
  }

  async presentLoading() {
    if (!this.loading) {
      this.loading = await this.loadingCtrl.create({
        duration: 15000,
        backdropDismiss: true,
      });
      return this.loading.present();
    }
  }

  async showErrorAlert() {
    const alert = await this.alertCtrl.create({
      header: 'Error',
      message:
        'We ran into an error creating the post. Please check that everything is filled out and try again.',
      buttons: ['OK'],
    });

    await alert.present();
  }

  async missingDataError(message: string) {
    const alert = await this.alertCtrl.create({
      header: 'Error',
      message,
      buttons: ['OK'],
    });

    await alert.present();
  }

  async loadMentionables() {
    let tmpMentionables = [];

    const allUnblockedVillagers = this.store.selectSnapshot(
      VillagerState.allUnblockedVillagersExcludeCurrent
    );
    tmpMentionables = [
      ...allUnblockedVillagers.map((villager) => villager._UID),
    ];

    this.mentionables = [...new Set(tmpMentionables)];
    console.log('[POST BODY INPUT] Mentionables: ', this.mentionables);
  }
}
