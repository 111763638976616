import { Injectable } from '@angular/core';
import {
  Connected,
  Emitted,
  NgxsFirestoreConnect,
  StreamConnected,
  StreamEmitted,
} from '@ngxs-labs/firestore-plugin';
import {
  Action,
  NgxsAfterBootstrap,
  NgxsOnInit,
  Selector,
  State,
  StateContext,
  Store,
} from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { ExchangeItem, ExchangeStateModel } from '../models/exchange.model';
import { ExchangeService } from '../services/exchange.service';
import {
  ClearExchanges,
  CreateExchange,
  DeleteExchange,
  FetchExchanges,
  MarkExchangeAsInterested,
  MarkExchangeAsNotInterested,
  SilentlyUpdateSelectedPost,
  UpdateExchange,
  UpdateVillageTopics,
} from './app.actions';
import { StateUtilities } from './state-utilities';
import { DiscussionState } from './discussion.state';

@State<ExchangeStateModel>({
  name: 'exchanges',
  defaults: {
    exchangeItems: [],
  },
})
@Injectable()
export class ExchangeState implements NgxsOnInit, NgxsAfterBootstrap {
  constructor(
    private exchangeSvc: ExchangeService,
    private ngxsFirestoreConnect: NgxsFirestoreConnect,
    private utilities: StateUtilities,
    private store: Store
  ) {}

  ngxsOnInit() {
    this.ngxsFirestoreConnect.connect(FetchExchanges, {
      to: (action) =>
        this.exchangeSvc.collection$((ref) =>
          ref.where(
            'CIRCLE_UIDS',
            'array-contains-any',
            action.payload.villagerCircleIds
          )
        ),
      cancelPrevious: true,
    });
  }

  ngxsAfterBootstrap(ctx: StateContext<ExchangeStateModel>) {}

  @Selector()
  static allExchangeItems(state: ExchangeStateModel): ExchangeItem[] {
    return state.exchangeItems;
  }

  @Action(FetchExchanges)
  startExchangeListener(
    ctx: StateContext<ExchangeStateModel>,
    action: FetchExchanges
  ) {
    this.exchangeSvc.setVillageId(action.payload.villageUID);
  }

  // @Action(StreamConnected(FetchExchanges))
  // streamConnected(
  //   ctx: StateContext<ExchangeStateModel>,
  //   { action }: Connected<FetchExchanges>
  // ) {
  //   console.log('[Exchange State] Stream connected');
  // }

  @Action(StreamEmitted(FetchExchanges))
  streamEmitted(
    ctx: StateContext<ExchangeStateModel>,
    { action, payload }: Emitted<FetchExchanges, ExchangeItem[]>
  ) {
    const selectedChatroom = this.store.selectSnapshot(
      DiscussionState.selectedPost
    );
    if (payload && selectedChatroom) {
      const matchingPost = payload.find(
        (x) => x._UID === selectedChatroom._UID
      );
      if (matchingPost) {
        this.store.dispatch(
          new SilentlyUpdateSelectedPost({ post: matchingPost })
        );
      }
    }

    ctx.patchState({
      exchangeItems: payload,
    });
  }

  @Action(ClearExchanges)
  clear(ctx: StateContext<ExchangeStateModel>) {
    ctx.setState({
      exchangeItems: [],
    });
  }

  @Action(CreateExchange)
  createExchangeItem(
    ctx: StateContext<ExchangeStateModel>,
    action: CreateExchange
  ) {
    const { exchangeItem } = action.payload;
    this.store.dispatch(
      new UpdateVillageTopics({ topics: exchangeItem.TOPICS })
    );

    return this.exchangeSvc.create$(exchangeItem).pipe(
      tap(() => {
        console.log('[Chatroom State] successfully created exchange item');
      })
    );
  }

  @Action(UpdateExchange)
  updateExchange(ctx, action: UpdateExchange) {
    const { post } = action.payload;
    const { _UID } = post;
    if (!_UID) {
      console.error('Missing Id: ', post);
      alert('Error Updating Exchange');
      return false;
    }
    this.store.dispatch(new UpdateVillageTopics({ topics: post.TOPICS }));
    return this.exchangeSvc.update$(_UID, post);
  }

  @Action(DeleteExchange)
  deleteExchange(ctx, action: DeleteExchange) {
    const { post } = action.payload;
    return this.exchangeSvc.delete$(post._UID);
  }

  @Action(MarkExchangeAsInterested)
  async markAsGoing(ctx: StateContext<any>, action: MarkExchangeAsInterested) {
    const { post, villagerUid, villagerName } = action.payload;
    const existingInterests = [...post.INTERESTS];

    console.log(`Adding ${villagerUid} as interested: ${existingInterests}`);

    let newInterests = [...existingInterests];
    newInterests.push({
      UID: villagerUid,
      NAME: villagerName,
    });
    newInterests = [...new Set(newInterests)];

    if (newInterests.length > existingInterests.length) {
      await this.utilities.updateParticipants(post, villagerUid);

      return this.exchangeSvc.updateWithoutConverter(post._UID, {
        INTERESTS: newInterests,
      });
    } else {
      console.log(
        'Not updating exchange doc, current user already participant in this post'
      );
      return null;
    }
  }

  @Action(MarkExchangeAsNotInterested)
  markAsNotGoing(ctx: StateContext<any>, action: MarkExchangeAsNotInterested) {
    const { post, villagerUid } = action.payload;

    const interests = [...post.INTERESTS];
    console.log(`Remove going ${villagerUid} from interested: ${interests}`);
    const idx = interests.findIndex((x) => x.UID === villagerUid);
    if (idx > -1) {
      interests.splice(idx, 1);

      return this.exchangeSvc.updateWithoutConverter(post._UID, {
        INTERESTS: interests,
      });
    } else {
      return null;
    }
  }
}
