import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { AnalyticsService } from 'src/app/analytics.service';
import { ChatMessage } from 'src/app/models/chat-message.model';
import { ImageService } from 'src/app/services/images.service';
import { SetReplyMessage } from 'src/app/state/app.actions';
import { ChatMessageOptionsModalPage } from '../chat-message-options-modal/chat-message-options-modal.page';
import { EmojiMartModalPage } from '../emoji-mart-modal/emoji-mart-modal.page';

@Component({
  selector: 'app-post-view-images-modal',
  templateUrl: './post-view-images-modal.page.html',
  styleUrls: ['./post-view-images-modal.page.scss'],
})
export class PostViewImagesModalPage implements OnInit {
  path$: Observable<string>;
  @Input() imagePath: string;
  @Input() message: ChatMessage;
  @Input() type: 'post' | 'dm' | 'courtyard';

  constructor(
    public modalCtrl: ModalController,
    private imageSvc: ImageService,
    private analytics: AnalyticsService,
    private store: Store,
  ) {}

  ngOnInit() {
    if (this.imagePath) {
      console.log('[Post Image Modal] path to view: ', this.imagePath);
      this.path$ = this.imageSvc.getPostPhoto(this.imagePath, '1080');
    }
  }

  close() {
    this.analytics.logEvent('post_view_images_close_clicked', {});
    this.modalCtrl.dismiss();
  }

  async showMessageOptions() {
    if (!this.message.DELETED) {
      const modal = await this.modalCtrl.create({
        component: ChatMessageOptionsModalPage,
        initialBreakpoint: 0.5,
        breakpoints: [0, 0.25, 0.5, 0.75],
        componentProps: {
          message: this.message,
          type: this.type,
        },
      });
      return await modal.present();
    }
  }

  async openEmojiMart() {
    // const existing = await this.modalCtrl.getTop();
    // if (existing) this.modalCtrl.dismiss();

    const modal = await this.modalCtrl.create({
      component: EmojiMartModalPage,
      initialBreakpoint: 0.5,
      breakpoints: [0, 0.5, 1],
      componentProps: {
        message: this.message,
        type: this.type,
      },
    });
    return await modal.present();
  }

  async reply() {
    const existing = await this.modalCtrl.getTop();
    if (existing) this.modalCtrl.dismiss();
    this.store.dispatch(new SetReplyMessage({message: this.message}))
  }
}
