import { VillagerState } from './../../state/villager.state';
import {
  AlertController,
  LoadingController,
  ModalController,
  NavController,
  Platform,
} from '@ionic/angular';
import { AngularFireAuth } from '@angular/fire/auth';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import { VillageType } from 'src/app/models/village.model';
import {
  Signup,
  GetVillager,
  SwitchVillage,
} from 'src/app/state/app.actions';
import { AngularFireFunctions } from '@angular/fire/functions';
import { Router } from '@angular/router';
import { NotificationPermissionModalPage } from 'src/app/modals/notification-permission-modal/notification-permission-modal.page';
import { AuthState } from 'src/app/state/auth.state';
import { Observable } from 'rxjs';
import { JoinedCircle } from 'src/app/models/circle.model';
import packageInfo from '../../../../package.json';

@Component({
  selector: 'app-create-join-village',
  templateUrl: './create-join-village.component.html',
  styleUrls: ['./create-join-village.component.scss'],
})
export class CreateJoinVillageComponent implements OnInit {
  villageCreateForm = new FormGroup({
    vision: new FormControl(''),
    values: new FormControl(''),
    name: new FormControl('', Validators.required),
  });

  inviteForm = new FormGroup({
    inviteCode: new FormControl(''),
  });

  loading: HTMLIonLoadingElement;
  saving = false;
  signupStepOne = true;
  signupStepTwo = false;
  signupStepThree = false;
  showVillageTypeOptions = false;
  showVillageForm = false;
  showInviteForm = false;
  villageType: VillageType;
  villageTypes: VillageType[] = [
    {
      name: 'Eco-Village',
      value: 'ECO_VILLAGE',
      description: 'Village practicing regenerative living',
    },
    {
      name: 'Intentional Community',
      value: 'INTENTIONAL_COMMUNITY',
      description: 'Village of people living together with shared values',
    },
    {
      name: 'Co-Housing',
      value: 'CO_HOUSING',
      description: 'Village based in a multi-dwelling building',
    },
    {
      name: 'Community Organization',
      value: 'COMMUNITY_ORGANIZATION',
      description: 'Village based around an organization',
    },
    {
      name: 'Neighborhood',
      value: 'NEIGHBORHOOD',
      description: 'Village formed around a city neighborhood structure',
    },
    {
      name: 'Parent Group',
      value: 'PARENT_GROUP',
      description: 'Group of parents supporting each other',
    },
    {
      name: 'Friends & Family',
      value: 'FRIENDS_AND_FAMILY',
      description: 'Group of family & friends who get together in person',
    },
    {
      name: 'Other',
      value: 'OTHER',
      description:
        'Choose this if none of the options above describe your village',
    },
  ];

  get vision() {
    return this.villageCreateForm.get('vision');
  }

  get values() {
    return this.villageCreateForm.get('values');
  }

  get name() {
    return this.villageCreateForm.get('name');
  }

  get inviteCode() {
    return this.inviteForm.get('inviteCode');
  }

  @Select(VillagerState.currentVillage) currentVillage$: Observable<string>;

  @Input() signup = false;
  @Input() signupData: {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    circles: JoinedCircle[];
    inviteCode: string;
  };
  @Input() startAt: 'stepTwo' | 'stepThree' = 'stepTwo';
  @Output() goBack = new EventEmitter();

  constructor(
    private store: Store,
    private authSvc: AngularFireAuth,
    private fns: AngularFireFunctions,
    private alertCtrl: AlertController,
    private modalCtrl: ModalController,
    private platform: Platform,
    private navCtrl: NavController,
    private router: Router,
    private loadingCtrl: LoadingController
  ) {}

  ngOnInit() {
    //if there is an invite code, skip directly to joinOtherVillage() and don't forget to add circles
    if(this.signupData && this.signupData.inviteCode !== '') {
      this.inviteCode.setValue(this.signupData.inviteCode)
      this.joinOtherVillage()
    } else {
      switch (this.startAt) {
        case 'stepTwo':
          this.signupStepTwo = true;
          this.showVillageTypeOptions = false;
          break;
        case 'stepThree':
          this.signupStepTwo = false;
          this.showVillageTypeOptions = true;
          break;
        default:
          this.signupStepTwo = false;
          this.showVillageTypeOptions = false;
      }
    }
    // is this is part of the signup flow, then do anything needed here
    // if (this.signup) {}
  }

  async joinOtherVillage() {
    this.saving = true;

    const existing = await this.loadingCtrl.getTop();
    if (existing) existing.dismiss();
    if (!this.loading) {
      this.loading = await this.loadingCtrl.create({
        duration: 30000,
        message: 'Joining village...',
        backdropDismiss: true,
      });
      await this.loading.present();
    }
    console.log('Joining village with invite code: ', this.inviteCode.value);

    // create auth user
    try {
      const villagerId = await this.fetchOrCreateAuthUser();

      // only create villager if in signup flow
      if (this.signup) {
        try {
          await this.fns
            .httpsCallable('villager-createMember')({
              villagerId,
              firstName: this.signupData.firstName,
              lastName: this.signupData.lastName,
              email: this.signupData.email,
              appVersion: packageInfo.version || 'DNE'
            })
            .toPromise();
        } catch (e) {
          console.error('Error creating villager: ', e);
        }
      }

      // create villager & lookup to join village server side
      try {
        const { villageId } = await this.fns
          .httpsCallable('village-joinVillageByInviteCode')({
            inviteCode: this.inviteCode.value,
            villagerId,
            circles: this.signupData.circles
          })
          .toPromise();

        if (this.signup) {
          this.launchApp(villagerId, villageId);
        } else {
          this.switchVillages(villagerId, villageId);
        }
      } catch (e) {
        console.error('Error joining village: ', e);
      }
    } catch (e) {
      console.error('Error creating auth user: ', e);
      if (e.code === 'auth/email-already-in-use') {
        this.showErrorAlert(
          'Email already taken. Please use a different email address.'
        );
      }
    }
  }

  // new
  async createNewVillage() {
    this.saving = true;

    const villagerId = await this.fetchOrCreateAuthUser();

    if (this.signup) {
      await this.fns
        .httpsCallable('villager-createSteward')({
          villagerId,
          firstName: this.signupData.firstName,
          lastName: this.signupData.lastName,
          email: this.signupData.email,
          appVersion: packageInfo.version || 'DNE'
        })
        .toPromise();
    }

    const { villageId } = await this.fns
      .httpsCallable('village-createVillage')({
        villagerId,
        villageName: this.name.value,
        values: this.values.value,
        vision: this.vision.value,
        type: this.villageType.value,
      })
      .toPromise();

    if (this.signup) {
      this.launchApp(villagerId, villageId);
    } else {
      this.switchVillages(villagerId, villageId);
    }
  }

  async launchApp(villagerId: string, villageId: string) {
    console.log('Created village with id: ', villageId);
    console.log('Created villager with id: ', villagerId);

    // set auth state
    this.store.dispatch(
      new Signup({
        email: this.signupData.email,
        uid: villagerId,
      })
    );

    // fetch villager
    // this triggers the app bootstrap in app.component.ts
    await this.store.dispatch(new GetVillager({ uid: villagerId })).toPromise();

    this.saving = false;
    if (this.loading) {
      this.loading.dismiss();
      this.loading = null;
    }
    if (this.platform.is('capacitor')) {
      this.openNotificationModal();
    } else {
      this.navCtrl.setDirection('root');
      this.router.navigate(['/pages/home'], {
        replaceUrl: true,
      });
      this.handleWrapup();
    }
  }

  async switchVillages(villagerId: string, villageId: string) {
    this.saving = false;

    console.log('[DEBUG] Newly created village id: ', villageId);
    this.currentVillage$.subscribe((currentVillage) => {
      console.log('[DEBUG] village changed on villager to: ', currentVillage);
      if (currentVillage === villageId) {
        this.store
          .dispatch(
            new SwitchVillage({
              villagerUid: villagerId,
              villageUid: villageId,
            })
          )
          .subscribe(() => {
            if (this.loading) {
              this.loading.dismiss();
              this.loading = null;
            }
          });

        this.handleWrapup();
      }
    });
  }

  joinKickstarterVillage() {}

  async fetchOrCreateAuthUser() {
    let villagerId;
    if (this.signup) {
      const response = await this.authSvc.createUserWithEmailAndPassword(
        this.signupData.email,
        this.signupData.password
      );
      villagerId = response.user.uid;
    } else {
      villagerId = this.store.selectSnapshot(AuthState.uid);
    }

    return villagerId;
  }

  backStepOne() {
    this.signupStepOne = true;
    this.signupStepTwo = false;
    this.signupStepThree = false;
    this.showVillageTypeOptions = false;

    this.goBack.emit();
  }

  backStepTwo() {
    this.signupStepOne = false;
    this.signupStepTwo = true;
    this.signupStepThree = false;
    this.showVillageTypeOptions = false;

    if(!this.signup) {
      this.goBack.emit();
    }
  }

  forwardStepTwo() {
    this.signupStepOne = false;
    this.signupStepTwo = true;
    this.showVillageTypeOptions = false;
    // if there's an invite code, ask if they want to join that village
    // otherwise, show the village join options
  }

  setVillageType(type: VillageType) {
    this.signupStepOne = false;
    this.signupStepTwo = false;
    this.showVillageTypeOptions = false;
    this.showVillageForm = true;
    this.villageType = type;
    console.log('Setting village type to: ', type);
  }

  showVillageCodeForm() {
    this.showInviteForm = true;
  }

  proceedToVillageTypeOptions() {
    this.signupStepTwo = false;
    this.showVillageTypeOptions = true;
  }

  exitVillageForm() {
    this.signupStepOne = false;
    this.signupStepTwo = true;
    this.showVillageTypeOptions = false;
    this.showVillageForm = false;
  }

  async showErrorAlert(message: string) {
    const alert = await this.alertCtrl.create({
      header: 'Error',
      message,
      buttons: ['OK'],
    });

    await alert.present();
  }

  async handleWrapup() {
    const existingModal = await this.modalCtrl.getTop();
    if (existingModal) this.modalCtrl.dismiss();
    if (this.loading) {
      this.loading.dismiss();
      this.loading = null;
    }
  }

  async openNotificationModal() {
    const modal = await this.modalCtrl.create({
      component: NotificationPermissionModalPage,
    });

    await modal.present();
  }
}
