import { Injectable } from '@angular/core';
import '@capacitor-community/firebase-analytics';
import { environment } from 'src/environments/environment';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
import { Device } from '@capacitor/device';
import packageInfo from '../../package.json';
import { Store } from '@ngxs/store';
import { VillagerState } from './state/villager.state';
import { VillageState } from './state/village.state';


@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  constructor(private store: Store) {}

  async init() {
    await this.initFirebaseAnalytics();

    FirebaseAnalytics.getAppInstanceId();

    // Platform: Web/Android/iOS
    FirebaseAnalytics.setCollectionEnabled({
      enabled: true,
    });
  }

  initFirebaseAnalytics(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const device = await Device.getInfo();
      if (device.platform == 'web') {
        try {
          const result = await FirebaseAnalytics.initializeFirebase(
            environment.firebase
          );
        } catch (e) {
          reject(e);
        }
      }
      resolve('done');
    });
  }

  setAnalyticsUserId(uid: string) {
    /**
     * Platform: Web/Android/iOS
     * Sets the user ID property.
     * @param userId - unique identifier of a user
     * @returns void
     * https://firebase.google.com/docs/analytics/userid
     */
    FirebaseAnalytics.setUserId({
      userId: uid,
    });
  }

  setAnalyticsPage(id: string, name: string) {
    /**
     * Platform: Android/iOS
     * Sets the current screen name, which specifies the current visual context in your app.
     * @param screenName - name of the current screen to track
     *        nameOverride - name of the screen class to override
     * @returns instanceId - individual instance id value
     * https://firebase.google.com/docs/analytics/screenviews
     * example:
     *   FirebaseAnalytics.setScreenName({
     * screenName: 'login',
     * nameOverride: 'LoginScreen'
     * });
     */

    FirebaseAnalytics.setScreenName({
      screenName: id,
      nameOverride: name,
    });
  }

  logEvent(name: string, params: any) {
    params = {
      ...params,
      userId: this.store.selectSnapshot(VillagerState.uid),
      villageId: this.store.selectSnapshot(VillageState.uid),
      version: packageInfo.version,
      appName: environment.appName
    };

    /**
     * Platform: Web/Android/iOS
     * Logs an app event.
     * @param name - name of the event to log
     *        params - key/value pairs of properties (25 maximum per event)
     * @returns void
     */
    // FirebaseAnalytics.logEvent({
    //   name: 'select_content',
    //   params: {
    //     content_type: 'image',
    //     content_id: 'P12453',
    //     items: [{ name: 'Kittens' }]
    //   }
    // });

    FirebaseAnalytics.logEvent({
      name,
      params,
    });
  }

  /**
   * @param name
   * @param component
   * @param message
   */
  logError(name: string, component: string, error: Error) {
    const { message } = error;
    const params = {
      component,
      type: 'error',
      message,
      userId: this.store.selectSnapshot(VillagerState.uid),
      villageId: this.store.selectSnapshot(VillageState.uid),
      version: packageInfo.version,
      appName: environment.appName
    };
    FirebaseAnalytics.logEvent({
      name,
      params,
    });
  }
}
