import { ExchangeItem } from './exchange.model';
import { Share } from './share.model';
import { SupportRequest } from './support-request.model';
import { Hangout } from './hangout.model';
import { ReadReceipt } from './read-receipt.model';
import { ConflictResolution } from './conflict-resolution.model';
import { Announcement } from './announcement.model';
import { Courtyard } from 'src/app/models/courtyard.model';
import { DirectChatroom } from './direct-chatroom.model';
import { SharedList } from './shared-list.model';
import { JoinedCircle } from './circle.model';
import { RecipientNotificationSettings } from './notification-settings';

export interface StoredDocument {
  path: string;
  type: string;
}

export interface ClientPostType {
  type: PostType;
  name: string;
  icon: string;
  color: string;
}

export interface FilterType {
  type: string;
  name: string;
  icon: string;
  color: string;
}

export const ProfileFilterTypes: FilterType[] = [
  {
    type: 'ARCHIVED',
    name: 'Archived',
    icon: 'reader',
    color: 'dark',
  },
];

export const ConfigurableFilterTypes: FilterType[] = [
  {
    type: 'SUPPORT',
    name: 'Support',
    icon: 'heart',
    color: 'danger',
  },
  {
    type: 'EXCHANGE',
    name: 'Exchange',
    icon: 'repeat',
    color: 'primary',
  },
  {
    type: 'CONFLICT',
    name: 'Conflict Resolution',
    icon: 'bonfire',
    color: 'danger',
  },
];

export const DefaultFilterTypes: FilterType[] = [
  {
    type: 'CLEAR',
    name: 'Clear Filter',
    icon: 'close-circle',
    color: 'dark',
  },
  {
    type: 'JOINED',
    name: 'Joined Post',
    icon: 'arrow-undo-circle',
    color: 'primary',
  },
  {
    type: 'ANNOUNCEMENT',
    name: 'Announcement',
    icon: 'megaphone',
    color: 'warning',
  },
  {
    type: 'HANGOUT',
    name: 'Event',
    icon: 'calendar',
    color: 'tertiary',
  },
  {
    type: 'SHARE',
    name: 'Share',
    icon: 'reader',
    color: 'dark',
  },
  {
    type: 'PAST_HANGOUTS',
    name: 'Past Event',
    icon: 'time',
    color: 'tertiary',
  },
];

export const PostTypes: ClientPostType[] = [
  {
    type: 'SHARE',
    name: 'Share',
    icon: 'reader',
    color: 'dark',
  },
  {
    type: 'SUPPORT',
    name: 'Support',
    icon: 'heart',
    color: 'danger',
  },
  {
    type: 'HANGOUT',
    name: 'Event',
    icon: 'calendar',
    color: 'tertiary',
  },
  {
    type: 'EXCHANGE',
    name: 'Exchange',
    icon: 'repeat',
    color: 'primary',
  },

  {
    type: 'CONFLICT',
    name: 'Conflict Resolution',
    icon: 'bonfire',
    color: 'danger',
  },
  {
    type: 'ANNOUNCEMENT',
    name: 'Announcement',
    icon: 'megaphone',
    color: 'warning',
  },
  {
    type: 'LIST',
    name: 'List',
    icon: 'list',
    color: 'dark',
  },
  // {
  //   type: 'DECISION',
  //   name: 'Poll',
  //   icon: 'podium',
  //   color: 'secondary'
  // },
];

export type PostType =
  | 'SHARE'
  | 'HANGOUT'
  | 'EXCHANGE'
  | 'SUPPORT'
  | 'ANNOUNCEMENT'
  | 'DECISION'
  | 'DIRECT_MESSAGE'
  | 'CONFLICT'
  | 'LIST';

export type GenericPost =
  | Share
  | Announcement
  | SupportRequest
  | Hangout
  | ExchangeItem
  | ConflictResolution
  | SharedList;

export type GenericChatroom = GenericPost | Courtyard | DirectChatroom;

export interface PostToken {
  UID: string;
  FCM_TOKEN: string;
}

export interface PostCore {
  TITLE?: string;
  BODY: string; // encodedURI
  UPDATED_AT: Date | string | any;
  MESSAGE_COUNT: number | any; // firebase.default.firebase.firestore.FieldValue;
  IMAGE_PATHS: string[];
  DOCUMENT_PATHS?: StoredDocument[];
  PARTICIPANTS?: string[]; // DEPRECATED
  PARTICIPANT_UIDS: string[];
  PARTICIPANT_READ_RECEIPTS: ReadReceipt[];
  RECIPIENT_NOTIFICATION_SETTINGS: RecipientNotificationSettings;
  REPORTED_BY: string[];
  TOPICS: string[];
  CIRCLES: JoinedCircle[]; // needed for displaying the ion-chips on posts.
  CIRCLE_UIDS: string[]; // array of circle uids that have access to this post
  ARCHIVED: boolean;
  _UID?: string;
  _CREATOR_UID: string;
  _CREATED_AT: Date | string | any;
  _SERVER_TIMESTAMP: any; // firebase.firestore.FieldValue.serverTimestamp
  _CREATOR_FIRST_NAME: string;
  _CREATOR_LAST_NAME: string;
  _TYPE: PostType;
  _MENTION_UIDS: string[];
}

export interface FeedStateModel {
  currentVillagerId: string;
  blockedVillagers: string[];
  filterType: string;
  filteredPosts: GenericPost[];
  participatedPosts: GenericPost[];
  allPosts: GenericPost[];
  initialBuildComplete: boolean;
  loadingData: boolean;
}

export const isPost = (post: PostCore): boolean => {
  if (
    post.hasOwnProperty('_UID') &&
    post.hasOwnProperty('_CREATOR_UID') &&
    post.hasOwnProperty('_SERVER_TIMESTAMP') &&
    post.hasOwnProperty('_CREATOR_FIRST_NAME') &&
    post.hasOwnProperty('_CREATOR_LAST_NAME') &&
    post.hasOwnProperty('_TYPE') &&
    // post.hasOwnProperty('TITLE') &&
    post.hasOwnProperty('BODY')
  ) {
    return true;
  } else {
    return false;
  }
};
export const showPost = (
  post: PostCore,
  villagerId: string,
  blockedVillagers: string[]
) => {
  if (!villagerId) return false;

  if (post.ARCHIVED) return false;

  if (
    isPost(post) &&
    !post.REPORTED_BY.includes(villagerId) &&
    !blockedVillagers.includes(post._CREATOR_UID)
  ) {
    return true;
  } else {
    console.log('[UTILS] Not showing post in feed: ', post);
    return false;
  }
};
