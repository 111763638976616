import { Injectable } from '@angular/core';
import {
  Emitted,
  NgxsFirestoreConnect,
  StreamEmitted,
} from '@ngxs-labs/firestore-plugin';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { Circle, CircleStateModel } from '../models/circle.model';
import { CircleService } from '../services/circle.service';
import { CircleActions } from './circle.actions';
import * as firebase from 'firebase';
import { addMilliseconds } from 'date-fns/esm';

@State<CircleStateModel>({
  name: 'circles',
  defaults: {
    circles: [],
  },
})
@Injectable()
export class CircleState {
  constructor(
    private ngxsFirestoreConnect: NgxsFirestoreConnect,
    private circleSvc: CircleService,
    private store: Store
  ) {}

  ngxsOnInit() {
    this.ngxsFirestoreConnect.connect(CircleActions.FetchAll, {
      to: () => this.circleSvc.collection$(),
      cancelPrevious: true,
    });
  }

  @Selector()
  static allCircles(state: CircleStateModel): Circle[] {
    return state.circles;
  }

  @Selector()
  static isCircleAdmin(
    state: CircleStateModel
  ): (villagerId: string, circleId: string) => boolean {
    return (villagerId: string, circleId: string) => {
      const circle = state.circles.find((x) => x._UID === circleId)
      return circle.ADMIN_UIDS.includes(villagerId);
    };
  }

  @Action(CircleActions.FetchAll)
  fetchChatrooms(
    ctx: StateContext<CircleStateModel>,
    action: CircleActions.FetchAll
  ) {
    console.log(
      '[Circle State] Fetching all circles for village: ',
      action.payload.villageId
    );
    this.circleSvc.setVillageId(action.payload.villageId);
  }

  @Action(StreamEmitted(CircleActions.FetchAll))
  streamEmitted(
    ctx: StateContext<CircleStateModel>,
    { action, payload }: Emitted<CircleActions.FetchAll, Circle[]>
  ) {
    // console.log('[Circle State] new circle data emitted: ', payload)
    ctx.patchState({
      circles: payload,
    });
  }

  @Action(CircleActions.AddAdminsToCircle)
  addAdmins(
    ctx: StateContext<CircleStateModel>,
    action: CircleActions.AddAdminsToCircle
  ) {
    const { circle, adminIds } = action.payload;

    return this.circleSvc.updateArrayUnionAdmins(circle._UID, {
      ADMIN_UIDS: firebase.default.firestore.FieldValue.arrayUnion(
        ...adminIds
      )
    })
  }

  @Action(CircleActions.RemoveAdminsFromCircle)
  removeAdmins(
    ctx: StateContext<CircleStateModel>,
    action: CircleActions.RemoveAdminsFromCircle
  ) {

    const { circleId, adminIds } = action.payload;
    console.log(
      '[DEBUG] circle state: removing admins from circle: ',
      adminIds,
      circleId
    );
    return this.circleSvc.updateArrayUnionAdmins(circleId, {
      ADMIN_UIDS: firebase.default.firestore.FieldValue.arrayRemove(
        ...adminIds
      ),
    });
  }

  @Action(CircleActions.AddVillagersToCircle)
  addVillagers(
    ctx: StateContext<CircleStateModel>,
    action: CircleActions.AddVillagersToCircle
  ) {
    const { circle, villagerIds } = action.payload;

    return this.circleSvc.updateArrayUnion(circle._UID, {
      VILLAGER_UIDS: firebase.default.firestore.FieldValue.arrayUnion(
        ...villagerIds
      ),
    });
  }

  @Action(CircleActions.AddVillagerToJoinedCircles)
  addVillagerToJoined(
    ctx: StateContext<CircleStateModel>,
    action: CircleActions.AddVillagerToJoinedCircles
  ) {
    const { joinedCircles, villagerId } = action.payload;
    joinedCircles.forEach((joinedCircle) => {
      // console.log(`[DEBUG] Adding villager ${villagerId} to circle: ${joinedCircle.NAME}`)
      this.circleSvc.updateArrayUnion(joinedCircle.UID, {
        VILLAGER_UIDS:
          firebase.default.firestore.FieldValue.arrayUnion(villagerId),
      });
    });
  }

  @Action(CircleActions.RemoveVillagersFromCircle)
  removeVillager(
    ctx: StateContext<CircleStateModel>,
    action: CircleActions.RemoveVillagersFromCircle
  ) {
    const { circleId, villagerIds } = action.payload;
    console.log(
      '[DEBUG] circle state: removing villagers from circle: ',
      villagerIds,
      circleId
    );
    return this.circleSvc.updateArrayUnion(circleId, {
      VILLAGER_UIDS: firebase.default.firestore.FieldValue.arrayRemove(
        ...villagerIds
      ),
    });
  }

  @Action(CircleActions.Clear)
  clear(ctx: StateContext<CircleStateModel>, action: CircleActions.Clear) {
    ctx.setState({
      circles: [],
    });
  }
}
