import { Component, Input, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ChatMessage } from 'src/app/models/chat-message.model';
import { ClearReplyMessage } from 'src/app/state/app.actions';
import { MessageReplyState } from 'src/app/state/message-reply.state';

@Component({
  selector: 'app-message-reply-preview',
  templateUrl: './message-reply-preview.component.html',
  styleUrls: ['./message-reply-preview.component.scss'],
})
export class MessageReplyPreviewComponent implements OnInit {
  @Input() replyMessage: ChatMessage;
  constructor(private store: Store) {}

  ngOnInit() {}

  async deleteReply() {
    this.store.dispatch(new ClearReplyMessage());
  }
}
