<ion-list class="member-list" [ngClass]="shouldDisplayAbove ? 'display-up' : ''">
  <ion-item
    (click)="setVillager(villager)"
    *ngFor="let villager of villagers; index as i"
    [color]="i === selectedIndex ? 'light' : ''"
  >
    <app-villager-small-avatar
      [firstName]="villager.FIRST_NAME"
      [lastName]="villager.LAST_NAME"
      [uid]="villager._UID"
      [recentlyActive]="villager.RECENTLY_ACTIVE"
      [navToProfile]="false"
    ></app-villager-small-avatar>
    <ion-label>{{ villager.FIRST_NAME }} {{ villager.LAST_NAME }}</ion-label>
  </ion-item>
</ion-list>
