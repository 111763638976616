import { Component, Input, OnInit } from '@angular/core';
import { MenuController, ModalController } from '@ionic/angular';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { AnalyticsService } from 'src/app/analytics.service';
import { ChangeVillagesModalPage } from 'src/app/modals/change-villages-modal/change-villages-modal.page';
import { VillageState } from 'src/app/state/village.state';

@Component({
  selector: 'app-switch-village-header-button',
  templateUrl: './switch-village-header-button.component.html',
  styleUrls: ['./switch-village-header-button.component.scss'],
})
export class SwitchVillageHeaderButtonComponent implements OnInit {
  @Select(VillageState.villageName) villageName$: Observable<string>;
  @Select(VillageState.hasProAccess) proAccess$: Observable<boolean>;
  @Select(VillageState.configVillageLabel) villageLabel$: Observable<string>;

  @Input() header = false;
  @Input() color = 'dark';
  @Input() showLabel = false;
  @Input() sideMenu = false;

  constructor(
    private analytics: AnalyticsService,
    private modalCtrl: ModalController,
    private menuCtrl: MenuController
  ) {}

  ngOnInit() {
    // this.proAccess$.subscribe((pro) => alert('Pro? ' + pro))
  }

  async joinOrSwitchVillage() {
    this.menuCtrl.close();
    this.analytics.logEvent('home_open_join_or_switch_modal', {});
    const modal = await this.modalCtrl.create({
      component: ChangeVillagesModalPage,
    });

    return await modal.present();
  }
}
