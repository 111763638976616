<ion-grid>
  <ion-row *ngIf="supportRequest?._EMOTION">
    <ion-col>
      <div class="subheader">Feeling</div>
      <div style="margin-left: -5px">
        {{ supportRequest?._EMOTION | titlecase }}
        <span style="font-size: 24px">{{ emoji }}</span>
      </div>
    </ion-col>
    <ion-col></ion-col>
  </ion-row>
  <ion-row style="margin-top: 20px">
    <ion-col>
      <div *ngIf="supportRequest?.BODY">
        <div
          class="post-message-body"
          *ngIf="!showFullBody; else fullBody"
          [innerHtml]="
            supportRequest.BODY
              | truncateBody: 400:true
              | mentionified: supportRequest._MENTION_UIDS
              | safehtml
          "
        ></div>
        <ion-button
          style="float: right; margin-top: 5px"
          size="small"
          color="dark"
          fill="outline"
          *ngIf="supportRequest?.BODY.length > 400 && !showFullBody"
          (click)="goToDetails()"
          >Read full post
          <ion-icon name="chevron-forward-outline"></ion-icon>
        </ion-button>
        <ng-template #fullBody>
          <div
            (click)="clickBody($event)"
            class="post-message-body"
            [innerHtml]="
              supportRequest.BODY
                | mentionified: supportRequest._MENTION_UIDS
                | safehtml
            "
          ></div>
        </ng-template>
      </div>
    </ion-col>
  </ion-row>
</ion-grid>
<div *ngIf="supportRequest?.IMAGE_PATHS.length > 0">
  <ion-grid style="padding: 0">
    <ion-row>
      <ion-col *ngFor="let path of supportRequest?.IMAGE_PATHS">
        <div class="post-image-wrapper">
          <app-post-image [path]="path"></app-post-image>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</div>
