<ion-list>
  <ion-list-header
    ><app-courtyards-label [showTitlecase]="true"></app-courtyards-label
    >&nbsp;Info</ion-list-header
  >
  <ion-item>
    <ion-label class="ion-text-wrap">
      <div class="center-aligned-row">
        Notifications:&nbsp;
        <span style="font-style: italic">{{ isMuted ? 'Off' : 'On' }}</span>
      </div>
      <ion-button
        (click)="toggleNotificationsMuted()"
        fill="outline"
        color="dark"
        [disabled]="loading"
      >
        {{ isMuted ? 'Enable' : 'Mute' }}&nbsp;
        <ion-icon *ngIf="isMuted" color="dark" name="notifications"></ion-icon>
        <ion-icon
          *ngIf="!isMuted"
          color="dark"
          name="notifications-off"
        ></ion-icon>
      </ion-button>
    </ion-label>
  </ion-item>
  <ion-item>
    <ion-label class="ion-text-wrap">
      <p>Name</p>
      <div>{{ courtyard.TITLE }}</div>
    </ion-label>
  </ion-item>
  <ion-item>
    <ion-label class="ion-text-wrap">
      <p>Description</p>
      <div *ngIf="courtyard.DESCRIPTION; else noDescription"
      [innerHtml]="courtyard.DESCRIPTION | safehtml">
      </div>
      <ng-template #noDescription>
        <ion-text color="medium" style="font-style: italic"
          >No description</ion-text
        >
      </ng-template>
    </ion-label>
  </ion-item>
  <div class="ion-padding">
    <ion-button (click)="edit()" expand="block" color="primary">
      <ion-label>Edit</ion-label>
      <ion-icon slot="end" name="create"></ion-icon>
    </ion-button>
    <ion-button (click)="showDeleteWarning()" expand="block" color="danger">
      <ion-label>Delete</ion-label>
      <ion-icon slot="end" name="trash"></ion-icon>
    </ion-button>
  </div>
  <ng-template #nonAdmin>
    <ion-item *ngIf="courtyard.DESCRIPTION">
      <ion-label color="medium" class="ion-text-wrap">
        <div>
          Only <app-stewards-label [plural]="true"></app-stewards-label> can
          edit & delete
          <app-courtyards-label
            [showTitlecase]="false"
            [plural]="true"
          ></app-courtyards-label
          >.
        </div>
      </ion-label>
    </ion-item>
  </ng-template>
</ion-list>
