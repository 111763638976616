<ion-header class="ion-no-border" translucent="false">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button fill="clear" (click)="showDetailsPopover($event)">
        <ion-icon
          color="dark"
          name="ellipsis-vertical"
          slot="icon-only"
        ></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>{{courtyard.TITLE}}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="close()">
        <ion-icon color="dark" slot="icon-only" name="close-circle"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content #messageContainer [scrollEvents]="true" [fullscreen]="true">
  <div *ngIf="(messages$ | async) as messages">
    <div *ngIf="messages.length === 0" style="text-align: center">
      <p class="medium">Send the first message!</p>
    </div>
    <app-chat-message
      *ngFor="let message of messages; trackBy: trackByFn"
      [message]="message"
      [type]="'courtyard'"
    ></app-chat-message>
  </div>
  <div style="height: 90px"></div>
  <div *ngIf="newMessageHasImages" style="height: 200px"></div>
</ion-content>

<div *ngIf="viewLoaded">
  <ion-footer class="ion-padding">
    <app-chat-input
      [chatroom]="selectedCourtyard"
      [type]="'courtyard'"
      (hasMedia)="onImageChange($event)"
    ></app-chat-input>
  </ion-footer>
</div>
