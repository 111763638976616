import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import { ActionSheetController, ModalController } from '@ionic/angular';
import { Select, Store } from '@ngxs/store';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { AnalyticsService } from 'src/app/analytics.service';
import { ChatMessageOptionsModalPage } from 'src/app/modals/chat-message-options-modal/chat-message-options-modal.page';
import { ChatMessage } from 'src/app/models/chat-message.model';
import { Villager } from 'src/app/models/villager.model';
import { CreateBulletinService } from 'src/app/services/create-bulletin.service';
import {
  DirectChatroomReact,
  DiscussionReact,
} from 'src/app/state/app.actions';
import { CourtyardActions } from 'src/app/state/courtyard.actions';
import { VillagerState } from 'src/app/state/villager.state';

@Component({
  selector: 'app-chat-message',
  templateUrl: './chat-message.component.html',
  styleUrls: ['./chat-message.component.scss'],
})
export class ChatMessageComponent implements OnInit, OnChanges {
  currentUserUid: string;
  isOpen = false;
  calculatedReactions = new Map();

  devReactionCount = 3;
  emojiMart = false;

  _MESSAGE_TYPE: string;

  @Select(VillagerState.allVillagers) allVillagers$: Observable<Villager[]>;
  @Input() message: ChatMessage;
  @Input() type: 'post' | 'dm' | 'courtyard';
  constructor(
    private store: Store,
    public actionSheetController: ActionSheetController,
    private bulletinSvc: CreateBulletinService,
    private router: Router,
    private modalCtrl: ModalController,
    private analyticsSvc: AnalyticsService
  ) {}

  ngOnInit() {
    this.currentUserUid = this.store.selectSnapshot(VillagerState.uid);
    if (this.message._HAS_PHOTO) {
      this._MESSAGE_TYPE = 'photo';
    } else if (this.message._HAS_VOICE_NOTE) {
      this._MESSAGE_TYPE = 'voice_note';
    } else if (this.message._REPLIED_TO_UID) {
      this._MESSAGE_TYPE = 'reply';
    } else {
      this._MESSAGE_TYPE = 'text';
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    // console.log('Change Detected: ', changes.message);
    if (changes.message.firstChange) {
      changes.message.currentValue.REACTIONS.forEach((reaction) => {
        this.calculatedReactions.set(
          reaction.EMOJI,
          this.calculatedReactions.get(reaction.EMOJI)
            ? this.calculatedReactions.get(reaction.EMOJI) + 1
            : 1
        );
      });
    } else {
      if (
        changes.message.previousValue.REACTIONS.length !==
        changes.message.currentValue.REACTIONS.length
      ) {
        this.calculatedReactions = new Map();
        changes.message.currentValue.REACTIONS.forEach((reaction) => {
          this.calculatedReactions.set(
            reaction.EMOJI,
            this.calculatedReactions.get(reaction.EMOJI)
              ? this.calculatedReactions.get(reaction.EMOJI) + 1
              : 1
          );
        });
      }
    }
  }

  shouldShowTimeago(): boolean {
    if (
      moment(this.message._CREATED_AT).isBefore(
        moment().subtract(30, 'seconds')
      ) &&
      this.message.SHOW_TIMESTAMP
    ) {
      return true;
    } else return false;
  }

  async onClickMessage(ev: Event) {
    const target = ev.target as HTMLElement;
    if (target?.className === 'mentionified') {
      const uid = target.getAttribute('data-mention-uid');
      if (uid) {
        const existing = await this.modalCtrl.getTop();
        if (existing) await this.modalCtrl.dismiss();
        this.router.navigateByUrl(`member/${uid}`);
      }
    } else {
      this.showMessageOptions();
    }
  }

  async react(emoji: string) {
    console.log('reacting with: ', emoji);
    const payload = {
      message: this.message,
      emoji,
      villagerUid: this.currentUserUid,
    };

    switch (this.type) {
      case 'courtyard':
        this.store.dispatch(new CourtyardActions.React(payload));
        break;
      case 'post':
        this.store.dispatch(new DiscussionReact(payload));
        break;
      case 'dm':
        this.store.dispatch(new DirectChatroomReact(payload));
        break;
      default:
        alert(
          'We ran into an error adding your reaction. Please try again or contact support'
        );
    }

    this.analyticsSvc.logEvent('add_reaction', {});
  }

  async showMessageOptions() {
    // const villager = this.store.selectSnapshot(VillagerState.currentVillager);
    if (!this.message.DELETED) {
      const modal = await this.modalCtrl.create({
        component: ChatMessageOptionsModalPage,
        initialBreakpoint: 0.5,
        breakpoints: [0, 0.25, 0.5, 0.75],
        componentProps: {
          message: this.message,
          type: this.type,
        },
      });
      return await modal.present();
    }
  }

  async createPostFromMessage(type: string) {
    switch (type.toLowerCase()) {
      case 'event':
        this.bulletinSvc.openCreateHangoutModal('', this.message._BODY);
        break;
      case 'support':
        this.bulletinSvc.openSupportRequestModal(this.message._BODY);
        break;
      case 'post':
        this.bulletinSvc.openCreateShareModal(this.message._BODY);
        break;
      case 'request':
        this.bulletinSvc.openRequestModal(this.message._BODY);
        break;
      case 'offering':
        this.bulletinSvc.openOfferingModal(this.message._BODY);
        break;
    }
  }
}
