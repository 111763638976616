import { Pipe, PipeTransform } from '@angular/core';
import { Circle } from '../models/circle.model';

@Pipe({
  name: 'circleLabel',
})
export class CircleIdToLabelPipe implements PipeTransform {
  transform(circleId: string, allCircles: Circle[]): string {
    if(!allCircles) return '';
    const circle = allCircles.find((x) => x._UID === circleId);
    if (circle) {
      const { NAME } = circle;
      return `${NAME}`;
    } else return circleId;
  }
}
