import { Injectable } from '@angular/core';
import { NgxsFirestore } from '@ngxs-labs/firestore-plugin';
import { Share } from '../models/share.model';
import * as firebase from 'firebase';
import 'firebase/firestore';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ShareService extends NgxsFirestore<Share> {
  idField = '_UID';

  protected get path() {
    return `VILLAGES/${this.villageId}/SHARES`;
  }

  private VILLAGEID = '';

  public setVillageId(villageId) {
    this.VILLAGEID = villageId;
  }

  protected get villageId() {
    return this.VILLAGEID;
  }

  /**
   * @deprecated
   */
  updateIfExists(id, data) {
    return of(
      this.adapter.firestore
        .doc(`${this.path}/${id}`)
        .ref.withConverter(this.converter)
        .update(data)
    );
  }

  updateWithoutConverter(id: string, update: Partial<Share>) {
    return of(this.adapter.firestore.doc(`${this.path}/${id}`).update(update));
  }

  converter: firebase.default.firestore.FirestoreDataConverter<Share> = {
    toFirestore: (value: Partial<Share>) => {
      // NOTE: careful here. Partial updates come through here as well.
      let db = { ...value };
      if (db.hasOwnProperty('BODY')) {
        db = {
          ...db,
          BODY: encodeURI(value.BODY),
        };
      }
      // console.log('[SHARE SERVICE] Sending to firestore:  ', db);
      return db;
    },
    fromFirestore: (snapshot, options) => {
      let data = snapshot.data(options) as Share;

      if (snapshot.metadata.hasPendingWrites) {
        const ts = firebase.default.firestore.Timestamp.now();
        data = {
          ...data,
          BODY: decodeURI(data.BODY),
          _CREATED_AT: ts.toDate(),
          UPDATED_AT: ts.toDate(),
        };
      } else {
        data = {
          ...data,
          BODY: decodeURI(data.BODY),
          _CREATED_AT: data._SERVER_TIMESTAMP.toDate(),
          UPDATED_AT: data.UPDATED_AT.toDate(),
        };

        if (!data.hasOwnProperty('IMAGE_PATHS')) {
          data.IMAGE_PATHS = [];
        }

        if (!data.hasOwnProperty('PARTICIPANT_READ_RECEIPTS')) {
          data.PARTICIPANT_READ_RECEIPTS = [];
        }

        // patch PARTICIPANTS over to PARTICIPANT_UIDS
        if (!data.hasOwnProperty('PARTICIPANT_UIDS')) {
          data.PARTICIPANT_UIDS = [...data.PARTICIPANTS];
        }

        // patch no REPORTED_BY
        if (!data.hasOwnProperty('REPORTED_BY')) {
          data.REPORTED_BY = [];
        }

        // patch no TOPICS
        if (!data.hasOwnProperty('TOPICS')) {
          data.TOPICS = [];
        }

        if (!data.hasOwnProperty('ARCHIVED')) {
          data.ARCHIVED = false;
        }

        // patch no DOCUMENTS
        if (!data.hasOwnProperty('DOCUMENT_PATHS')) {
          data.DOCUMENT_PATHS = [];
        }

        // patch no CIRCLES
        if (!data.hasOwnProperty('CIRCLES')) {
          data.CIRCLES = [{ NAME: 'Members', UID: 'MEMBERS' }];
        }

        // patch no CIRCLE_UIDS
        if (!data.hasOwnProperty('CIRCLE_UIDS')) {
          data.CIRCLE_UIDS = ['MEMBERS'];
        }
      }

      // console.log('[BULLETIN SERVICE] Received from firestore:  ', data._UID);

      return data;
    },
  };
}
