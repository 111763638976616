<ion-header class="ion-no-border" translucent="false">
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button (click)="close()">
        <ion-icon color="dark" slot="icon-only" name="close-circle"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title
      ><span *ngIf="!edit">Create a</span
      ><span *ngIf="edit">Update</span>&nbsp;<app-courtyards-label
        [showTitlecase]="true"
      ></app-courtyards-label
    ></ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-card>
    <ion-card-content>
      <p>
        <app-courtyards-label
          [plural]="true"
          [showTitlecase]="true"
        ></app-courtyards-label>
        &nbsp;are open spaces for everyone in a
        <app-village-label></app-village-label> to chat & connect freely.
      </p>

      <form [formGroup]="courtyardForm">
        <ion-item
          lines="none"
          class="full-width-item form-outline-item"
          [ngClass]="{
          invalid: title.invalid && (title.dirty || title.touched)
        }"
        >
          <ion-input
            formControlName="title"
            placeholder="Name (required)"
          ></ion-input>
        </ion-item>
        <ion-item lines="none" class="full-width-item">
          <ion-textarea
            rows="8"
            autocapitalize="on"
            autocorrect="on"
            formControlName="description"
            placeholder="Description (optional) "
            [ngClass]="{
              invalid: description.invalid && description.touched
            }"
          >
          </ion-textarea>
        </ion-item>

        <div *ngIf="villagerCircles$ | async as availableCircles">
          <ion-grid>
            <ion-row>
              <ion-col size="10" sizeLg="11">
                <div class="subheader">Assign channel to circle</div>
              </ion-col>
              <ion-col size="2" sizeLg="1">
                <ion-button
                  style="position: absolute; right: -5px; top: -12px"
                  fill="clear"
                  (click)="showHelper($event)"
                >
                  <ion-icon
                    style="font-size: 30px;"
                    color="dark"
                    slot="icon-only"
                    name="information-circle"
                  ></ion-icon>
                </ion-button>
              </ion-col>
              <ion-col size="12">
                <ion-item lines="none" class="full-width-item">
                  <ion-label
                    style="text-align: left"
                    *ngIf="villageName$ | async as name"
                  >
                    Select circle
                  </ion-label>
                  <ion-select
                    multiple="false"
                    formControlName="assignedCircle"
                    [compareWith]="compareWith"
                    [disabled]="edit"
                  >
                    <ion-select-option
                      *ngFor="let circle of mappedCircles"
                      [value]="circle"
                      >{{ circle.NAME }}</ion-select-option
                    >
                  </ion-select>
                </ion-item>
              </ion-col>
            </ion-row>
          </ion-grid>
        </div>
      </form>

      <ion-button
        *ngIf="edit === false"
        [disabled]="courtyardForm.invalid"
        expand="block"
        (click)="save()"
      >
        <ion-label>Create</ion-label>
      </ion-button>
      <ion-button
        *ngIf="edit === true"
        [disabled]="courtyardForm.invalid"
        expand="block"
        (click)="update()"
        >Save Changes
      </ion-button>
    </ion-card-content>
  </ion-card>
</ion-content>
