import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { Store } from '@ngxs/store';
import {
  SetVoiceNoteCurrentTime,
  SetVoiceNoteUrl,
  VoiceNoteEnded,
  VoiceNoteIsLoading,
} from '../state/app.actions';

@Injectable({
  providedIn: 'root',
})
export class AudioService {
  player: HTMLAudioElement;
  currentUrl: string;
  playing: boolean;
  duration: number;
  constructor(private store: Store, private loadingCtrl: LoadingController) {}

  getCurrentUrl(): string {
    return this.currentUrl;
  }

  getAudioPlayer(url: string): HTMLAudioElement {
    if (this.currentUrl === url) {
      return this.player;
    }
  }

  /**
   * Use this when starting a new recording or navigating the page away
   */
  killAnyExistingAudio() {
    this.store.dispatch(new SetVoiceNoteUrl({ voiceNoteUrl: null }));
    if (this.player) {
      this.player.pause();
      this.player = null;
    }
  }

  toggle(url: string) {
    // User Clicked Play For The First Time
    if (!this.player) {
      console.log('no existing player');
      this.initializeAudio(url);
    } else if (url === this.currentUrl) {
      console.log('toggle current player');
      this.togglePlay();
    } else {
      console.log('switching audio src');
      // this.switchAudioLoading();
      this.store
        .dispatch([
          new SetVoiceNoteUrl({ voiceNoteUrl: null }),
          new SetVoiceNoteCurrentTime({ currentTime: 0 }),
        ]) // clear app state
        .subscribe(() => {
          this.player.pause(); // pause the current playing file
          this.player = null; // removing the player
          this.initializeAudio(url); // start new audio with new url
        });
    }
  }

  togglePlay() {
    this.playing ? this.player.pause() : this.player.play();
  }

  async initializeAudio(url: string) {
    await this.store
      .dispatch([
        new VoiceNoteIsLoading({ loading: true }),
        new SetVoiceNoteCurrentTime({ currentTime: 0 }),
      ])
      .toPromise();

    this.store
      .dispatch(new SetVoiceNoteUrl({ voiceNoteUrl: url }))
      .subscribe(() => {
        this.currentUrl = url;
        this.player = new Audio(url);
        this.player.oncanplaythrough = () => {
          this.store.dispatch(new VoiceNoteIsLoading({ loading: false }));
          this.player.play();
        };
        this.player.onplay = () => {
          this.playing = true;
        };
        this.player.onpause = () => {
          this.playing = false;
        };

        this.player.ontimeupdate = () => {
          this.store.dispatch(
            new SetVoiceNoteCurrentTime({
              currentTime: this.player.currentTime,
            })
          );
        };

        this.player.onended = () => {
          this.store.dispatch(new VoiceNoteEnded({ voiceNoteUrl: url }));
          this.player = null;
          this.currentUrl = '';
        };

        this.player.loop = false;
        this.player.load();
      });
  }

  getProgress(duration: number): number {
    return this.player ? this.player.currentTime / duration : 0;
  }

  // async switchAudioLoading() {
  //   const loading = await this.loadingCtrl.create({
  //     duration: 1000,
  //     backdropDismiss: true,
  //   });
  //   return loading.present();
  // }
}
