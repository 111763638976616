import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  ModalController,
  ToastController,
  AlertController,
  LoadingController,
  Platform,
} from '@ionic/angular';
import * as firebase from 'firebase';
import 'firebase/firestore';
import { Share } from 'src/app/models/share.model';
import { EMOTIONS } from 'src/app/models/support-request.model';
import { Select, Store } from '@ngxs/store';
import { CreateShare, UpdateShare } from 'src/app/state/app.actions';
import { VillagerState } from 'src/app/state/villager.state';
import { Villager } from 'src/app/models/villager.model';
import { Observable } from 'rxjs';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { AnalyticsService } from 'src/app/analytics.service';
import { VillageState } from 'src/app/state/village.state';
import { SHARE_KARMA } from 'src/app/app.config';
import { PostFileUploadComponent } from 'src/app/shared/post-file-upload/post-file-upload.component';
import { StoredDocument } from 'src/app/models/post-core.model';
import { JoinedCircle } from 'src/app/models/circle.model';
import { AppState } from 'src/app/state/app.state';
import { RecipientNotificationSettings } from 'src/app/models/notification-settings';
import { CreatePostHelpersService } from 'src/app/services/create-post-helpers.service';
import { PostMentionableInputComponent } from 'src/app/shared/post-mentionable-input/post-mentionable-input.component';
import { VillagerActions } from 'src/app/state/villager.actions';

@Component({
  selector: 'app-create-post-modal',
  templateUrl: './create-post-modal.page.html',
  styleUrls: ['./create-post-modal.page.scss'],
})
export class CreatePostModalPage implements OnInit, AfterViewInit {
  creator: Villager;
  loading: any;
  emotions = EMOTIONS;
  selectedEmotion = null;
  SHARE_KARMA = SHARE_KARMA;
  component = 'create-post-modal';
  placeholderText = `Share about what you're feeling, a topic you want to discuss or get feedback on`;

  // for existing files on post
  tmpImageUrls: string[] = [];
  tmpDocUrls: StoredDocument[] = [];

  topics: string[] = [];
  assignedCircles: JoinedCircle[] = [];

  mentionables: string[] = [];

  showFeeling = false;
  showPhotos = false;
  showTopics = false;

  isMobile = false;

  @Select(VillageState.villageName) villageName$: Observable<string>;
  @Select(VillageState.showKarma) showKarma$: Observable<boolean>;
  @Select(AppState.postCirclesAssigned) postCirclesAssigned$: Observable<
    JoinedCircle[]
  >;

  @Input() village: string;
  @Input() edit = false;
  @Input() post: Share;
  @Input() description: string;

  @ViewChild(PostMentionableInputComponent)
  mentionInput: PostMentionableInputComponent;
  @ViewChild(PostFileUploadComponent) fileUpload: PostFileUploadComponent;

  constructor(
    public modalCtrl: ModalController,
    private store: Store,
    private toastCtrl: ToastController,
    private alertCtrl: AlertController,
    private loadingCtrl: LoadingController,
    private utils: UtilitiesService,
    private analytics: AnalyticsService,
    private helpers: CreatePostHelpersService,
    private platform: Platform
  ) {}

  ngAfterViewInit(): void {
    if (this.edit === true) {
      console.log('edit post: ', this.post._UID);
      this.mentionInput.setBody(this.utils.removeExistingTags(this.post.BODY), this.post._MENTION_UIDS);
    } else {
      if (this.description) {
        this.mentionInput.setBody(this.utils.removeExistingTags(this.description), []);
      }
    }
  }

  ngOnInit() {
    this.isMobile = this.platform.is('mobile');
    this.postCirclesAssigned$.subscribe((assignedCircles: JoinedCircle[]) => {
      this.assignedCircles = assignedCircles;
    });

    if (this.edit === true) {
      if (this.post.FEELING) this.selectedEmotion = this.post.FEELING;
      this.tmpImageUrls = [...this.post.IMAGE_PATHS];
      this.tmpDocUrls = [...this.post.DOCUMENT_PATHS];
      this.topics = [...this.post.TOPICS];
      this.assignedCircles = [...this.post.CIRCLES];
    }

    this.loadMentionables();
  }

  onSetTopics(topics: string[]) {
    this.topics = topics;
  }

  handleDeletePhoto(event, index: number) {
    this.tmpImageUrls.splice(index, 1);
  }

  handleDeleteDocument(index: number) {
    this.tmpDocUrls.splice(index, 1);
  }

  async createPost() {
    const { BODY } = this.mentionInput.parseMentionsAndBody();
    if (!BODY || BODY.trim().length === 0) {
      this.missingDataError(
        'Missing description. Please add a description and try again.'
      );
    } else {
      try {
        let photoPaths = [];
        let documentPaths = [];
        if (this.fileUpload) {
          photoPaths = await this.fileUpload.uploadPhotos();
          documentPaths = await this.fileUpload.uploadDocuments();
        }
        this.savePost(photoPaths, documentPaths);
      } catch (e) {
        this.analytics.logEvent('share_create_error', {
          error: e,
        });
        alert('Error creating post');
      }
    }
  }

  async updatePost() {
    await this.presentLoading();
    this.analytics.logEvent('share_update', {
      shareId: this.post._UID,
    });
    let newPhotoPaths = [];
    let newDocumentPaths: StoredDocument[] = [];
    try {
      if (this.fileUpload) {
        newPhotoPaths = await this.fileUpload.uploadPhotos();
        newDocumentPaths = await this.fileUpload.uploadDocuments();
      }
    } catch (e) {
      alert('Error updating photos & documents');
      this.analytics.logError('error_updating_post_files', this.component, e);
    }
    let allImages = [...this.tmpImageUrls];
    let allDocuments = [...this.tmpDocUrls];
    if (newPhotoPaths.length > 0) {
      allImages = this.tmpImageUrls.concat(newPhotoPaths);
    }
    if (newDocumentPaths.length > 0) {
      allDocuments = this.tmpDocUrls.concat(newDocumentPaths);
    }

    const CIRCLE_UIDS = this.assignedCircles.map((x) => x.UID);
    const RECIPIENT_NOTIFICATION_SETTINGS: RecipientNotificationSettings =
      this.helpers.getNotificationRecipientsForCircle(CIRCLE_UIDS);

    let post: Share = {
      ...this.post,
      FEELING: this.selectedEmotion,
      IMAGE_PATHS: allImages,
      DOCUMENT_PATHS: allDocuments,
      TOPICS: this.topics,
      RECIPIENT_NOTIFICATION_SETTINGS,
      CIRCLES: this.assignedCircles,
      CIRCLE_UIDS,
    };

    const { _MENTION_UIDS, BODY } = this.mentionInput.parseMentionsAndBody();
    post._MENTION_UIDS = _MENTION_UIDS;
    post.BODY = this.utils.parseLinksFromText(BODY);

    this.store.dispatch(new UpdateShare({ post })).subscribe(
      () => {
        this.analytics.logEvent('share_update_success', {});
        this.presentToast('Successfully updated share.', false);
        this.modalCtrl.dismiss({ action: null });
        this.loading.dismiss();
        this.loading = null;
      },
      (err) => {
        this.analytics.logEvent('share_update_error', {
          shareId: this.post._UID,
          error: err,
        });
        console.error(err);
        this.showErrorAlert();
        this.loading.dismiss();
        this.loading = null;
      }
    );
  }

  async savePost(photoPaths: string[], documentPaths: StoredDocument[]) {
    console.log('Saving');
    if (this.assignedCircles.length === 0) {
      this.missingDataError(
        'Missing circle. Please specify what circle you want to share this with.'
      );
      return false;
    }
    this.analytics.logEvent('post_save_new', {});
    const uid = this.store.selectSnapshot(VillagerState.uid);
    const firstName = this.store.selectSnapshot(VillagerState.firstName);
    const lastName = this.store.selectSnapshot(VillagerState.lastName);
    const CIRCLE_UIDS = this.assignedCircles.map((x) => x.UID);
    const RECIPIENT_NOTIFICATION_SETTINGS: RecipientNotificationSettings =
      this.helpers.getNotificationRecipientsForCircle(CIRCLE_UIDS);

    let share: Share = {
      _CREATOR_UID: uid,
      _CREATOR_FIRST_NAME: firstName,
      _CREATOR_LAST_NAME: lastName,
      _SERVER_TIMESTAMP:
        firebase.default.firestore.FieldValue.serverTimestamp(),
      _CREATED_AT: new Date(),
      _TYPE: 'SHARE',
      BODY: null,
      FEELING: this.selectedEmotion,
      REPORTED_BY: [],
      PARTICIPANT_UIDS: [uid],
      PARTICIPANT_READ_RECEIPTS: [{ UID: uid, LAST_READ_MESSAGE_COUNT: 0 }],
      RECIPIENT_NOTIFICATION_SETTINGS,
      CELEBRATIONS: [],
      HEARINGS: [],
      MESSAGE_COUNT: 0,
      UPDATED_AT: firebase.default.firestore.FieldValue.serverTimestamp(),
      IMAGE_PATHS: photoPaths,
      DOCUMENT_PATHS: documentPaths,
      TOPICS: this.topics,
      ARCHIVED: false,
      CIRCLES: this.assignedCircles,
      CIRCLE_UIDS,
      _MENTION_UIDS: [],
    };

    const { _MENTION_UIDS, BODY } = this.mentionInput.parseMentionsAndBody();
    share._MENTION_UIDS = _MENTION_UIDS;
    share.BODY = this.utils.parseLinksFromText(BODY);

    this.presentLoading();
    this.store.dispatch(new CreateShare({ post: share })).subscribe(
      (result) => {
        this.store.dispatch(new VillagerActions.MarkOnboardedTaskComplete({ villagerId: uid, field: "HAS_CREATED_POST" }))
        this.analytics.logEvent('post_save_new_success', {});
        this.presentToast('Successfully created share.', true);
        this.modalCtrl.dismiss({ action: 'create-close' });
        this.loading.dismiss();
        this.loading = null;
      },
      (err) => {
        this.analytics.logEvent('post_save_new_error', {
          error: err,
        });
        console.error(err);
        this.showErrorAlert();
        this.loading.dismiss();
        this.loading = null;
      }
    );
  }

  selectEmotion(event: { emotion: string; icon: string }) {
    if (!this.selectedEmotion) {
      this.selectedEmotion = event.emotion;
    } else if (this.selectedEmotion === event.emotion) {
      this.selectedEmotion = null;
    } else {
      this.selectedEmotion = event.emotion;
    }
  }

  async presentToast(header: string, update = false) {
    let message;
    if (update) {
      message = `You earned ${SHARE_KARMA} karma!`;
    }
    const toast = await this.toastCtrl.create({
      header: header,
      message,
      color: 'primary',
      duration: 3000,
      position: 'top',
    });
    toast.present();
  }

  async presentLoading() {
    if (!this.loading) {
      this.loading = await this.loadingCtrl.create({
        duration: 15000,
        backdropDismiss: true,
      });
      return this.loading.present();
    }
  }

  async showErrorAlert() {
    const alert = await this.alertCtrl.create({
      header: 'Error',
      message:
        'We ran into an error creating the post. Please check that everything is filled out and try again.',
      buttons: ['OK'],
    });

    await alert.present();
  }

  async missingDataError(message: string) {
    const alert = await this.alertCtrl.create({
      header: 'Error',
      message,
      buttons: ['OK'],
    });

    await alert.present();
  }

  async loadMentionables() {
    let tmpMentionables = [];

    const allUnblockedVillagers = this.store.selectSnapshot(
      VillagerState.allUnblockedVillagersExcludeCurrent
    );
    tmpMentionables = [
      ...allUnblockedVillagers.map((villager) => villager._UID),
    ];

    this.mentionables = [...new Set(tmpMentionables)];
    console.log('[POST BODY INPUT] Mentionables: ', this.mentionables);
  }
}
