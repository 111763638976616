import { Component, Input, OnInit } from '@angular/core';
import {
  AngularFireStorage,
  AngularFireStorageReference,
  AngularFireUploadTask,
} from '@angular/fire/storage';
import { combineLatest, from, Observable } from 'rxjs';
import firebase from 'firebase';
import { filter, switchMap } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { VillageState } from 'src/app/state/village.state';
import 'firebase/firestore';
import { AnalyticsService } from 'src/app/analytics.service';
import { LoadingController } from '@ionic/angular';
import { StoredDocument } from 'src/app/models/post-core.model';

@Component({
  selector: 'app-post-file-upload',
  templateUrl: './post-file-upload.component.html',
  styleUrls: ['./post-file-upload.component.scss'],
})
export class PostFileUploadComponent implements OnInit {
  ref: AngularFireStorageReference;
  task: AngularFireUploadTask;
  files: File[] = [];
  uploads: any[];
  uploadPhotoPercent$: Observable<number>;
  uploadDocumentPercent$: Observable<number>;
  // uploadPercent: number;
  downloadURLs = [];
  uploading = false;

  loading: HTMLIonLoadingElement;

  // for picture uploads
  photoFiles: File[] = [];
  documentFiles: File[] = [];

  uniqueFileSeperator = '_upload_';

  constructor(
    private bucket: AngularFireStorage,
    private store: Store,
    private analytics: AnalyticsService,
    private loadingCtrl: LoadingController
  ) {}

  // for existing files on post
  @Input() tmpImageUrls: string[] = [];
  @Input() tmpDocUrls: StoredDocument[] = [];
  ngOnInit() {}

  fileChange(event) {
    console.log('File change: ', event);
    this.files.push(event.target.files[0]);
  }

  onSelect(event) {
    console.log(event);
    if (
      this.tmpImageUrls.length +
        this.tmpDocUrls.length +
        this.photoFiles.length +
        this.documentFiles.length >=
      3
    ) {
      alert('You can only add 3 images or documents for now!');
    } else {
      event.addedFiles.forEach(async (file: File) => {
        try {
          const valid = await file.slice(0, 1).arrayBuffer(); // try to read only the first byte
          if (valid) {
            if (file.type.includes('image/')) {
              console.log('Adding IMAGE: ', file);
              this.photoFiles.push(file);
              console.log('Files: ', this.photoFiles);
            } else {
              console.log('Adding DOCUMENT: ', file);
              this.documentFiles.push(file);
            }
          } else {
            alert(
              `File is invalid. Suggestion: Uploads don't work directly from Google Drive. Use local files instead`
            );
          }
        } catch (e) {
          alert(
            `File is invalid. Suggestion: Uploads don't work directly from Google Drive. Use local files instead`
          );
        }
      });
    }
  }

  public uploadDocuments(): Promise<StoredDocument[]> {
    return new Promise((resolve, reject) => {
      if (this.documentFiles.length === 0) {
        resolve([]);
      } else {
        console.log('Uploading new documents: ', this.documentFiles);
        this.presentLoading('Uploading documents');
        const storedDocuments: StoredDocument[] = [];
        const allPercentage: Observable<number>[] = [];
        const villageId = this.store.selectSnapshot(VillageState.uid);
        const downloadUrls$ = this.documentFiles.map((file: File) => {
          // create a random id
          const randomId = Math.random().toString(36).substring(2);
          const filePath = `villages/${villageId}/documents/${randomId}${this.uniqueFileSeperator}${file.name}`;
          storedDocuments.push({
            path: filePath,
            type: file.type,
          });
          console.log('Putting file on path: ', filePath);
          // create a reference to the storage bucket location
          const ref = this.bucket.ref(filePath);
          // the put method creates an AngularFireUploadTask
          // and kicks off the upload
          const task = ref.put(file);
          const _percentage$ = task.percentageChanges();
          allPercentage.push(_percentage$);
          // observe percentage changes
          this.uploadDocumentPercent$ = task.percentageChanges();

          // get notified when the download URL is available
          return task.snapshotChanges().pipe(
            filter((task) => task.state === firebase.storage.TaskState.SUCCESS),
            switchMap(() => from(ref.getDownloadURL()))
          );
        });
        combineLatest([...downloadUrls$]).subscribe(
          (urls) => {
            this.downloadURLs = urls;
            console.log('Have download urls: ', urls);
            this.uploading = false;
            if (this.loading) this.loading.dismiss();
            resolve(storedDocuments);
          },
          (err) => {
            this.analytics.logEvent('create_post_upload_photo_error', {
              error: err,
            });
            this.uploading = false;
            if (this.loading) this.loading.dismiss();
            reject(err);
          }
        );
      }
    });
  }

  public uploadPhotos(): Promise<string[]> {
    return new Promise((resolve, reject) => {
      if (this.photoFiles.length === 0) {
        resolve([]);
      } else {
        this.presentLoading('Uploading Photos');
        const thumbPaths = [];
        this.uploading = true;
        this.uploads = [];
        const villageId = this.store.selectSnapshot(VillageState.uid);
        const allPercentage: Observable<number>[] = [];
        const downloadUrls$ = this.photoFiles.map((file) => {
          const ts = new Date().getTime().toString();
          const filePath = `villages/${villageId}/pictures/${ts}_${file.name}`;
          const thumbPath = `villages/${villageId}/pictures/thumbs/${ts}_${file.name}`;
          thumbPaths.push(thumbPath.replace(/\.[^/.]+$/, '')); // remove the extension
          const fileRef = this.bucket.ref(filePath);
          const task = this.bucket.upload(filePath, file);
          const _percentage$ = task.percentageChanges();
          allPercentage.push(_percentage$);

          // observe percentage changes
          this.uploadPhotoPercent$ = task.percentageChanges();

          // get notified when the download URL is available
          return task.snapshotChanges().pipe(
            filter((task) => task.state === firebase.storage.TaskState.SUCCESS),
            switchMap(() => from(fileRef.getDownloadURL()))
          );
        });

        combineLatest([...downloadUrls$]).subscribe(
          (urls) => {
            this.downloadURLs = urls;
            console.log('Have photo download urls: ', urls);
            this.uploading = false;
            if (this.loading) this.loading.dismiss();
            resolve(thumbPaths);
          },
          (err) => {
            this.analytics.logEvent('create_post_upload_photo_error', {
              error: err,
            });
            this.uploading = false;
            if (this.loading) this.loading.dismiss();
            reject(err);
          }
        );
      }
    });
  }

  removePhoto(event) {
    console.log(event);
    this.photoFiles.splice(this.photoFiles.indexOf(event), 1);
  }

  removeDocument(document) {
    this.documentFiles.splice(this.documentFiles.indexOf(document), 1);
  }

  async presentLoading(message: string) {
    this.loading = await this.loadingCtrl.create({
      duration: 15000,
      message,
      backdropDismiss: true,
    });
    return this.loading.present();
  }
}
