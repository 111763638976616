<ion-grid>
  <ion-row>
    <ion-col size="6">
      <div *ngIf="bulletin?.FEELING" style="margin-left: 4px">
        <div class="subheader">Feeling</div>
        <div style="margin-left: -5px">
          <span>
            {{ bulletin?.FEELING | titlecase }}
          </span>
          <span style="font-size: 24px">{{ emoji }}</span>
        </div>
      </div>
    </ion-col>
    <!-- <ion-col size="8">
      <div class="subheader">Topics</div>
      <div>
        <ion-chip *ngFor="let topic of bulletin.TOPICS">
          <ion-label>{{ topic }}</ion-label>
        </ion-chip>
      </div>
    </ion-col> -->
  </ion-row>
  <ion-row>
    <ion-col>
      <div *ngIf="bulletin?.BODY">
        <div
          class="post-message-body"
          *ngIf="!showFullBody; else fullBody"
          [innerHtml]="
            bulletin.BODY
              | truncateBody: 400:true
              | mentionified: bulletin._MENTION_UIDS
              | safehtml
          "
        ></div>
        <ion-button
          style="float: right; margin-top: 5px"
          size="small"
          color="dark"
          fill="outline"
          *ngIf="bulletin.BODY.length > 400 && !showFullBody"
          (click)="goToDetails()"
          >Read full post
          <ion-icon name="chevron-forward-outline"></ion-icon>
        </ion-button>
        <ng-template #fullBody>
          <div
            (click)="clickBody($event)"
            class="post-message-body"
            [innerHtml]="
              bulletin.BODY | mentionified: bulletin._MENTION_UIDS | safehtml
            "
          ></div>
        </ng-template>
      </div>
    </ion-col>
  </ion-row>
</ion-grid>

<div *ngIf="bulletin?.IMAGE_PATHS.length > 0">
  <ion-grid style="padding: 0">
    <ion-row>
      <ion-col *ngFor="let path of bulletin?.IMAGE_PATHS">
        <div class="post-image-wrapper">
          <app-post-image [path]="path"></app-post-image>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</div>
