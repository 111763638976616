import { Injectable } from '@angular/core';
import {
  Connected,
  Disconnect,
  Emitted,
  NgxsFirestoreConnect,
  StreamConnected,
  StreamEmitted,
} from '@ngxs-labs/firestore-plugin';
import {
  Action,
  NgxsOnInit,
  Selector,
  State,
  StateContext,
  Store,
} from '@ngxs/store';
import { GenericPost } from '../models/post-core.model';
import { NotificationService } from '../services/notification.service';
import {
  FetchVillagerNotifications,
  MarkNotificationAsRead,
  AppNotificationPostLookup,
  SetPushBadgeCount,
  SetSelectedPost,
  PushNotificationPostLookup,
  ClearPushNotification,
  RefreshVillagerNotifications,
  ClearAllNotitications,
  MarkMatchingNotificationsAsRead,
  FilterAppNotifications,
  DeleteAppNotification,
  CourtyardNotificationLookup,
  SetNotificationVillageFilter,
  ClearNotificationVillageFilter,
} from './app.actions';
import { AppNotification } from '../models/notification.model';
import * as firebase from 'firebase';
import { concatMap, take, tap } from 'rxjs/operators';
import 'firebase/firestore';
import { PushNotificationService } from '../services/push-notification.service';
import { AnalyticsService } from '../analytics.service';
import { HangoutService } from '../services/hangout.service';
import { ShareService } from '../services/share.service';
import { ConflictResolutionService } from '../services/conflict-resolution.service';
import { ExchangeService } from '../services/exchange.service';
import { SupportRequestService } from '../services/support-request.service';
import { Observable } from 'rxjs';
import { ModalController, Platform, ToastController } from '@ionic/angular';
import { VillageChatroomModalPage } from '../modals/village-chatroom-modal/village-chatroom-modal.page';
import { AnnouncementService } from '../services/announcement.service';
import { Router } from '@angular/router';
import { CourtyardService } from '../services/courtyard.service';
import { Courtyard } from '../models/courtyard.model';
import { CourtyardActions } from './courtyard.actions';
import { CourtyardChatModalPage } from '../modals/courtyard-chat-modal/courtyard-chat-modal.page';

export interface NotificationStateModel {
  notifications: AppNotification[];
  filteredNotifications: AppNotification[];
  villageFilter: string;
  notificationPost: GenericPost;
  typeFilter: 'courtyard' | 'village' | 'messages' | 'all';
}

@State<NotificationStateModel>({
  name: 'notifications',
  defaults: {
    notifications: [],
    villageFilter: null,
    filteredNotifications: [],
    notificationPost: null,
    typeFilter: null,
  },
})
@Injectable()
export class NotificationState implements NgxsOnInit {
  component = 'notification-state';
  constructor(
    private ngxsFirestoreConnect: NgxsFirestoreConnect,
    private notificationSvc: NotificationService,
    private pushSvc: PushNotificationService,
    private store: Store,
    private analytics: AnalyticsService,
    private hangoutSvc: HangoutService,
    private shareSvc: ShareService,
    private conflictSvc: ConflictResolutionService,
    private exchangeSvc: ExchangeService,
    private supportSvc: SupportRequestService,
    private annoucementSvc: AnnouncementService,
    private toastCtrl: ToastController,
    private modalCtrl: ModalController,
    private platform: Platform,
    private router: Router,
    private courtyardSvc: CourtyardService
  ) {}

  ngxsOnInit() {
    this.ngxsFirestoreConnect.connect(FetchVillagerNotifications, {
      to: (action) => {
        switch (action.payload.villageName) {
          case 'All Villages':
            return this.notificationSvc.collection$((ref) =>
              ref.limit(40).orderBy('CREATED_AT', 'desc')
            );
          default:
            return this.notificationSvc.collection$((ref) =>
              ref
                .where('VILLAGE_NAME', '==', action.payload.villageName)
                .limit(40)
                .orderBy('CREATED_AT', 'desc')
            );
        }
      },
      cancelPrevious: true,
    });
  }

  @Selector()
  static notificationPost(state: NotificationStateModel): GenericPost {
    return state.notificationPost;
  }

  @Selector()
  static notifications(state: NotificationStateModel): AppNotification[] {
    return state.notifications;
  }

  @Selector()
  static filteredNotifications(
    state: NotificationStateModel
  ): AppNotification[] {
    return state.filteredNotifications;
  }

  @Action(CourtyardNotificationLookup)
  courtyardLookup(
    ctx: StateContext<NotificationStateModel>,
    action: CourtyardNotificationLookup
  ) {
    const { villageId, courtyardId } = action.payload;
    console.log('Getting courtyard doc: ', villageId, courtyardId);
    this.courtyardSvc.setVillageId(villageId);
    return this.courtyardSvc.docOnce$(courtyardId).pipe(
      tap((doc: Courtyard) => {
        if (doc) {
          console.log('Got courtyard doc: ', doc);
          ctx.dispatch(new CourtyardActions.SetSelected({ courtyard: doc }));

          if (this.platform.is('mobile')) {
            this.openCourtyardChatroomModal(doc);
          } else {
            this.router.navigateByUrl(`pages/home`);
          }
        } else {
          this.presentErrorToast();
        }
      })
    );
  }

  @Action(AppNotificationPostLookup)
  getPost(
    ctx: StateContext<NotificationStateModel>,
    action: AppNotificationPostLookup
  ) {
    let fetch$: Observable<GenericPost>;
    const { postId, villageId } = action.payload;
    switch (action.payload.type) {
      case 'HANGOUTS':
        this.hangoutSvc.setVillageId(villageId);
        fetch$ = this.hangoutSvc.docOnce$(postId);
        break;
      case 'SHARES':
        this.shareSvc.setVillageId(villageId);
        fetch$ = this.shareSvc.docOnce$(postId);
        break;
      case 'EXCHANGE':
        this.exchangeSvc.setVillageId(villageId);
        fetch$ = this.exchangeSvc.docOnce$(postId);
        break;
      case 'SUPPORT_REQUESTS':
        this.supportSvc.setVillageId(villageId);
        fetch$ = this.supportSvc.docOnce$(postId);
        break;
      case 'CONFLICTS':
        this.conflictSvc.setVillageId(villageId);
        fetch$ = this.conflictSvc.docOnce$(postId);
        break;
      case 'ANNOUNCEMENTS':
        this.annoucementSvc.setVillageId(villageId);
        fetch$ = this.annoucementSvc.docOnce$(postId);
        break;
      default:
        console.error('Missing type for notification lookup', action.payload);
        break;
    }
    if (fetch$) {
      return fetch$.pipe(
        tap((doc: GenericPost) => {
          if (doc) {
            console.log('Fetched doc from notification lookup: ', doc);
            ctx
              .dispatch(new SetSelectedPost({ post: doc, location: 'CHAT' }))
              .subscribe(() => this.openPostChatroomModal());
          } else {
            this.presentErrorToast();
          }
        })
      );
    }
  }

  // TODO marge this AppNotificationPostLookup. Same same.
  @Action(PushNotificationPostLookup)
  getPostFromPush(
    ctx: StateContext<NotificationStateModel>,
    action: AppNotificationPostLookup
  ) {
    let fetch$: Observable<GenericPost>;
    const { postId, villageId } = action.payload;
    switch (action.payload.type) {
      case 'HANGOUTS':
        this.hangoutSvc.setVillageId(villageId);
        fetch$ = this.hangoutSvc.docOnce$(postId);
        break;
      case 'SHARES':
        this.shareSvc.setVillageId(villageId);
        fetch$ = this.shareSvc.docOnce$(postId);
        break;
      case 'EXCHANGE':
        this.exchangeSvc.setVillageId(villageId);
        fetch$ = this.exchangeSvc.docOnce$(postId);
        break;
      case 'SUPPORT_REQUESTS':
        this.supportSvc.setVillageId(villageId);
        fetch$ = this.supportSvc.docOnce$(postId);
        break;
      case 'CONFLICTS':
        this.conflictSvc.setVillageId(villageId);
        fetch$ = this.conflictSvc.docOnce$(postId);
        break;
      case 'ANNOUNCEMENTS':
        this.annoucementSvc.setVillageId(villageId);
        fetch$ = this.annoucementSvc.docOnce$(postId);
        break;
      default:
        console.error('Missing type for notification lookup', action.payload);
        break;
    }
    if (fetch$) {
      return fetch$.pipe(
        take(1),
        tap((doc: GenericPost) => {
          if (doc) {
            console.log('Fetched doc from push notification lookup: ', doc);
            ctx
              .dispatch(new SetSelectedPost({ post: doc, location: 'CHAT' }))
              .subscribe(() => {
                this.openPostChatroomModal();
              });
          } else {
            this.presentErrorToast();
          }
        })
      );
    }
  }

  @Action(DeleteAppNotification)
  delete(ctx, action: DeleteAppNotification) {
    return this.notificationSvc.delete$(action.payload.notification._UID);
  }

  @Action(SetNotificationVillageFilter)
  filterPosts(
    ctx: StateContext<NotificationStateModel>,
    action: SetNotificationVillageFilter
  ) {
    const { villageName } = action.payload;
    const { typeFilter } = ctx.getState();

    console.log('DEBUG type filter: ', typeFilter);

    ctx.patchState({
      villageFilter: villageName,
    });

    ctx.dispatch(new FilterAppNotifications({ filter: typeFilter }));
  }

  @Action(ClearNotificationVillageFilter)
  clearVillageFilter(
    ctx: StateContext<NotificationStateModel>,
    action: ClearNotificationVillageFilter
  ) {
    ctx.patchState({ villageFilter: null });

    const { typeFilter } = ctx.getState();
    ctx.dispatch(new FilterAppNotifications({ filter: typeFilter }));
  }

  @Action(SetPushBadgeCount)
  setBadgeCount() {
    console.log('[App] Setting badge count');
    this.pushSvc.setBadgeCount();
  }

  @Action(RefreshVillagerNotifications)
  refresh(ctx, action: RefreshVillagerNotifications) {
    const { villagerUid } = action.payload;
    if (!villagerUid) {
      alert('Error. Cant refresh notifications.');
      this.analytics.logError(
        'error_refresh_notifications',
        this.component,
        new Error('No id to refresh with')
      );
    } else {
      this.notificationSvc.setVillagerId(villagerUid);
      return this.store
        .dispatch(new Disconnect(FetchVillagerNotifications))
        .pipe(
          concatMap(() =>
            this.store.dispatch(
              new FetchVillagerNotifications({
                villagerId: villagerUid,
                villageName: ctx.getState().villageFilter || 'All Villages',
              })
            )
          )
        );
    }
  }

  @Action(FetchVillagerNotifications)
  fetch(ctx, action: FetchVillagerNotifications) {
    this.notificationSvc.setVillagerId(action.payload.villagerId);
  }

  @Action(StreamConnected(FetchVillagerNotifications))
  notificationsConnected(
    ctx: StateContext<NotificationStateModel>,
    { action }: Connected<FetchVillagerNotifications>
  ) {
    console.log('[Villager State] AppNotification Stream connected');
  }

  @Action(StreamEmitted(FetchVillagerNotifications))
  notificationsEmitted(
    ctx: StateContext<NotificationStateModel>,
    { action, payload }: Emitted<FetchVillagerNotifications, AppNotification[]>
  ) {
    console.log(
      '[AppNotification State] successfully fetched notifications: ',
      payload.length
    );
    ctx.patchState({
      notifications: payload.sort(
        (a: AppNotification, b: AppNotification) => b.CREATED_AT - a.CREATED_AT
      ),
    });

    const { typeFilter } = ctx.getState();
    if (typeFilter) {
      ctx.dispatch(new FilterAppNotifications({ filter: typeFilter }));
    } else {
      ctx.dispatch(new FilterAppNotifications({ filter: 'all' }));
    }
  }

  @Action(FilterAppNotifications)
  filterNotifications(
    ctx: StateContext<NotificationStateModel>,
    action: FilterAppNotifications
  ) {
    const { notifications, villageFilter } = ctx.getState();
    const { filter } = action.payload;

    let filteredNotifications = [];
    ctx.patchState({ typeFilter: filter });
    switch (filter) {
      case 'all':
        filteredNotifications = [...notifications];
        break;
      case 'messages':
        filteredNotifications = [
          ...notifications.filter((x) => x.CONTENT_TYPE === 'DIRECT'),
        ];
        break;
      case 'village':
        filteredNotifications = [
          ...notifications.filter(
            (x) =>
              x.CONTENT_TYPE !== 'DIRECT' && x.CONTENT_TYPE !== 'COURTYARDS'
          ),
        ];
        break;
      case 'courtyard':
        filteredNotifications = [
          ...notifications.filter((x) => x.CONTENT_TYPE === 'COURTYARDS'),
        ];

        break;
      default:
        ctx.patchState({
          filteredNotifications: [...notifications],
        });
        break;
    }

    if (filter !== 'messages' && villageFilter)
      filteredNotifications = [
        ...filteredNotifications.filter(
          (x) => x.VILLAGE_NAME === villageFilter
        ),
      ];

    const unreadCount = filteredNotifications.filter((x) => !x.READ).length;
    console.log(
      `DEBUG filtered down to : ${filteredNotifications.length} unread: ${unreadCount}`
    );

    ctx.patchState({ filteredNotifications });
  }

  @Action(MarkMatchingNotificationsAsRead)
  markAllMatchingAsRead(
    ctx: StateContext<NotificationStateModel>,
    action: MarkMatchingNotificationsAsRead
  ) {
    const { notifications } = ctx.getState();
    const unreadMatchingNotifications = notifications.filter(
      (x) => x.CONTENT_UID === action.payload.contentId && x.READ === false
    );

    console.log(
      `[Debug] Marking matching notifications: `,
      unreadMatchingNotifications
    );

    unreadMatchingNotifications.forEach((notification) => {
      ctx.dispatch(
        new MarkNotificationAsRead({ notificationId: notification._UID })
      );
    });
  }

  @Action(MarkNotificationAsRead)
  markNotificationAsRead(
    ctx: StateContext<NotificationStateModel>,
    action: MarkNotificationAsRead
  ) {
    console.log(
      `[DEBUG] Marking notification read: ${action.payload.notificationId}`
    );
    return this.notificationSvc.updateIfExists(action.payload.notificationId, {
      READ: true,
      READ_AT: firebase.default.firestore.FieldValue.serverTimestamp(),
    });
  }

  @Action(ClearPushNotification)
  clearPush(ctx, action: ClearPushNotification) {
    this.pushSvc.clearNotificationsByContentId(action.payload.contentId);
  }

  @Action(ClearAllNotitications)
  clear(ctx: StateContext<NotificationStateModel>) {
    ctx.setState({
      notifications: [],
      villageFilter: null,
      filteredNotifications: [],
      notificationPost: null,
      typeFilter: null,
    });
  }

  public async openPostChatroomModal() {
    const existingModal = await this.modalCtrl.getTop();
    if (existingModal) await this.modalCtrl.dismiss();
    const modal = await this.modalCtrl.create({
      component: VillageChatroomModalPage,
    });

    return await modal.present();
  }

  public async openCourtyardChatroomModal(courtyard) {
    const existingModal = await this.modalCtrl.getTop();
    if (existingModal) await this.modalCtrl.dismiss();
    console.log('launching modal');
    const modal = await this.modalCtrl.create({
      component: CourtyardChatModalPage,
      componentProps: { courtyard },
    });

    return await modal.present();
  }

  async presentErrorToast() {
    const toast = await this.toastCtrl.create({
      header: 'Error. Cannot find this post',
      message: `It might have been deleted by the villager`,
      color: 'primary',
      duration: 3000,
      position: 'top',
    });
    toast.present();
  }
}
