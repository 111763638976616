import { Injectable } from '@angular/core';
import { NgxsFirestore } from '@ngxs-labs/firestore-plugin';
import * as firebase from 'firebase';
import 'firebase/firestore';
import { of } from 'rxjs';
import { Village } from '../models/village.model';

export const sanitizeVillage = (value: Village): Village => {
  let db = { ...value };

  if (db.hasOwnProperty('VALUES')) {
    db = {
      ...db,
      VALUES: encodeURI(db.VALUES),
    };
  }
  if (db.hasOwnProperty('VISION')) {
    db = {
      ...db,
      VISION: encodeURI(db.VISION),
    };
  }
  if (db.hasOwnProperty('VILLAGERS')) {
    db = {
      ...db,
      VILLAGERS: [...new Set(db.VILLAGERS)],
    };
  }

  console.log('[VILLAGE SERVICE] sending to database: ', db);
  return db;

}

export const hydrateVillage = (snapshot, options) => {
  let data = snapshot.data(options) as Village;
  console.log('[VILLAGE SERVICE] returning from database: ', data);

  data = {
    ...data,
    CREATED_AT: data.CREATED_AT.toDate(),
  }

  if (!data.hasOwnProperty('PUSH_RECIPIENTS')) {
    data = {
      ...data,
      PUSH_RECIPIENTS: [],
    };
  }
  if (data.hasOwnProperty('VALUES')) {
    data = {
      ...data,
      VALUES: decodeURI(data.VALUES),
    };
  } else {
    data = {
      ...data,
      VALUES: '',
    };
  }
  if (data.hasOwnProperty('VISION')) {
    data = {
      ...data,
      VISION: decodeURI(data.VISION),
    };
  } else {
    data = {
      ...data,
      VISION: '',
    };
  }
  if (data.hasOwnProperty('VILLAGERS')) {
    data = {
      ...data,
      VILLAGERS: [...new Set(data.VILLAGERS)],
    };
  }

  // patch no TOPICS
  if (!data.hasOwnProperty('TOPICS')) {
    data.TOPICS = [];
  }

  // patch no CONFIG_SHOW_EMAIL
  if (!data.hasOwnProperty('CONFIG_SHOW_EMAIL')) {
    data.CONFIG_SHOW_EMAIL = true;
  }

  // patch no CONFIG_SHOW_CONFLICT_RES
  if (!data.hasOwnProperty('CONFIG_SHOW_CONFLICT_RES')) {
    data.CONFIG_SHOW_CONFLICT_RES = true;
  }

  // patch no CONFIG_SHOW_CREATE_CIRCLE
  if (!data.hasOwnProperty('CONFIG_SHOW_CREATE_CIRCLE')) {
    data.CONFIG_SHOW_CREATE_CIRCLE = true;
  }

  // patch no CONFIG_SHOW_SWITCH_VILLAGE
  if (!data.hasOwnProperty('CONFIG_SHOW_SWITCH_VILLAGE')) {
    data.CONFIG_SHOW_SWITCH_VILLAGE = true;
  }

  if (!data.hasOwnProperty('CONFIG_VILLAGE_LABEL')) {
    data.CONFIG_VILLAGE_LABEL = 'village';
  }

  if (!data.hasOwnProperty('CONFIG_COURTYARDS_LABEL')) {
    data.CONFIG_COURTYARDS_LABEL = 'channel';
  }

  if (!data.hasOwnProperty('CONFIG_MEMBERS_LABEL')) {
    data.CONFIG_MEMBERS_LABEL = 'member';
  }

  if (!data.hasOwnProperty('CONFIG_STEWARDS_LABEL')) {
    data.CONFIG_STEWARDS_LABEL = 'steward';
  }

  if (!data.hasOwnProperty('CONFIG_KARMA_LABEL')) {
    data.CONFIG_KARMA_LABEL = 'karma';
  }

  if (!data.hasOwnProperty('CONFIG_SHOW_KARMA')) {
    data.CONFIG_SHOW_KARMA = true;
  }

  if (!data.hasOwnProperty('CONFIG_SHOW_POTLUCK')) {
    data.CONFIG_SHOW_POTLUCK = true;
  }

  if (!data.hasOwnProperty('CONFIG_SHOW_COFFEE')) {
    data.CONFIG_SHOW_COFFEE = true;
  }

  if (!data.hasOwnProperty('CONFIG_SHOW_PARTY')) {
    data.CONFIG_SHOW_PARTY = true;
  }

  if (!data.hasOwnProperty('CONFIG_SHOW_ADVENTURE')) {
    data.CONFIG_SHOW_ADVENTURE = true;
  }

  if (!data.hasOwnProperty('CONFIG_SHOW_DUO')) {
    data.CONFIG_SHOW_DUO = true;
  }

  if (!data.hasOwnProperty('CONFIG_SHOW_TRIO')) {
    data.CONFIG_SHOW_TRIO = true;
  }

  if (!data.hasOwnProperty('CONFIG_SHOW_EMOTIONAL_SUPPORT')) {
    data.CONFIG_SHOW_EMOTIONAL_SUPPORT = true;
  }

  if (!data.hasOwnProperty('CONFIG_SHOW_OFFER')) {
    data.CONFIG_SHOW_OFFER = true;
  }

  if (!data.hasOwnProperty('CONFIG_SHOW_REQUEST')) {
    data.CONFIG_SHOW_REQUEST = true;
  }

  if (!data.hasOwnProperty('CONFIG_SHOW_SHARED_LIST')) {
    data.CONFIG_SHOW_SHARED_LIST = true;
  }

  if (!data.hasOwnProperty('CONFIG_IS_PRIVATE_WHITELABEL')) {
    data.CONFIG_IS_PRIVATE_WHITELABEL = false;
  }

  if (!data.hasOwnProperty('CONFIG_DUO_LABEL')) {
    data.CONFIG_DUO_LABEL = 'duo';
  }

  if (!data.hasOwnProperty('CONFIG_TRIO_LABEL')) {
    data.CONFIG_TRIO_LABEL = 'trio';
  }

  if (!data.hasOwnProperty('CONFIG_VILLAGERS_CAN_INVITE')) {
    data.CONFIG_VILLAGERS_CAN_INVITE = true;    // turn off in some cases like private white labels
  }

  if (!data.hasOwnProperty('CONFIG_CAN_MANAGE_POSTS')) {
    data.CONFIG_CAN_MANAGE_POSTS = [];
  }

  // console.log('RETURNING VILLAGE: ', JSON.stringify(data));
  return data;
}
@Injectable({
  providedIn: 'root',
})
export class VillageService extends NgxsFirestore<Village> {
  idField = '_UID';
  path = 'VILLAGES';

  /**
   * @deprecated
   */
  updateIfExists(id, data) {
    const convertedData = this.converter.toFirestore(data);

    return of(
      this.adapter.firestore.doc(`${this.path}/${id}`).ref.update(convertedData)
    );
  }

  updateWithoutConverter(id: string, update: Partial<Village>) {
    return of(this.adapter.firestore.doc(`${this.path}/${id}`).update(update));
  }

  converter: firebase.default.firestore.FirestoreDataConverter<Village> = {
    toFirestore: sanitizeVillage,
    fromFirestore: hydrateVillage
  };
}
