<img
  [ngClass]="isOwner ? 'owner-image' : 'other-image'"
  (click)="openViewImageModal(); $event.stopPropagation()"
  *ngIf="photoUrl$ | async as url; else loading"
  [src]="url"
/>
<ng-template #loading>
  <ion-skeleton-text
    animated
    style="width: 250px; height: 150px; border-radius: 10px"
  ></ion-skeleton-text>
</ng-template>
