import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { RecipientNotificationSettings } from '../models/notification-settings';
import { VillagerState } from '../state/villager.state';

@Injectable({
  providedIn: 'root'
})
export class CreatePostHelpersService {

  constructor(private store: Store) { }

  getNotificationRecipientsForCircle(
    circleIds: string[]
  ): RecipientNotificationSettings {
    const villageId = this.store.selectSnapshot(
      VillagerState.currentVillager
    ).VILLAGE;
    const fellowVillagers = this.store.selectSnapshot(
      VillagerState.fellowVillagers
    );
    return fellowVillagers.reduce((acc, x) => {
      const joinedVillage = x.VILLAGES.find((y) => y.UID === villageId);
      const joinedCircleIds = joinedVillage.CIRCLE_UIDS;
      if (
        !joinedVillage ||
        !joinedCircleIds.some((id) => circleIds.includes(id))
      ) {
        return acc;
      }

      const { FCM_TOKEN } = x;
      const { PUSH } = joinedVillage;
      return { ...acc, [x._UID]: { PUSH, FCM_TOKEN } };
    }, {} as RecipientNotificationSettings);
  }
}
