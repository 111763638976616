import { Injectable } from '@angular/core';
import { NgxsFirestore } from '@ngxs-labs/firestore-plugin';
import { ChatMessage } from '../models/chat-message.model';
import * as firebase from 'firebase';
import 'firebase/firestore';
import { of } from 'rxjs';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class CourtyardMessageService extends NgxsFirestore<ChatMessage> {
  idField = '_UID';

  protected get path() {
    return `VILLAGES/${this.villageId}/COURTYARDS/${this.courtyardId}/MESSAGES`;
  }

  private VILLAGE_UID;
  private COURTYARD_UID;

  public setVillageId(villageId) {
    this.VILLAGE_UID = villageId;
  }

  public setCourtyardId(uid) {
    // alert('Setting Courtyard ID: ' + uid);
    this.COURTYARD_UID = uid;
  }

  protected get villageId() {
    return this.VILLAGE_UID;
  }

  protected get courtyardId() {
    return this.COURTYARD_UID;
  }

  /**
   * @deprecated
   */
  updateIfExists(id, data) {
    return of(
      this.adapter.firestore
        .doc(`${this.path}/${id}`)
        .ref.withConverter(this.converter)
        .update(data)
    );
  }

  updateWithoutConverter(id: string, update: Partial<ChatMessage>) {
    return of(this.adapter.firestore.doc(`${this.path}/${id}`).update(update));
  }

  sendMessage(message: ChatMessage) {
    return new Promise((resolve, reject) => {
      if (!this.villageId || !this.courtyardId) {
        console.error(
          'No village or courtyard ids set in path. ',
          this.villageId,
          this.courtyardId
        );
        reject('Incorrect path, missing parts');
      } else {
        resolve(this.create$(message));
      }
    });
  }

  fromFirestore(messages: any[]): ChatMessage[] {
    messages.forEach((x, idx) => {
      if (idx > 0) {
        const previousMessage = messages[idx - 1];
        if (previousMessage._CREATOR_UID !== x._CREATOR_UID) {
          x.SHOW_TIMESTAMP = true;
          x.SHOW_AVATAR = true;
        } else if (
          moment(previousMessage._CREATED_AT).isAfter(
            moment(x._CREATED_AT).subtract(15, 'minutes')
          )
        ) {
          x.SHOW_TIMESTAMP = false;
          x.SHOW_AVATAR = false;
        } else {
          x.SHOW_TIMESTAMP = true;
          x.SHOW_AVATAR = true;
        }
      } else {
        x.SHOW_TIMESTAMP = true;
        x.SHOW_AVATAR = true;
      }
    });
    return messages.map((message: ChatMessage) => {
      message = {
        ...message,
        _CREATED_AT: message._CREATED_AT.toDate(),
        _BODY: decodeURI(message._BODY),
      };
      if (!message.hasOwnProperty('DELETED')) {
        message = {
          ...message,
          DELETED: false,
        };
      }

      if (!message.hasOwnProperty('_HAS_PHOTO')) {
        message = {
          ...message,
          _HAS_PHOTO: false,
        };
      }

      if (!message.hasOwnProperty('REACTIONS')) {
        message = {
          ...message,
          REACTIONS: [],
        };
      }
      return message;
    });
  }

  converter: firebase.default.firestore.FirestoreDataConverter<ChatMessage> = {
    toFirestore: (value: any) => {
      let db = { ...value };

      if (value.hasOwnProperty('_BODY')) {
        db = { ...value, _BODY: encodeURI(value._BODY) };
      }
      return db;
    },
    // DEPRECATED
    fromFirestore: (snapshot, options) => {
      let data = snapshot.data(options) as ChatMessage;

      if (snapshot.metadata.hasPendingWrites) {
        const ts = firebase.default.firestore.Timestamp.now();
        data = {
          ...data,
          _BODY: decodeURI(data._BODY),
          _CREATED_AT: ts.toDate(),
        };
      } else {
        data = {
          ...data,
          _BODY: decodeURI(data._BODY),
          _CREATED_AT: data._SERVER_TIMESTAMP.toDate(),
        };
      }
      if (!data.hasOwnProperty('DELETED')) {
        data = {
          ...data,
          DELETED: false,
        };
      }

      if (!data.hasOwnProperty('_HAS_PHOTO')) {
        data = {
          ...data,
          _HAS_PHOTO: false,
        };
      }

      if (!data.hasOwnProperty('REACTIONS')) {
        data = {
          ...data,
          REACTIONS: [],
        };
      }

      return data;
    },
  };
}
