import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { AnalyticsService } from 'src/app/analytics.service';
import { EmojiMartModalPage } from 'src/app/modals/emoji-mart-modal/emoji-mart-modal.page';
import { PostViewImagesModalPage } from 'src/app/modals/post-view-images-modal/post-view-images-modal.page';
import { ChatMessage } from 'src/app/models/chat-message.model';
import { ImageService } from 'src/app/services/images.service';

@Component({
  selector: 'app-chat-image',
  templateUrl: './chat-image.component.html',
  styleUrls: ['./chat-image.component.scss'],
})
export class ChatImageComponent implements OnInit {
  tryCount = 0;
  MAX_TRY = 2;
  errorWithFile = false;
  photoUrl$: Observable<string>;
  @Input() allowExpansion = true;
  @Input() url: string;
  @Input() message: ChatMessage;
  @Input() type: 'post' | 'dm' | 'courtyard';
  @Input() isOwner: boolean = false;

  constructor(
    private imageSvc: ImageService,
    private modalCtrl: ModalController,
    private analytics: AnalyticsService,
  ) {}

  ngOnInit() {
    if (this.url) {
      this.photoUrl$ = this.imageSvc.getPostPhoto(this.url, '256');
      let timer;
      const sub = this.photoUrl$.subscribe(
        () => {
          sub.unsubscribe();
          clearInterval(timer);
        },
        (err) => {
          timer = setInterval(() => {
            if (this.tryCount < this.MAX_TRY) {
              this.tryCount++;
              this.photoUrl$ = this.imageSvc.getPostPhoto(this.url, '256');
              const newSub = this.photoUrl$.subscribe(() => {
                newSub.unsubscribe();
                clearInterval(timer);
              });
            } else {
              clearInterval(timer);
              this.errorWithFile = true;
            }
          }, 5000);
        }
      );
    }
  }

  public async openViewImageModal() {
    this.analytics.logEvent('chat_view_image_clicked', {});
    if (!this.allowExpansion) return false;
    const modal = await this.modalCtrl.create({
      component: PostViewImagesModalPage,
      componentProps: {
        imagePath: this.url,
        message: this.message,
        type: this.type
      },
    });

    return await modal.present();
  }
}
