import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class VoiceNoteService {

  constructor(private bucket: AngularFireStorage) {}

  // voice-notes are stored with their path. i.e.:
  // villages/<UID>/voice-notes/beazy-d_If-VScyBY-unsplash
  // we need to append the extension (always .wav)
  getVoiceNote(
    filePath: string,
  ): Observable<string> {
    console.log('[Voice Note Service] Getting voice-note: ', filePath);

    const ref = this.bucket.ref(filePath);

    return ref.getDownloadURL().pipe(take(1));
  }
}
