import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
  // console.log = () => {};   // remove logs in prod
  // console.warn = () => {};   // remove warn in prod
  // console.error = () => {};   // remove errors in prod
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
