<ion-header class="ion-no-border" translucent="false">
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button (click)="modalCtrl.dismiss({action: 'regular-close'})">
        <ion-icon color="dark" slot="icon-only" name="close-circle"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title
      ><span *ngIf="!edit">Create</span><span *ngIf="edit">Update</span>
      <span *ngIf="type === 'REQUEST'"> Request</span>
      <span *ngIf="type === 'OFFERING'"> Offering</span>
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-card>
    <ion-card-content style="padding: 20px">
      <form [formGroup]="exchangeForm" style="margin-bottom: 20px">
        <ion-item class="full-width-item form-outline-item" lines="none">
          <ion-input
            autocapitalize="on"
            autocorrect="on"
            [ngClass]="{
              invalid: title.invalid && (title.dirty || title.touched)
            }"
            formControlName="title"
            [placeholder]="placeholder"
          ></ion-input>
        </ion-item>

        <app-post-mentionable-input
          [mentionables]="mentionables"
          [placeholderText]="placeholder"
          [shouldDisplayAbove]="false"
        >
        </app-post-mentionable-input>
        <ion-item class="full-width-item">
          <ion-label *ngIf="type === 'REQUEST'">Suggested Skill</ion-label>
          <ion-label *ngIf="type === 'OFFERING'">Related Skill</ion-label>
          <ion-select
            placeholder="Select One"
            [interfaceOptions]="customActionSheetOptions"
            interface="action-sheet"
            formControlName="skill"
          >
            <ion-select-option
              *ngFor="let skill of skills"
              [value]="skill.value"
            >
              {{ skill.title }}
            </ion-select-option>
          </ion-select>
        </ion-item>
      </form>

      <ion-grid *ngIf="edit">
        <ion-row *ngIf="tmpImageUrls.length > 0" class="ion-align-items-center">
          <ion-col size="12">
            <div class="subheader" style="margin-top: 10px; margin-bottom: 5px">
              Edit Photos
            </div>
          </ion-col>
          <ion-col *ngFor="let path of tmpImageUrls; let i = index">
            <div class="post-image-wrapper">
              <app-edit-images
                (deletePhotoEvent)="handleDeletePhoto($event, i)"
                [path]="path"
              ></app-edit-images>
            </div>
          </ion-col>
        </ion-row>
        <ion-row *ngIf="tmpDocUrls.length > 0" class="ion-align-items-center">
          <ion-col>
            <div class="subheader" style="margin-top: 10px; margin-bottom: 5px">
              Edit Documents
            </div>
            <app-post-document
              *ngFor="let doc of tmpDocUrls; let i = index"
              (click)="handleDeleteDocument(i)"
              [document]="doc"
              [edit]="true"
            ></app-post-document>
          </ion-col>
        </ion-row>
      </ion-grid>

      <ion-grid>
        <ion-row>
          <ion-col>
            <div class="subheader">Add to your post</div>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="12" sizeLg="4">
            <ion-button
              color="secondary"
              expand="block"
              (click)="showPhotos = !showPhotos"
            >
              <ion-icon slot="start" name="images"></ion-icon>
              <ion-label>Photos/Docs</ion-label>
            </ion-button>
          </ion-col>
          <ion-col size="12" sizeLg="4">
            <ion-button
              color="dark"
              expand="block"
              (click)="showTopics = !showTopics"
            >
              <ion-icon slot="start" name="pricetags"></ion-icon>
              <ion-label>Topics</ion-label>
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>

      <app-post-file-upload
        *ngIf="showPhotos"
        [tmpDocUrls]="tmpDocUrls"
        [tmpImageUrls]="tmpImageUrls"
      ></app-post-file-upload>

      <app-add-topics
        *ngIf="showTopics"
        [topics]="topics"
        (setTopics)="onSetTopics($event)"
      ></app-add-topics>

      <app-post-circle-assignment
        [alreadyAssignedCircles]="assignedCircles"
      ></app-post-circle-assignment>

      <ion-button
        *ngIf="!edit"
        [disabled]="exchangeForm.invalid"
        (click)="createExchangeItem()"
        color="primary"
        expand="block"
        style="margin-top: 20px"
      >
        Create

        <ion-chip
          *ngIf="(showKarma$ | async) === true"
          style="position: absolute; right: 5px"
          slot="end"
          color="light"
          class="karma-chip"
        >
          <ion-avatar>
            <img src="../../../assets/images/badge-images/karma.png" />
          </ion-avatar>
          <ion-label><strong>+{{EXCHANGE_KARMA}}</strong></ion-label>
        </ion-chip>

        <ion-icon
          *ngIf="!submitting"
          slot="end"
          name="arrow-forward-outline"
        ></ion-icon>
      </ion-button>
      <ion-button
        *ngIf="edit"
        color="primary"
        expand="block"
        (click)="updateExchange()"
        style="margin-top: 20px"
      >
        Update Exchange
      </ion-button>
    </ion-card-content>
  </ion-card>
</ion-content>
