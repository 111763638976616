import { Pipe, PipeTransform } from '@angular/core';
import { Villager } from '../models/villager.model';

@Pipe({
  name: 'villageridToName',
})
export class VillageridToNamePipe implements PipeTransform {
  transform(villagerId: string, allVillagers: Villager[]): string {
    const villager = allVillagers.find((x) => x._UID === villagerId);
    if (villager) {
      const { FIRST_NAME, LAST_NAME } = villager;
      return `${FIRST_NAME} ${LAST_NAME}`;
    } else return villagerId;
  }
}
