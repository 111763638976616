<ion-header class="ion-no-border" translucent="false">
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button (click)="modalCtrl.dismiss({action: 'regular-close'})">
        <ion-icon color="dark" slot="icon-only" name="close-circle"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title
      ><span *ngIf="!edit">Create </span
      ><span *ngIf="edit"> Update</span> {{type}}</ion-title
    >
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-card>
    <ion-card-content>
      <form [formGroup]="villageEventForm">
        <ion-item
          lines="none"
          class="full-width-item form-outline-item"
          [ngClass]="{
          invalid: title.invalid && (title.dirty || title.touched)
        }"
        >
          <ion-input
            autocapitalize="on"
            autocorrect="on"
            formControlName="title"
            placeholder="Give your event a title"
          ></ion-input>
        </ion-item>
        <ion-item
          lines="none"
          class="full-width-item form-outline-item"
          [ngClass]="{
          invalid: location.invalid && (location.dirty || location.touched)
        }"
        >
          <ion-input
            autocorrect="on"
            formControlName="location"
            placeholder="Enter location (zoom links ok)"
          ></ion-input>
        </ion-item>
        <ion-item
          *ngIf="!showStartTime"
          (click)="toggleShowStartTime()"
          class="full-width-item"
        >
          <ion-label>Add Start Time</ion-label>
          <ion-button shape="round" slot="end" color="primary">
            <ion-icon name="add-outline"></ion-icon>
          </ion-button>
        </ion-item>

        <ion-item
          [ngClass]="showStartTime ? 'ion-show': 'ion-hide'"
          class="full-width-item"
          button="true"
          id="open-startdate-input"
        >
          <ion-label>Starts</ion-label>
          <ion-text slot="end"
            >{{ startDateTime.value | date:'MMM d, y, h:mm a' }}</ion-text
          >
          <ion-icon slot="end" color="primary" name="calendar"></ion-icon>
          <ion-popover trigger="open-startdate-input" show-backdrop="true">
            <ng-template>
              <ion-datetime
                size="cover"
                [showDefaultButtons]="true"
                #popoverStarttime
                formControlName="startDateTime"
                presentation="time-date"
                [min]="minFutureDate"
                [max]="maxFutureDate"
                [minuteValues]="minuteValues"
              ></ion-datetime>
            </ng-template>
          </ion-popover>
        </ion-item>

        <ion-item
          *ngIf="!showEndTime"
          (click)="toggleShowEndTime()"
          class="full-width-item"
        >
          <ion-label>Add End Time</ion-label>
          <ion-button shape="round" slot="end" color="primary">
            <ion-icon name="add-outline"></ion-icon>
          </ion-button>
        </ion-item>

        <ion-item
          [ngClass]="showEndTime ? 'ion-show': 'ion-hide'"
          class="full-width-item"
          button="true"
          id="open-enddate-input"
        >
          <ion-label>Ends</ion-label>
          <ion-text slot="end"
            >{{ endDateTime.value | date:'MMM d, y, h:mm a' }}
          </ion-text>

          <ion-icon slot="end" color="primary" name="calendar"></ion-icon>

          <ion-popover trigger="open-enddate-input" show-backdrop="true">
            <ng-template>
              <ion-datetime
                size="cover"
                [showDefaultButtons]="true"
                #popoverEndtime
                formControlName="endDateTime"
                presentation="time-date"
                [min]="minFutureDate"
                [max]="maxFutureDate"
                [minuteValues]="minuteValues"
              ></ion-datetime>
            </ng-template>
          </ion-popover>
        </ion-item>

        <ion-item class="full-width-item">
          <ion-label>Capacity </ion-label>
          <ion-select
            [compareWith]="compareWith"
            formControlName="capacity"
            ok-text="Okay"
            cancel-text="Dismiss"
            [disabled]="type === 'Duo' || type === 'Trio'"
          >
            <ion-select-option
              *ngFor="let option of availableSpots"
              [value]="option"
              >{{option.name}}</ion-select-option
            >
          </ion-select>
        </ion-item>
      </form>

      <app-post-mentionable-input
        [mentionables]="mentionables"
        [placeholderText]="placeholderText"
        [shouldDisplayAbove]="false"
      >
      </app-post-mentionable-input>

      <app-event-organizers
        [selectedCircles]="assignedCircles"
        [selectedOrganizers]="assignedOrganizers"
        (assignOrganizers)="onSetOrganizers($event)"
      >
      </app-event-organizers>

      <ion-grid>
        <ion-row>
          <ion-col>
            <div class="subheader">Add to your post</div>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="12" sizeLg="4">
            <ion-button
              color="secondary"
              expand="block"
              (click)="showPhotos = !showPhotos"
            >
              <ion-icon slot="start" name="images"></ion-icon>
              <ion-label>Photos/Docs</ion-label>
            </ion-button>
          </ion-col>
          <ion-col size="12" sizeLg="4">
            <ion-button
              color="dark"
              expand="block"
              (click)="showTopics = !showTopics"
            >
              <ion-icon slot="start" name="pricetags"></ion-icon>
              <ion-label>Topics</ion-label>
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>

      <app-post-file-upload
        *ngIf="showPhotos"
        [tmpDocUrls]="tmpDocUrls"
        [tmpImageUrls]="tmpImageUrls"
      ></app-post-file-upload>

      <ion-grid *ngIf="edit">
        <ion-row *ngIf="tmpImageUrls.length > 0" class="ion-align-items-center">
          <ion-col size="12">
            <div class="subheader" style="margin-top: 10px; margin-bottom: 5px">
              Edit Photos
            </div>
          </ion-col>
          <ion-col *ngFor="let path of tmpImageUrls; let i = index">
            <div class="post-image-wrapper">
              <app-edit-images
                (deletePhotoEvent)="handleDeletePhoto($event, i)"
                [path]="path"
              ></app-edit-images>
            </div>
          </ion-col>
        </ion-row>
        <ion-row *ngIf="tmpDocUrls.length > 0" class="ion-align-items-center">
          <ion-col>
            <div class="subheader" style="margin-top: 10px; margin-bottom: 5px">
              Edit Documents
            </div>
            <app-post-document
              *ngFor="let doc of tmpDocUrls; let i = index"
              (click)="handleDeleteDocument(i)"
              [document]="doc"
              [edit]="true"
            ></app-post-document>
          </ion-col>
        </ion-row>
      </ion-grid>

      <app-add-topics
        *ngIf="showTopics"
        [topics]="topics"
        (setTopics)="onSetTopics($event)"
      ></app-add-topics>

      <app-post-circle-assignment
        [alreadyAssignedCircles]="assignedCircles"
      ></app-post-circle-assignment>

      <div style="height: 20px"></div>
      <ion-button
        *ngIf="edit === false"
        [disabled]="villageEventForm.invalid"
        expand="block"
        (click)="createHangout()"
      >
        <ion-label>Create</ion-label>

        <ion-chip
          *ngIf="(showKarma$ | async) === true"
          style="position: absolute; right: 5px"
          slot="end"
          color="light"
          class="karma-chip"
        >
          <ion-avatar>
            <img src="../../../assets/images/badge-images/karma.png" />
          </ion-avatar>
          <ion-label><strong>+{{EVENT_KARMA}}</strong></ion-label>
        </ion-chip>
      </ion-button>
      <ion-button
        *ngIf="edit === true"
        [disabled]="villageEventForm.invalid"
        expand="block"
        (click)="updateHangout()"
        >Save Changes
      </ion-button>
    </ion-card-content>
  </ion-card>
</ion-content>
