import { Injectable } from '@angular/core';
import { NgxsFirestore } from '@ngxs-labs/firestore-plugin';
import * as firebase from 'firebase';
import 'firebase/firestore';
import { of } from 'rxjs';
import { SharedList, SharedListItem } from '../models/shared-list.model';

@Injectable({
  providedIn: 'root',
})
export class SharedListService extends NgxsFirestore<SharedList> {
  idField = '_UID';

  protected get path() {
    return `VILLAGES/${this.villageId}/LISTS`;
  }

  private VILLAGEID = '';

  public setVillageId(villageId) {
    this.VILLAGEID = villageId;
  }

  protected get villageId() {
    return this.VILLAGEID;
  }

  /**
   * @deprecated
   */
   updateIfExists(id, data) {
    return of(
      this.adapter.firestore
        .doc(`${this.path}/${id}`)
        .ref.withConverter(this.converter)
        .update(data)
    );
  }

  updateWithoutConverter(id: string, update: Partial<SharedList>) {
    return of(this.adapter.firestore.doc(`${this.path}/${id}`).update(update));
  }

  converter: firebase.default.firestore.FirestoreDataConverter<SharedList> = {
    toFirestore: (value: SharedList) => {
      // NOTE: careful here. Partial updates come through here as well.
      return value;
    },
    fromFirestore: (snapshot, options) => {
      let data = snapshot.data(options) as SharedList;

      if (snapshot.metadata.hasPendingWrites) {
        const ts = firebase.default.firestore.Timestamp.now();
        data = {
          ...data,
          _CREATED_AT: ts.toDate(),
          UPDATED_AT: ts.toDate(),
        };
      } else {
        data = {
          ...data,
          _CREATED_AT: data._SERVER_TIMESTAMP.toDate(),
          UPDATED_AT: data.UPDATED_AT.toDate(),
        };
      }
      console.log(
        '[SHARED LIST SERVICE] Received from firestore:  ',
        data._UID
      );

      if (!data.hasOwnProperty('ARCHIVED')) {
        data.ARCHIVED = false;
      }

      // patch no CIRCLES
      if (!data.hasOwnProperty('CIRCLES')) {
        data.CIRCLES = [{ NAME: 'Members', UID: 'MEMBERS' }];
      }

      // patch no CIRCLE_UIDS
      if (!data.hasOwnProperty('CIRCLE_UIDS')) {
        data.CIRCLE_UIDS = ['MEMBERS'];
      }

      return data;
    },
  };
}
