<div *ngIf="villagerCircles$ | async as availableCircles">
  <ion-grid>
    <ion-row>
      <ion-col size="10" sizeLg="11">
        <form [formGroup]="circleForm">
          <ion-item lines="none" class="full-width-item">
            <ion-label
              style="text-align: right"
              *ngIf="villageName$ | async as name"
            >
              Sharing in {{ name }} with:
            </ion-label>
            <ion-select
              multiple="true"
              formControlName="assignedCircles"
              [compareWith]="compareWith"
            >
              <ion-select-option
                *ngFor="let circle of mappedCircles"
                [value]="circle"
                >{{ circle.NAME }}</ion-select-option
              >
            </ion-select>
          </ion-item>
        </form>
      </ion-col>
      <ion-col size="2" sizeLg="1">
        <ion-button
          style="float: right; margin-right: -10px"
          fill="clear"
          (click)="showHelper($event)"
        >
          <ion-icon
            style="font-size: 30px; margin-top: -20px"
            color="dark"
            slot="icon-only"
            name="information-circle"
          ></ion-icon>
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</div>
