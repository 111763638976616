import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy, Router } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { NgxsFirestoreModule } from '@ngxs-labs/firestore-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsModule } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthState } from './state/auth.state';
import { environment } from 'src/environments/environment';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireFunctionsModule } from '@angular/fire/functions';
import { AngularFireStorageModule } from '@angular/fire/storage';
import {
  AngularFirePerformanceModule,
  PerformanceMonitoringService,
} from '@angular/fire/performance';

import {
  init as SentryInit,
  createErrorHandler,
  TraceService,
} from '@sentry/angular-ivy';

import { FeedState } from './state/feed.state';
import { VillagerState } from './state/villager.state';
import { ShareService } from './services/share.service';
import { TimeagoModule, TimeagoClock } from 'ngx-timeago';
import { MyClock } from './app.config';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ExchangeService } from './services/exchange.service';
import { HangoutService } from './services/hangout.service';
import { ShareState } from './state/share.state';
import { ExchangeState } from './state/exchange.state';
import { HangoutState } from './state/hangouts.state';
import { DiscussionState } from './state/discussion.state';
import { SupportRequestState } from './state/support-requests.state';
import { SupportRequestService } from './services/support-request.service';
import { StateUtilities } from './state/state-utilities';
import { VillageState } from './state/village.state';
import { DirectChatroomState } from './state/direct-chatroom.state';
import { ImageService } from './services/images.service';
import { HttpClientModule } from '@angular/common/http';
import { NotificationState } from './state/notification.state';
import { BackgroundService } from './services/background.service';
import { AppState } from './state/app.state';
import { AppErrorHandler } from './errors/app-error-handler';
import { ConflictState } from './state/conflict.state';
import { TutorialState, TutorialStateModel } from './state/tutorial.state';
import { AnnouncementService } from './services/announcement.service';
import { AnnouncementState } from './state/announcement.state';
import { SharedListState } from './state/shared-list.state';
import { CourtyardState } from './state/courtyard.state';
import { CircleState } from './state/circle.state';
import { MemberInviteState } from './state/member-invite.state';
import { CreateBulletinService } from './services/create-bulletin.service';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { MessageReplyState } from './state/message-reply.state';
import { SwitchVillageHeaderButtonComponent } from './shared/switch-village-header-button/switch-village-header-button.component';
import { ThemeService } from './services/theme.service';
import packageInfo from '../../package.json';

SentryInit({
  dsn: environment.sentryDSN,
  environment: environment.sentryEnv,
  release: `rvr@${packageInfo.version}`,
  dist: `rvr@${packageInfo.version}`,
  tracesSampleRate: environment.sentrySampleRate,
});
@NgModule({
  declarations: [AppComponent, SwitchVillageHeaderButtonComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    IonicModule.forRoot({
      mode: 'ios',
    }),
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireFunctionsModule,
    AngularFireStorageModule,
    AngularFirePerformanceModule,
    PickerModule,
    TimeagoModule.forRoot({
      clock: { provide: TimeagoClock, useClass: MyClock },
    }),
    NgxsModule.forRoot(
      [
        AuthState,
        FeedState,
        VillagerState,
        ShareState,
        AnnouncementState,
        ExchangeState,
        HangoutState,
        SupportRequestState,
        DiscussionState,
        VillageState,
        DirectChatroomState,
        NotificationState,
        MessageReplyState,
        AppState,
        ConflictState,
        TutorialState,
        SharedListState,
        CourtyardState,
        CircleState,
        MemberInviteState,
      ],
      {
        developmentMode: !environment.production,
      }
    ),
    NgxsStoragePluginModule.forRoot({
      key: [
        AuthState,
        TutorialState,
        VillageState,
        VillagerState,
        // CourtyardState,
        // CircleState,
        // AnnouncementState,
        // HangoutState,
        // SharedListState,
      ], // add other states we want to cache here
      migrations: [
        {
          version: undefined,
          key: 'tutorial',
          versionKey: 'tutorialOnboard',
          migrate: (state: TutorialStateModel) => {
            console.log('[STATE] Running migration!');
            return {
              welcomeSeen: state.welcomeSeen,
              tutorialSlidesSeen: state.tutorialSlidesSeen
                ? state.tutorialSlidesSeen
                : false,
              videoOverviewSeen: state.videoOverviewSeen
                ? state.videoOverviewSeen
                : false,
              version: '0.15.0', // Important to set this to the next version!
            };
          },
        },
      ],
    }),
    // NgxsLoggerPluginModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    NgxsFirestoreModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [
    ShareService,
    ExchangeService,
    HangoutService,
    AnnouncementService,
    SupportRequestService,
    StateUtilities,
    ImageService,
    BackgroundService,
    PerformanceMonitoringService,
    CreateBulletinService,
    ThemeService,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    // Attach the Sentry ErrorHandlers
    { provide: ErrorHandler, useClass: AppErrorHandler },
    { provide: ErrorHandler, useValue: createErrorHandler() },
    { provide: TraceService, deps: [Router] },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  exports: [SwitchVillageHeaderButtonComponent],
})
export class AppModule {}
