<ion-card [ngClass]="!showBorder ? 'no-border' : 'none'">
  <ion-card-content>
    <div *ngIf="villager.VILLAGES.length > 0">
      <ion-card-subtitle>Your Villages</ion-card-subtitle>

      <!-- <ion-list-header class="modal-header">Your Other Villages</ion-list-header> -->
      <ion-radio-group [value]="villager.VILLAGE">
        <ion-item
          *ngFor="let village of villager.VILLAGES"
          class="full-width-item"
          (click)="setCurrentVillage(village.UID)"
        >
          <app-village-avatar
            [villageId]="village.UID"
            [villageName]="village.NAME"
          ></app-village-avatar>
          <ion-label style="margin-left: 10px">{{ village.NAME }}</ion-label>
          <ion-radio [value]="village.UID" slot="start"></ion-radio>
        </ion-item>
      </ion-radio-group>
    </div>

    <div class="subheader" style="margin-top: 40px">
      Join, leave, or create your own <app-village-label></app-village-label>
    </div>

    <div style="margin-top: 20px">
      <app-join-village-button
        [iconOnly]="false"
        (done)="handleWrapup()"
      ></app-join-village-button>
    </div>

    <ion-button
      style="margin-top: 20px"
      expand="block"
      color="success"
      (click)="createNewVillage()"
    >
      <ion-label>
        Create a&nbsp;<app-village-label
          [showTitlecase]="true"
        ></app-village-label>
      </ion-label>

      <ion-icon slot="end" name="add-circle"></ion-icon>
    </ion-button>

    <ion-button
      style="margin-top: 20px"
      expand="block"
      color="danger"
      (click)="leaveVillage()"
    >
      Leave {{ currentVillagerVillage.NAME }}
    </ion-button>
  </ion-card-content>
</ion-card>
