import { Component, Input, OnInit } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { StoredDocument } from 'src/app/models/post-core.model';

@Component({
  selector: 'app-post-document',
  templateUrl: './post-document.component.html',
  styleUrls: ['./post-document.component.scss'],
})
export class PostDocumentComponent implements OnInit {
  @Input() document: StoredDocument;
  previewIconPath: string;
  path$: Observable<string>;
  fileName: string;
  uniqueFileSeperator = '_upload_';

  @Input() edit = false;
  constructor(private bucket: AngularFireStorage) {}

  ngOnInit() {
    console.log('[DOC] Document: ', this.document);
    const tmp =
      this.document.path.split('/')[this.document.path.split('/').length - 1];
    console.log('[DOC] tmp: ', tmp);
    const tmp2 = tmp.split(this.uniqueFileSeperator);
    console.log('[DOC] tmp2: ', tmp2);
    if (tmp2.length > 1) {
      this.fileName = tmp2[1];
    } else {
      this.fileName = tmp2[0];
    }
    console.log('File name: ', this.fileName);
    switch (this.document.type) {
      case 'application/pdf':
        this.previewIconPath = '../../../assets/images/badge-images/pdf.png';
        break;
      case 'text/plain':
        this.previewIconPath =
          '../../../assets/images/badge-images/txt-file.png';
        break;

        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
          this.previewIconPath =
          '../../../assets/images/badge-images/doc.png';
        break;
      default:
        this.previewIconPath = '../../../assets/images/badge-images/file.png';
        break;
    }
  }

  download() {
    // edit is a workaround for reusing this component during post edit
    if(this.edit) return false;
    const ref = this.bucket.ref(this.document.path);
    this.path$ = ref.getDownloadURL();
    this.path$
      .pipe(take(1))
      .subscribe((url) => {
        console.log('[DOC] url: ', url)
        window.open(url, '_blank')
      });
  }
}
