
export interface Skill {
  title: string;
  value: string;
  description: string;
  selected: boolean;
  image: string;
}

export const SKILLS: Skill[] = [
  {
    title: 'Healing',
    value: 'HEALING',
    description: 'Massage, acupuncture, meditation, diet, ritual etc.',
    selected: false,
    image: '../../assets/images/exchange-images/Healing-2.jpg',
  },
  {
    title: 'Outdoor Rec',
    value: 'OUTDOOR_RECREATION',
    description: 'Hiking, camping, kayaking, climbing, surfing etc.',
    selected: false,
    image: '../../assets/images/exchange-images/Outdoors-1.jpg',
  },
  {
    title: 'Art',
    value: 'ART',
    description: 'Painting, writing, story telling etc.',
    selected: false,
    image: '../../assets/images/exchange-images/Art-1.jpg',
  },
  {
    title: 'Music',
    value: 'MUSIC',
    description: 'Instruments, singing, lessons etc.',
    selected: false,
    image: '../../assets/images/exchange-images/Music-1.jpg',
  },
  {
    title: 'Media',
    value: 'MEDIA',
    description:
      'Music production, video work, graphic design, photography etc.',
    selected: false,
    image: '../../assets/images/exchange-images/Media-1.jpg',
  },
  {
    title: 'Child Care',
    value: 'CHILD_CARE',
    description: 'Babysitting, day care, play dates etc.',
    selected: false,
    image: '../../assets/images/exchange-images/Childcare-1.jpg',
  },
  {
    title: 'Elder Care',
    value: 'ELDER_CARE',
    description: 'Visits, walking, transport, support etc.',
    selected: false,
    image: '../../assets/images/exchange-images/Eldercare-1.jpg',
  },
  {
    title: 'Pet Care',
    value: 'PET CARE',
    description: 'Pet sitting, walking, grooming etc.',
    selected: false,
    image: '../../assets/images/exchange-images/PetCare-3.jpg',
  },
  {
    title: 'Cooking',
    value: 'COOKING',
    description: 'Meal prep, drop off, teaching, food sharing etc.',
    selected: false,
    image: '../../assets/images/exchange-images/Cooking-1.jpg',
  },
  {
    title: 'Yard Work',
    value: 'YARD_WORK',
    description: 'Landscaping, gardening, growing etc.',
    selected: false,
    image: '../../assets/images/exchange-images/Yardwork-2.jpg',
  },
  {
    title: 'Construction',
    value: 'CONSTRUCTION',
    description: 'Building such as woodworking, electrical, plumbing.',
    selected: false,
    image: '../../assets/images/exchange-images/Construction-1.jpg',
  },
  {
    title: 'Cleaning',
    value: 'CLEANING',
    description: 'Cleaning and removing junk.',
    selected: false,
    image: '../../assets/images/exchange-images/Cleaning-1.jpg',
  },
  {
    title: 'Maintenance',
    value: 'MAINTENANCE',
    description: 'Tool share, car mechanics etc.',
    selected: false,
    image: '../../assets/images/exchange-images/Maintenance-1.jpg',
  },

  {
    title: 'Technology',
    value: 'COMPUTER',
    description: 'Tech help, programming, backups etc.',
    selected: false,
    image: '../../assets/images/exchange-images/Computer-2.jpg',
  },
  {
    title: 'Repair',
    value: 'REPAIR',
    description: 'Fixing household items such as clothes or appliances.',
    selected: false,
    image: '../../assets/images/exchange-images/Repair-1.jpg',
  },
  {
    title: 'Athletics',
    value: 'ATHLETICS',
    description: 'Sports, hiking, coaching, physiotherapy etc.',
    selected: false,
    image: '../../assets/images/exchange-images/Athletics-1.jpg',
  },
  {
    title: 'Personal Growth',
    value: 'PERSONAL_DEVELOPMENT',
    description: 'Coaching, therapy, conflict resolution, etc.',
    selected: false,
    image: '../../assets/images/exchange-images/PersonalGrowth-1.jpg',
  },

  {
    title: 'Delivery',
    value: 'DELIVERY',
    description: 'Food, post office, building materials, firewood etc.',
    selected: false,
    image: '../../assets/images/exchange-images/Delivery-2.jpg',
  },
  {
    title: 'Venue Hosting',
    value: 'VENUE_HOSTING',
    description: 'Room for 5+ villagers to gather.',
    selected: false,
    image: '../../assets/images/exchange-images/VenueHosting-1.jpg',
  },

  {
    title: 'Aesthetics',
    value: 'AESTHETICS',
    description: 'Hair cuts, make up, nails, tattoo etc.',
    selected: false,
    image: '../../assets/images/exchange-images/Aesthetics-3.jpg',
  },
  {
    title: 'Other',
    value: 'OTHER',
    description: 'Other skills not listed here',
    selected: false,
    image: '../../assets/images/exchange-images/other-1.jpg',
  },
];
