<ion-row>
  <ion-col>
    <div *ngIf="post?.BODY">
      <div
        class="post-message-body"
        *ngIf="!showFullBody; else fullBody"
        [innerHtml]="
        post.BODY
            | truncateBody: 400:true
            | mentionified: post._MENTION_UIDS
            | safehtml
        "
      ></div>
      <ion-button
        style="float: right; margin-top: 5px"
        size="small"
        color="dark"
        fill="outline"
        *ngIf="post.BODY.length > 400 && !post"
        (click)="goToDetails()"
        >Read full post
        <ion-icon name="chevron-forward-outline"></ion-icon>
      </ion-button>
      <ng-template #fullBody>
        <div
          (click)="clickBody($event)"
          class="post-message-body"
          [innerHtml]="
          post.BODY | mentionified: post._MENTION_UIDS | safehtml
          "
        ></div>
      </ng-template>
    </div>
  </ion-col>
</ion-row>
<ion-item *ngIf="!sharedList.READ_ONLY" class="full-width-item" lines="none">
  <ion-button style="font-size: 16px" slot="start" fill="clear" (click)="toggleCompleted()">
    <ion-icon slot="start" name="checkmark-circle"></ion-icon>
    <ion-label *ngIf="!showCompleted">Show Completed</ion-label>
    <ion-label *ngIf="showCompleted">Hide Completed</ion-label>
  </ion-button>
</ion-item>
<ion-list>
  <ion-item-sliding
    #slideItem
    *ngFor="let item of filteredItems; let i = index"
  >
    <ion-item class="full-width-item">
      <ion-checkbox
        (ionChange)="toggleCompletion(item)"
        [checked]="item.COMPLETED || sharedList.READ_ONLY"
        [disabled]="sharedList.READ_ONLY"
      ></ion-checkbox>
      <ion-label>{{ item.NAME }}</ion-label>
    </ion-item>
    <ion-item-options side="end">
      <ion-item-option (click)="deleteItem(i)" color="danger"
        >Delete</ion-item-option
      >
    </ion-item-options>
  </ion-item-sliding>
</ion-list>
<ion-item
  style="margin-top: 20px"
  class="full-width-item form-outline-item"
  lines="none"
>
  <ion-input
    (keyup.enter)="addItem()"
    [(ngModel)]="newItem"
    placeholder="Add item ..."
  ></ion-input>
  <ion-button (click)="addItem()" slot="end" fill="clear">
    <ion-icon color="primary" slot="icon-only" name="add-circle"></ion-icon>
  </ion-button>
</ion-item>
