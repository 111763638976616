<div *ngIf="villagerCircles$ | async as availableCircles">
  <div class="subheader">Add Event Organizers</div>
  <p>
    You can let other
    <app-members-label [plural]="true"></app-members-label> make edits to this
    event by making them an organizer.
  </p>
  <form [formGroup]="organizerForm">
    <ion-item class="full-width-item">
      <ion-label>
        <ion-icon name="person-add-outline"></ion-icon>
        Select organizers
      </ion-label>
      <ion-select
        multiple="true"
        formControlName="organizers"
        [compareWith]="compareWith"
      >
        <ion-select-option
          *ngFor="let villager of possibleVillagers"
          [value]="villager"
          >{{ villager.FIRST_NAME }} {{ villager.LAST_NAME }}</ion-select-option
        >
      </ion-select>
    </ion-item>
  </form>


</div>
