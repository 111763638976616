import { Injectable } from '@angular/core';
import {
  Connected,
  Emitted,
  NgxsFirestoreConnect,
  StreamConnected,
  StreamEmitted,
} from '@ngxs-labs/firestore-plugin';
import {
  Action,
  NgxsOnInit,
  Selector,
  State,
  StateContext,
  Store,
} from '@ngxs/store';
import { tap } from 'rxjs/operators';
import {
  ClearSharedList,
  CreateSharedList,
  DeleteSharedList,
  FetchSharedLists,
  SilentlyUpdateSelectedPost,
  UpdateSharedList,
} from './app.actions';

import { DiscussionState } from './discussion.state';
import { SharedListService } from '../services/shared-list.service';
import { SharedList, SharedListStateModel } from '../models/shared-list.model';
import { AnalyticsService } from '../analytics.service';

@State<SharedListStateModel>({
  name: 'sharedLists',
  defaults: {
    lists: [],
  },
})
@Injectable()
export class SharedListState implements NgxsOnInit {
  constructor(
    private listSvc: SharedListService,
    private ngxsFirestoreConnect: NgxsFirestoreConnect,
    private store: Store,
    private analytics: AnalyticsService
  ) {}

  ngxsOnInit(ctx: StateContext<SharedListStateModel>) {
    this.ngxsFirestoreConnect.connect(FetchSharedLists, {
      to: (action) =>
        this.listSvc.collection$((ref) =>
          ref.where(
            'CIRCLE_UIDS',
            'array-contains-any',
            action.payload.villagerCircleIds
          )
        ),
        cancelPrevious: true
    });
  }

  @Selector()
  static allLists(state: SharedListStateModel): SharedList[] {
    return state.lists;
  }

  @Selector()
  static getListById(
    state: SharedListStateModel
  ): (listId: string) => SharedList {
    return (listId: string) => {
      return state.lists.find((x) => x._UID === listId);
    };
  }

  @Action(FetchSharedLists)
  startSharedListListener(
    ctx: StateContext<SharedListStateModel>,
    action: FetchSharedLists
  ) {
    const { villageUID } = action.payload;
    this.listSvc.setVillageId(villageUID);
  }

  @Action(StreamConnected(FetchSharedLists))
  streamConnected(
    ctx: StateContext<SharedListStateModel>,
    { action }: Connected<FetchSharedLists>
  ) {
    console.log('[Conflict State] Stream connected');
  }

  @Action(StreamEmitted(FetchSharedLists))
  streamEmitted(
    ctx: StateContext<SharedListStateModel>,
    { action, payload }: Emitted<FetchSharedLists, SharedList[]>
  ) {
    // console.log(
    //   '[DEBUG] Shared lists EMITTED: ',
    //   payload.map((x) => x._UID)
    // );

    const selectedChatroom = this.store.selectSnapshot(
      DiscussionState.selectedPost
    );
    if (payload && selectedChatroom) {
      const matchingPost = payload.find(
        (x) => x._UID === selectedChatroom._UID
      );
      if (matchingPost) {
        this.store.dispatch(
          new SilentlyUpdateSelectedPost({ post: matchingPost })
        );
      }
    }

    ctx.patchState({
      lists: payload,
    });
  }

  @Action(CreateSharedList)
  createSharedList(
    ctx: StateContext<SharedListStateModel>,
    action: CreateSharedList
  ) {
    const { post } = action.payload;
    return this.listSvc.create$(post).pipe(
      tap(() => {
        console.log('[List State] successfully created list');
      })
    );
  }

  @Action(ClearSharedList)
  clearState(ctx: StateContext<SharedListStateModel>) {
    ctx.setState({
      lists: [],
    });
  }

  @Action(UpdateSharedList)
  updateList(ctx, action: UpdateSharedList) {
    const { postId, partialPost } = action.payload;
    if (!postId) {
      console.error('Missing Id: ', postId);
      this.analytics.logError(
        'error_missingId_update_sharedlist',
        'Shared List State',
        new Error('Missing Id Shared List Update')
      );
      alert('Error Updating List');
      return false;
    }
    return this.listSvc.update$(postId, partialPost);
  }

  @Action(DeleteSharedList)
  deleteList(ctx, action: DeleteSharedList) {
    const { post } = action.payload;
    return this.listSvc.delete$(post._UID);
  }
}
