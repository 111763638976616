import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { GenericPost } from 'src/app/models/post-core.model';
import { EMOTIONS, SupportRequest } from 'src/app/models/support-request.model';
import { SetSelectedPost } from 'src/app/state/app.actions';
import { FeedState } from 'src/app/state/feed.state';

@Component({
  selector: 'app-support-card-body',
  templateUrl: './support-card-body.component.html',
  styleUrls: ['./support-card-body.component.scss'],
})
export class SupportCardBodyComponent implements OnInit {
  supportRequest: SupportRequest;
  emotions = EMOTIONS;
  emoji: string = null;

  @Select(FeedState.allPosts) allPosts$: Observable<GenericPost[]>;
  @Input() showFullBody = true;
  @Input() post: GenericPost;

  constructor(
    private store: Store,
    private router: Router,
    private modalCtrl: ModalController
  ) {}

  ngOnInit() {
    this.supportRequest = this.post as SupportRequest;
    this.store
      .select(FeedState.getPostById)
      .pipe(map((filterFn) => filterFn(this.post._UID)))
      .subscribe((post) => {
        if (post) {
          // console.log('[Request Card Body] updated request: ', post);
          this.supportRequest = post as SupportRequest;
          this.getEmoji();
        }
      });

    this.getEmoji();
  }

  goToDetails() {
    this.store.dispatch(
      new SetSelectedPost({ post: this.supportRequest, location: 'DETAILS' })
    );
    this.router.navigateByUrl(`pages/post-details`);
  }

  getEmoji() {
    this.emoji = this.emotions.find(
      (x) => x.emotion === this.supportRequest._EMOTION
    ).icon;
  }

  async clickBody(ev: Event) {
    const target = ev.target as HTMLElement;
    if (target?.className === 'mentionified') {
      const uid = target.getAttribute('data-mention-uid');
      if (uid) {
        const existing = await this.modalCtrl.getTop();
        if (existing) await this.modalCtrl.dismiss();
        this.router.navigateByUrl(`member/${uid}`);
      }
    }
  }
}
