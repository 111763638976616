import { ChatMessage, Reaction } from '../models/chat-message.model';
import { Courtyard } from '../models/courtyard.model';

export namespace CourtyardActions {
  export class Create {
    static readonly type = '[Courtyard] Create Courtyard';
    constructor(public payload: { courtyard: Courtyard }) {}
  }

  export class UpdateCourtyard {
    static readonly type = '[Courtyard] Update Courtyard';
    constructor(public payload: { courtyard: Courtyard }) {}
  }

  export class Delete {
    static readonly type = '[Courtyard] Delete Courtyard';
    constructor(public payload: { courtyardId: string }) {}
  }

  export class AddParticipant {
    static readonly type = '[Courtyard] Add Participant';
  }

  export class ToggleMutedId {
    static readonly type = '[Courtyard] Add/Remove ID from MUTED_UIDS';
    constructor(public payload: { uid: string }) {}
  }

  export class SetSelected {
    static readonly type = '[Courtyard] Set Selected Courtyard';
    constructor(public payload: { courtyard: Courtyard }) {}
  }

  export class SetSelectedById {
    static readonly type = '[Courtyard] Set Selected Courtyard by Id';
    constructor(public payload: { courtyardId: string }) {}
  }

  export class RemoveSelected {
    static readonly type = '[Courtyard] Remove Selected Courtyard';
  }

  export class RemoveParticipant {
    static readonly type = '[Courtyard] Remove Participant';
    constructor(public payload: { courtyard: Courtyard; villagerId: string }) {}
  }

  export class UpdateReadReceipts {
    static readonly type = '[Courtyard] Update Read Receipts';
    constructor(public payload: { courtyard: Courtyard }) {}
  }

  export class FetchAll {
    static readonly type = '[Courtyard] Fetch all courtyards';
    constructor(public payload: { villageId: string }) {}
  }

  export class RefreshAll {
    static readonly type = '[Courtyard] Refresh all courtyards';
    constructor(public payload: { villageId: string }) {}
  }

  export class FetchMessages {
    static readonly type = '[Courtyard] Fetch messages';
  }

  export class SendMessage {
    static readonly type = '[Courtyard] Send Message';
    constructor(public payload: { message: ChatMessage; villageId: string }) {}
  }

  export class React {
    static readonly type = '[Courtyard] Add Reaction'
    constructor(
      public payload: { message: ChatMessage, emoji: string, villagerUid: string }
    ) {}
  }

  export class DeleteMessage {
    static readonly type = '[Courtyard] Delete Message';
    constructor(public payload: { messageId: string }) {}
  }

  export class Clear {
    static readonly type = '[Courtyard] Clear';
  }

  export class SetDefault {
    static readonly type = '[Courtyard] Set default courtyard';
  }
}
