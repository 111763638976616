export const environment = {
  production: true,
  appName: 'reVillager',
  isPrivateWhitelabel: false,
  avatarBackgroundHex: '57ACA3',
  whiteLabelInviteCode: '',
  sentryDSN: 'https://ec2be8ba17104239b38be1e7e3654679@o4505115156480000.ingest.sentry.io/4505115158183936',
  sentryEnv: 'prod',
  sentrySampleRate: 0.5,
  showTutorialSlides: true,
  rvrSupportUID: 'QP2ypgxtRoeRQFpWPODXRItraT12',
  coverUrl: 'https://firebasestorage.googleapis.com/v0/b/unity-ddev.appspot.com/o/defaults%2Fbackground_cover.png?alt=media&token=31c7c848-2b81-43f3-afae-f0ee32ec67aa',
  firebase : {
    apiKey: 'AIzaSyByKB_yX_rICJ0IlIPoJcrDK_v8_MMLdT4',
    authDomain: 'unity-ddev.firebaseapp.com',
    projectId: 'unity-ddev',
    storageBucket: 'unity-ddev.appspot.com',
    messagingSenderId: '643054741456',
    appId: '1:643054741456:web:89a18eafd9ef6fc031bbd5',
    measurementId: 'G-V2344H5V05',
  },
};
