import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';
import {
  Connected,
  Disconnect,
  Emitted,
  NgxsFirestoreConnect,
  StreamConnected,
  StreamEmitted,
} from '@ngxs-labs/firestore-plugin';
import {
  Action,
  Actions,
  NgxsAfterBootstrap,
  ofActionCompleted,
  Selector,
  State,
  StateContext,
  Store,
} from '@ngxs/store';
import { ChatMessage, Reaction } from '../models/chat-message.model';
import {
  DirectChatroom,
  DirectChatroomStateModel,
  DirectParticipant,
} from '../models/direct-chatroom.model';
import { DirectChatroomMessagesService } from '../services/direct-message.service';
import {
  AcceptDirectRequest,
  AddDirectMembers,
  ChangeDirectChatTitle,
  ChangeDirectSegment,
  ClearDirectChatrooms,
  ClearVillageChatroom,
  CreateDyadChatroom,
  CreateGroupChatroom,
  DeleteDirectChatMessage,
  DirectChatroomReact,
  FetchDirectChatroomMessages,
  FetchDirectChatrooms,
  FetchDirectChatroomVillagers,
  LeaveGroupDM,
  RefreshDirectChatrooms,
  RejectDirectRequest,
  RemoveSelectedDirectChatroom,
  ReplyPrivately,
  SendDirectChatMessage,
  SetSelectedDirectChatroom,
  SetSelectedDirectChatroomById,
  ToggleChatroomMutedId,
  UpdateDirectChatReadReceipts,
} from './app.actions';

import * as firebase from 'firebase';
import 'firebase/firestore';
import { DirectChatroomService } from '../services/direct-chatroom.service';
import { VillagerState } from './villager.state';
import { has, isEqual } from 'lodash';
import { AngularFirestore } from '@angular/fire/firestore';
import { ReadReceipt } from '../models/read-receipt.model';
import * as moment from 'moment';
import { UtilitiesService } from '../services/utilities.service';

import { Villager } from '../models/villager.model';
import { PushNotificationService } from '../services/push-notification.service';
import { concatMap } from 'rxjs/operators';
import { AnalyticsService } from '../analytics.service';
import { LoadingController, ToastController } from '@ionic/angular';
import { VillagerService } from '../services/villager.service';
import { AppState } from './app.state';

@State<DirectChatroomStateModel>({
  name: 'directChatrooms',
  defaults: {
    allChatrooms: [],
    joinedChatrooms: [],
    pendingChatrooms: [],
    selectedChatroom: null,
    messages: [],
    segment: 'joined',
    chatroomVillagers: [],
  },
})
@Injectable()
export class DirectChatroomState implements NgxsAfterBootstrap {
  loading: HTMLIonLoadingElement;
  component = 'direct-chatroom-state';
  constructor(
    private chatroomMessagesSvc: DirectChatroomMessagesService,
    private ngxsFirestoreConnect: NgxsFirestoreConnect,
    private chatroomSvc: DirectChatroomService,
    private store: Store,
    private angularFire: AngularFirestore,
    private pushSvc: PushNotificationService,
    private analytics: AnalyticsService,
    private toastCtrl: ToastController,
    private loadingCtrl: LoadingController,
    private villagerSvc: VillagerService,
    private actions$: Actions,
    private fns: AngularFireFunctions
  ) {}

  ngxsOnInit() {
    this.ngxsFirestoreConnect.connect(FetchDirectChatrooms, {
      to: (action) =>
        this.chatroomSvc.collection$((ref) =>
          ref.where(
            'PARTICIPANT_UIDS',
            'array-contains',
            action.payload.villagerUid
          )
        ),
      cancelPrevious: true,
    });

    this.ngxsFirestoreConnect.connect(FetchDirectChatroomMessages, {
      to: () => this.chatroomMessagesSvc.collection$(),
      cancelPrevious: true,
    });
  }

  ngxsAfterBootstrap(ctx?: StateContext<any>): void {}

  @Selector()
  static villagerChatrooms(state: DirectChatroomStateModel): DirectChatroom[] {
    return state.allChatrooms;
  }

  @Selector()
  static joinedChatrooms(state: DirectChatroomStateModel): DirectChatroom[] {
    return state.joinedChatrooms;
  }

  @Selector()
  static chatroomVillagers(state: DirectChatroomStateModel): Villager[] {
    return state.chatroomVillagers;
  }

  @Selector()
  static allChatrooms(state: DirectChatroomStateModel): DirectChatroom[] {
    return state.allChatrooms;
  }

  @Selector()
  static pendingChatrooms(
    state: DirectChatroomStateModel
  ): (villagerId: string) => DirectChatroom[] {
    return (villagerId: string) => {
      return state.pendingChatrooms.filter((room) => {
        return room._CREATOR_UID === villagerId || room.MESSAGE_COUNT > 0;
      });
    };
  }

  @Selector()
  static chatroom(state: DirectChatroomStateModel): DirectChatroom {
    return state.selectedChatroom;
  }

  @Selector()
  static participants(state: DirectChatroomStateModel): DirectParticipant[] {
    return state.selectedChatroom.PARTICIPANTS;
  }

  @Selector()
  static messages(state: DirectChatroomStateModel): ChatMessage[] {
    return state.messages;
  }

  @Selector()
  static segment(state: DirectChatroomStateModel): 'joined' | 'pending' {
    return state.segment;
  }

  @Action(RefreshDirectChatrooms)
  refreshChats(
    ctx: StateContext<DirectChatroomStateModel>,
    action: RefreshDirectChatrooms
  ) {
    const { villagerUid } = action.payload;
    if (!villagerUid) {
      alert('Error. Cannot fetch chatrooms. Please logout and back in.');
      this.analytics.logEvent('direct_chatroom_state_refresh_error', {
        err: 'No Villager ID to refresh chats from',
      });
    } else {
      return this.store
        .dispatch([
          new Disconnect(FetchDirectChatrooms),
          new Disconnect(FetchDirectChatroomMessages),
        ])
        .pipe(
          concatMap(() =>
            this.store.dispatch(new FetchDirectChatrooms({ villagerUid }))
          )
        );
    }
  }

  @Action(FetchDirectChatrooms)
  fetchChatrooms(
    ctx: StateContext<DirectChatroomStateModel>,
    action: FetchDirectChatrooms
  ) {}

  @Action(StreamConnected(FetchDirectChatrooms))
  streamConnected(
    ctx: StateContext<DirectChatroomStateModel>,
    { action }: Connected<FetchDirectChatrooms>
  ) {}

  @Action(StreamEmitted(FetchDirectChatrooms))
  streamEmitted(
    ctx: StateContext<DirectChatroomStateModel>,
    { action, payload }: Emitted<FetchDirectChatrooms, DirectChatroom[]>
  ) {
    const { selectedChatroom } = ctx.getState();

    const rooms = payload.sort((a, b) => b.UPDATED_AT - a.UPDATED_AT);
    console.warn('rooms', rooms)
    ctx.patchState({
      allChatrooms: rooms,
    });

    let chatroomToPatch;
    if (selectedChatroom) {
      chatroomToPatch = payload.find((x) => x._UID === selectedChatroom._UID);
      ctx.patchState({
        selectedChatroom: chatroomToPatch,
      });
    }

    const villagerId = this.store.selectSnapshot(VillagerState.uid);
    if (villagerId) {
      const joined = rooms.filter((x) => {
        let participant: DirectParticipant = x.PARTICIPANTS.find(
          (y) => y.UID === villagerId
        );
        if (participant.ACCEPTED === true) return x;
      });

      const requests = rooms.filter((x) => {
        let participant: DirectParticipant = x.PARTICIPANTS.find(
          (y) => y.UID === villagerId
        );
        if (participant.ACCEPTED === false && participant.REJECTED === false)
          return x;
      });

      console.warn('joined', joined)
      console.warn('requests', requests)

      ctx.patchState({
        allChatrooms: rooms,
        joinedChatrooms: joined,
        pendingChatrooms: requests,
      });
    }
  }

  @Action(ChangeDirectSegment)
  changeSegment(
    ctx: StateContext<DirectChatroomStateModel>,
    action: ChangeDirectSegment
  ) {
    ctx.patchState({
      segment: action.payload.segment,
    });
  }

  @Action(StreamConnected(FetchDirectChatroomMessages))
  messageStreamConnected(
    ctx: StateContext<DirectChatroomStateModel>,
    { action }: Connected<FetchDirectChatroomMessages>
  ) {
  }

  @Action(StreamEmitted(FetchDirectChatroomMessages))
  async messageStreamEmitted(
    ctx: StateContext<DirectChatroomStateModel>,
    { action, payload }: Emitted<FetchDirectChatroomMessages, ChatMessage[]>
  ) {

    // mark new incoming messages as read
    const { selectedChatroom } = ctx.getState();
    if (selectedChatroom) {
      ctx.dispatch(
        new UpdateDirectChatReadReceipts({
          chatroom: selectedChatroom,
        })
      );
    }

    const messages = payload.sort((a, b) => a._CREATED_AT - b._CREATED_AT);
    messages.forEach((x, idx) => {
      if (idx > 0) {
        const previousMessage = messages[idx - 1];
        if (previousMessage._CREATOR_UID !== x._CREATOR_UID) {
          x.SHOW_TIMESTAMP = true;
          x.SHOW_AVATAR = true;
        } else if (
          moment(previousMessage._CREATED_AT).isAfter(
            moment(x._CREATED_AT).subtract(15, 'minutes')
          )
        ) {
          x.SHOW_TIMESTAMP = false;
          x.SHOW_AVATAR = false;
        } else {
          x.SHOW_TIMESTAMP = true;
          x.SHOW_AVATAR = true;
        }
      } else {
        x.SHOW_TIMESTAMP = true;
        x.SHOW_AVATAR = true;
      }
    });
    ctx.patchState({
      messages,
    });

    if (this.loading) {
      await this.loading.dismiss();
      this.loading = null;
    }
  }

  @Action(ReplyPrivately)
  replyPrivately(
    ctx: StateContext<DirectChatroomStateModel>,
    action: ReplyPrivately
  ) {
    const { currentVillager, postOwner } = action.payload;
    // dont need to check for existing rooms.
    // CreateDyadChatroom handles that for us
    ctx.dispatch(
      new CreateDyadChatroom({
        creatorVillager: currentVillager,
        participant: postOwner,
      })
    );
  }

  @Action(CreateDyadChatroom)
  async createDyadChatroom(
    ctx: StateContext<DirectChatroomStateModel>,
    action: CreateDyadChatroom
  ) {
    const { creatorVillager, participant } = action.payload;
    const participants = [creatorVillager, participant];

    // 1. check to make sure no chatroom exists
    const chatroomsLoaded = await this.checkChatroomsLoaded(
      creatorVillager.UID
    );
    const response = this.checkForExistingRooms(ctx, participants);
    if (response == 'already exists') {
      return false;
    }

    // 2. Create a dyad room
    const dyadRoom: DirectChatroom = {
      _UID: this.angularFire.createId(),
      TITLE: null,
      DESCRIPTION: null,
      PARTICIPANTS: participants,
      PARTICIPANT_UIDS: participants.map((x) => x.UID),
      PARTICIPANT_READ_RECEIPTS: participants.map((x) => ({
        UID: x.UID,
        LAST_READ_MESSAGE_COUNT: 0,
      })),
      MUTED_UIDS: [],
      UPDATED_AT: new Date(),
      MESSAGE_COUNT: 0,
      _CREATOR_UID: creatorVillager.UID,
      _CREATOR_FIRST_NAME: creatorVillager.FIRST_NAME,
      _CREATOR_LAST_NAME: creatorVillager.LAST_NAME,
      _TYPE: 'DYAD',
      _CREATED_AT: new Date(),
      _SERVER_TIMESTAMP:
        firebase.default.firestore.FieldValue.serverTimestamp(),
    };
    console.log('[Direct Chatroom State] creating dyad room: ', dyadRoom);
    await ctx
      .dispatch(new SetSelectedDirectChatroom({ chatroom: dyadRoom }))
      .toPromise();
    return this.chatroomSvc.create$(dyadRoom);
  }

  @Action(CreateGroupChatroom)
  async createGroupChatroom(
    ctx: StateContext<DirectChatroomStateModel>,
    action: CreateGroupChatroom
  ) {
    const { creatorVillager, participants } = action.payload;
    // 1. check to make sure no chatroom exists
    const chatroomsLoaded = await this.checkChatroomsLoaded(
      creatorVillager.UID
    );
    console.log('Chatrooms Have Loaded: ', chatroomsLoaded);
    console.log('Now can continue checking');
    const response = this.checkForExistingRooms(ctx, [
      creatorVillager,
      ...participants,
    ]);
    if (response == 'already exists') {
      return false;
    }

    // 2. Create a group chatroom
    const PARTICIPANT_UIDS = [
      creatorVillager.UID,
      ...participants.map((x) => x.UID),
    ];
    const PARTICIPANT_READ_RECEIPTS: ReadReceipt[] = PARTICIPANT_UIDS.map(
      (UID) => ({ UID, LAST_READ_MESSAGE_COUNT: 0 })
    );

    const groupRoom: DirectChatroom = {
      _UID: this.angularFire.createId(),
      TITLE: action.payload.title,
      DESCRIPTION: action.payload.description || null,
      PARTICIPANTS: [creatorVillager, ...participants],
      PARTICIPANT_UIDS,
      PARTICIPANT_READ_RECEIPTS,
      MUTED_UIDS: [],
      UPDATED_AT: new Date(),
      MESSAGE_COUNT: 0,
      _CREATOR_UID: creatorVillager.UID,
      _CREATOR_FIRST_NAME: creatorVillager.FIRST_NAME,
      _CREATOR_LAST_NAME: creatorVillager.LAST_NAME,
      _TYPE: 'GROUP',
      _CREATED_AT: new Date(),
      _SERVER_TIMESTAMP:
        firebase.default.firestore.FieldValue.serverTimestamp(),
    };
    console.log('[Direct Chatroom State] creating group room: ', groupRoom);
    await ctx
      .dispatch(new SetSelectedDirectChatroom({ chatroom: groupRoom }))
      .toPromise();
    return this.chatroomSvc.create$(groupRoom);
  }

  @Action(SetSelectedDirectChatroomById)
  setSelectedChatroomById(
    ctx: StateContext<DirectChatroomStateModel>,
    action: SetSelectedDirectChatroomById
  ) {
    const { allChatrooms } = ctx.getState();
    const chatroom = allChatrooms.find(
      (x) => x._UID === action.payload.chatroomId
    );
    if (chatroom) {
      ctx.dispatch(new SetSelectedDirectChatroom({ chatroom }));
    } else {
      console.error(
        'Cannot set selected chatroom by ID. Chatrooms probably not loaded'
      );
      alert('Error looking up chatroom.');
    }
  }

  @Action(SetSelectedDirectChatroom)
  async setSelectedChatroom(
    ctx: StateContext<DirectChatroomStateModel>,
    action: SetSelectedDirectChatroom
  ) {

    const { chatroom } = action.payload;

    this.pushSvc.clearNotificationsByContentId(chatroom._UID);

    // show caution if blocked person inside chatroom
    const currentVillager = this.store.selectSnapshot(
      VillagerState.currentVillager
    );
    let blockedVillagers = [];
    if (currentVillager.BLOCKED_VILLAGERS) {
      blockedVillagers = chatroom.PARTICIPANT_UIDS.filter((id) =>
        currentVillager.BLOCKED_VILLAGERS.includes(id)
      );
    }

    if (blockedVillagers.length > 0) {
      alert('This chatroom contains a villager you blocked');
    }

    // clear existing messages
    await ctx.dispatch(new RemoveSelectedDirectChatroom()).toPromise();
    // clear Village Chatroom state so no conflict
    this.store.dispatch(new ClearVillageChatroom());

    this.chatroomMessagesSvc.setChatroomId(chatroom._UID);

    ctx.patchState({
      selectedChatroom: chatroom,
    });

    ctx.dispatch(
      new FetchDirectChatroomVillagers({
        participantIds: chatroom.PARTICIPANT_UIDS,
      })
    );

    ctx.dispatch(new FetchDirectChatroomMessages());

  }

  @Action(FetchDirectChatroomVillagers)
  fetchDirectChatroomVillagers(
    ctx: StateContext<DirectChatroomStateModel>,
    { payload: { participantIds } }: FetchDirectChatroomVillagers
  ) {
    let villagersPromise = [];

    participantIds.map((id) => {
      villagersPromise.push(this.villagerSvc.docOnce$(id).toPromise());
    });

    Promise.all(villagersPromise).then((docs) => {
      const villagers = docs;
      const uid = this.store.selectSnapshot(VillagerState.uid);

      ctx.patchState({
        chatroomVillagers: villagers.filter((x) => x._UID !== uid),
      });
    });
  }

  @Action(LeaveGroupDM)
  leaveGroupDm(
    ctx: StateContext<DirectChatroomStateModel>,
    action: LeaveGroupDM
  ) {
    const { chatroomId, villagerId } = action.payload;

    const { allChatrooms } = ctx.getState();
    const currentChatroom = allChatrooms.find((x) => x._UID === chatroomId);

    const update: Partial<DirectChatroom> = {
      PARTICIPANTS: [
        ...currentChatroom.PARTICIPANTS.filter((x) => x.UID !== villagerId),
      ],
      PARTICIPANT_UIDS: [
        ...currentChatroom.PARTICIPANT_UIDS.filter((x) => x !== villagerId),
      ],
      PARTICIPANT_READ_RECEIPTS: [
        ...currentChatroom.PARTICIPANT_READ_RECEIPTS.filter(
          (x) => x.UID !== villagerId
        ),
      ],
    };

    console.log(
      '[Direct Chatroom State] Removing participant from chatroom: ',
      update
    );

    return this.chatroomSvc.updateWithoutConverter(chatroomId, update);
  }

  @Action(AddDirectMembers)
  addDirectMembers(
    ctx: StateContext<DirectChatroomStateModel>,
    action: AddDirectMembers
  ) {
    const { chatroomId, newParticipants } = action.payload;

    const { allChatrooms } = ctx.getState();
    const currentChatroom = allChatrooms.find((x) => x._UID === chatroomId);

    const update: Partial<DirectChatroom> = {
      PARTICIPANTS: [...currentChatroom.PARTICIPANTS, ...newParticipants],
      PARTICIPANT_UIDS: [
        ...currentChatroom.PARTICIPANT_UIDS,
        ...newParticipants.map((x) => x.UID),
      ],
      PARTICIPANT_READ_RECEIPTS: [
        ...currentChatroom.PARTICIPANT_READ_RECEIPTS,
        ...newParticipants.map((x) => ({
          UID: x.UID,
          LAST_READ_MESSAGE_COUNT: 0,
        })),
      ],
    };

    return this.chatroomSvc.updateWithoutConverter(chatroomId, update);
  }

  @Action(ChangeDirectChatTitle)
  changeChatroomTitle(
    ctx: StateContext<DirectChatroomStateModel>,
    action: ChangeDirectChatTitle
  ) {
    const { chatroomId, newTitle } = action.payload;

    const update: Partial<DirectChatroom> = {
      TITLE: newTitle,
    };

    return this.chatroomSvc.updateWithoutConverter(chatroomId, update);
  }

  @Action(UpdateDirectChatReadReceipts)
  async UpdateDirectChatReadReceipts(
    ctx: StateContext<DirectChatroomStateModel>,
    action: UpdateDirectChatReadReceipts
  ) {
    const { chatroom } = action.payload;
    const currentVillagerId = this.store.selectSnapshot(VillagerState.uid);
    const response = await this.fns
      .httpsCallable('readReceipts-updateDM')({
        chatroomId: chatroom._UID,
        villagerId: currentVillagerId,
      })
      .toPromise();

    console.log(
      '[DEBUG] [Direct Chatroom State] DONE UPDATING READ RECEIPTS: ',
      response
    );
  }

  @Action(RemoveSelectedDirectChatroom)
  clearActiveChatroom(ctx: StateContext<DirectChatroomStateModel>) {
    this.store.dispatch(new Disconnect(FetchDirectChatroomMessages));
    ctx.patchState({
      selectedChatroom: null,
      messages: [],
    });
  }

  @Action(FetchDirectChatroomMessages)
  async fetchMessages(
    ctx: StateContext<DirectChatroomStateModel>,
    action: FetchDirectChatroomMessages
  ) {
    await this.presentLoading('Loading Messages');
  }

  @Action(DeleteDirectChatMessage)
  deleteMessage(
    ctx: StateContext<DirectChatroomStateModel>,
    action: DeleteDirectChatMessage
  ) {
    const { messageId } = action.payload;

    return this.chatroomMessagesSvc.updateWithoutConverter(messageId, {
      DELETED: true,
    });
  }

  @Action(SendDirectChatMessage)
  async sendMessage(
    ctx: StateContext<DirectChatroomStateModel>,
    action: SendDirectChatMessage
  ) {
    const { selectedChatroom } = ctx.getState();
    const { message, villageId } = action.payload;
    const villagerId = this.store.selectSnapshot(VillagerState.uid);

    console.log('message to send: ', message);

    const response = await this.fns
      .httpsCallable('dms-sendMessage')({
        message,
        chatroomId: selectedChatroom._UID,
        villagerId,
        villageId,
      })
      .toPromise();

    console.log('[DM SERVER] DONE SENDING MESSAGE: ', response);
  }

  @Action(DirectChatroomReact)
  async react(
    ctx: StateContext<DirectChatroomStateModel>,
    action: DirectChatroomReact
  ) {
    const { message, emoji, villagerUid } = action.payload;

    let reactions = [...message.REACTIONS];

    if (reactions.length > 0) {
      console.log('checking existing reactions');
      const index = reactions.findIndex(
        (x) => x.VILLAGER_UID === villagerUid && x.EMOJI === emoji
      );

      if (index > -1) {
        console.log('removing reaction');
        reactions.splice(index, 1);
      } else {
        console.log('adding reaction');
        let reaction: Reaction = {
          EMOJI: emoji,
          VILLAGER_UID: villagerUid,
        };
        reactions.push(reaction);
      }
    } else {
      console.log('adding first reaction');
      let reaction: Reaction = {
        EMOJI: emoji,
        VILLAGER_UID: villagerUid,
      };
      reactions.push(reaction);
    }

    console.log(`updating ${message._UID} reactions with: `, reactions);
    return this.chatroomMessagesSvc.updateWithoutConverter(message._UID, {
      REACTIONS: reactions,
    });
  }

  @Action(AcceptDirectRequest)
  acceptRequest(
    ctx: StateContext<DirectChatroomStateModel>,
    action: AcceptDirectRequest
  ) {
    const { chatroom, villagerId } = action.payload;

    const idx = chatroom.PARTICIPANTS.findIndex((x) => x.UID === villagerId);
    if (idx > -1) {
      let cloned = [...chatroom.PARTICIPANTS];
      const participant: DirectParticipant = { ...cloned[idx], ACCEPTED: true };
      cloned[idx] = participant;

      return this.chatroomSvc.updateWithoutConverter(chatroom._UID, {
        PARTICIPANTS: cloned,
        UPDATED_AT: firebase.default.firestore.FieldValue.serverTimestamp(),
      });
    } else {
      this.analytics.logError(
        'error_accept_dm',
        this.component,
        new Error('Could not find matching participant')
      );
      alert(`Error Accepting DM. Let us know and we'll fix this for you!`);
    }
  }

  @Action(RejectDirectRequest)
  rejectRequest(
    ctx: StateContext<DirectChatroomStateModel>,
    action: AcceptDirectRequest
  ) {
    const { chatroom, villagerId } = action.payload;

    const idx = chatroom.PARTICIPANTS.findIndex((x) => x.UID === villagerId);
    if (idx > -1) {
      let cloned = [...chatroom.PARTICIPANTS];
      const participant: DirectParticipant = { ...cloned[idx], REJECTED: true };
      cloned[idx] = participant;

      this.store.dispatch(new RemoveSelectedDirectChatroom());

      return this.chatroomSvc.updateWithoutConverter(chatroom._UID, {
        PARTICIPANTS: cloned,
        UPDATED_AT: firebase.default.firestore.FieldValue.serverTimestamp(),
      });
    } else {
      this.analytics.logError(
        'error_reject_dm',
        this.component,
        new Error('Could not find matching participant')
      );
      alert(`Error Rejecting DM. Let us know and we'll fix this for you!`);
    }
  }

  @Action(ClearDirectChatrooms)
  clearDMs(ctx: StateContext<DirectChatroomStateModel>) {
    ctx.setState({
      allChatrooms: [],
      joinedChatrooms: [],
      pendingChatrooms: [],
      selectedChatroom: null,
      messages: [],
      segment: 'joined',
      chatroomVillagers: [],
    });
  }

  @Action(ToggleChatroomMutedId)
  toggleChatroomMutedId(
    ctx: StateContext<DirectChatroomStateModel>,
    action: ToggleChatroomMutedId
  ) {
    const { uid } = action.payload;
    const {
      selectedChatroom: { MUTED_UIDS, _UID: courtyardUid },
    } = ctx.getState();

    let newMutedIds = [...MUTED_UIDS];
    const index = MUTED_UIDS.indexOf(uid);
    if (index > -1) {
      newMutedIds.splice(index, 1);
    } else {
      newMutedIds.push(uid);
    }

    return this.chatroomSvc.updateWithoutConverter(courtyardUid, {
      MUTED_UIDS: newMutedIds,
    });
  }

  private checkChatroomsLoaded(villagerUid: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const hasDataLoaded = this.store.selectSnapshot(AppState.dmsLoaded);
      console.log('Have chatrooms loaded? ', hasDataLoaded);
      if (!hasDataLoaded) {
        // register listener above the dispatch below.
        this.actions$
          .pipe(ofActionCompleted(StreamEmitted(FetchDirectChatrooms)))
          .subscribe(() => {
            console.log('Data received');
            resolve('done');
          });

        console.log('Dispatching fetch ');
        this.store.dispatch(new FetchDirectChatrooms({ villagerUid }));
      } else {
        resolve('done');
      }
    });
  }

  private checkForExistingRooms(
    ctx: StateContext<DirectChatroomStateModel>,
    newParticipants: DirectParticipant[]
  ) {
    console.log(
      '[Direct Chat State] does room exist? ',
      newParticipants.map((x) => x.UID)
    );
    const { allChatrooms } = ctx.getState();
    const existingRoom = allChatrooms.find((x) => {
      const newParticipantIds = new Set(newParticipants.map((x) => x.UID));
      const existingParticipantIds = new Set(x.PARTICIPANT_UIDS);
      console.log(
        '[Direct Chat State] checking participants for set equality: ',
        isEqual(newParticipantIds, existingParticipantIds)
      );
      return isEqual(newParticipantIds, existingParticipantIds);
    });

    if (existingRoom) {
      console.log('[Direct Chatroom State] chatroom already exists');
      ctx.dispatch(new SetSelectedDirectChatroom({ chatroom: existingRoom }));
      // this.showAlreadyExists();
      return 'already exists';
    } else {
      console.log('[Direct Chatroom state] no existing room. creating new one');
      return 'create new room';
    }
  }

  async showAlreadyExists() {
    const alert = await this.toastCtrl.create({
      message: 'This chatroom already exists',
      position: 'top',
      duration: 4000,
      color: 'primary',
      buttons: ['OK'],
    });

    await alert.present();
  }

  async presentLoading(message: string = '') {
    if (!this.loading) {
      this.loading = await this.loadingCtrl.create({
        duration: 3000,
        message,
        backdropDismiss: true,
      });
      return this.loading.present();
    }
  }
}
