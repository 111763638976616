import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, ofActionCompleted, Select, Store } from '@ngxs/store';
import { StreamEmitted } from '@ngxs-labs/firestore-plugin';
import {
  AlertController,
  IonContent,
  IonInput,
  LoadingController,
  ModalController,
  NavController,
  ToastController,
} from '@ionic/angular';
import { AngularFireAuth } from '@angular/fire/auth';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { GetVillager, JoinVillage, Login } from 'src/app/state/app.actions';
import { AnalyticsService } from 'src/app/analytics.service';
import { AuthState } from 'src/app/state/auth.state';
import { environment } from 'src/environments/environment';
import { OnboardingSlidesPage } from 'src/app/modals/onboarding-slides/onboarding-slides.page';
import { decodedEmailInvite } from 'src/app/models/auth.model';
import { Village } from 'src/app/models/village.model';
import { Villager } from 'src/app/models/villager.model';
import { VillagerState } from 'src/app/state/villager.state';
import { MemberInviteActions } from 'src/app/state/member-invite.actions';
import { JoinedCircle } from 'src/app/models/circle.model';
import { take } from 'rxjs/operators';
import { tadaAnimation } from 'angular-animations';

@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
  animations: [tadaAnimation()],
})
export class LoginPage implements OnInit {
  appName = environment.appName;
  running = false;
  passwordType = 'password';
  passwordIcon = 'eye-off';
  loading: HTMLIonLoadingElement = null;
  loginHandler: Subscription;
  nativeEmailInput: HTMLInputElement = null;
  nativePasswordInput: HTMLInputElement = null;

  @ViewChild('loginContent', { read: IonContent, static: true })
  content: IonContent;

  @ViewChild('emailInput', { static: true }) emailInput: IonInput;
  @ViewChild('passwordInput', { static: true }) passwordInput: IonInput;

  @Select(AuthState.isAuthenticated) isAuthenticated$: Observable<boolean>;

  public email = '';
  public password = '';

  waveHello = false;
  emailedInviteCode: string;
  invitedEmail: string;
  inviteId: string;
  circles: JoinedCircle[] = [];


  constructor(
    private router: Router,
    private alertCtrl: AlertController,
    private store: Store,
    public auth: AngularFireAuth,
    private analytics: AnalyticsService,
    private navCtrl: NavController,
    private modalCtrl: ModalController,
    private activatedRoute: ActivatedRoute,
    private fns: AngularFireFunctions,
    private actions$: Actions,
    private loadingCtrl: LoadingController,
    private toastCtrl: ToastController
  ) {}

  async ngOnInit() {
    combineLatest([
      this.actions$.pipe(ofActionCompleted(StreamEmitted(GetVillager))),
      this.actions$.pipe(ofActionCompleted(Login)),
    ])
      .pipe(take(1))
      .subscribe(([villager, login]) => {
        if (villager && login) {
          if (this.invitedEmail && this.emailedInviteCode && this.inviteId) {
            if (this.invitedEmail === this.email) {
              // otherwise can accept someone elses invite...
              this.launchInviteAlert(
                this.invitedEmail,
                this.emailedInviteCode,
                this.inviteId,
                this.circles
              );
            }
          }
        }
      });

    this.waveHello = true;
    setTimeout(() => {
      this.waveHello = false;
    }, 1000);

    // this.nativeEmailInput = await this.emailInput.getInputElement();
    // this.nativePasswordInput = await this.passwordInput.getInputElement();

    // this.nativeEmailInput.addEventListener('change', (ev: Event) => {
    //   requestAnimationFrame(() => {
    //     this.email = (ev.target as HTMLInputElement).value;
    //   });
    // });

    // this.nativePasswordInput.addEventListener('change', (ev: Event) => {
    //   requestAnimationFrame(() => {
    //     this.password = (ev.target as HTMLInputElement).value;
    //   });
    // });

    this.activatedRoute.queryParamMap.subscribe((route) => {
      const encodedInvite = route.get('invite');
      console.log('encodedInvite: ', encodedInvite);
      if (encodedInvite) {
        const decodedInvite: decodedEmailInvite = JSON.parse(
          atob(decodeURI(encodedInvite))
        );

        console.log('decodedInvite: ', decodedInvite);

        if (decodedInvite) {
          const { email, inviteCode, inviteId, circles } = decodedInvite;
          if (email) this.invitedEmail = email;
          if (inviteCode) this.emailedInviteCode = inviteCode;
          if (inviteId) this.inviteId = inviteId;
          if (circles) this.circles = circles;

          const isAuthenticated = this.store.selectSnapshot(
            AuthState.isAuthenticated
          );
          if (isAuthenticated) {
            this.launchInviteAlert(email, inviteCode, inviteId, circles);
          } else {
            requestAnimationFrame(() => {
              this.email = email;
            });
          }
        }
      }
    });
  }

  ionViewWillEnter() {
    const isAuthenticated = this.store.selectSnapshot(
      AuthState.isAuthenticated
    );
    if (isAuthenticated) {
      this.navCtrl.setDirection('root');
      this.router.navigate(['/pages/home'], { replaceUrl: true });
    }
  }

  @HostListener('document:keydown.enter', ['$event'])
  onEnterKeyboardEvent(ev) {
    if (ev.target === this.nativeEmailInput) {
      this.passwordInput.setFocus();
    } else if (ev.target === this.nativePasswordInput) {
      this.login();
    }
  }

  async login() {
    this.running = true;
    const email = this.email;
    const password = this.password;

    this.analytics.logEvent('login_clicked', {});

    try {
      await this.store.dispatch(new Login({ email, password })).toPromise();
      this.running = false;
      this.navCtrl.setDirection('root');
      this.router.navigate(['/pages/home'], { replaceUrl: true });
    } catch (e) {
      this.analytics.logEvent('login_error', {
        error: e,
        errorCode: e.code,
      });
      this.running = false;
      switch (e.code) {
        case 'auth/network-request-failed':
          console.error('No internet connection');
          this.showErrorAlert(
            'Error Connecting. Please check your internet connection.'
          );
          break;
        default:
          this.showErrorAlert('Error Logging in');
      }

      console.error(e);
    }
  }

  async launchInviteAlert(
    email: string,
    inviteCode: string,
    inviteId: string,
    circles: JoinedCircle[]
  ) {

    await this.presentLoading('Joining Village...');
    const village: Village = await this.fns
      .httpsCallable('invitation-getVillageFromInviteCode')({
        inviteCode,
      })
      .toPromise();

    const villager: Villager = this.store.selectSnapshot(
      VillagerState.currentVillager
    );

    const existingLoading = await this.loadingCtrl.getTop();
    if (existingLoading) {
      existingLoading.dismiss();
    }

    const alert = await this.alertCtrl.create({
      header: `You've been invited to join ${village.NAME}`,
      cssClass: 'darker-backdrop',
      message: `Click accept to join this village`,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'danger',
          handler: () => {
            this.clearInviteCache();
          },
        },
        {
          text: 'Ok',
          handler: () => {
            this.addVillagerToVillage(inviteCode, villager._UID, circles);
          },
        },
      ],
    });

    await alert.present();
  }

  async addVillagerToVillage(
    inviteCode: string,
    villagerId: string,
    circles: JoinedCircle[]
  ) {
    try {
      console.log('circles is: ', circles);
      const { villageId } = await this.fns
        .httpsCallable('village-joinVillageByInviteCode')({
          inviteCode,
          villagerId,
          circles,
        })
        .toPromise();
    } catch (e) {
      console.error('Error joining village: ', e);
    }
  }

  async joinVillageByInvite(
    village: Village,
    villager: Villager,
    email: string,
    inviteId: string,
    circlesToJoin: JoinedCircle[]
  ) {
    this.store.dispatch(
      new MemberInviteActions.MarkInviteAsAccepted({
        inviteId,
        email,
      })
    );

    this.clearInviteCache();

    if (villager.VILLAGES && !villager.VILLAGES_UIDS.includes(village._UID)) {
      await this.store
        .dispatch([
          new JoinVillage({
            villager,
            village,
            updateVillageCircles: true,
            circlesToJoin,
          }),
        ])
        .toPromise();

      this.showSuccessAlert(village.NAME);

      if (this.loading) {
        this.loading.dismiss();
        this.loading = null;
      }
    } else {
      this.showAlreadyJoinedErrorAlert();
      if (this.loading) {
        this.loading.dismiss();
        this.loading = null;
      }
    }
  }

  clearInviteCache() {
    localStorage.removeItem('email');
    localStorage.removeItem('inviteCode');
    localStorage.removeItem('inviteId');
    localStorage.removeItem('circles');
  }

  async showSuccessAlert(villageName: string) {
    const alert = await this.alertCtrl.create({
      header: `Welcome to ${villageName}!`,
      message: `Tip: Introduce yourself 👋`,
      buttons: ['OK'],
    });

    await alert.present();
  }

  async showAlreadyJoinedErrorAlert() {
    const alert = await this.alertCtrl.create({
      header: 'Hey!',
      message: 'We noticed you are already apart of this Village!',
      buttons: ['OK'],
    });

    await alert.present();
  }

  hideShowPassword() {
    this.passwordType = this.passwordType === 'text' ? 'password' : 'text';
    this.passwordIcon = this.passwordIcon === 'eye-off' ? 'eye' : 'eye-off';
  }

  goToGetStarted() {
    this.analytics.logEvent('login_dont_have_an_account_clicked', {});
    this.router.navigateByUrl('/signup');
  }

  async passwordReset() {
    const alert = await this.alertCtrl.create({
      header: 'Enter your email address',
      message: `If there's an email address associated with an account, we'll send you an email to reset your password.`,
      inputs: [
        {
          name: 'email',
          type: 'email',
          placeholder: 'Email Address',
        },
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'danger',
          handler: () => {
            console.log('Confirm Cancel');
          },
        },
        {
          text: 'Ok',
          handler: (data) => {
            console.log('Reset password with : ', data.email);
            this.triggerPasswordReset(data.email);
          },
        },
      ],
    });

    await alert.present();
  }

  async presentLoading(message: string = '') {
    const existing = await this.loadingCtrl.getTop();
    if (existing) existing.dismiss();
    if (!this.loading) {
      this.loading = await this.loadingCtrl.create({
        duration: 30000,
        message,
        backdropDismiss: true,
      });
      return this.loading.present();
    }
  }


  triggerPasswordReset(email: string) {
    this.analytics.logEvent('login_password_reset_clicked', {});

    this.fns
      .httpsCallable('bot-passwordResetTriggeredSOS')({
        email,
      })
      .toPromise();

    this.sendPasswordReset(email).then(
      () => {
        this.showSuccessToast();
      },
      (err) => {
        this.analytics.logEvent('login_password_reset_error', {
          error: err,
        });
        this.showErrorAlert(
          'Error resetting password. Please email support@revillager.com'
        );
      }
    );
  }

  sendPasswordReset(email: string) {
    return this.auth.sendPasswordResetEmail(email);
  }

  async showSuccessToast() {
    const toast = await this.toastCtrl.create({
      duration: 3000,
      message: 'Password reset email sent. Make sure to check your spam folder',
      position: 'top',
      color: 'primary',
    });
    return await toast.present();
  }

  async showErrorAlert(message: string) {
    const alert = await this.alertCtrl.create({
      header: 'Error',
      message,
      buttons: ['OK'],
    });

    await alert.present();
  }

  async onboard() {
    const modal = await this.modalCtrl.create({
      component: OnboardingSlidesPage,
    });

    return await modal.present();
  }
}
