<!-- <div class="image-wrapper">


  <ion-chip color="dark" >


  </ion-chip>
</div> -->

<ion-item class="full-width-item">
  <ion-thumbnail slot="start">
    <img [src]="previewIconPath" />
  </ion-thumbnail>
  <ion-label class="ion-text-wrap">{{ fileName }}</ion-label>
  <ion-icon slot="end" *ngIf="!edit" (click)="download()" name="cloud-download-outline"></ion-icon>
  <ion-icon slot="end" *ngIf="edit" name="trash-outline" color="danger"></ion-icon>
</ion-item>
