import { Injectable } from '@angular/core';
import { ShareService } from '../services/share.service';
import { ExchangeService } from '../services/exchange.service';
import { HangoutService } from '../services/hangout.service';
import { SupportRequestService } from '../services/support-request.service';

import * as firebase from 'firebase';
import 'firebase/firestore';
import { GenericPost, PostType } from '../models/post-core.model';
import { AnalyticsService } from '../analytics.service';
import { Store } from '@ngxs/store';
import { VillageState } from './village.state';
import { ReadReceipt } from '../models/read-receipt.model';
import { ConflictResolutionService } from '../services/conflict-resolution.service';
import { AlertController } from '@ionic/angular';
import { AnnouncementService } from '../services/announcement.service';
import { SharedListService } from '../services/shared-list.service';
import { Courtyard } from '../models/courtyard.model';
import { CourtyardService } from '../services/courtyard.service';
import { RecipientToken } from '../models/chat-message.model';
import { Villager } from '../models/villager.model';

@Injectable({
  providedIn: 'root',
})
export class StateUtilities {
  constructor(
    private exchangeSvc: ExchangeService,
    private bulletinSvc: ShareService,
    private announcementSvc: AnnouncementService,
    private hangoutSvc: HangoutService,
    private supportRequestSvc: SupportRequestService,
    private conflictSvc: ConflictResolutionService,
    private analytics: AnalyticsService,
    private store: Store,
    private alertCtrl: AlertController,
    private sharedListSvc: SharedListService,
    private courtyardSvc: CourtyardService
  ) {}

  setUpdatedAtTimestamp(chatroom: GenericPost) {
    const { _UID, _TYPE } = chatroom;
    if (!_UID) {
      console.error('ERROR: Missing UID on post.');
      this.analytics.logEvent('error_utilities_missing_id_update_timestamp', {
        missingId: _UID,
      });
      return;
    }
    const update = {
      UPDATED_AT: firebase.default.firestore.FieldValue.serverTimestamp(),
    };
    switch (_TYPE) {
      case 'SHARE':
        return this.bulletinSvc.updateWithoutConverter(_UID, update);
      case 'HANGOUT':
        return this.hangoutSvc.updateWithoutConverter(_UID, update);
      case 'EXCHANGE':
        return this.exchangeSvc.updateWithoutConverter(_UID, update);
      case 'SUPPORT':
        return this.supportRequestSvc.updateWithoutConverter(_UID, update);
      case 'CONFLICT':
        return this.conflictSvc.updateWithoutConverter(_UID, update);
      case 'ANNOUNCEMENT':
        return this.announcementSvc.updateWithoutConverter(_UID, update);
      case 'LIST':
        return this.sharedListSvc.updateWithoutConverter(_UID, update);

      default:
        this.analytics.logEvent('chatroom_update_timestamp_error', {
          villageId: this.store.selectSnapshot(VillageState.uid),
          chatroomId: chatroom._UID,
          error: 'Not a matching _TYPE ' + chatroom._TYPE,
        });
        alert('Not a matching _TYPE ' + chatroom._TYPE);

        break;
    }
  }

  incrementMessageCount(chatroom: GenericPost) {
    const { _UID, _TYPE } = chatroom;
    if (!_UID) {
      console.error('ERROR: Missing UID on post.');
      this.analytics.logEvent(
        'error_utilities_missing_id_increment_messagecount',
        {
          missingId: _UID,
        }
      );
      return;
    }
    const update = {
      MESSAGE_COUNT: firebase.default.firestore.FieldValue.increment(1),
    };
    switch (_TYPE) {
      case 'SHARE':
        return this.bulletinSvc.updateWithoutConverter(_UID, update);
      case 'HANGOUT':
        return this.hangoutSvc.updateWithoutConverter(_UID, update);
      case 'EXCHANGE':
        return this.exchangeSvc.updateWithoutConverter(_UID, update);
      case 'SUPPORT':
        return this.supportRequestSvc.updateWithoutConverter(_UID, update);
      case 'CONFLICT':
        return this.conflictSvc.updateWithoutConverter(_UID, update);
      case 'ANNOUNCEMENT':
        return this.announcementSvc.updateWithoutConverter(_UID, update);
      case 'LIST':
        return this.sharedListSvc.updateWithoutConverter(_UID, update);

      default:
        this.analytics.logEvent('chatroom_increment_message_count_error', {
          villageId: this.store.selectSnapshot(VillageState.uid),
          chatroomId: chatroom._UID,
          error: `Not a matching _TYPE: ${_TYPE}`,
        });
        console.error('Not a matching _TYPE ', _TYPE);
        break;
    }
  }

  updateParticipants(post: GenericPost, creatorUID: string) {
    const { _UID, _TYPE, PARTICIPANT_UIDS } = post;
    if (!_UID) {
      console.error('ERROR: Missing UID on post.');
      this.analytics.logEvent('error_utilities_missing_id_update_participant', {
        missingId: _UID,
        PARTICIPANT_UIDS,
      });
      return;
    }

    var existingParticipants = [];
    if (PARTICIPANT_UIDS) {
      existingParticipants = [...PARTICIPANT_UIDS];
    }

    let newParticipants = [...existingParticipants];
    newParticipants.push(creatorUID);
    newParticipants = [...new Set(newParticipants)];

    if (newParticipants.length > existingParticipants.length) {
      console.log(
        `Adding new participants ${newParticipants} vs old: ${existingParticipants}`
      );
      this.showJoinAlert();
      const update = { PARTICIPANT_UIDS: newParticipants };
      switch (_TYPE) {
        case 'SHARE':
          return this.bulletinSvc
            .updateWithoutConverter(_UID, update)
            .toPromise();

        case 'HANGOUT':
          return this.hangoutSvc
            .updateWithoutConverter(_UID, update)
            .toPromise();

        case 'EXCHANGE':
          return this.exchangeSvc
            .updateWithoutConverter(_UID, update)
            .toPromise();

        case 'SUPPORT':
          return this.supportRequestSvc
            .updateWithoutConverter(_UID, update)
            .toPromise();

        case 'CONFLICT':
          return this.conflictSvc
            .updateWithoutConverter(_UID, update)
            .toPromise();

        case 'ANNOUNCEMENT':
          return this.announcementSvc
            .updateWithoutConverter(_UID, update)
            .toPromise();

        case 'LIST':
          return this.sharedListSvc
            .updateWithoutConverter(_UID, update)
            .toPromise();

        default:
          this.analytics.logEvent('post_update_participants_error', {
            villageId: this.store.selectSnapshot(VillageState.uid),
            chatroomId: post._UID,
            error: `Not a matching _TYPE: ${_TYPE}`,
          });
          console.error('Not a matching _TYPE 3 ', _TYPE);

          break;
      }
    } else {
      console.log(
        'Not updating chatroom doc, current user already participant in this chatroom'
      );
    }
  }

  removeParticipantFromCourtyard(courtyard: Courtyard, villagerId: string) {
    const { _UID, PARTICIPANTS, PARTICIPANT_UIDS } = courtyard;
    if (!_UID) {
      console.error('ERROR: Missing UID on post.');
      this.analytics.logEvent('error_utilities_missing_id_remove_participant', {
        missingId: _UID,
        PARTICIPANT_UIDS,
      });
      return;
    }
    var updatedParticipantUids = [];
    if (PARTICIPANT_UIDS) {
      updatedParticipantUids = [...PARTICIPANT_UIDS];
    }

    const idx = updatedParticipantUids.indexOf(villagerId);
    if (idx > -1) {
      updatedParticipantUids.splice(idx, 1);
    }

    let updatedParticipants = [];
    if (PARTICIPANTS) {
      updatedParticipants = [
        ...PARTICIPANTS.filter((x) => {
          return x.UID !== villagerId;
        }),
      ];
    }

    const update = {
      PARTICIPANT_UIDS: updatedParticipantUids,
      PARTICIPANTS: updatedParticipants,
    };

    this.courtyardSvc.updateWithoutConverter(_UID, update).toPromise();
  }

  removeParticipantFromDiscussion(post: GenericPost, villagerId: string) {
    const { _UID, _TYPE, PARTICIPANT_UIDS } = post;
    if (!_UID) {
      console.error('ERROR: Missing UID on post.');
      this.analytics.logEvent('error_utilities_missing_id_remove_participant', {
        missingId: _UID,
        PARTICIPANT_UIDS,
      });
      return;
    }
    var updatedParticipants = [];
    if (PARTICIPANT_UIDS) {
      updatedParticipants = [...PARTICIPANT_UIDS];
    }

    const idx = updatedParticipants.indexOf(villagerId);
    if (idx > -1) {
      updatedParticipants.splice(idx, 1);
    }
    const update = {
      PARTICIPANT_UIDS: updatedParticipants,
    };
    switch (_TYPE) {
      case 'SHARE':
        this.bulletinSvc.updateWithoutConverter(_UID, update).toPromise();
        break;
      case 'HANGOUT':
        this.hangoutSvc.updateWithoutConverter(_UID, update).toPromise();
        break;
      case 'EXCHANGE':
        this.exchangeSvc.updateWithoutConverter(_UID, update).toPromise();

        break;
      case 'SUPPORT':
        this.supportRequestSvc.updateWithoutConverter(_UID, update).toPromise();
        break;

      case 'CONFLICT':
        this.conflictSvc.updateWithoutConverter(_UID, update).toPromise();
        break;

      case 'ANNOUNCEMENT':
        this.announcementSvc.updateWithoutConverter(_UID, update).toPromise();
        break;

      case 'LIST':
        this.sharedListSvc.updateWithoutConverter(_UID, update).toPromise();
        break;

      default:
        this.analytics.logEvent('discussion_remove_participant_error', {
          villageId: this.store.selectSnapshot(VillageState.uid),
          chatroomId: post._UID,
          error: 'Not a matching _TYPE' + post._TYPE,
        });
        alert('Not a matching _TYPE 3 ' + post._TYPE);

        break;
    }
  }

  reportPost(post: GenericPost, reporterId: string): Promise<any> {
    const { _UID, _TYPE, REPORTED_BY } = post;
    if (!_UID) {
      console.error('ERROR: Missing UID on post.');
      this.analytics.logEvent('error_utilities_missing_id_reportpost', {
        missingId: _UID,
        REPORTED_BY,
      });
      return;
    }
    var postReporters = [];
    if (REPORTED_BY) {
      postReporters = [...REPORTED_BY];
    }

    postReporters.push(reporterId);
    const update = { REPORTED_BY: postReporters };
    switch (_TYPE) {
      case 'SHARE':
        return this.bulletinSvc
          .updateWithoutConverter(_UID, update)
          .toPromise();

      case 'HANGOUT':
        return this.hangoutSvc.updateWithoutConverter(_UID, update).toPromise();
      case 'EXCHANGE':
        return this.exchangeSvc
          .updateWithoutConverter(_UID, update)
          .toPromise();

      case 'SUPPORT':
        return this.supportRequestSvc
          .updateWithoutConverter(_UID, update)
          .toPromise();

      case 'CONFLICT':
        return this.conflictSvc
          .updateWithoutConverter(_UID, update)
          .toPromise();

      case 'ANNOUNCEMENT':
        return this.announcementSvc
          .updateWithoutConverter(_UID, update)
          .toPromise();

      case 'LIST':
        return this.sharedListSvc
          .updateWithoutConverter(_UID, update)
          .toPromise();

      default:
        this.analytics.logEvent('post_report_error', {
          villageId: this.store.selectSnapshot(VillageState.uid),
          chatroomId: post._UID,
          error: `Not a matching _TYPE: ${_TYPE}`,
        });
        console.error('Error updating ', _TYPE);
        return;
    }
  }

  updateReadReceipts(
    uid: string,
    type: PostType,
    readReceipts: ReadReceipt[]
  ): Promise<any> {
    // console.log('DEBUG [Utilities] Updated read receipts: ', uid, readReceipts);
    if (!uid) {
      console.error('ERROR: Missing UID on post.');
      this.analytics.logEvent(
        'error_utilities_missing_id_update_readreceipts',
        {
          missingId: uid,
          PARTICIPANT_READ_RECEIPTS: readReceipts,
        }
      );
      return;
    }
    const update = {
      PARTICIPANT_READ_RECEIPTS: readReceipts,
    };
    switch (type) {
      case 'SHARE':
        return this.bulletinSvc.updateWithoutConverter(uid, update).toPromise();
      case 'HANGOUT':
        return this.hangoutSvc.updateWithoutConverter(uid, update).toPromise();

      case 'EXCHANGE':
        return this.exchangeSvc.updateWithoutConverter(uid, update).toPromise();

      case 'SUPPORT':
        return this.supportRequestSvc
          .updateWithoutConverter(uid, update)
          .toPromise();

      case 'CONFLICT':
        return this.conflictSvc.updateWithoutConverter(uid, update).toPromise();

      case 'ANNOUNCEMENT':
        return this.announcementSvc
          .updateWithoutConverter(uid, update)
          .toPromise();

      case 'LIST':
        return this.sharedListSvc
          .updateWithoutConverter(uid, update)
          .toPromise();

      default:
        this.analytics.logEvent('read_receipts_update_error', {
          postId: uid,
          error: 'Not a matching _TYPE' + type,
        });
        alert('Error updating ' + type);
        break;
    }
  }

  getPostFromNotification(postId: string, type: string): Promise<GenericPost> {
    return new Promise((resolve, reject) => {
      console.log(
        `[Utilities] Getting post ${postId} type: ${type} from notification`
      );
      switch (type as any) {
        case 'POST': // PATCH FOR OLD BAD DATA
        case 'SHARE':
        case 'SHARES':
          resolve(this.bulletinSvc.docOnce$(postId).toPromise());
          break;
        case 'HANGOUT':
        case 'HANGOUTS':
          resolve(this.hangoutSvc.docOnce$(postId).toPromise());
          break;
        case 'EXCHANGE':
        case 'EXCHANGES':
          resolve(this.exchangeSvc.docOnce$(postId).toPromise());
          break;
        case 'SUPPORT':
        case 'SUPPORT_REQUESTS':
        case 'SUPPORTS':
          resolve(this.supportRequestSvc.docOnce$(postId).toPromise());
          break;
        case 'CONFLICT':
        case 'CONFLICT_RESOLUTION':
          resolve(this.conflictSvc.docOnce$(postId).toPromise());
        case 'ANNOUNCEMENT':
          resolve(this.announcementSvc.docOnce$(postId).toPromise());
        case 'LIST':
          resolve(this.sharedListSvc.docOnce$(postId).toPromise());
        default:
          this.analytics.logEvent('notification_select_no_type_error', {
            villageId: this.store.selectSnapshot(VillageState.uid),
            postId: postId,
            error: 'Not a matching _TYPE' + type,
          });
          alert('Not a matching _TYPE 5 ' + type);
          reject('No matching type');
          break;
      }
    });
  }

  async showJoinAlert() {
    const alert = await this.alertCtrl.create({
      message:
        'We added this post to your Joined Posts so you can find it easier!',
      buttons: ['Got it!'],
    });

    await alert.present();
  }
}

export function getParticipantTokens(
  uids: string[],
  villagers: { [key: string]: Villager },
  villageId: string
): RecipientToken[] {
  const dedupedUids = [...new Set(uids)];
  return dedupedUids.reduce((acc, uid) => {
    const participantToken = getParticipantToken(villagers[uid], villageId);
    return participantToken ? [...acc, participantToken] : acc;
  }, []);
}

export function getParticipantToken(
  villager: Villager | undefined,
  villageId: string
): RecipientToken | undefined {
  if (villager && villager.NOTIFICATIONS_ALLOWED && villager.FCM_TOKEN) {
    const joinedVillage = villager.VILLAGES.find((y) => y.UID === villageId);
    if (joinedVillage && joinedVillage.PUSH) {
      return { UID: villager._UID, FCM_TOKEN: villager.FCM_TOKEN };
    }
  }
}
