import { Component, OnInit } from '@angular/core';
import {
  ModalController,
} from '@ionic/angular';
import { Store } from '@ngxs/store';
import { Villager } from 'src/app/models/villager.model';
import { VillagerState } from 'src/app/state/villager.state';

@Component({
  selector: 'app-change-villages-modal',
  templateUrl: './change-villages-modal.page.html',
  styleUrls: ['./change-villages-modal.page.scss'],
})
export class ChangeVillagesModalPage implements OnInit {

  villager: Villager;

  constructor(
    private store: Store,
    public modalCtrl: ModalController,
  ) {}

  ngOnInit() {
    this.villager = this.store.selectSnapshot(VillagerState.currentVillager);
  }

}
