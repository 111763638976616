import { Injectable } from '@angular/core';
import { AnalyticsService } from '../analytics.service';

@Injectable({
  providedIn: 'root',
})
export class HangoutPromptsService {
  private prompts = [
    `Something I wish more people knew about me is`,
    `A highlight of my last week/month/year is`,
    `Something I'm looking forward to is`,
    `Something I'd like to accomplish in the next quarter/6 months/year is`,
    `If there was 25 hours in a day suddenly, I'd use the extra hour to`,
    `Something I've always wanted to learn more about but never had the chance to is`,
    `If I were a teacher, the subject I would teach is`,

    `If i had all the money in the world, I would…`,
    `When i am at the end of my life, one thing i am most proud of would be…`,
    `When i am at the end of my life, one thing i wish i will have done is…`,
    `Something i think i’m terrible at that i wish i was better at is…`,
    `I feel the most excited about life when i’m…`,
    `I feel the most challenged by life when i’m…`,
  ];
  constructor(private analytics: AnalyticsService) {}

  getHangoutPrompts(count: number) {
    return this.getRandom(this.prompts, count)
  }

  private getRandom(arr: any[], n: number) {
    var result = new Array(n),
      len = arr.length,
      taken = new Array(len);
    if (n > len)
      throw new RangeError('getRandom: more elements taken than available');
    while (n--) {
      var x = Math.floor(Math.random() * len);
      result[n] = arr[x in taken ? taken[x] : x];
      taken[x] = --len in taken ? taken[len] : len;
    }
    return result;
  }
}
