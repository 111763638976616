import { Injectable } from '@angular/core';
import {
  Connected,
  Emitted,
  NgxsFirestoreConnect,
  StreamConnected,
  StreamEmitted,
} from '@ngxs-labs/firestore-plugin';
import {
  Action,
  NgxsAfterBootstrap,
  NgxsOnInit,
  Selector,
  State,
  StateContext,
  Store,
} from '@ngxs/store';
import * as moment from 'moment';
import { tap } from 'rxjs/operators';
import {
  SupportRequest,
  SupportRequestStateModel,
} from '../models/support-request.model';
import { SupportRequestService } from '../services/support-request.service';
import {
  FetchSupportRequests,
  CreateSupportRequest,
  ClearSupportRequests,
  OfferSupport,
  RemoveSupport,
  SilentlyUpdateSelectedPost,
  UpdateSupportRequest,
  DeleteSupportRequest,
  UpdateVillageTopics,
} from './app.actions';
import { StateUtilities } from './state-utilities';
import { DiscussionState } from './discussion.state';

@State<SupportRequestStateModel>({
  name: 'supportRequest',
  defaults: {
    supportRequests: [],
  },
})
@Injectable()
export class SupportRequestState implements NgxsOnInit, NgxsAfterBootstrap {
  constructor(
    private supportSvc: SupportRequestService,
    private ngxsFirestoreConnect: NgxsFirestoreConnect,
    private utilities: StateUtilities,
    private store: Store
  ) {}

  ngxsOnInit() {
    const sevenDaysAgo = moment().subtract(7, 'days').toDate();
    this.ngxsFirestoreConnect.connect(FetchSupportRequests, {
      to: (action) =>
        this.supportSvc.collection$((ref) =>
          ref
            // .where('_CREATED_AT', '>=', sevenDaysAgo)
            .where(
              'CIRCLE_UIDS',
              'array-contains-any',
              action.payload.villagerCircleIds
            )
        ),
      cancelPrevious: true,
    });
  }

  ngxsAfterBootstrap(ctx: StateContext<SupportRequestStateModel>) {}

  @Selector()
  static allSupportRequests(state: SupportRequestStateModel): SupportRequest[] {
    return state.supportRequests;
  }

  @Action(FetchSupportRequests)
  startSupportListener(
    ctx: StateContext<SupportRequestStateModel>,
    action: FetchSupportRequests
  ) {
    this.supportSvc.setVillageId(action.payload.villageUID);
  }

  // @Action(StreamConnected(FetchSupportRequests))
  // streamConnected(
  //   ctx: StateContext<SupportRequestStateModel>,
  //   { action }: Connected<FetchSupportRequests>
  // ) {
  //   console.log('[Share State] Stream connected');
  // }

  @Action(StreamEmitted(FetchSupportRequests))
  streamEmitted(
    ctx: StateContext<SupportRequestStateModel>,
    { action, payload }: Emitted<FetchSupportRequests, SupportRequest[]>
  ) {
    const selectedChatroom = this.store.selectSnapshot(
      DiscussionState.selectedPost
    );
    if (payload && selectedChatroom) {
      const matchingPost = payload.find(
        (x) => x._UID === selectedChatroom._UID
      );
      if (matchingPost) {
        this.store.dispatch(
          new SilentlyUpdateSelectedPost({ post: matchingPost })
        );
      }
    }

    ctx.patchState({
      supportRequests: payload,
    });
  }

  @Action(ClearSupportRequests)
  clear(ctx: StateContext<SupportRequestStateModel>) {
    ctx.setState({
      supportRequests: [],
    });
  }

  @Action(CreateSupportRequest)
  createSupportRequest(
    ctx: StateContext<SupportRequestStateModel>,
    action: CreateSupportRequest
  ) {
    const { supportRequest } = action.payload;
    this.store.dispatch(
      new UpdateVillageTopics({ topics: supportRequest.TOPICS })
    );

    return this.supportSvc.create$(supportRequest).pipe(
      tap(() => {
        console.log(
          '[Support Request State] successfully created support request'
        );
      })
    );
  }

  @Action(UpdateSupportRequest)
  updateRequest(
    ctx: StateContext<SupportRequestStateModel>,
    action: UpdateSupportRequest
  ) {
    const { post } = action.payload;
    const { _UID } = post;
    if (!_UID) {
      console.error('Missing Id. Not updating support request');
      alert('Error Updating Support Request');
      return false;
    }
    this.store.dispatch(new UpdateVillageTopics({ topics: post.TOPICS }));
    return this.supportSvc.update$(post._UID, post);
  }

  @Action(DeleteSupportRequest)
  deleteSupportRequest(ctx, action: DeleteSupportRequest) {
    const { post } = action.payload;
    return this.supportSvc.delete$(post._UID);
  }

  @Action(OfferSupport)
  async offerSupport(
    ctx: StateContext<SupportRequestStateModel>,
    action: OfferSupport
  ) {
    const { request, villagerUid, villagerName } = action.payload;
    const existingSupporters = [...request.SUPPORTERS];

    let newSupporters = [...existingSupporters];
    newSupporters.push({
      CREATOR_UID: villagerUid,
      CREATOR_NAME: villagerName,
    });
    newSupporters = [...new Set(newSupporters)];

    if (newSupporters.length > existingSupporters.length) {
      await this.utilities.updateParticipants(request, villagerUid);

      return this.supportSvc.updateWithoutConverter(request._UID, {
        SUPPORTERS: newSupporters,
      });
    } else {
      console.log(
        'Not updating support request doc, current user already supports in this request'
      );
      return null;
    }
  }

  @Action(RemoveSupport)
  removeSupport(
    ctx: StateContext<SupportRequestStateModel>,
    action: RemoveSupport
  ) {
    const { request, villagerUid } = action.payload;

    const supporters = [...request.SUPPORTERS];

    const idx = supporters.findIndex((x) => x.CREATOR_UID === villagerUid);
    if (idx > -1) {
      supporters.splice(idx, 1);

      return this.supportSvc.updateWithoutConverter(request._UID, {
        SUPPORTERS: supporters,
      });
    } else {
      return null;
    }
  }
}
