import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import {
  AlertController,
  LoadingController,
  ModalController,
  PopoverController,
  ToastController,
} from '@ionic/angular';
import { Select, Store } from '@ngxs/store';
import {
  Courtyard,
  CourtyardParticipant,
} from 'src/app/models/courtyard.model';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { VillagerState } from 'src/app/state/villager.state';
import * as firebase from 'firebase';
import 'firebase/firestore';
import { CourtyardActions } from 'src/app/state/courtyard.actions';
import { AnalyticsService } from 'src/app/analytics.service';
import { debounceTime, map } from 'rxjs/operators';
import { Circle, JoinedCircle } from 'src/app/models/circle.model';
import { combineLatest, Observable } from 'rxjs';
import { AppState } from 'src/app/state/app.state';
import { VillageState } from 'src/app/state/village.state';
import { PostCircleExplainPopoverComponent } from 'src/app/shared/post-circle-explain-popover/post-circle-explain-popover.component';
import { CircleState } from 'src/app/state/circle.state';
import { RecipientNotificationSettings } from 'src/app/models/notification-settings';
import { VillagerActions } from 'src/app/state/villager.actions';
import { Villager } from 'src/app/models/villager.model';
import { CourtyardState } from 'src/app/state/courtyard.state';
import _ from 'lodash';

@Component({
  selector: 'app-create-courtyard-modal',
  templateUrl: './create-courtyard-modal.page.html',
  styleUrls: ['./create-courtyard-modal.page.scss'],
})
export class CreateCourtyardModalPage implements OnInit {
  loading: HTMLIonLoadingElement;
  courtyardForm = new FormGroup({
    title: new FormControl('', [Validators.required]),
    description: new FormControl(''),
    assignedCircle: new FormControl(''),
  });

  get title() {
    return this.courtyardForm.get('title');
  }

  get description() {
    return this.courtyardForm.get('description');
  }

  get assignedCircle() {
    return this.courtyardForm.get('assignedCircle');
  }

  defaultCircle: JoinedCircle = {
    NAME: 'Members',
    UID: 'MEMBERS',
  };

  @Select(VillageState.villageName) villageName$: Observable<string>;
  @Select(VillagerState.currentVillagerCircles) villagerCircles$: Observable<
    JoinedCircle[]
  >;
  @Select(AppState.postCirclesAssigned) postCirclesAssigned$: Observable<
    JoinedCircle[]
  >;
  @Select(CircleState.allCircles) allCircles$: Observable<Circle[]>;
  mappedCircles: JoinedCircle[] = [];

  // defaultChannelNames: string[] = ['general', 'heart-shares', 'just-for-fun'];

  @Input() courtyard?: Courtyard;
  @Input() edit = false;
  constructor(
    private modalCtrl: ModalController,
    private utils: UtilitiesService,
    private store: Store,
    private loadingCtrl: LoadingController,
    private analytics: AnalyticsService,
    private alertCtrl: AlertController,
    private toastCtrl: ToastController,
    private popoverCtrl: PopoverController
  ) {}

  ngOnInit() {
    if (this.edit === true) {
      if (this.courtyard.TITLE) {
        this.title.setValue(this.courtyard.TITLE);
        // if (this.defaultChannelNames.indexOf(this.courtyard.TITLE) > -1) {
        //   this.title.disable();
        // }
      }

      this.description.setValue(
        this.utils.removeExistingTags(this.courtyard.DESCRIPTION)
      );

      this.assignedCircle.setValue(this.courtyard.CIRCLE, { emitEvent: false });
    } else {
      this.assignedCircle.setValue(this.defaultCircle, { emitEvent: false });
    }

    this.title.valueChanges.pipe(debounceTime(5)).subscribe((value) => {
      if (value) {
        value = value.replace(/\s+/g, '-').toLowerCase();
        this.title.setValue(value, { emitEvent: false });
      }
    });

    combineLatest([this.villagerCircles$, this.allCircles$]).subscribe(
      ([villagerCircles, allCircles]) => {
        this.mappedCircles = [];
        villagerCircles.forEach((joinedCircle) => {
          const mappedCircle = allCircles.find(
            (x) => x._UID === joinedCircle.UID
          );
          this.mappedCircles.push({
            UID: mappedCircle._UID,
            NAME: mappedCircle.NAME,
          });
        });
        console.log(
          '[DEBUG] Circles to display in select: ',
          this.mappedCircles
        );
      }
    );
  }

  close() {
    this.modalCtrl.dismiss();
  }

  async save() {
    const existingCourtyards = this.store.selectSnapshot(
      CourtyardState.allCourtyards
    );
    const CIRCLE_UID = this.assignedCircle.value.UID;
    const circleCourtyardTitles = existingCourtyards
      .filter((x) => x.CIRCLE_UID === CIRCLE_UID)
      .map((x) => x.TITLE);
    if (circleCourtyardTitles.indexOf(this.title.value) > -1) {
      this.showErrorAlert(
        'Sorry, that courtyard name is already taken for this circle. Please enter a different name and save again!'
      );
      return;
    }
    this.analytics.logEvent('courtyard_save_new', {});
    const parsedText = this.utils.parseLinksFromText(this.description.value);

    await this.presentLoading();
    const uid = this.store.selectSnapshot(VillagerState.uid);
    const firstName = this.store.selectSnapshot(VillagerState.firstName);
    const lastName = this.store.selectSnapshot(VillagerState.lastName);

    const participant: CourtyardParticipant = {
      UID: uid,
      FIRST_NAME: firstName,
      LAST_NAME: lastName,
    };

    const courtyard: Courtyard = {
      _CREATOR_UID: uid,
      _CREATOR_FIRST_NAME: firstName,
      _CREATOR_LAST_NAME: lastName,
      _SERVER_TIMESTAMP:
        firebase.default.firestore.FieldValue.serverTimestamp(),
      _CREATED_AT: new Date(),
      TITLE: this.title.value,
      DESCRIPTION: parsedText,
      PARTICIPANT_UIDS: [uid],
      PARTICIPANTS: [participant],
      PARTICIPANT_READ_RECEIPTS: [{ UID: uid, LAST_READ_MESSAGE_COUNT: 0 }],
      MUTED_UIDS: [],
      _TYPE: 'PUBLIC',
      MESSAGE_COUNT: 0,
      UPDATED_AT: firebase.default.firestore.FieldValue.serverTimestamp(),
      CIRCLE_UID,
      CIRCLE: this.assignedCircle.value,
    };

    this.store.dispatch(new CourtyardActions.Create({ courtyard })).subscribe(
      () => {
        this.analytics.logEvent('create_courtyard_success', {});
        this.courtyardForm.reset();
        this.modalCtrl.dismiss({ action: 'create-close' });
        this.loading.dismiss();
        this.loading = null;
        this.store.dispatch(
          new VillagerActions.MarkOnboardedTaskComplete({
            villagerId: uid,
            field: 'HAS_CREATED_CHANNEL',
          })
        );
      },
      (err) => {
        this.analytics.logEvent('create_courtyard_error', {
          error: err,
        });
        console.error(err);
        this.showErrorAlert();
        this.loading.dismiss();
        this.loading = null;
      }
    );
  }

  async update() {
    this.analytics.logEvent('courtyard_update', {
      courtyardId: this.courtyard._UID,
    });
    const existingCourtyards = this.store.selectSnapshot(
      CourtyardState.allCourtyards
    );
    const CIRCLE_UID = this.assignedCircle.value.UID;
    const circleCourtyardTitles = existingCourtyards
      .filter((x) => x.CIRCLE_UID === CIRCLE_UID)
      .map((x) => x.TITLE);
    if (this.title.value !== this.courtyard.TITLE && circleCourtyardTitles.indexOf(this.title.value) > -1) {
      this.showErrorAlert(
        'Sorry, that courtyard name is already taken for this circle. Please enter a different name and save again!'
      );
      return;
    }
    await this.presentLoading();
    const update: Courtyard = {
      ...this.courtyard,
      DESCRIPTION: this.utils.parseLinksFromText(this.description.value),
      TITLE: this.title.value,
    };

    this.store
      .dispatch(new CourtyardActions.UpdateCourtyard({ courtyard: update }))
      .subscribe(
        () => {
          this.analytics.logEvent('courtyard_update_success', {});
          this.presentToast('Successfully updated courtyard');
          this.courtyardForm.reset();
          this.modalCtrl.dismiss();
          if (this.loading) {
            this.loading.dismiss();
            this.loading = null;
          }
        },
        (err) => {
          this.analytics.logEvent('courtyard_update_error', { err });
          if (this.loading) {
            this.loading.dismiss();
            this.loading = null;
          }
        }
      );
  }

  async presentToast(header: string) {
    const toast = await this.toastCtrl.create({
      header,
      color: 'primary',
      duration: 3000,
      position: 'top',
      buttons: ['Ok'],
    });
    toast.present();
  }

  compareWith(o1: JoinedCircle, o2: JoinedCircle | JoinedCircle[]) {
    if (!o1 || !o2) {
      return o1 === o2;
    }

    if (Array.isArray(o2)) {
      return o2.some((u: JoinedCircle) => u.UID === o1.UID);
    }

    return o1.UID === o2.UID;
  }

  async showHelper(e) {
    const popover = await this.popoverCtrl.create({
      component: PostCircleExplainPopoverComponent,
      event: e,
    });

    await popover.present();
  }

  async presentLoading() {
    if (!this.loading) {
      this.loading = await this.loadingCtrl.create({
        duration: 15000,
        backdropDismiss: true,
      });
      return this.loading.present();
    }
  }

  async showErrorAlert(msg?: string) {
    const alert = await this.alertCtrl.create({
      header: 'Error',
      message: msg
        ? msg
        : 'We ran into an error creating the courtyard. Please check that everything is filled out and try again.',
      buttons: ['OK'],
    });

    await alert.present();
  }
}
