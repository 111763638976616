import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { AlertController } from '@ionic/angular';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { Circle, JoinedCircle } from 'src/app/models/circle.model';
import { HangoutIntent } from 'src/app/models/hangout.model';
import { Villager } from 'src/app/models/villager.model';
import { AppState } from 'src/app/state/app.state';
import { CircleState } from 'src/app/state/circle.state';
import { VillagerState } from 'src/app/state/villager.state';

@Component({
  selector: 'app-event-organizers',
  templateUrl: './event-organizers.component.html',
  styleUrls: ['./event-organizers.component.scss'],
})
export class EventOrganizersComponent implements OnInit {
  organizerForm = new FormGroup({
    organizers: new FormControl(''),
  });

  get organizers() {
    return this.organizerForm.get('organizers');
  }

  @Select(VillagerState.currentVillagerCircles) villagerCircles$: Observable<
    JoinedCircle[]
  >;
  @Select(CircleState.allCircles) allCircles$: Observable<Circle[]>;
  @Select(AppState.postCirclesAssigned) postCirclesAssigned$: Observable<
    JoinedCircle[]
  >;
  @Select(VillagerState.currentVillager) currentVillager$: Observable<Villager>;
  currentVillager: Villager;

  villageCircles: Circle[];
  possibleVillagers: HangoutIntent[];
  possibleOrganizers: any;

  alert: HTMLIonAlertElement;

  @Input() selectedCircles: JoinedCircle[] = [];
  @Input() selectedOrganizers: HangoutIntent[];
  @Output() assignOrganizers: EventEmitter<HangoutIntent[]> = new EventEmitter<
    HangoutIntent[]
  >();

  constructor(private store: Store, private alertCtrl: AlertController) {}

  ngOnInit() {
    this.currentVillager$.subscribe((x) => {
      if (!x) return;
      this.currentVillager = x;
    });

    if (this.selectedOrganizers) {
      this.organizers.setValue(this.selectedOrganizers);
    }
    // this emits the selected villagers back to the create hangout modal
    this.organizers.valueChanges.subscribe((organizers: HangoutIntent[]) => {
      console.log('[DEBUG] Emitting organizers: ', organizers);
      this.assignOrganizers.emit(organizers);
    });

    // this listens to the app state which the circle-assign component triggers changes in
    // and builds a list of actual villager objects which are then used in the template ion-select
    this.postCirclesAssigned$.subscribe((assignedCircles: JoinedCircle[]) => {
      const possibleVillagerUids = this.getPossibleVillagerIds(assignedCircles);
      this.buildVillagerList(possibleVillagerUids);
      this.getForVillagerOutsideCircle();

      // TODO alert and remove any selected villagers that aren't in the list of possible's
    });
  }

  getPossibleVillagerIds(assignedCircles: JoinedCircle[]): string[] {
    let villagerIds = [];
    if (assignedCircles && assignedCircles.length > 0) {
      this.villageCircles = this.store.selectSnapshot(CircleState.allCircles);
      assignedCircles.forEach((joinedCircle: JoinedCircle) => {
        const matchingCircle = this.villageCircles.find(
          (x) => x._UID === joinedCircle.UID
        );
        if (matchingCircle) {
          console.log('[DEBUG] Found matching circle: ', matchingCircle);
          villagerIds = villagerIds.concat(matchingCircle.VILLAGER_UIDS);
          console.log('[DEBUG] Initial set villager ids: ', villagerIds);
        }
      });
    }
    villagerIds = [...new Set(villagerIds)];

    console.log('[DEBUG] Joined villager ids: ', villagerIds);
    return villagerIds;
  }

  buildVillagerList(ids: string[]) {
    const allVillagers = this.store.selectSnapshot(VillagerState.allVillagers);
    console.log('[DEBUG] All villagers: ', allVillagers);
    this.possibleVillagers = allVillagers
      .filter(
        (x) => ids.includes(x._UID) && x._UID !== this.currentVillager._UID
      )
      .map((x) => ({
        UID: x._UID,
        FIRST_NAME: x.FIRST_NAME,
        LAST_NAME: x.LAST_NAME,
      }));

    console.log('[DEBUG] Joined villager objects: ', this.possibleVillagers);
  }

  getForVillagerOutsideCircle() {
    console.log(
      'DEBUG checking for villagers outside the current circles: ',
      this.selectedOrganizers,
      this.possibleVillagers
    );

    if (this.selectedOrganizers.length === 0) {
      return false;
    }

    let outsideVillagers: any[] = [];

    const assignedVillagerIds = this.possibleVillagers.map((x) => x.UID);
    this.selectedOrganizers.forEach((x) => {
      if (!assignedVillagerIds.includes(x.UID)) {
        console.log('DEBUG found outside villager: ', x);
        outsideVillagers.push(x);
      }
    });

    if (outsideVillagers.length > 0) {
      console.warn('Villagers outside of circle: ', outsideVillagers);
      // this.showErrorAlert()
    }
  }

  compareWith(o1: HangoutIntent, o2: HangoutIntent | HangoutIntent[]) {
    if (!o1 || !o2) {
      return o1 === o2;
    }

    if (Array.isArray(o2)) {
      return o2.some((u: HangoutIntent) => u.UID === o1.UID);
    }

    return o1.UID === o2.UID;
  }

  async showErrorAlert() {
    if (this.alert) {
      this.alert.dismiss();
    }
    this.alert = await this.alertCtrl.create({
      header: 'Oops!',
      message: `There's organizers outside of the circle you've given access to this event.`,
      buttons: ['OK'],
    });

    await this.alert.present();
  }
}
