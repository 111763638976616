import { Injectable, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { AnalyticsService } from '../analytics.service';
import { CreateAnnouncementModalPage } from '../modals/create-announcement-modal/create-announcement-modal.page';
import { CreateConflictModalPage } from '../modals/create-conflict-modal/create-conflict-modal.page';
import { CreateExchangeModalPage } from '../modals/create-exchange-modal/create-exchange-modal.page';
import { CreateHangoutModalPage } from '../modals/create-hangout-modal/create-hangout-modal.page';
import { CreateListModalPage } from '../modals/create-list-modal/create-list-modal.page';
import { CreatePostModalPage } from '../modals/create-post-modal/create-post-modal.page';
import { CreateSupportRequestModalPage } from '../modals/create-support-request-modal/create-support-request-modal.page';

@Injectable({
  providedIn: 'root',
})
export class CreateBulletinService implements OnInit {
  constructor(
    public modalCtrl: ModalController,
    private router: Router,
    private analytics: AnalyticsService
  ) {}

  ngOnInit() {}

  async openCreateShareModal(description?: string) {
    const existing = await this.modalCtrl.getTop();
    if (existing) this.modalCtrl.dismiss();
    this.analytics.logEvent('share_open_create_modal', {});
    const modal = await this.modalCtrl.create({
      component: CreatePostModalPage,
      componentProps: {
        description: description
      }
    });

    modal.onDidDismiss().then((data) => {
      if (data.data && data.data.action === 'create-close') {
        this.router.navigateByUrl('/pages/board');
      }
    });

    return await modal.present();
  }

  async openRequestModal(description?: string) {
    const existing = await this.modalCtrl.getTop();
    if (existing) this.modalCtrl.dismiss();
    this.analytics.logEvent('exchange_open_create_request_modal', {});
    const modal = await this.modalCtrl.create({
      component: CreateExchangeModalPage,
      componentProps: {
        type: 'REQUEST',
        description: description,
      },
    });

    modal.onDidDismiss().then((data) => {
      if (data.data && data.data.action === 'create-close') {
        this.router.navigateByUrl('/pages/board');
      }
    });

    return await modal.present();
  }

  async openOfferingModal(description?: string) {
    const existing = await this.modalCtrl.getTop();
    if (existing) this.modalCtrl.dismiss();
    this.analytics.logEvent('exchange_open_create_offering_modal', {});
    const modal = await this.modalCtrl.create({
      component: CreateExchangeModalPage,
      componentProps: {
        type: 'OFFERING',
        description: description,
      },
    });

    modal.onDidDismiss().then((data) => {
      if (data.data && data.data.action === 'create-close') {
        this.router.navigateByUrl('/pages/board');
      }
    });

    return await modal.present();
  }

  async openConflictModal(description?: string) {
    const existing = await this.modalCtrl.getTop();
    if (existing) this.modalCtrl.dismiss();
    this.analytics.logEvent('conflict_open_create_modal', {});
    const modal = await this.modalCtrl.create({
      component: CreateConflictModalPage,
      componentProps: {
        description: description
      },
    });

    modal.onDidDismiss().then((data) => {
      if (data.data && data.data.action === 'create-close') {
        this.router.navigateByUrl('/pages/board');
      }
    });

    return await modal.present();
  }

  async openSupportRequestModal(description?: string) {
    const existing = await this.modalCtrl.getTop();
    if (existing) this.modalCtrl.dismiss();
    this.analytics.logEvent('support_open_create_modal', {});
    const modal = await this.modalCtrl.create({
      component: CreateSupportRequestModalPage,
      componentProps: {
        description: description
      }
    });

    modal.onDidDismiss().then((data) => {
      if (data.data && data.data.action === 'create-close') {
        this.router.navigateByUrl('/pages/board');
      }
    });

    return await modal.present();
  }

  async openCreateAnnouncementModal(description?: string) {
    const existing = await this.modalCtrl.getTop();
    if (existing) this.modalCtrl.dismiss();
    this.analytics.logEvent('hangout_open_announcement_modal', {});
    const modal = await this.modalCtrl.create({
      component: CreateAnnouncementModalPage,
      componentProps: {
        description
      }
    });

    modal.onDidDismiss().then((data) => {
      if (data.data && data.data.action === 'create-close') {
        this.router.navigateByUrl('/pages/board');
      }
    });

    return await modal.present();
  }

  async openCreateHangoutModal(type: string, description?: string) {
    const existing = await this.modalCtrl.getTop();
    if (existing) this.modalCtrl.dismiss();
    this.analytics.logEvent('hangout_open_create_modal', {});
    const modal = await this.modalCtrl.create({
      component: CreateHangoutModalPage,
      componentProps: { type, description },
    });

    modal.onDidDismiss().then((data) => {
      if (data.data && data.data.action === 'create-close') {
        this.router.navigateByUrl('/pages/board');
      }
    });

    return await modal.present();
  }

  async openSharedListModal() {
    const existing = await this.modalCtrl.getTop();
    if (existing) this.modalCtrl.dismiss();
    this.analytics.logEvent('conflict_shared_list_create_modal', {});
    const modal = await this.modalCtrl.create({
      component: CreateListModalPage,
      componentProps: {},
    });

    modal.onDidDismiss().then((data) => {
      if (data.data && data.data.action === 'create-close') {
        this.router.navigateByUrl('/pages/board');
      }
    });

    return await modal.present();
  }
}
