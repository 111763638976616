import { CourtyardMessageService } from './../../services/courtyard-message.service';
import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  IonContent,
  IonTextarea,
  LoadingController,
  ModalController,
  PopoverController,
  ToastController,
} from '@ionic/angular';
import { Actions, ofActionCompleted, Select, Store } from '@ngxs/store';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { filter, distinctUntilKeyChanged, map, first } from 'rxjs/operators';
import { AnalyticsService } from 'src/app/analytics.service';
import { ChatMessage } from 'src/app/models/chat-message.model';
import { Courtyard } from 'src/app/models/courtyard.model';
import { Villager } from 'src/app/models/villager.model';
import { CourtyardActions } from 'src/app/state/courtyard.actions';
import { CourtyardState } from 'src/app/state/courtyard.state';
import { VillagerState } from 'src/app/state/villager.state';
import 'firebase/firestore';
import { CourtyardDetailsPopoverComponent } from 'src/app/shared/courtyard-details-popover/courtyard-details-popover.component';
import { AngularFireStorage } from '@angular/fire/storage';
import { ClearReplyMessage } from 'src/app/state/app.actions';
import { VillageState } from 'src/app/state/village.state';
import { AngularFirestore } from '@angular/fire/firestore';
import * as moment from 'moment';

@Component({
  selector: 'app-courtyard-chat-modal',
  templateUrl: './courtyard-chat-modal.page.html',
  styleUrls: ['./courtyard-chat-modal.page.scss'],
})
export class CourtyardChatModalPage
  implements OnInit, OnDestroy, AfterViewInit
{
  newMessageHasImages = false;
  viewLoaded = false;
  // messageBody: string;
  selectedCourtyard: Courtyard;
  // placeholderText = `Send a message..`;
  // sending = false;
  // isCurrentVillager$: Observable<boolean>;
  currentVillager: Villager;

  // for picture uploads
  files: File[] = [];
  uploads: any[];
  uploadPercent: Observable<number>;
  downloadURLs = [];
  uploading = false;
  tempUrls: string[] = [];
  scrollTimeout = null;
  courtyardSub: Subscription;

  messages$: Observable<ChatMessage[]>;
  messagesSub: Subscription;
  mainSub: Subscription;
  villageId: string;
  MESSAGE_COUNTER = 25;
  uniqueCourtyard$: Observable<Courtyard>;

  isNonNullOrUndefined<T>(value: T): value is NonNullable<T> {
    return value != null;
  }

  @Select(VillagerState.currentVillager) villager$: Observable<Villager>;
  @Select(CourtyardState.selectedCourtyard) courtyard$: Observable<Courtyard>;
  // @Select(CourtyardState.messages) messages$: Observable<ChatMessage[]>;
  @Select(CourtyardState.participantUids) participantUids$: Observable<
    string[]
  >;
  @Select(VillageState.uid) villageId$: Observable<string>;
  participantUids: string[];

  @ViewChild('messageContainer') messageContainer: IonContent;
  @ViewChild('messageInput') messageInput: IonTextarea;

  @Input() courtyard: Courtyard;
  constructor(
    private store: Store,
    private actions$: Actions,
    private analytics: AnalyticsService,
    private modalCtrl: ModalController,
    private popoverCtrl: PopoverController,
    private bucket: AngularFireStorage,
    private toastCtrl: ToastController,
    private firestore: AngularFirestore,
    private loadingCtrl: LoadingController,
    private courtyardMessageSvc: CourtyardMessageService
  ) {}

  ngOnInit() {
    this.villager$.subscribe((x) => (this.currentVillager = x));

    this.selectedCourtyard = this.store.selectSnapshot(
      CourtyardState.selectedCourtyard
    );

    this.uniqueCourtyard$ = this.courtyard$.pipe(
      filter(this.isNonNullOrUndefined),
      distinctUntilKeyChanged('_UID')
    );

    this.uniqueCourtyard$.subscribe((x) => {
      this.selectedCourtyard = x;
    });

    this.mainSub = combineLatest([
      this.uniqueCourtyard$,
      this.villageId$,
    ]).subscribe(([uniqueCourtyard, villageId]) => {
      if (uniqueCourtyard && villageId) {
        this.villageId = villageId;
        // this.selectedCourtyard = uniqueCourtyard;
        this.messages$ = this.firestore
          .collection(
            `VILLAGES/${villageId}/COURTYARDS/${uniqueCourtyard._UID}/MESSAGES`,
            (ref) =>
              ref
                .orderBy('_CREATED_AT', 'asc')
                .limitToLast(this.MESSAGE_COUNTER)
          )
          .valueChanges({ idField: '_UID' })
          .pipe(map(this.courtyardMessageSvc.fromFirestore));
      }
    });

    this.messagesSub = this.messages$.subscribe(async () => {
      this.store.dispatch(
        new CourtyardActions.UpdateReadReceipts({
          courtyard: this.selectedCourtyard,
        })
      );
    });

    this.messages$.pipe(first()).subscribe(async () => {
      this.scrollToBottom();
      const existing = await this.loadingCtrl.getTop();
      if (existing) {
        existing.dismiss();
      }
    });

    this.participantUids$.subscribe((uids) => {
      this.participantUids = uids;
    });

    this.actions$
      .pipe(ofActionCompleted(CourtyardActions.SendMessage))
      .subscribe(() => {
        // this.messageBody = null;
        this.scrollToBottom();
      });
  }

  ngAfterViewInit(): void {
    this.messageContainer.ionScroll.subscribe((event) => {
      if (event.detail.scrollTop < 50) {
        this.fetchMoreMessages();
      }
    });
  }

  fetchMoreMessages() {
    if (this.selectedCourtyard.MESSAGE_COUNT > this.MESSAGE_COUNTER) {
      const currentCounter = this.MESSAGE_COUNTER;
      this.MESSAGE_COUNTER += 25;
      this.messages$ = this.firestore
        .collection(
          `VILLAGES/${this.villageId}/COURTYARDS/${this.selectedCourtyard._UID}/MESSAGES`,
          (ref) =>
            ref
              .orderBy('_CREATED_AT', 'asc')
              .startAfter(currentCounter)
              .limitToLast(this.MESSAGE_COUNTER)
        )
        .valueChanges({ idField: '_UID' })
        .pipe(map(this.courtyardMessageSvc.fromFirestore));
    }
  }

  onImageChange(hasPhotos: boolean) {
    this.newMessageHasImages = hasPhotos;
  }

  ionViewDidEnter() {
    this.viewLoaded = true;
  }

  scrollToBottom() {
    if (this.scrollTimeout) {
      clearTimeout(this.scrollTimeout);
    }
    this.scrollTimeout = setTimeout(() => {
      if (this.messageContainer) this.messageContainer.scrollToBottom(300);
    }, 200);
  }

  turnOffNotifications() {
    this.store.dispatch(
      new CourtyardActions.RemoveParticipant({
        villagerId: this.currentVillager._UID,
        courtyard: this.selectedCourtyard,
      })
    );
    this.analytics.logEvent('courtyard_leave_success', {});
    this.showRemovedFromNotifications();
  }

  trackByFn(idx: number, message: ChatMessage) {
    return message._UID;
  }

  close() {
    this.store.dispatch(new CourtyardActions.RemoveSelected());
    this.messagesSub.unsubscribe();
    this.mainSub.unsubscribe();
    // this.courtyardSub.unsubscribe();
    this.modalCtrl.dismiss();
    // remove replies from state if present
    this.store.dispatch(new ClearReplyMessage());
  }

  async showDetailsPopover(ev) {
    const popover = await this.popoverCtrl.create({
      component: CourtyardDetailsPopoverComponent,
      event: ev,
      cssClass: 'wide-popover',
      translucent: false,
    });
    await popover.present();
  }

  async showRemovedFromNotifications() {
    const toast = await this.toastCtrl.create({
      message: 'You will no longer receive notifications for this chat.',
      position: 'bottom',
      duration: 5000,
      color: 'primary',
      buttons: ['OK'],
    });

    await toast.present();
  }

  ngOnDestroy() {
    // can perform any messages$ cleanup here
    this.messagesSub.unsubscribe();
    this.mainSub.unsubscribe();
  }
}
