import { Injectable } from '@angular/core';
import { NgxsFirestore } from '@ngxs-labs/firestore-plugin';
import * as firebase from 'firebase';
import { of } from 'rxjs';
import { AppNotification } from '../models/notification.model';

@Injectable({
  providedIn: 'root',
})
export class NotificationService extends NgxsFirestore<AppNotification> {
  idField = '_UID';

  debug() {
    console.log(
      `FETCH DATA FROM PATH: VILLAGERS/${this.villagerId}/NOTIFICATIONS`
    );
  }

  protected get path() {
    return `VILLAGERS/${this.villagerId}/NOTIFICATIONS`;
  }

  private VILLAGERID = '';

  public setVillagerId(villagerId) {
    this.VILLAGERID = villagerId;
  }

  protected get villagerId() {
    return this.VILLAGERID;
  }

  // notifications dont user toFirestore converter
  updateIfExists(id, data) {
    return of(
      this.adapter.firestore.doc(`${this.path}/${id}`).ref.update(data)
    );
  }

  converter: firebase.default.firestore.FirestoreDataConverter<AppNotification> =
    {
      toFirestore: (value: AppNotification) => {
        return value;
      },
      fromFirestore: (snapshot, options) => {
        let data = snapshot.data(options) as AppNotification;

        let _UID = snapshot.id;

        if (snapshot.metadata.hasPendingWrites) {
          const ts = firebase.default.firestore.Timestamp.now();
          data = {
            ...data,
            _UID,
            CREATED_AT: ts.toDate(),
            READ_AT: ts.toDate(),
          };
        } else {
          data = {
            ...data,
            _UID,
            CREATED_AT: data.CREATED_AT.toDate(),
          };
        }

        // console.log('[NOTIFICATION SERVICE] Received from firestore:  ', data._UID);

        return data;
      },
    };
}
