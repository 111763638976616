import { ErrorHandler, Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';

@Injectable()
export class AppErrorHandler implements ErrorHandler {
  constructor(private fns: AngularFireFunctions) {}

  handleError(error: Error) {
    try {
      const { message, stack } = error;

      this.fns
        .httpsCallable('errors-logActionError')({
          message,
          stack,
        })
        .toPromise();
    } catch (e) {
      // Silent Catch And Do Nothing For Now
      console.error('app error handler: ', e);
    }

    // Make sure to rethrow the error so Angular can pick it up
    throw error;
  }
}
