<ion-grid>
  <ion-row>
    <ion-col>
      <app-event-time-header [post]="hangout"></app-event-time-header>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col>
      <div *ngIf="hangout?.BODY">
        <div
          class="post-message-body"
          *ngIf="!showFullBody; else fullBody"
          [innerHtml]="hangout.BODY | truncateBody: 400:true | safehtml"
        ></div>
        <ion-button
          style="float: right; margin-top: 5px"
          size="small"
          color="dark"
          fill="outline"
          *ngIf="hangout.BODY.length > 400 && !showFullBody"
          (click)="goToDetails()"
          >Read full post
          <ion-icon name="chevron-forward-outline"></ion-icon>
        </ion-button>
        <ng-template #fullBody>
          <div
            class="post-message-body"
            [innerHtml]="hangout.BODY | safehtml"
          ></div>
        </ng-template>
      </div>
    </ion-col>
  </ion-row>
</ion-grid>
<div *ngIf="hangout?.IMAGE_PATHS.length > 0">
  <ion-grid style="padding: 0">
    <ion-row>
      <ion-col *ngFor="let path of hangout?.IMAGE_PATHS">
        <div class="post-image-wrapper">
          <app-post-image [path]="path"></app-post-image>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</div>
<div *ngIf="hangout?.ORGANIZERS.length > 0">
  <ion-grid>
    <ion-row>
      <ion-col>
        <ion-list>
          <div class="subheader">Event Organizers</div>
          <ion-item
            class="full-width-item"
            *ngFor="let organizer of hangout.ORGANIZERS"
          >
            <app-villager-small-avatar
              [firstName]="organizer.FIRST_NAME"
              [lastName]="organizer.LAST_NAME"
              [uid]="organizer.UID"
            ></app-villager-small-avatar>
            <ion-label>
              {{ organizer.FIRST_NAME }} {{ organizer.LAST_NAME }}
            </ion-label>
          </ion-item>
        </ion-list>
      </ion-col>
    </ion-row>
  </ion-grid>
</div>
<app-hangout-card-actions
  [post]="hangout"
  [villagerUID]="villagerUid"
></app-hangout-card-actions>
