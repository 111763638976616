import { TimeagoModule, TimeagoClock } from "ngx-timeago";

import { Observable, interval } from "rxjs";

// timeago pipe runs every 60 seconds
export class MyClock extends TimeagoClock {
  tick(then: number): Observable<number> {
    return interval(15 * 1000);
  }
}

export const CIRCLE_IMAGE_BATH_PATH = 'villages'
export const VILLAGE_IMAGE_BATH_PATH = 'villages'
export const AVATAR_BASE_PATH = "users/avatars";
export const POST_FILES_PATH = "";

export const HANGOUT_KARMA = 3;
export const EVENT_KARMA = 3;
export const SHARE_KARMA = 1;
export const EXCHANGE_KARMA = 3;
export const OFFERING_KARMA = EXCHANGE_KARMA;
export const REQUEST_KARMA = EXCHANGE_KARMA;
export const CONFLICT_KARMA = 5;
export const SUPPORT_KARMA = 5;

export const MENTIONIFY_CLASSNAME = "mentionify";
export const MENTIONIFY_HIDDEN = "mentionify-hidden";
