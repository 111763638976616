import { Injectable } from '@angular/core';
import { NgxsFirestore } from '@ngxs-labs/firestore-plugin';
import { ConflictResolution } from '../models/conflict-resolution.model';
import * as firebase from 'firebase';
import 'firebase/firestore';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ConflictResolutionService extends NgxsFirestore<ConflictResolution> {
  idField = '_UID';

  protected get path() {
    return `VILLAGES/${this.villageId}/CONFLICTS`;
  }

  private VILLAGEID = '';

  public setVillageId(villageId) {
    this.VILLAGEID = villageId;
  }

  protected get villageId() {
    return this.VILLAGEID;
  }

  /**
   * @deprecated
   */
   updateIfExists(id, data) {
    return of(
      this.adapter.firestore
        .doc(`${this.path}/${id}`)
        .ref.withConverter(this.converter)
        .update(data)
    );
  }

  updateWithoutConverter(id: string, update: Partial<ConflictResolution>) {
    return of(this.adapter.firestore.doc(`${this.path}/${id}`).update(update));
  }

  converter: firebase.default.firestore.FirestoreDataConverter<ConflictResolution> =
    {
      toFirestore: (value: ConflictResolution) => {
        // NOTE: careful here. Partial updates come through here as well.
        let db = { ...value };
        if (db.hasOwnProperty('BODY')) {
          db = {
            ...db,
            BODY: encodeURI(value.BODY),
          };
        }

        if (db.hasOwnProperty('WHATS_NEEDED')) {
          db = {
            ...db,
            WHATS_NEEDED: encodeURI(value.WHATS_NEEDED),
          };
        }
        if (db.hasOwnProperty('WHATS_DESIRED')) {
          db = {
            ...db,
            WHATS_DESIRED: encodeURI(value.WHATS_DESIRED),
          };
        }

        return db;
      },
      fromFirestore: (snapshot, options) => {
        let data = snapshot.data(options) as ConflictResolution;

        if (snapshot.metadata.hasPendingWrites) {
          const ts = firebase.default.firestore.Timestamp.now();
          data = {
            ...data,
            BODY: decodeURI(data.BODY),
            WHATS_NEEDED: decodeURI(data.WHATS_NEEDED),
            WHATS_DESIRED: decodeURI(data.WHATS_DESIRED),
            _CREATED_AT: ts.toDate(),
            UPDATED_AT: ts.toDate(),
          };
        } else {
          data = {
            ...data,
            BODY: decodeURI(data.BODY),
            WHATS_NEEDED: decodeURI(data.WHATS_NEEDED),
            WHATS_DESIRED: decodeURI(data.WHATS_DESIRED),
            _CREATED_AT: data._SERVER_TIMESTAMP.toDate(),
            UPDATED_AT: data.UPDATED_AT.toDate(),
          };
        }
        console.log('[CONFLICT SERVICE] Received from firestore:  ', data._UID);

        // patch no TOPICS
        if (!data.hasOwnProperty('TOPICS')) {
          data.TOPICS = [];
        }

        if (!data.hasOwnProperty('ARCHIVED')) {
          data.ARCHIVED = false;
        }

        // patch no CIRCLES
        if (!data.hasOwnProperty('CIRCLES')) {
          data.CIRCLES = [{ NAME: 'Members', UID: 'MEMBERS' }];
        }

        // patch no CIRCLE_UIDS
        if (!data.hasOwnProperty('CIRCLE_UIDS')) {
          data.CIRCLE_UIDS = ['MEMBERS'];
        }

        return data;
      },
    };
}
