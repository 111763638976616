<ion-grid>
  <ion-row style="margin-bottom: 20px">
    <ion-col>
      <ion-chip color="dark" outline="true" (click)="react('❤️')">
        <ion-label>❤️</ion-label>
      </ion-chip>
    </ion-col>
    <ion-col>
      <ion-chip color="dark" outline="true" (click)="react('🙌')">
        <ion-label>🙌</ion-label>
      </ion-chip>
    </ion-col>
    <ion-col>
      <ion-chip color="dark" outline="true" (click)="react('✨')">
        <ion-label>✨</ion-label>
      </ion-chip>
    </ion-col>
    <ion-col>
      <ion-chip color="dark" outline="true" (click)="react('🙏')">
        <ion-label>🙏</ion-label>
      </ion-chip>
    </ion-col>
    <ion-col>
      <ion-chip color="dark" outline="true" (click)="openEmojiMart()">
        <ion-icon name="happy-outline" style="margin-top: -2px"></ion-icon>
        <ion-label style="margin-top: -2px; margin-left: -8px;">+</ion-label>
      </ion-chip>
    </ion-col>
    <ion-col  *ngIf="message._CREATOR_UID !== currentUserUid">
      <ion-chip (click)="reply()" color="dark" outline="true">
        <ion-icon name="arrow-undo"></ion-icon>
        <ion-label style="text-align: center">Reply</ion-label>
      </ion-chip>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col>
      <ion-button
        color="primary"
        expand="block"
        (click)="createBulletinFromMessage()"
        lines="none"
        *ngIf="!message._HAS_PHOTO && !message._HAS_VOICE_NOTE"
      >
        <ion-icon name="create-outline"></ion-icon>
        <ion-label style="text-align: center">Create Post</ion-label>
      </ion-button>


      <ion-button
        color="danger"
        expand="block"
        *ngIf="message._CREATOR_UID === currentUserUid"
        (click)="showDeleteConfirm()"
      >
        <ion-icon name="trash-outline"></ion-icon>
        <ion-label style="text-align: center">Delete Message</ion-label>
      </ion-button>
    </ion-col>
  </ion-row>
</ion-grid>
