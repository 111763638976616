import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { map } from 'rxjs/operators';
import { Hangout } from 'src/app/models/hangout.model';
import { GenericPost } from 'src/app/models/post-core.model';
import { FeedState } from 'src/app/state/feed.state';

@Component({
  selector: 'app-event-time-header',
  templateUrl: './event-time-header.component.html',
  styleUrls: ['./event-time-header.component.scss'],
})
export class EventTimeHeaderComponent implements OnInit {
  hangout: Hangout;
  timezone: string;
  @Input() post: GenericPost;
  constructor(private store: Store) {}

  ngOnInit() {
    this.hangout = this.post as Hangout;
    this.timezone = new Date().toLocaleTimeString('en-us',{timeZoneName:'short'}).split(' ')[2];
    // console.log(
    //   `[HANGOUT TIME] ${this.hangout._UID} Start: ${this.isValidDate(this.hangout.START_DATETIME)}`
    // );
    // if (this.hangout.END_DATETIME)
    //   console.log(
    //     `[HANGOUT TIME] ${this.hangout._UID} End: ${this.isValidDate(this.hangout.END_DATETIME)}`
    //   );


    this.store
      .select(FeedState.getPostById)
      .pipe(map((filterFn) => filterFn(this.hangout._UID)))
      .subscribe((post) => {
        if (post) {
          this.hangout = post as Hangout;
        }
      });
  }

  isValidDate(d: any) {
    return d instanceof Date && !isNaN(d.getTime());
  }
}
