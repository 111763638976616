import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { Store } from '@ngxs/store';
import { AnalyticsService } from 'src/app/analytics.service';
import { CreateBulletinModalPage } from 'src/app/modals/create-bulletin-modal/create-bulletin-modal.page';
import { EmojiMartModalPage } from 'src/app/modals/emoji-mart-modal/emoji-mart-modal.page';
import { ChatMessage } from 'src/app/models/chat-message.model';
import {
  DeleteDirectChatMessage,
  DeleteVillageMessage,
  DirectChatroomReact,
  DiscussionReact,
  SetReplyMessage,
} from 'src/app/state/app.actions';
import { CourtyardActions } from 'src/app/state/courtyard.actions';
import { VillagerState } from 'src/app/state/villager.state';

@Component({
  selector: 'app-chat-message-options',
  templateUrl: './chat-message-options.component.html',
  styleUrls: ['./chat-message-options.component.scss'],
})
export class ChatMessageOptionsComponent implements OnInit {
  currentUserUid: string;
  replyMessage: ChatMessage;
  @Input() message: ChatMessage;
  @Input() type: 'post' | 'dm' | 'courtyard';
  constructor(
    private analytics: AnalyticsService,
    private alertController: AlertController,
    private store: Store,
    public modalCtrl: ModalController,
  ) {}

  ngOnInit() {
    this.currentUserUid = this.store.selectSnapshot(VillagerState.uid);
  }

  async react(emoji: string) {
    console.log('reacting with: ', emoji);

    const payload = {
      message: this.message,
      emoji,
      villagerUid: this.currentUserUid,
    };

    switch (this.type) {
      case 'courtyard':
        this.store.dispatch(new CourtyardActions.React(payload));
        break;
      case 'post':
        this.store.dispatch(new DiscussionReact(payload));
        break;
      case 'dm':
        this.store.dispatch(new DirectChatroomReact(payload));
        break;
      default:
        alert(
          'We ran into an error adding your reaction. Please try again or contact support'
        );
    }

    this.modalCtrl.dismiss();
  }

  async openEmojiMart() {
    const existing = await this.modalCtrl.getTop();
    if (existing) this.modalCtrl.dismiss();

    const modal = await this.modalCtrl.create({
      component: EmojiMartModalPage,
      initialBreakpoint: 0.5,
      breakpoints: [0, 0.5, 1],
      componentProps: {
        message: this.message,
        type: this.type,
      },
    });
    return await modal.present();
  }

  async reply() {
    let existing = await this.modalCtrl.getTop();
    if (existing) this.modalCtrl.dismiss();

    this.store.dispatch(new SetReplyMessage({ message: this.message }));
  }

  async createBulletinFromMessage() {
    const existing = await this.modalCtrl.getTop();
    if (existing) this.modalCtrl.dismiss();

    const modal = await this.modalCtrl.create({
      component: CreateBulletinModalPage,
      componentProps: {
        description: this.message._BODY,
      },
    });
    return await modal.present();
  }

  async showDeleteConfirm() {
    const alert = await this.alertController.create({
      header: 'Careful!',
      message: `Are you sure you want to delete your post?`,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'dark',
          handler: (blah) => {
            console.log('Confirm Cancel');
          },
        },
        {
          text: 'Delete',
          role: 'delete',
          cssClass: 'danger',
          handler: async () => {
            this.deleteMessage();
            const existing = await this.modalCtrl.getTop();
            if (existing) this.modalCtrl.dismiss();
          },
        },
      ],
    });

    await alert.present();
  }

  async deleteMessage() {
    this.analytics.logEvent(`chat_message_delete_clicked`, {});
    if (this.type === 'dm') {
      this.store.dispatch(
        new DeleteDirectChatMessage({ messageId: this.message._UID })
      );
    } else if (this.type === 'post') {
      this.store.dispatch(
        new DeleteVillageMessage({ messageId: this.message._UID })
      );
    } else if (this.type === 'courtyard') {
      this.store.dispatch(
        new CourtyardActions.DeleteMessage({ messageId: this.message._UID })
      );
    } else {
      alert(
        'There was an issue deleting the message. Please try again. ' +
          this.type
      );
    }

    setTimeout(async () => {
      const existing = await this.modalCtrl.getTop();
      if (existing) this.modalCtrl.dismiss();
    }, 300);
  }
}
