<!-- <ion-header
  *ngIf="(isMobile$ | async) === true"
  collapse="condense"
  class="ion-no-border"
>
  <ion-toolbar class="desktop-header">
    <ion-title size="large">Share</ion-title>
  </ion-toolbar>
</ion-header> -->

<!-- Mobile  -->
<!-- TODO @nick, what is description? -->
<!-- *ngIf="(isMobile$ | async) === true || description;" -->
<ion-grid class="layout-wide-grid" style="margin-top: 40px">
  <div class="header">In-Person Events</div>
  <div class="scroll-row" style="padding-top: 10px; padding-bottom: 12px">
    <ion-card color="secondary" (click)="openCreateHangoutModal('')">
      <ion-card-content style="padding-top: 15px">
        <div>
          <ion-icon
            style="font-size: 2rem; display: inline-block"
            name="people"
            color="light"
          ></ion-icon>
          <app-board-karma-chip
            *ngIf="(showKarma$ | async) === true"
            [amount]="EVENT_KARMA"
          ></app-board-karma-chip>
        </div>
        <div style="margin-top: 5px">
          <ion-item
            color="secondary"
            detail
            class="full-width-item"
            lines="none"
          >
            <ion-label color="light">Event</ion-label>
          </ion-item>
        </div>
      </ion-card-content>
    </ion-card>
    <ion-card
      color="secondary"
      *ngIf="(showPotluck$ | async) === true"
      (click)="openCreateHangoutModal('Potluck')"
    >
      <ion-card-content style="padding-top: 15px">
        <div>
          <ion-icon
            style="font-size: 2rem; display: inline-block"
            name="pizza"
            color="light"
          ></ion-icon>
          <app-board-karma-chip
            *ngIf="(showKarma$ | async) === true"
            [amount]="EVENT_KARMA"
          ></app-board-karma-chip>
        </div>
        <div style="margin-top: 5px">
          <ion-item
            color="secondary"
            detail
            class="full-width-item"
            lines="none"
          >
            <ion-label color="light">Potluck</ion-label>
          </ion-item>
        </div>
      </ion-card-content>
    </ion-card>
    <ion-card
      color="secondary"
      *ngIf="(showParty$ | async) === true"
      (click)="openCreateHangoutModal('Party')"
    >
      <ion-card-content style="padding-top: 15px">
        <div>
          <ion-icon
            style="font-size: 2rem; display: inline-block"
            name="sparkles"
            color="light"
          ></ion-icon>
          <app-board-karma-chip
            *ngIf="(showKarma$ | async) === true"
            [amount]="EVENT_KARMA"
          ></app-board-karma-chip>
        </div>
        <div style="margin-top: 5px">
          <ion-item
            color="secondary"
            detail
            class="full-width-item"
            lines="none"
          >
            <ion-label color="light">Celebration</ion-label>
          </ion-item>
        </div>
      </ion-card-content>
    </ion-card>
    <ion-card
      color="secondary"
      *ngIf="(showAdventure$ | async) === true"
      (click)="openCreateHangoutModal('Adventure')"
    >
      <ion-card-content style="padding-top: 15px">
        <div>
          <ion-icon
            style="font-size: 2rem; display: inline-block"
            name="flower"
            color="light"
          ></ion-icon>
          <app-board-karma-chip
            *ngIf="(showKarma$ | async) === true"
            [amount]="EVENT_KARMA"
          ></app-board-karma-chip>
        </div>
        <div style="margin-top: 5px">
          <ion-item
            color="secondary"
            detail
            class="full-width-item"
            lines="none"
          >
            <ion-label color="light">Adventure</ion-label>
          </ion-item>
        </div>
      </ion-card-content>
    </ion-card>
    <ion-card
      color="secondary"
      *ngIf="(showCoffee$ | async) === true"
      (click)="openCreateHangoutModal('Coffee/Tea')"
    >
      <ion-card-content style="padding-top: 15px">
        <div>
          <ion-icon
            style="font-size: 2rem; display: inline-block"
            name="cafe"
            color="light"
          ></ion-icon>
          <app-board-karma-chip
            *ngIf="(showKarma$ | async) === true"
            [amount]="EVENT_KARMA"
          ></app-board-karma-chip>
        </div>
        <div style="margin-top: 5px">
          <ion-item
            color="secondary"
            detail
            class="full-width-item"
            lines="none"
          >
            <ion-label color="light">Coffee/Tea</ion-label>
          </ion-item>
        </div>
      </ion-card-content>
    </ion-card>
    <ion-card
      color="secondary"
      *ngIf="(showDuo$ | async) === true"
      (click)="openCreateHangoutModal('Duo')"
    >
      <ion-card-content style="padding-top: 15px">
        <div>
          <ion-icon
            style="font-size: 2rem; display: inline-block"
            name="people"
            color="light"
          ></ion-icon>
          <app-board-karma-chip
            *ngIf="(showKarma$ | async) === true"
            [amount]="EVENT_KARMA"
          ></app-board-karma-chip>
        </div>
        <div style="margin-top: 5px">
          <ion-item
            color="secondary"
            detail
            class="full-width-item"
            lines="none"
          >
            <ion-label color="light">Hangout</ion-label>
          </ion-item>
        </div>
      </ion-card-content>
    </ion-card>
    <ion-card color="secondary" (click)="openCreateHangoutModal('')">
      <ion-card-content style="padding-top: 15px">
        <div>
          <ion-icon
            style="font-size: 2rem; display: inline-block"
            name="people"
            color="light"
          ></ion-icon>
          <app-board-karma-chip
            *ngIf="(showKarma$ | async) === true"
            [amount]="EVENT_KARMA"
          ></app-board-karma-chip>
        </div>
        <div style="margin-top: 5px">
          <ion-item
            color="secondary"
            detail
            class="full-width-item"
            lines="none"
          >
            <ion-label color="light">Meeting</ion-label>
          </ion-item>
        </div>
      </ion-card-content>
    </ion-card>
  </div>

  <div class="header">Board Posts</div>
  <ion-row>
    <!-- ANNOUNCEMENT -->
    <ion-col
      size="12"
      sizeMd="6"
      sizeXl="4"
      *ngIf="(isSteward$ | async) === true || (canAnnounce$ | async) === true"
    >
      <ion-card color="warning" (click)="openCreateAnnouncementModal()">
        <ion-card-content>
          <div>
            <ion-icon
              style="font-size: 2rem; display: inline-block"
              name="megaphone"
              slot="start"
              color="light"
            ></ion-icon>
          </div>
          <div style="margin-top: 5px">
            <ion-item
              color="warning"
              class="ion-no-padding"
              lines="none"
              detail
            >
              <ion-label>Post an Announcement</ion-label>
            </ion-item>
          </div>
        </ion-card-content>
      </ion-card>
    </ion-col>

    <!-- EVENT -->
    <!-- <ion-col size="12" sizeMd="6" sizeXl="4">
      <ion-card color="secondary" (click)="openCreateHangoutModal('')">
        <ion-card-content>
          <div>
            <ion-icon
              style="font-size: 2rem; display: inline-block"
              name="calendar"
              color="light"
            ></ion-icon>
            <app-board-karma-chip
              *ngIf="(showKarma$ | async) === true"
              [amount]="EVENT_KARMA"
            ></app-board-karma-chip>
          </div>
          <div style="margin-top: 5px">
            <ion-item
              color="secondary"
              class="ion-no-padding"
              lines="none"
              detail
            >
              <ion-label color="light">Create an Event</ion-label>
            </ion-item>
          </div>
        </ion-card-content>
      </ion-card>
    </ion-col> -->

    <!-- EMOTIONAL SUPPORT -->
    <ion-col size="12" sizeMd="6" sizeXl="4">
      <ion-card
        color="danger"
        *ngIf="(showEmotionalSupport$ | async) === true"
        (click)="openSupportRequestModal()"
      >
        <ion-card-content>
          <div>
            <ion-icon
              style="font-size: 2rem; display: inline-block"
              name="heart"
              slot="start"
              color="light"
            ></ion-icon>
            <app-board-karma-chip
              *ngIf="(showKarma$ | async) === true"
              [amount]="SUPPORT_KARMA"
            ></app-board-karma-chip>
          </div>
          <div style="margin-top: 5px">
            <ion-item color="danger" class="ion-no-padding" lines="none" detail>
              <ion-label color="light">Get Support</ion-label>
            </ion-item>
          </div>
        </ion-card-content>
      </ion-card>
    </ion-col>

    <!-- SHARE -->
    <ion-col size="12" sizeMd="6" sizeXl="4">
      <ion-card color="tertiary" (click)="openCreateShareModal()">
        <ion-card-content>
          <div>
            <ion-icon
              style="font-size: 2rem; display: inline-block"
              name="reader"
              slot="start"
              color="light"
            ></ion-icon>
            <app-board-karma-chip
              *ngIf="(showKarma$ | async) === true"
              [amount]="SHARE_KARMA"
            ></app-board-karma-chip>
          </div>
          <div style="margin-top: 5px">
            <ion-item
              color="tertiary"
              class="ion-no-padding"
              lines="none"
              detail
            >
              <ion-label color="light">Post a share</ion-label>
            </ion-item>
          </div>
        </ion-card-content>
      </ion-card>
    </ion-col>

    <!-- OFFERING -->
    <ion-col size="12" sizeMd="6" sizeXl="4">
      <ion-card
        color="primary"
        *ngIf="(showOffer$ | async) === true"
        (click)="openOfferingModal()"
      >
        <ion-card-content>
          <div>
            <ion-icon
              style="font-size: 2rem; display: inline-block"
              name="repeat"
              slot="start"
              color="light"
            ></ion-icon>
            <app-board-karma-chip
              *ngIf="(showKarma$ | async) === true"
              [amount]="OFFERING_KARMA"
            ></app-board-karma-chip>
          </div>
          <div style="margin-top: 5px">
            <ion-item
              color="primary"
              class="ion-no-padding"
              lines="none"
              detail
            >
              <ion-label color="light">Create an Offering</ion-label>
            </ion-item>
          </div>
        </ion-card-content>
      </ion-card>
    </ion-col>

    <!-- REQUEST -->
    <ion-col size="12" sizeMd="6" sizeXl="4">
      <ion-card
        color="primary"
        *ngIf="(showRequest$ | async) === true"
        (click)="openRequestModal()"
      >
        <ion-card-content>
          <div>
            <ion-icon
              style="font-size: 2rem; display: inline-block"
              name="repeat"
              slot="start"
              color="light"
            ></ion-icon>
            <app-board-karma-chip
              *ngIf="(showKarma$ | async) === true"
              [amount]="REQUEST_KARMA"
            ></app-board-karma-chip>
          </div>
          <div style="margin-top: 5px">
            <ion-item
              color="primary"
              class="ion-no-padding"
              lines="none"
              detail
            >
              <ion-label color="light">Create a Request</ion-label>
            </ion-item>
          </div>
        </ion-card-content>
      </ion-card>
    </ion-col>

    <!-- CONFLICT -->
    <ion-col size="12" sizeMd="6" sizeXl="4">
      <ion-card
        color="danger"
        *ngIf="(showConflict$ | async) === true"
        (click)="openConflictModal()"
      >
        <ion-card-content>
          <div>
            <ion-icon
              style="font-size: 2rem; display: inline-block"
              name="bonfire"
              slot="start"
              color="light"
            ></ion-icon>
            <app-board-karma-chip
              *ngIf="(showKarma$ | async) === true"
              [amount]="CONFLICT_KARMA"
            ></app-board-karma-chip>
          </div>
          <div></div>
          <div style="margin-top: 5px">
            <ion-item color="danger" class="ion-no-padding" lines="none" detail>
              <ion-label color="light">Resolve Conflict</ion-label>
            </ion-item>
          </div>
        </ion-card-content>
      </ion-card>
    </ion-col>

    <!-- LIST -->
    <ion-col size="12" sizeMd="6" sizeXl="4">
      <ion-card
        color="success"
        *ngIf="(showSharedList$ | async) === true"
        (click)="openSharedListModal()"
      >
        <ion-card-content>
          <div>
            <ion-icon
              style="font-size: 2rem; display: inline-block"
              name="list"
              slot="start"
              color="light"
            ></ion-icon>
          </div>
          <div style="margin-top: 5px">
            <ion-item
              color="success"
              class="ion-no-padding"
              lines="none"
              detail
            >
              <ion-label color="light">Create a Shared List</ion-label>
            </ion-item>
          </div>
        </ion-card-content>
      </ion-card>
    </ion-col>
  </ion-row>
</ion-grid>
<!-- End Mobile -->

<div style="height: 20vh"></div>
