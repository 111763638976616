import { VillageAdminComponent } from './village-admin/village-admin.component';
import { CreateJoinVillageComponent } from './create-join-village/create-join-village.component';
import { CircleAvatarEditComponent } from './circle-avatar-edit/circle-avatar-edit.component';
import { CircleAvatarComponent } from './circle-avatar/circle-avatar.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PostCoreCardComponent } from './post-core-card/post-core-card.component';
import { IonicModule } from '@ionic/angular';
import { NgDatePipesModule } from 'ngx-pipes';
import { TimeagoModule } from 'ngx-timeago';
import { ChatMessageComponent } from './chat-message/chat-message.component';
import { BulletinCardBodyComponent } from './bulletin-card-body/bulletin-card-body.component';
import { BulletinCardActionsComponent } from './bulletin-card-actions/bulletin-card-actions.component';
import { ExchangeCardBodyComponent } from './exchange-card-body/exchange-card-body.component';
import { ExchangeCardActionsComponent } from './exchange-card-actions/exchange-card-actions.component';
import { HangoutCardBodyComponent } from './hangout-card-body/hangout-card-body.component';
import { HangoutCardActionsComponent } from './hangout-card-actions/hangout-card-actions.component';
import { PostDropdownDetailsComponent } from './post-dropdown-details/post-dropdown-details.component';
import { SupportCardBodyComponent } from './support-card-body/support-card-body.component';
import { SupportCardActionsComponent } from './support-card-actions/support-card-actions.component';
import { VillageChatroomCardComponent } from './village-chatroom-card/village-chatroom-card.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PostChatListItemComponent } from './post-chat-list-item/post-chat-list-item.component';
import { DirectChatListItemComponent } from './direct-chat-list-item/direct-chat-list-item.component';
import { DirectChatroomCardComponent } from './direct-chatroom-card/direct-chatroom-card.component';
import { DirectChatParticipantPopoverComponent } from './direct-chat-participant-popover/direct-chat-participant-popover.component';
import { ProfilePhotoComponent } from './profile-photo/profile-photo.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { PostImageComponent } from './post-image/post-image.component';
import { VillagerPostsFeedComponent } from './villager-posts-feed/villager-posts-feed.component';
import { VillagerCardComponent } from './villager-card/villager-card.component';
import { MyIf } from '../directives/my-if.directive';
import { VillageMembersListComponent } from './village-members-list/village-members-list.component';
import { NotificationListItemComponent } from './notification-list-item/notification-list-item.component';
import { VillagerCardMiniComponent } from './villager-card-mini/villager-card-mini.component';
import { EditImagesComponent } from './edit-images/edit-images.component';
import { VillageNotificationSettingsComponent } from './village-notification-settings/village-notification-settings.component';
import { VillagerSmallAvatarComponent } from './villager-small-avatar/villager-small-avatar.component';
import { SelectOrJoinVillageComponent } from './select-or-join-village/select-or-join-village.component';
import { SafehtmlPipe } from '../pipes/safehtml.pipe';
import { VisionAndValuesComponent } from './vision-and-values/vision-and-values.component';
import { InviteCardBodyComponent } from './invite-card-body/invite-card-body.component';
import { ConflictCardBodyComponent } from './conflict-card-body/conflict-card-body.component';
import { NoVillageComponent } from './no-village/no-village.component';
import { UpcomingEventsComponent } from './upcoming-events/upcoming-events.component';
import { JoinVillageButtonComponent } from './join-village-button/join-village-button.component';
// import { SwitchVillageHeaderButtonComponent } from './switch-village-header-button/switch-village-header-button.component';
import { TruncateBodyPipe } from '../pipes/truncate-body.pipe';
import { VillageAnnouncementsComponent } from './village-announcements/village-announcements.component';
import { AddTopicsComponent } from './add-topics/add-topics.component';
import { MentionModule } from 'angular-mentions';
import { VillageAnnouncementCardBodyComponent } from './village-announcement-card-body/village-announcement-card-body.component';
import { EventTimeHeaderComponent } from './event-time-header/event-time-header.component';
import { VillageListsComponent } from './village-lists/village-lists.component';
import { SharedListBodyComponent } from './shared-list-body/shared-list-body.component';
import { BoardSearchFilterComponent } from './board-search-filter/board-search-filter.component';
import { LoadingListPreviewCardComponent } from './loading-list-preview-card/loading-list-preview-card.component';
import { CreateBulletinComponent } from './create-bulletin/create-bulletin.component';
import { CourtyardDetailsPopoverComponent } from './courtyard-details-popover/courtyard-details-popover.component';
import { ChatImageComponent } from './chat-image/chat-image.component';
import { ChatInputComponent } from './chat-input/chat-input.component';
import { PostFileUploadComponent } from './post-file-upload/post-file-upload.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { PostDocumentComponent } from './post-document/post-document.component';
import { PostCircleAssignmentComponent } from './post-circle-assignment/post-circle-assignment.component';
import { CircleAdminComponent } from './circle-admin/circle-admin.component';
import { VillageridToNamePipe } from '../pipes/villagerid-to-name.pipe';
import { VillagerCircleChipsComponent } from './villager-circle-chips/villager-circle-chips.component';
import { VillageLabelComponent } from './village-label/village-label.component';
import { MembersLabelComponent } from './members-label/members-label.component';
import { CourtyardsLabelComponent } from './courtyard-label/courtyard-label.component';
import { StewardsLabelComponent } from './stewards-label/stewards-label.component';
import { KarmaLabelComponent } from './karma-label/karma-label.component';
import { CircleIdToLabelPipe } from '../pipes/circle-label.pipe';
import { JoinedCircleIdToLabelPipe } from '../pipes/joinedCircle-label.pipe';
import { HomeEventsTileComponent } from './home-events-tile/home-events-tile.component';
import { HomeAnnouncementsTileComponent } from './home-announcements-tile/home-announcements-tile.component';
import { HomeListsTileComponent } from './home-lists-tile/home-lists-tile.component';
import { HomeVillagersTileComponent } from './home-villagers-tile/home-villagers-tile.component';
import { HomeVillageTileComponent } from './home-village-tile/home-village-tile.component';
import { DuoLabelComponent } from './duo-label/duo-label.component';
import { TrioLabelComponent } from './trio-label/trio-label.component';
import { EventOrganizersComponent } from './event-organizers/event-organizers.component';
import { DmChatroomComponent } from './dm-chatroom/dm-chatroom.component';
import { ChatroomDetailsComponent } from './chatroom-details/chatroom-details.component';
import { SupportFabComponent } from './support-fab/support-fab.component';
import { DmInputComponent } from './dm-input/dm-input.component';
import { ChatVoiceNoteComponent } from './chat-voice-note/chat-voice-note.component';
import { VoiceRecordingPopoverComponent } from './voice-recording-popover/voice-recording-popover.component';
import { MentionifyDirective } from '../directives/mentionify/mentionify.directive';
import { MemberMentionPopupComponent } from './member-mention-popup/member-mention-popup.component';
import { MentionifiedPipe } from '../pipes/mentionified.pipe';
import { PostCircleExplainPopoverComponent } from './post-circle-explain-popover/post-circle-explain-popover.component';
import { MemberInviteCircleExplainPopoverComponent } from './member-invite-circle-explain-popover/member-invite-circle-explain-popover.component';
import { HomeCirclesTileComponent } from './home-circles-tile/home-circles-tile.component';
import { HomeCreateVillageTileComponent } from './home-create-village-tile/home-create-village-tile.component';
import { CircleOverviewComponent } from './circle-overview/circle-overview.component';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { EmojiPickerComponent } from './emoji-picker/emoji-picker.component';
import { MessageReplyPreviewComponent } from './message-reply-preview/message-reply-preview.component';
import { ChatMessageOptionsComponent } from './chat-message-options/chat-message-options.component';
import { PostMentionableInputComponent } from './post-mentionable-input/post-mentionable-input.component';
import { DmUnreadCountComponent } from './dm-unread-count/dm-unread-count.component';
import { VillageManageOverviewComponent } from './village-manage-overview/village-manage-overview.component';
import { VillageManageAdminComponent } from './village-manage-admin/village-manage-admin.component';
import { VillageManageThemeComponent } from './village-manage-theme/village-manage-theme.component';
import { VillageAvatarEditComponent } from './village-avatar-edit/village-avatar-edit.component';
import { VillageAvatarComponent } from './village-avatar/village-avatar.component';
import { VillageCoverEditComponent } from './village-cover-edit/village-cover-edit.component';
import { SafeurlPipe } from '../pipes/safeurl.pipe';
import { BoardKarmaChipComponent } from './board-karma-chip/board-karma-chip.component';
import { VillageManageTutorialVideosComponent } from './village-manage-tutorial-videos/village-manage-tutorial-videos.component';


@NgModule({
  declarations: [
    PostCoreCardComponent,
    ChatMessageComponent,
    BulletinCardBodyComponent,
    VillageAnnouncementCardBodyComponent,
    BulletinCardActionsComponent,
    ExchangeCardBodyComponent,
    ExchangeCardActionsComponent,
    HangoutCardBodyComponent,
    HangoutCardActionsComponent,
    PostDropdownDetailsComponent,
    SupportCardBodyComponent,
    SupportCardActionsComponent,
    VillageChatroomCardComponent,
    PostChatListItemComponent,
    DirectChatListItemComponent,
    DirectChatroomCardComponent,
    DirectChatParticipantPopoverComponent,
    ProfilePhotoComponent,
    PostImageComponent,
    VillagerPostsFeedComponent,
    VillagerCardComponent,
    MyIf,
    VillageMembersListComponent,
    NotificationListItemComponent,
    VillagerCardMiniComponent,
    EditImagesComponent,
    VillagerSmallAvatarComponent,
    VillageNotificationSettingsComponent,
    SelectOrJoinVillageComponent,
    VisionAndValuesComponent,
    SafehtmlPipe,
    SafeurlPipe,
    InviteCardBodyComponent,
    ConflictCardBodyComponent,
    NoVillageComponent,
    UpcomingEventsComponent,
    VillageAnnouncementsComponent,
    JoinVillageButtonComponent,
    // SwitchVillageHeaderButtonComponent,
    TruncateBodyPipe,
    AddTopicsComponent,
    EventTimeHeaderComponent,
    VillageListsComponent,
    SharedListBodyComponent,
    BoardSearchFilterComponent,
    LoadingListPreviewCardComponent,
    CreateBulletinComponent,
    CourtyardDetailsPopoverComponent,
    ChatImageComponent,
    ChatVoiceNoteComponent,
    ChatInputComponent,
    PostFileUploadComponent,
    PostDocumentComponent,
    PostCircleAssignmentComponent,
    CircleAdminComponent,
    VillageridToNamePipe,
    VillagerCircleChipsComponent,
    VillageLabelComponent,
    MembersLabelComponent,
    CourtyardsLabelComponent,
    StewardsLabelComponent,
    DuoLabelComponent,
    TrioLabelComponent,
    KarmaLabelComponent,
    CircleIdToLabelPipe,
    JoinedCircleIdToLabelPipe,
    HomeEventsTileComponent,
    HomeAnnouncementsTileComponent,
    HomeListsTileComponent,
    HomeVillagersTileComponent,
    HomeVillageTileComponent,
    EventOrganizersComponent,
    DmChatroomComponent,
    ChatroomDetailsComponent,
    SupportFabComponent,
    DmInputComponent,
    VoiceRecordingPopoverComponent,
    MentionifyDirective,
    MemberMentionPopupComponent,
    MentionifiedPipe,
    PostCircleExplainPopoverComponent,
    MemberInviteCircleExplainPopoverComponent,
    HomeCirclesTileComponent,
    HomeCreateVillageTileComponent,
    CircleOverviewComponent,
    EmojiPickerComponent,
    MessageReplyPreviewComponent,
    ChatMessageOptionsComponent,
    PostMentionableInputComponent,
    DmUnreadCountComponent,
    CircleAvatarComponent,
    CircleAvatarEditComponent,
    CreateJoinVillageComponent,
    VillageAdminComponent,
    VillageManageOverviewComponent,
    VillageManageAdminComponent,
    VillageManageThemeComponent,
    VillageAvatarEditComponent,
    VillageAvatarComponent,
    VillageCoverEditComponent,
    BoardKarmaChipComponent,
    VillageManageTutorialVideosComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    TimeagoModule,
    NgDatePipesModule,
    ImageCropperModule,
    ReactiveFormsModule,
    MentionModule,
    NgxDropzoneModule,
    PickerModule,
  ],
  exports: [
    TimeagoModule,
    PostCoreCardComponent,
    ChatMessageComponent,
    BulletinCardBodyComponent,
    VillageAnnouncementCardBodyComponent,
    BulletinCardActionsComponent,
    ExchangeCardBodyComponent,
    ExchangeCardActionsComponent,
    HangoutCardBodyComponent,
    HangoutCardActionsComponent,
    PostDropdownDetailsComponent,
    SupportCardBodyComponent,
    SupportCardActionsComponent,
    VillageChatroomCardComponent,
    PostChatListItemComponent,
    DirectChatListItemComponent,
    DirectChatroomCardComponent,
    DirectChatParticipantPopoverComponent,
    ProfilePhotoComponent,
    PostImageComponent,
    VillagerPostsFeedComponent,
    VillagerCardComponent,
    MyIf,
    VillageMembersListComponent,
    NotificationListItemComponent,
    VillagerCardMiniComponent,
    EditImagesComponent,
    VillagerSmallAvatarComponent,
    VillageNotificationSettingsComponent,
    SelectOrJoinVillageComponent,
    VisionAndValuesComponent,
    SafehtmlPipe,
    SafeurlPipe,
    InviteCardBodyComponent,
    ConflictCardBodyComponent,
    NoVillageComponent,
    UpcomingEventsComponent,
    VillageAnnouncementsComponent,
    JoinVillageButtonComponent,
    // SwitchVillageHeaderButtonComponent,
    TruncateBodyPipe,
    AddTopicsComponent,
    EventTimeHeaderComponent,
    VillageListsComponent,
    SharedListBodyComponent,
    BoardSearchFilterComponent,
    LoadingListPreviewCardComponent,
    CreateBulletinComponent,
    CourtyardDetailsPopoverComponent,
    ChatImageComponent,
    ChatVoiceNoteComponent,
    ChatInputComponent,
    PostFileUploadComponent,
    PostDocumentComponent,
    PostCircleAssignmentComponent,
    CircleAdminComponent,
    VillageridToNamePipe,
    VillagerCircleChipsComponent,
    VillageLabelComponent,
    MembersLabelComponent,
    CourtyardsLabelComponent,
    StewardsLabelComponent,
    DuoLabelComponent,
    TrioLabelComponent,
    KarmaLabelComponent,
    CircleIdToLabelPipe,
    JoinedCircleIdToLabelPipe,
    HomeEventsTileComponent,
    HomeAnnouncementsTileComponent,
    HomeListsTileComponent,
    HomeVillagersTileComponent,
    HomeVillageTileComponent,
    EventOrganizersComponent,
    DmChatroomComponent,
    ChatroomDetailsComponent,
    SupportFabComponent,
    DmInputComponent,
    VoiceRecordingPopoverComponent,
    MentionifyDirective,
    MemberMentionPopupComponent,
    MentionifiedPipe,
    PostCircleExplainPopoverComponent,
    MemberInviteCircleExplainPopoverComponent,
    HomeCirclesTileComponent,
    HomeCreateVillageTileComponent,
    CircleOverviewComponent,
    EmojiPickerComponent,
    MessageReplyPreviewComponent,
    ChatMessageOptionsComponent,
    PostMentionableInputComponent,
    DmUnreadCountComponent,
    CircleAvatarComponent,
    CircleAvatarEditComponent,
    CreateJoinVillageComponent,
    VillageAdminComponent,
    VillageManageOverviewComponent,
    VillageManageAdminComponent,
    VillageManageThemeComponent,
    VillageAvatarEditComponent,
    VillageAvatarComponent,
    VillageCoverEditComponent,
    BoardKarmaChipComponent,
    VillageManageTutorialVideosComponent
  ],
})
export class SharedModule {}
