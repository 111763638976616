import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PopoverController } from '@ionic/angular';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Hangout } from 'src/app/models/hangout.model';
import { GenericPost } from 'src/app/models/post-core.model';
import { SetSelectedPost } from 'src/app/state/app.actions';
import { FeedState } from 'src/app/state/feed.state';
import { VillagerState } from 'src/app/state/villager.state';

@Component({
  selector: 'app-hangout-card-body',
  templateUrl: './hangout-card-body.component.html',
  styleUrls: ['./hangout-card-body.component.scss'],
})
export class HangoutCardBodyComponent implements OnInit {

  hangout: Hangout;
  @Select(FeedState.allPosts) allPosts$: Observable<GenericPost[]>;
  @Input() showFullBody = true;
  @Input() post: GenericPost;

  villagerUid: string;
  spotOrSpotsLeft: string = 'spots left';
  attendeeNames: string[] = [];

  constructor(
    private store: Store,
    private popoverCtrl: PopoverController,
    private router: Router
  ) {}

  ngOnInit() {
    this.hangout = this.post as Hangout;
    if (this.hangout) this.buildGoingUi();

    this.store
      .select(FeedState.getPostById)
      .pipe(map((filterFn) => filterFn(this.post._UID)))
      .subscribe((post) => {
        if (post) {
          this.hangout = post as Hangout;
          this.buildGoingUi();
        }
      });

    this.villagerUid = this.store.selectSnapshot(VillagerState.uid);
  }

  goToDetails() {
    this.store.dispatch(
      new SetSelectedPost({ post: this.hangout, location: 'DETAILS' })
    );
    this.router.navigateByUrl(`pages/post-details`);
  }

  buildGoingUi() {
    if (this.hangout.CAPACITY - this.hangout.GOING.length === 1) {
      this.spotOrSpotsLeft = 'spot left';
    }

    this.hangout.GOING.forEach((going) => {
      this.attendeeNames.push(going.FIRST_NAME);
    });
  }

  async showAttendeeList(ev: any) {
    // const popover = await this.popoverCtrl.create({
    //   component: DirectChatParticipantPopoverComponent,
    //   event: ev,
    //   translucent: true,
    // });
    // await popover.present();
  }
}
