import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { Hangout } from 'src/app/models/hangout.model';
import { ImageService } from 'src/app/services/images.service';

@Component({
  selector: 'app-hangout-attendees-modal',
  templateUrl: './hangout-attendees-modal.page.html',
  styleUrls: ['./hangout-attendees-modal.page.scss'],
})
export class HangoutAttendeesModalPage implements OnInit {
  thumbnailUrl$: Observable<string>;
  @Input() hangout: Hangout;
  constructor(
    private modalCtrl: ModalController,
    public imageSvc: ImageService
  ) {}

  ngOnInit() {
    const { _CREATOR_UID, _CREATOR_FIRST_NAME, _CREATOR_LAST_NAME } =
      this.hangout;
    this.thumbnailUrl$ = this.imageSvc.getAvatar$(
      _CREATOR_UID,
      _CREATOR_FIRST_NAME,
      _CREATOR_LAST_NAME,
      '64'
    );
  }

  close() {
    this.modalCtrl.dismiss();
  }
}
