import { Component, OnInit, ViewChild } from '@angular/core';
import { IonSlides, ModalController } from '@ionic/angular';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { Tutorial } from 'src/app/state/tutorial.state';
import { AnalyticsService } from 'src/app/analytics.service';
import { VillagerActions } from 'src/app/state/villager.actions';
import { AngularFirestore } from '@angular/fire/firestore';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-onboarding-slides',
  templateUrl: './onboarding-slides.page.html',
  styleUrls: ['./onboarding-slides.page.scss'],
})
export class OnboardingSlidesPage implements OnInit {
  // Optional parameters to pass to the swiper instance.
  // See http://idangero.us/swiper/api/ for valid options.
  slideOpts = {
    initialSlide: 0,
    speed: 400,
    pagination: { clickable: true },
  };

  youTubeOnboardingLink = 'https://www.youtube.com/embed/nRuFe518P4U'

  @ViewChild('slides') slides: IonSlides;
  constructor(
    private router: Router,
    private modalCtrl: ModalController,
    private store: Store,
    private analytics: AnalyticsService,
    private firebase: AngularFirestore,
    public sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.firebase
      .doc(`LINKS/dLlJl25c9CsSgtM5CVJg`)
      .get()
      .toPromise()
      .then((doc) => {
        const links = doc.data() as any;
        this.youTubeOnboardingLink = links.YT_ONBOARDING as string;
      });
  }

  onNext() {
    const idx = this.slides.getActiveIndex();
    this.analytics.logEvent('tutorial_next', { slideNum: idx });
  }

  onPrevious() {
    const idx = this.slides.getActiveIndex();
    this.analytics.logEvent('tutorial_previous', { slideNum: idx });
  }

  async goToHome() {
    this.analytics.logEvent('tutorial_complete', {});
    this.store.dispatch(new Tutorial.SlidesSeen({ seen: true }));
    const existing = await this.modalCtrl.getTop();
    if (existing) this.modalCtrl.dismiss();
    this.router.navigate(['/pages/home'], { replaceUrl: true });
  }

  async next() {
    this.analytics.logEvent('tutorial_next_clicked', {});
    const isEnd = await this.slides.isEnd();
    if (!isEnd) {
      this.slides.slideNext(400, false);
    }
  }

  close() {
    this.analytics.logEvent('tutorial_canceled', {});
    this.store.dispatch(new Tutorial.SlidesSeen({ seen: true }));
    this.modalCtrl.dismiss();
  }

  async previous() {
    const isBeginning = await this.slides.isBeginning();
    if (!isBeginning) {
      this.slides.slidePrev(400, false);
    }
  }

  async optIntoOnboardingEmails() {
    console.log('opting into first week emails');
    this.store.dispatch(
      new VillagerActions.OptIntoOnboardingEmails({ optIn: true })
    );
  }
}
