import { Component, Input, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { VillageState } from 'src/app/state/village.state';

@Component({
  selector: 'app-stewards-label',
  template: `<span *ngIf="stewardsLabel$ | async as label"
    >{{ showTitlecase ? (label | titlecase) : label
    }}<span *ngIf="plural">s</span></span
  >`,
})
export class StewardsLabelComponent implements OnInit {
  @Select(VillageState.stewardsLabel) stewardsLabel$: Observable<string>;

  @Input() showTitlecase = false;
  @Input() plural = false;
  constructor() {}

  ngOnInit() {}
}
