import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth.guard';
import { SignupPageModule } from './pages/signup/signup.module';
import { LoginPageModule } from './pages/login/login.module';

const routes: Routes = [
  {
    path: '',
    // canActivate: [AuthGuard],
    loadChildren: () =>
      import('./tabs/tabs.module').then((m) => m.TabsPageModule),
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./pages/login/login.module').then((m) => m.LoginPageModule),
  },
  {
    path: 'signup',
    loadChildren: () =>
      import('./pages/signup/signup.module').then((m) => m.SignupPageModule),
  },
  {
    path: 'signup/:inviteCode',
    loadChildren: () =>
      import('./pages/signup/signup.module').then((m) => m.SignupPageModule),
  },
  {
    path: 'notifications',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/notifications/notifications.module').then(
        (m) => m.NotificationsPageModule
      ),
  },
  {
    path: 'roadmap',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/roadmap/roadmap.module').then((m) => m.RoadmapPageModule),
  },
  {
    path: 'create',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/create/create.module').then((m) => m.CreatePageModule),
  },
  // {
  //   path: 'profile',
  //   canActivate: [AuthGuard],
  //   loadChildren: () =>
  //     import('./pages/profile/profile.module').then((m) => m.ProfilePageModule),
  // },
  // {
  //   path: 'profile/:id',
  //   canActivate: [AuthGuard],
  //   loadChildren: () =>
  //     import('./pages/profile/profile.module').then((m) => m.ProfilePageModule),
  // },
  {
    path: 'create-post-modal',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modals/create-post-modal/create-post-modal.module').then(
        (m) => m.CreatePostModalPageModule
      ),
  },
  {
    path: 'create-support-request-modal',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        './modals/create-support-request-modal/create-support-request-modal.module'
      ).then((m) => m.CreateSupportRequestModalPageModule),
  },
  {
    path: 'create-exchange-modal',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        './modals/create-exchange-modal/create-exchange-modal.module'
      ).then((m) => m.CreateExchangeModalPageModule),
  },
  {
    path: 'village-chatroom-modal',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        './modals/village-chatroom-modal/village-chatroom-modal.module'
      ).then((m) => m.VillageChatroomModalPageModule),
  },
  {
    path: 'discussion-room/:id',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        './modals/village-chatroom-modal/village-chatroom-modal.module'
      ).then((m) => m.VillageChatroomModalPageModule),
  },
  {
    path: 'hangout-attendees-modal',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        './modals/hangout-attendees-modal/hangout-attendees-modal.module'
      ).then((m) => m.HangoutAttendeesModalPageModule),
  },
  {
    path: 'create-or-join-village',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        './modals/create-or-join-village-modal/create-or-join-village-modal.module'
      ).then((m) => m.CreateOrJoinVillageModalPageModule),
  },
  {
    path: 'create-or-join-village-modal',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        './modals/create-or-join-village-modal/create-or-join-village-modal.module'
      ).then((m) => m.CreateOrJoinVillageModalPageModule),
  },
  {
    path: 'change-villages-modal',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        './modals/change-villages-modal/change-villages-modal.module'
      ).then((m) => m.ChangeVillagesModalPageModule),
  },
  {
    path: 'post-interactions-modal',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        './modals/post-interactions-modal/post-interactions-modal.module'
      ).then((m) => m.PostInteractionsModalPageModule),
  },
  {
    path: 'exchange-interest-modal',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        './modals/exchange-interest-modal/exchange-interest-modal.module'
      ).then((m) => m.ExchangeInterestModalPageModule),
  },
  {
    path: 'support-offered-modal',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        './modals/support-offered-modal/support-offered-modal.module'
      ).then((m) => m.SupportOfferedModalPageModule),
  },
  {
    path: 'create-direct-chatroom-modal',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        './modals/create-direct-chatroom-modal/create-direct-chatroom-modal.module'
      ).then((m) => m.CreateDirectChatroomModalPageModule),
  },
  {
    path: 'direct-chatroom-modal',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        './modals/direct-chatroom-modal/direct-chatroom-modal.module'
      ).then((m) => m.DirectChatroomModalPageModule),
  },
  {
    path: 'post-details',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/post-details/post-details.module').then(
        (m) => m.PostDetailsPageModule
      ),
  },
  {
    path: 'post-view-images-modal',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        './modals/post-view-images-modal/post-view-images-modal.module'
      ).then((m) => m.PostViewImagesModalPageModule),
  },
  {
    path: 'discussions',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/discussions/discussions.module').then(
        (m) => m.DiscussionsPageModule
      ),
  },
  {
    path: 'messages',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/messages/messages.module').then(
        (m) => m.MessagesPageModule
      ),
  },
  {
    path: 'edit-profile-modal',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modals/edit-profile-modal/edit-profile-modal.module').then(
        (m) => m.EditProfileModalPageModule
      ),
  },
  {
    path: 'village-members-modal',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        './modals/village-members-modal/village-members-modal.module'
      ).then((m) => m.VillageMembersModalPageModule),
  },
  {
    path: 'notification-permission-modal',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        './modals/notification-permission-modal/notification-permission-modal.module'
      ).then((m) => m.NotificationPermissionModalPageModule),
  },
  {
    path: 'notification-settings-modal',
    loadChildren: () =>
      import(
        './modals/notification-settings-modal/notification-settings-modal.module'
      ).then((m) => m.NotificationSettingsModalPageModule),
  },
  {
    path: 'edit-vision-and-values-modal',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        './modals/manage-village-details-modal/manage-village-details-modal.module'
      ).then((m) => m.ManageVillageDetailsModalPageModule),
  },
  {
    path: 'submit-feedback-modal',
    loadChildren: () =>
      import(
        './modals/submit-feedback-modal/submit-feedback-modal.module'
      ).then((m) => m.SubmitFeedbackModalPageModule),
  },
  {
    path: 'create-conflict-modal',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        './modals/create-conflict-modal/create-conflict-modal.module'
      ).then((m) => m.CreateConflictModalPageModule),
  },
  {
    path: 'create-hangout-modal',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modals/create-hangout-modal/create-hangout-modal.module').then(
        (m) => m.CreateHangoutModalPageModule
      ),
  },
  {
    path: 'leave-village-modal',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modals/leave-village-modal/leave-village-modal.module').then(
        (m) => m.LeaveVillageModalPageModule
      ),
  },
  {
    path: 'tutorial-welcome-modal',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        './modals/tutorial-welcome-modal/tutorial-welcome-modal.module'
      ).then((m) => m.TutorialWelcomeModalPageModule),
  },
  {
    path: 'create-announcement-modal',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        './modals/create-announcement-modal/create-announcement-modal.module'
      ).then((m) => m.CreateAnnouncementModalPageModule),
  },
  {
    path: 'create-list-modal',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modals/create-list-modal/create-list-modal.module').then(
        (m) => m.CreateListModalPageModule
      ),
  },
  {
    path: 'home',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/courtyards/courtyards.module').then(
        (m) => m.CourtyardsPageModule
      ),
  },
  {
    path: 'create-courtyard-modal',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        './modals/create-courtyard-modal/create-courtyard-modal.module'
      ).then((m) => m.CreateCourtyardModalPageModule),
  },
  {
    path: 'bulletin-board',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/bulletin-board/bulletin-board.module').then(
        (m) => m.BulletinBoardPageModule
      ),
  },
  {
    path: 'courtyard-chat-modal',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modals/courtyard-chat-modal/courtyard-chat-modal.module').then(
        (m) => m.CourtyardChatModalPageModule
      ),
  },
  {
    path: 'create-bulletin-modal',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        './modals/create-bulletin-modal/create-bulletin-modal.module'
      ).then((m) => m.CreateBulletinModalPageModule),
  },
  {
    path: 'onboarding-join-village',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        './modals/onboarding-join-village/onboarding-join-village.module'
      ).then((m) => m.OnboardingJoinVillagePageModule),
  },
  // {
  //   path: 'admin',
  //   canActivate: [AuthGuard],
  //   loadChildren: () =>
  //     import('./pages/admin/admin.module').then((m) => m.AdminPageModule),
  // },
  {
    path: 'create-circle-modal',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modals/create-circle-modal/create-circle-modal.module').then(
        (m) => m.CreateCircleModalPageModule
      ),
  },
  {
    path: 'home-events-modal',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modals/home-events-modal/home-events-modal.module').then(
        (m) => m.HomeEventsModalPageModule
      ),
  },
  {
    path: 'home-villagers-modal',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modals/home-villagers-modal/home-villagers-modal.module').then(
        (m) => m.HomeVillagersModalPageModule
      ),
  },
  {
    path: 'home-village-modal',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modals/home-village-modal/home-village-modal.module').then(
        (m) => m.HomeVillageModalPageModule
      ),
  },
  {
    path: 'home-announcements-modal',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        './modals/home-announcements-modal/home-announcements-modal.module'
      ).then((m) => m.HomeAnnouncementsModalPageModule),
  },
  {
    path: 'home-lists-modal',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modals/home-lists-modal/home-lists-modal.module').then(
        (m) => m.HomeListsModalPageModule
      ),
  },
  {
    path: 'onboarding-slides',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modals/onboarding-slides/onboarding-slides.module').then(
        (m) => m.OnboardingSlidesPageModule
      ),
  },
  {
    path: 'members',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/members/members.module').then((m) => m.MembersPageModule),
  },
  {
    path: 'member',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modals/member-view/member-view.module').then(
        (m) => m.MemberViewPageModule
      ),
  },
  {
    path: 'member/:id',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modals/member-view/member-view.module').then(
        (m) => m.MemberViewPageModule
      ),
  },
  {
    path: 'dm-manage-modal',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modals/dm-manage-modal/dm-manage-modal.module').then(
        (m) => m.DmManageModalPageModule
      ),
  },
  {
    path: 'chatroom',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/dm-chatroom/dm-chatroom.module').then(
        (m) => m.DmChatroomPageModule
      ),
  },
  {
    path: 'chatroom/:id',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/dm-chatroom/dm-chatroom.module').then(
        (m) => m.DmChatroomPageModule
      ),
  },
  {
    path: 'ci-onboarding-slides',
    loadChildren: () =>
      import('./modals/ci-onboarding-slides/ci-onboarding-slides.module').then(
        (m) => m.CiOnboardingSlidesPageModule
      ),
  },
  {
    path: 'member-invite-modal',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modals/member-invite-modal/member-invite-modal.module').then(
        (m) => m.MemberInviteModalPageModule
      ),
  },
  {
    path: 'view-invites-modal',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modals/view-invites-modal/view-invites-modal.module').then(
        (m) => m.ViewInvitesModalPageModule
      ),
  },
  {
    path: 'circles',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/circles/circles.module').then((m) => m.CirclesPageModule),
  },
  {
    path: 'circle-detail-modal',
    loadChildren: () =>
      import('./modals/circle-detail-modal/circle-detail-modal.module').then(
        (m) => m.CircleDetailModalPageModule
      ),
  },
  {
    path: 'emoji-mart-modal',
    loadChildren: () =>
      import('./modals/emoji-mart-modal/emoji-mart-modal.module').then(
        (m) => m.EmojiMartModalPageModule
      ),
  },
  {
    path: 'chat-message-options-modal',
    loadChildren: () =>
      import(
        './modals/chat-message-options-modal/chat-message-options-modal.module'
      ).then((m) => m.ChatMessageOptionsModalPageModule),
  },
  {
    path: 'member-options-modal',
    loadChildren: () =>
      import('./modals/member-options-modal/member-options-modal.module').then(
        (m) => m.MemberOptionsModalPageModule
      ),
  },
  {
    path: 'village-admin',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/village-admin/village-admin.module').then(
        (m) => m.VillageAdminPageModule
      ),
  },
  {
    path: 'permission-detail-modal',
    loadChildren: () =>
      import(
        './modals/permission-detail-modal/permission-detail-modal.module'
      ).then((m) => m.PermissionDetailModalPageModule),
  },
  {
    path: 'create-village-modal',
    loadChildren: () =>
      import('./modals/create-village-modal/create-village-modal.module').then(
        (m) => m.CreateVillageModalPageModule
      ),
  },
  {
    path: 'village',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/village-detail/village-detail.module').then(
        (m) => m.VillageDetailPageModule
      ),
  },
  {
    path: 'village/:id',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/village-detail/village-detail.module').then(
        (m) => m.VillageDetailPageModule
      ),
  },
];

// provide fallback
routes.push({
  path: '**',
  redirectTo: '',
});

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      enableTracing: false,
    }),
    LoginPageModule,
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
