import { Injectable } from '@angular/core';
import { NgxsFirestore } from '@ngxs-labs/firestore-plugin';
import * as firebase from 'firebase';
import 'firebase/firestore';
import { of } from 'rxjs';
import { Circle } from '../models/circle.model';


@Injectable({
  providedIn: 'root',
})
export class CircleService extends NgxsFirestore<Circle> {
  idField = '_UID';

  protected get path() {
    return `VILLAGES/${this.villageId}/CIRCLES`;
  }

  private VILLAGEID = '';

  public setVillageId(villageId) {
    this.VILLAGEID = villageId;
  }

  protected get villageId() {
    return this.VILLAGEID;
  }

  /**
   * @deprecated
   */
   updateIfExists(id, data) {
    return of(
      this.adapter.firestore
        .doc(`${this.path}/${id}`)
        .ref.withConverter(this.converter)
        .update(data)
    );
  }

  updateWithoutConverter(id: string, update: Partial<Circle>) {
    return of(this.adapter.firestore.doc(`${this.path}/${id}`).update(update));
  }

  updateArrayUnion(id: string, update: {VILLAGER_UIDS: firebase.default.firestore.FieldValue}) {
    return of(this.adapter.firestore.doc(`${this.path}/${id}`).update(update));
  }

  updateArrayUnionAdmins(id: string, update: {ADMIN_UIDS: firebase.default.firestore.FieldValue}) {
    return of(this.adapter.firestore.doc(`${this.path}/${id}`).update(update));
  }

  converter: firebase.default.firestore.FirestoreDataConverter<Circle> = {
    toFirestore: (value: Circle) => {
      let db = { ...value };
      return db;
    },
    fromFirestore: (snapshot, options) => {
      let data = snapshot.data(options) as Circle;
      if (!data.hasOwnProperty('ADMIN_UIDS')) {
        data = {
          ...data,
          ADMIN_UIDS: [],
        };
      }
      return data;
    },
  };
}
