import { Injectable } from '@angular/core';
import { Network } from '@capacitor/network';
import { Store } from '@ngxs/store';
import { AppToggleIsOffline } from '../state/app.actions';


@Injectable({
  providedIn: 'root',
})
export class OfflineService {

  constructor(private store: Store) {}

  init() {
    Network.addListener('networkStatusChange', (status) => {
      this.store.dispatch(new AppToggleIsOffline({isOffline: !status.connected}))
    });

  }
}
