import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UtilitiesService {
  constructor() {}

  parseLinksFromText(text: string) {
    var retval = text;
    if (retval != null) {
      var urlRegex =
        /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gi;
      retval = text.replace(urlRegex, function (url) {
        let urlWithProtocol = `${url}`
        // Append http to the beginning of a url if it doesn't have a protocol already,
        // http is used becuase https will be redirected to if the site has https
        if(!url.startsWith('http')) {
          urlWithProtocol = 'http://' + url;
        }
        return '<a href="' + urlWithProtocol + '" target="_blank">' + url + '</a>';
      });
    }
    // console.log('[Utils] Links in text: ', retval);
    return retval;
  }

  removeExistingTags(text: string) {
    const regex = /(<([^>]+)>)/gi;
    let result = text.replace(regex, '');
    // console.log('[Utils] Removed tags from text: ', result);
    return result;
  }

  async asyncForEach(array: any[], callback: any) {
    const res = [];
    for (let index = 0; index < array.length; index++) {
      res.push(await callback(array[index], index, array));
    }
    return res;
  }
}
