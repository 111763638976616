<ion-header>
  <ion-toolbar>
    <ion-title>Create a Village</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>

  <app-create-join-village
  [signup]="false"
  (goBack)="goBack()"
  [startAt]="'stepThree'"
></app-create-join-village>

</ion-content>
