<!-- Start Topics -->
<div style="margin-top: 10px; margin-bottom: 5px">
  <div class="subheader">Add Topics</div>
  <p>Topics help keep things organized (search by entering #) <br> You can enter multiple topics at once (use a space to seperate) or enter them one at a time.</p>

  <ion-grid style="padding: 0">
    <ion-row style="padding: 0">
      <ion-col style="padding: 0">
        <ion-chip
          (click)="removeTopic(topic)"
          outline="true"
          color="primary"
          *ngFor="let topic of topics"
        >
          <ion-label>{{ topic }}</ion-label>
          <ion-icon name="close-circle"></ion-icon>
        </ion-chip>
      </ion-col>
    </ion-row>
    <ion-row style="padding: 0">
      <ion-col style="padding: 0">
        <div class="custom-input">
          <form [formGroup]="topicForm">
            <input
              style="width: 90%"
              (keyup.enter)="addTopic()"
              formControlName="newTopic"
              [mentionConfig]="mentionConfig"
              placeholder="Enter a #topic"
              autocomplete="true"
              spellcheck="true"
            />
          </form>
          <ion-button (click)="addTopic()" fill="clear">
            <ion-icon
              color="primary"
              slot="icon-only"
              name="add-circle"
            ></ion-icon>
          </ion-button>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</div>
<!-- End Topics -->
