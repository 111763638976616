import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { IonList } from '@ionic/angular';
import { Villager } from 'src/app/models/villager.model';

@Component({
  selector: 'app-member-mention-popup',
  templateUrl: './member-mention-popup.component.html',
  styleUrls: ['./member-mention-popup.component.scss'],
})
export class MemberMentionPopupComponent implements OnInit {
  selectedIndex = 0;
  @Input() villagers: Villager[];
  @Input() setVillager: (v: Villager) => void;
  @Input() shouldDisplayAbove: boolean = true;
  @ViewChild(IonList, { read: ElementRef }) list: ElementRef;
  constructor() {}

  @HostListener('document:keydown.ArrowDown', ['$event'])
  @HostListener('document:keydown.ArrowUp', ['$event'])
  arrowKeydown(ev: KeyboardEvent) {
    ev.preventDefault();
    ev.stopPropagation();
    const domList = this.list.nativeElement.children;
    if (
      ev.key === 'ArrowDown' &&
      this.selectedIndex < this.villagers.length - 1
    ) {
      this.selectedIndex++;
    } else if (ev.key === 'ArrowUp' && this.selectedIndex > 0) {
      this.selectedIndex--;
    }
    const item = domList[this.selectedIndex];
    if (item) item.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
  }

  ngOnInit(): void {}
}
