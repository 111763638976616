<ion-header>
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button (click)="modalCtrl.dismiss({action: 'regular-close'})">
        <ion-icon color="dark" slot="icon-only" name="close-circle"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>Leave <app-village-label [showTitlecase]="true"></app-village-label></ion-title>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-card [ngClass]="'no-border'">
    <ion-card-content>
      <div class="subheader"></div>
      <p>You can leave any <app-village-label></app-village-label> you'd like and rejoin later using the villages invite code.</p>
      <p> When you leave a <app-village-label></app-village-label>, your posts will remain but you will not be able to access them unless you rejoin the <app-village-label></app-village-label>.</p>
      <p>You will still maintain your direct messages and be able to send new messages to your existing contacts.</p>
      <div class="subheader" style="margin-top: 10px;" *ngIf="villager.VILLAGES.length > 1">
        Select The <app-village-label [showTitlecase]="true"></app-village-label> To Move To
      </div>
      <ion-list>
        <ion-radio-group [value]="villager.VILLAGE">
          <ion-item
            *ngFor="let village of villagesToJoin"
            class="full-width-item"
            (click)="selectVillageToJoin(village.UID)"
          >
            <ion-label>{{ village.NAME }}</ion-label>
            <ion-radio [value]="village.UID" slot="start"></ion-radio>
          </ion-item>

          <ion-radio></ion-radio>
        </ion-radio-group>
      </ion-list>
      <ion-button
        [disabled]="disableLeave()"
        style="margin-top: 20px"
        expand="block"
        color="primary"
        (click)="confirmLeaveVillage()"
      >
        Leave {{ currentVillageName }}
      </ion-button>
    </ion-card-content>
  </ion-card>
</ion-content>
