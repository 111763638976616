import { Component, Input, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { VillageState } from 'src/app/state/village.state';

@Component({
  selector: 'app-courtyards-label',
  template: `<span *ngIf="courtyardsLabel$ | async as label">{{
    showTitlecase ? (label | titlecase) : label
  }}<span *ngIf="plural">s</span></span>`,
})
export class CourtyardsLabelComponent implements OnInit {
  @Select(VillageState.courtyardsLabel) courtyardsLabel$: Observable<string>;

  @Input() showTitlecase = false;
  @Input() plural = false;
  constructor() {}

  ngOnInit() {}
}
