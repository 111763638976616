import { CreateVillageModalPage } from './../../modals/create-village-modal/create-village-modal.page';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  AlertController,
  LoadingController,
  ModalController,
} from '@ionic/angular';
import { Store } from '@ngxs/store';
import { ManageVillageDetailsModalPage } from 'src/app/modals/manage-village-details-modal/manage-village-details-modal.page';
import { AnalyticsService } from 'src/app/analytics.service';
import { SwitchVillage } from 'src/app/state/app.actions';
import { JoinedVillage, Villager } from 'src/app/models/villager.model';
import { LeaveVillageModalPage } from 'src/app/modals/leave-village-modal/leave-village-modal.page';

@Component({
  selector: 'app-select-or-join-village',
  templateUrl: './select-or-join-village.component.html',
  styleUrls: ['./select-or-join-village.component.scss'],
})
export class SelectOrJoinVillageComponent implements OnInit {
  verifying = false;
  verified = false;
  successMessage = null;
  saving = false;
  loading: any;
  component = 'select-or-join-village';

  currentVillagerVillage: JoinedVillage;

  @Input() villager: Villager;
  @Input() showBorder = true;
  @Output() selectedVillage: EventEmitter<any> = new EventEmitter<any>();
  constructor(
    private store: Store,
    private alertCtrl: AlertController,
    public modalCtrl: ModalController,
    private loadingCtrl: LoadingController,
    private analytics: AnalyticsService
  ) {}

  ngOnInit() {
    console.log('[Select or join] passed villager: ', this.villager)

    this.currentVillagerVillage = this.villager.VILLAGES.find(x => x.UID === this.villager.VILLAGE)
  }

  async setCurrentVillage(uid: string) {
    this.analytics.logEvent('select_or_join_village_clicked', {});
    // this.presentLoading();

    this.store
      .dispatch(
        new SwitchVillage({
          villagerUid: this.villager._UID,
          villageUid: uid,
        })
      )
      .subscribe(
        () => {
          // TODO figure out why this never fires. something about SwitchVillage never resolves
          console.log('[CHANGE VILLAGE] village has been changed');
          this.handleWrapup();
        },
        (err) => {
          this.analytics.logError('error_switching_village', this.component, err);
          this.handleWrapup();
        }
      );
  }

  async handleWrapup() {
    this.selectedVillage.emit();
    const modal = await this.modalCtrl.getTop();
    if (modal) this.modalCtrl.dismiss({ action: 'create-close' });
    if (this.loading) {
      this.loading.dismiss();
      this.loading = null;
    }
  }

  async showErrorAlert() {
    const alert = await this.alertCtrl.create({
      header: 'Error',
      message:
        'We ran into an error changing your village. Please check your invite code and try again.',
      buttons: ['OK'],
    });

    await alert.present();
  }

  async presentLoading() {
    if (!this.loading) {
      this.loading = await this.loadingCtrl.create({
        // message: 'Communicating through space & time...',
        duration: 15000,
        backdropDismiss: true,
      });
      return this.loading.present();
    }
  }

  async leaveVillage() {
    const existing = await this.modalCtrl.getTop();
    await existing.dismiss();
    const modal = await this.modalCtrl.create({
      component: LeaveVillageModalPage,
      componentProps: { villager: this.villager },
    });
    return await modal.present();
  }

  async createNewVillage() {
    const existing = await this.modalCtrl.getTop();
    if (existing) this.modalCtrl.dismiss();
    this.selectedVillage.emit();
    this.analytics.logEvent('create_new_village_clicked', {});
    const modal = await this.modalCtrl.create({
      component: CreateVillageModalPage,
    });

    modal.onDidDismiss().then((data) => {
      console.log('[CREATE PAGE] dismiss data: ', data);

      if (data.data && data.data.action === 'create-close') {
        console.log('[CREATE PAGE] navigating to exchange after create');
      }
    });

    return await modal.present();
  }
}
