import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController, Platform } from '@ionic/angular';
import { Store } from '@ngxs/store';
import {
  DirectChatroom,
  DirectParticipant,
} from 'src/app/models/direct-chatroom.model';
import {
  CreateDyadChatroom,
  SetSelectedDirectChatroom,
} from 'src/app/state/app.actions';
import { DirectChatroomState } from 'src/app/state/direct-chatroom.state';
import { VillageState } from 'src/app/state/village.state';
import {
  VillagerState,
  convertVillagerToParticipant,
} from 'src/app/state/villager.state';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-support-fab',
  templateUrl: './support-fab.component.html',
  styleUrls: ['./support-fab.component.scss'],
})
export class SupportFabComponent implements OnInit {
  constructor(
    private modalCtrl: ModalController,
    private store: Store,
    private router: Router,
    private dmState: DirectChatroomState,
    private platform: Platform
  ) {}

  ngOnInit() {}

  async sendSupportEmail() {
    const currentVillager = this.store.selectSnapshot(
      VillagerState.currentVillager
    );
    const allVillager = this.store.selectSnapshot(VillagerState.allVillagers);
    const villageUid = this.store.selectSnapshot(VillageState.uid);
    const allChatrooms: DirectChatroom[] = this.store.selectSnapshot(
      DirectChatroomState.allChatrooms
    );

    const supportAccountUID = environment.rvrSupportUID;
    // TODO: This UID needs to be bulletproof, so we need to set up the rvr support account in prod first,
    // and then use the ID generated there
    const supportChatExists = allChatrooms.find(
      (chatroom) =>
        chatroom.PARTICIPANT_UIDS.indexOf(supportAccountUID) > -1
    );
    if (!supportChatExists) {
      const creatorVillager: DirectParticipant = convertVillagerToParticipant(
        currentVillager,
        villageUid
      );
      const participant: DirectParticipant = {
        UID: environment.rvrSupportUID,
        FIRST_NAME: 'reVillager',
        LAST_NAME: 'Support',
        ACCEPTED: true,
        REJECTED: false,
        PUSH: null,
      };

      this.store
        .dispatch(new CreateDyadChatroom({ creatorVillager, participant }))
        .subscribe(() => {
          this.router.navigate(['/pages/messages']);
          if (this.platform.is('mobile') || this.platform.is('capacitor')) {
            this.router.navigateByUrl('chatroom');
          }
        });
    } else {
      this.store
        .dispatch(
          new SetSelectedDirectChatroom({ chatroom: supportChatExists })
        )
        .subscribe(() => {
          this.router.navigate(['/pages/messages']);
          if (this.platform.is('mobile') || this.platform.is('capacitor')) {
            this.router.navigateByUrl('chatroom');
          }
        });
    }

    return null;
  }

  refreshApp() {
    window.location.reload();
  }

  goToVillageAbout() {
    const villageId = this.store.selectSnapshot(VillageState.uid);
    this.router.navigateByUrl(`/village/${villageId}`);
  }
}
