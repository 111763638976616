<ion-header>
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button (click)="close()">
        <ion-icon color="dark" slot="icon-only" name="close-circle"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title></ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>

  <app-create-bulletin [description]="description"></app-create-bulletin>

</ion-content>
