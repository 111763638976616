import { Injectable } from '@angular/core';
import {
  Action,
  NgxsAfterBootstrap,
  Selector,
  State,
  StateContext,
  Store,
} from '@ngxs/store';
import { debounceTime, tap } from 'rxjs/operators';
import {
  FeedStateModel,
  GenericPost,
  showPost,
} from '../models/post-core.model';
import {
  ClearAllPosts,
  ClearPostsFilter,
  BuildFeed,
  FilterPosts,
  FeedBuildComplete,
  AddPostToParticipated,
  ReportPost,
  SetVillagerOnFeed,
  FeedBuildStart,
  SetSelectedPostById,
  SetSelectedPost,
} from './app.actions';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { VillageMessageService } from '../services/village-message.service';
import { ShareState } from './share.state';
import { ExchangeState } from './exchange.state';
import { HangoutState } from './hangouts.state';
import { SupportRequestState } from './support-requests.state';
import { VillagerState } from './villager.state';
import { StateUtilities } from './state-utilities';
import { ConflictState } from './conflict.state';
import { AnnouncementState } from './announcement.state';
import { Hangout } from '../models/hangout.model';
import moment from 'moment';
import { SharedListState } from './shared-list.state';

@State<FeedStateModel>({
  name: 'feed',
  defaults: {
    currentVillagerId: null,
    blockedVillagers: [],
    allPosts: [],
    filterType: null,
    filteredPosts: [],
    participatedPosts: [],
    initialBuildComplete: false,
    loadingData: false,
  },
})
@Injectable()
export class FeedState implements NgxsAfterBootstrap {
  feed: Subscription;

  constructor(
    private store: Store,
    public messageSvc: VillageMessageService,
    private utils: StateUtilities
  ) {}

  ngxsAfterBootstrap(ctx: StateContext<FeedStateModel>) {}

  @Selector()
  static loading(state: FeedStateModel): boolean {
    return state.loadingData;
  }

  @Selector()
  static allPosts(state: FeedStateModel): GenericPost[] {
    // can add sorting here based on other state vars

    return state.allPosts.filter((x) =>
      showPost(x, state.currentVillagerId, state.blockedVillagers)
    );
  }

  @Selector()
  static filteredPosts(state: FeedStateModel): GenericPost[] {
    return state.filteredPosts
      .filter((x) =>
        showPost(x, state.currentVillagerId, state.blockedVillagers)
      )
      .slice(0, 30);
    // .filter((x) =>
    //   moment(x._CREATED_AT).isAfter(moment().subtract(30, 'days'))
    // );
  }

  @Selector()
  static participatedPosts(state: FeedStateModel): GenericPost[] {
    return state.participatedPosts.filter((x) =>
      showPost(x, state.currentVillagerId, state.blockedVillagers)
    );
  }

  @Selector()
  static filterType(state: FeedStateModel): string {
    return state.filterType;
  }

  @Selector()
  static initialBuildComplete(state: FeedStateModel): boolean {
    return state.initialBuildComplete;
  }

  @Selector()
  static villagerPosts(
    state: FeedStateModel
  ): (villagerId: string) => GenericPost[] {
    return (villagerId: string) => {
      if (state.filterType == 'ARCHIVED') {
        return state.allPosts.filter((x) => x._CREATOR_UID === villagerId && x.ARCHIVED)
      }
      return state.allPosts.filter((x) => x._CREATOR_UID === villagerId && !x.ARCHIVED);
    };
  }

  @Selector()
  static getPostById(state: FeedStateModel): (postId: string) => GenericPost {
    return (postId: string) => {
      return state.allPosts.find((x) => x._UID === postId);
    };
  }

  @Action(BuildFeed)
  startListeningToPosts(ctx: StateContext<FeedStateModel>, action: BuildFeed) {
    if (this.feed) {
      this.feed.unsubscribe();
    }

    const userUid = this.store.selectSnapshot(VillagerState.uid);
    const shares$ = this.store.select(ShareState.allShares);
    const exchange$ = this.store.select(ExchangeState.allExchangeItems);
    const hangouts$ = this.store.select(HangoutState.allHangouts);
    const supportRequests$ = this.store.select(
      SupportRequestState.allSupportRequests
    );
    const conflicts$ = this.store.select(ConflictState.allConflicts);
    const announcements$ = this.store.select(
      AnnouncementState.allAnnouncements
    );
    const lists$ = this.store.select(SharedListState.allLists)

    this.feed = combineLatest([
      shares$,
      exchange$,
      hangouts$,
      supportRequests$,
      conflicts$,
      announcements$,
      lists$,
    ])
      .pipe(
        debounceTime(100),
        tap(
          ([
            shares,
            exchangeItems,
            hangouts,
            supportRequests,
            conflicts,
            announcements,
            lists,
          ]) => {
            const allPosts = [
              ...shares,
              ...exchangeItems,
              ...hangouts,
              ...supportRequests,
              ...conflicts,
              ...announcements,
              ...lists
            ].sort((a, b) => b._CREATED_AT - a._CREATED_AT);

            // if there's a current filter, apply it.
            // otherwise set filteredPosts to allPosts
            const { filterType } = ctx.getState();
            let filteredPosts = [];
            if (filterType) {
              filteredPosts = allPosts.filter((x) => x._TYPE === filterType);
            } else {
              filteredPosts = [...allPosts];
            }

            const participatedPosts = [
              ...allPosts
                // If a post has no participants it will cause an undefined
                // reference error so only check includes if participants exist
                .filter(
                  (x) =>
                    x.PARTICIPANT_UIDS?.includes(userUid) ||
                    x._CREATOR_UID === userUid ||
                    x.PARTICIPANTS?.includes(userUid) // DEPRECATED provided as patch
                )
                .sort((a, b) => b.UPDATED_AT - a.UPDATED_AT),
            ];

            const archivedPosts = allPosts.filter((x) => x.ARCHIVED);

            console.log(
              `[Feed] Built feed.
          All posts: ${allPosts.length}.
          Filtered posts: ${filteredPosts.length}.
          Participated posts: ${participatedPosts.length}
          Archived posts: ${archivedPosts.length}`
            );

            // console.log(
            //   '[Feed] Content: ',
            //   allPosts.map((x) => ({ uid: x._UID, creator: x._CREATOR_UID }))
            // );

            ctx.patchState({
              allPosts,
              filteredPosts,
              participatedPosts,
            });
          }
        )
      )
      .subscribe(() => {
        console.log('*** FEED EMITTED ***');
      });

    return this.feed;
  }

  @Action(SetSelectedPostById)
  setSelectedPostById(
    ctx: StateContext<FeedStateModel>,
    action: SetSelectedPostById
  ) {
    const { allPosts } = ctx.getState();
    console.log(
      'Have all posts: ',
      allPosts.map((x) => x._UID)
    );
    const { postId } = action.payload;
    const post = allPosts.find((x) => x._UID === postId);
    console.log('Found post by id:  ', post);
    if (post)
      return this.store.dispatch(
        new SetSelectedPost({ post, location: 'CHAT' })
      );
  }

  @Action(AddPostToParticipated)
  addPostToParticipated(
    ctx: StateContext<FeedStateModel>,
    action: AddPostToParticipated
  ) {
    const { participatedPosts } = ctx.getState();
    const { post } = action.payload;
    const updatedList = new Set([post, ...participatedPosts]);
    ctx.patchState({
      participatedPosts: [...updatedList].sort(
        (a, b) => b.UPDATED_AT - a.UPDATED_AT
      ),
    });
  }

  @Action(SetVillagerOnFeed)
  setVillager(ctx: StateContext<FeedStateModel>, action: SetVillagerOnFeed) {
    ctx.patchState({
      currentVillagerId: action.payload.villagerId,
      blockedVillagers: action.payload.blockedVillagers,
    });
  }

  @Action(FilterPosts)
  filterPosts(ctx: StateContext<FeedStateModel>, action: FilterPosts) {
    const { type } = action.payload;
    const { allPosts, participatedPosts, currentVillagerId, blockedVillagers } =
      ctx.getState();
    let filteredPosts = [];
    switch (type) {
      case 'ARCHIVED':
        filteredPosts = participatedPosts.filter((x) => x.ARCHIVED)
        break;
      case 'JOINED':
        filteredPosts = participatedPosts.filter((x) =>
          showPost(x, currentVillagerId, blockedVillagers)
        );
        break;
      case 'PAST_HANGOUTS':
        filteredPosts = [
          ...allPosts.filter((post) => {
            if (post._TYPE === 'HANGOUT') {
              const hangout = post as Hangout;
              if (
                hangout.START_DATETIME &&
                moment(hangout.START_DATETIME).isBefore(moment())
              ) {
                return post;
              }
            }
          }),
        ];
        break;
      default:
        filteredPosts = [...allPosts.filter((x) => x._TYPE === type)];
        break;
    }

    ctx.patchState({
      filteredPosts,
      filterType: type,
    });
  }

  @Action(ClearPostsFilter)
  clearPostsFilter(ctx: StateContext<FeedStateModel>) {
    const currentState = ctx.getState();
    ctx.patchState({
      filteredPosts: [...currentState.allPosts],
      filterType: null,
    });
  }

  @Action(ClearAllPosts)
  clearAllPosts(ctx: StateContext<FeedStateModel>) {
    // alert('Clearing state')
    ctx.patchState({
      allPosts: [],
      filteredPosts: [],
      // filterType: null,
      participatedPosts: [],
      initialBuildComplete: false,
      // currentVillagerId: null,
      // blockedVillagers: [],
    });
  }

  @Action(FeedBuildComplete)
  complete(ctx: StateContext<FeedStateModel>) {
    setTimeout(() => {
      ctx.patchState({
        initialBuildComplete: true,
        loadingData: false,
      });
      console.log('[Feed State] Post fetch complete');
    }, 500);
  }

  @Action(FeedBuildStart)
  start(ctx: StateContext<FeedStateModel>) {
    ctx.patchState({
      loadingData: true,
    });
  }

  @Action(ReportPost)
  reportPost(ctx: StateContext<FeedStateModel>, action: ReportPost) {
    const { post, reporterId } = action.payload;
    console.log(`Villager ${reporterId} is reporting post ${post._UID}`);

    return this.utils.reportPost(post, reporterId);
  }
}
