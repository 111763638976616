import { Injectable } from '@angular/core';
import { NgxsFirestore } from '@ngxs-labs/firestore-plugin';
import * as firebase from 'firebase';
import 'firebase/firestore';
import { of } from 'rxjs';
import { Hangout } from '../models/hangout.model';
import { isPost } from '../models/post-core.model';

@Injectable({
  providedIn: 'root',
})
export class HangoutService extends NgxsFirestore<Hangout> {
  idField = '_UID';

  protected get path() {
    return `VILLAGES/${this.villageId}/HANGOUTS`;
  }

  private VILLAGEID = '';

  public setVillageId(villageId) {
    this.VILLAGEID = villageId;
  }

  protected get villageId() {
    return this.VILLAGEID;
  }

  /**
   * @deprecated
   */
  updateIfExists(id, data) {
    return of(
      this.adapter.firestore
        .doc(`${this.path}/${id}`)
        .ref.withConverter(this.converter)
        .update(data)
    );
  }

  updateWithoutConverter(id: string, update: Partial<Hangout>) {
    return of(this.adapter.firestore.doc(`${this.path}/${id}`).update(update));
  }

  converter: firebase.default.firestore.FirestoreDataConverter<Hangout> = {
    toFirestore: (value: Hangout) => {
      // NOTE: careful here. Partial updates come through here as well.
      let db = { ...value };
      if (db.hasOwnProperty('BODY')) {
        db = {
          ...db,
          BODY: encodeURI(value.BODY),
        };
      }
      return db;
    },
    fromFirestore: (snapshot, options) => {
      let data = snapshot.data(options) as Hangout;
      console.warn('[Hangout] processing data: ', data, snapshot);
      if (snapshot.metadata.hasPendingWrites) {
        const ts = firebase.default.firestore.Timestamp.now();
        data = {
          ...data,
          BODY: decodeURI(data.BODY),
          _CREATED_AT: ts.toDate(),
          UPDATED_AT: ts.toDate(),
        };
      } else {
        data = {
          ...data,
          BODY: decodeURI(data.BODY),
        };

        if(data._SERVER_TIMESTAMP && data._SERVER_TIMESTAMP.toDate) {
          data._CREATED_AT = data._SERVER_TIMESTAMP.toDate();
        } else {
          data._CREATED_AT = new Date(data._SERVER_TIMESTAMP.seconds * 1000);
        }

        if(data.UPDATED_AT && data.UPDATED_AT.toDate) {
          data.UPDATED_AT = data.UPDATED_AT.toDate();
        } else {
          data.UPDATED_AT = new Date(data.UPDATED_AT.seconds * 1000);
        }
      }

      if (!data.hasOwnProperty('CAPACITY')) {
        data.CAPACITY = 0;
      }

      if (!data.hasOwnProperty('IMAGE_PATHS')) {
        data.IMAGE_PATHS = [];
      }

      if (!data.hasOwnProperty('DOCUMENT_PATHS')) {
        data.DOCUMENT_PATHS = [];
      }

      if (!data.hasOwnProperty('PARTICIPANT_READ_RECEIPTS')) {
        data.PARTICIPANT_READ_RECEIPTS = [];
      }

      if (!data.hasOwnProperty('PARTICIPANT_UIDS')) {
        data.PARTICIPANT_UIDS = [...data.PARTICIPANTS];
      }

      // patch no REPORTED_BY
      if (!data.hasOwnProperty('REPORTED_BY')) {
        data.REPORTED_BY = [];
      }

      // patch no TOPICS
      if (!data.hasOwnProperty('TOPICS')) {
        data.TOPICS = [];
      }

      if (!data.hasOwnProperty('ARCHIVED')) {
        data.ARCHIVED = false;
      }

      // patch no CIRCLES
      if (!data.hasOwnProperty('CIRCLES')) {
        data.CIRCLES = [{ NAME: 'Members', UID: 'MEMBERS' }];
      }

      // patch no CIRCLE_UIDS
      if (!data.hasOwnProperty('CIRCLE_UIDS')) {
        data.CIRCLE_UIDS = ['MEMBERS'];
      }

      // patch no ORGANIZERS
      if (!data.hasOwnProperty('ORGANIZERS')) {
        data.ORGANIZERS = [];
      }

      return data;
    },
  };
}
