import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { AnalyticsService } from 'src/app/analytics.service';
import { Select, Store } from '@ngxs/store';
import { VillagerState } from 'src/app/state/villager.state';
import { VillageState } from 'src/app/state/village.state';
import {
  EVENT_KARMA,
  SHARE_KARMA,
  OFFERING_KARMA,
  REQUEST_KARMA,
  CONFLICT_KARMA,
  SUPPORT_KARMA,
} from 'src/app/app.config';
import { Observable } from 'rxjs';
import { Village } from 'src/app/models/village.model';
import { AppState } from 'src/app/state/app.state';
import { CreateBulletinService } from 'src/app/services/create-bulletin.service';
import { map, skipWhile } from 'rxjs/operators';

@Component({
  selector: 'app-create-bulletin',
  templateUrl: './create-bulletin.component.html',
  styleUrls: ['./create-bulletin.component.scss'],
})
export class CreateBulletinComponent implements OnInit {
  EVENT_KARMA = EVENT_KARMA;
  SHARE_KARMA = SHARE_KARMA;
  OFFERING_KARMA = OFFERING_KARMA;
  REQUEST_KARMA = REQUEST_KARMA;
  CONFLICT_KARMA = CONFLICT_KARMA;
  SUPPORT_KARMA = SUPPORT_KARMA;
  village: Village;
  isSteward$: Observable<boolean>;
  canAnnounce$: Observable<boolean>;
  @Select(VillagerState.currentVillage) currentVillage$: Observable<string>;
  @Select(VillageState.currentVillage) village$: Observable<Village>;
  @Select(VillageState.uid) villageId$: Observable<string>;
  @Select(VillagerState.uid) villagerUID$: Observable<string>;
  @Select(AppState.isMobile) isMobile$: Observable<boolean>;
  @Select(VillageState.showKarma) showKarma$: Observable<boolean>;
  @Select(VillageState.showPotluck) showPotluck$: Observable<boolean>;
  @Select(VillageState.showCoffee) showCoffee$: Observable<boolean>;
  @Select(VillageState.showParty) showParty$: Observable<boolean>;
  @Select(VillageState.showAdventure) showAdventure$: Observable<boolean>;
  @Select(VillageState.showDuo) showDuo$: Observable<boolean>;
  @Select(VillageState.showTrio) showTrio$: Observable<boolean>;
  @Select(VillageState.showConflictResolution)
  showConflict$: Observable<boolean>;
  @Select(VillageState.showEmotionalSupport)
  showEmotionalSupport$: Observable<boolean>;
  @Select(VillageState.showOffer) showOffer$: Observable<boolean>;
  @Select(VillageState.showRequest) showRequest$: Observable<boolean>;
  @Select(VillageState.showSharedList) showSharedList$: Observable<boolean>;

  @Input() description?: string;
  constructor(
    public modalCtrl: ModalController,
    private router: Router,
    private analytics: AnalyticsService,
    private store: Store,
    private bulletinSvc: CreateBulletinService
  ) {}

  ngOnInit() {
    this.village$.subscribe((x) => {
      if (!x) return;
      this.village = x;
    });

    this.villageId$
      .pipe(skipWhile((villageId) => villageId === undefined))
      .subscribe((villageId) => {
        this.isSteward$ = this.store
          .select(VillagerState.currentVillagerIsStewardInVillage)
          .pipe(map((filterFn) => filterFn(villageId)));
      });

    this.villagerUID$
      .pipe(skipWhile((villagerId) => villagerId === undefined))
      .subscribe((villagerId) => {
        this.canAnnounce$ = this.store
          .select(VillageState.villagerCanAnnounce)
          .pipe(map((filterFn) => filterFn(villagerId)));
      });
  }

  async openCreateAnnouncementModal() {
    this.bulletinSvc.openCreateAnnouncementModal(this.description);
  }

  async openCreateHangoutModal(type: string) {
    this.bulletinSvc.openCreateHangoutModal(type, this.description);
  }

  async openSupportRequestModal() {
    this.bulletinSvc.openSupportRequestModal(this.description);
  }

  async openCreateShareModal() {
    this.bulletinSvc.openCreateShareModal(this.description);
  }

  async openOfferingModal() {
    this.bulletinSvc.openOfferingModal(this.description);
  }

  async openRequestModal() {
    this.bulletinSvc.openRequestModal(this.description);
  }

  async openConflictModal() {
    this.bulletinSvc.openConflictModal(this.description);
  }

  async openSharedListModal() {
    this.bulletinSvc.openSharedListModal();
  }
}
