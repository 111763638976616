import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { ChatMessage } from 'src/app/models/chat-message.model';
import { DeleteDirectChatMessage, DeleteVillageMessage } from 'src/app/state/app.actions';
import { CourtyardActions } from 'src/app/state/courtyard.actions';
import { CreateBulletinModalPage } from '../create-bulletin-modal/create-bulletin-modal.page';
import { EmojiMartModalPage } from '../emoji-mart-modal/emoji-mart-modal.page';
import { Store } from '@ngxs/store';
import { AnalyticsService } from 'src/app/analytics.service';

@Component({
  selector: 'app-chat-message-options-modal',
  templateUrl: './chat-message-options-modal.page.html',
  styleUrls: ['./chat-message-options-modal.page.scss'],
})
export class ChatMessageOptionsModalPage implements OnInit, OnChanges {
  @Input() message: ChatMessage;
  @Input() type: 'post' | 'dm' | 'courtyard';
  constructor(private store: Store, private alertController: AlertController,
    private analytics: AnalyticsService, public modalCtrl: ModalController) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    console.log('Change Detected: ', changes.message);
    this.message = changes.message.currentValue;
  }

  async closeReact() {
    const existing = await this.modalCtrl.getTop();
    if (existing) this.modalCtrl.dismiss();
  }

  async openEmojiMart() {
    const existing = await this.modalCtrl.getTop();
    if (existing) this.modalCtrl.dismiss();

    const modal = await this.modalCtrl.create({
      component: EmojiMartModalPage,
      initialBreakpoint: 0.5,
      breakpoints: [0, 0.5, 1],
      componentProps: {
        message: this.message,
        type: this.type,
      },
    });
    return await modal.present();
  }

  async createBulletinFromMessage() {
    const existing = await this.modalCtrl.getTop();
    if (existing) this.modalCtrl.dismiss();

    const modal = await this.modalCtrl.create({
      component: CreateBulletinModalPage,
      componentProps: {
        description: this.message._BODY,
      },
    });
    return await modal.present();
  }

  async showDeleteConfirm() {
    const alert = await this.alertController.create({
      header: 'Careful!',
      message: `Are you sure you want to delete your post?`,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'dark',
          handler: (blah) => {
            console.log('Confirm Cancel');
          },
        },
        {
          text: 'Delete',
          role: 'delete',
          cssClass: 'danger',
          handler: async () => {
            this.deleteMessage();
            const existing = await this.modalCtrl.getTop();
            if (existing) this.modalCtrl.dismiss();
          },
        },
      ],
    });

    await alert.present();
  }

  async deleteMessage() {
    this.analytics.logEvent(`chat_message_delete_clicked`, {});
    if (this.type === 'dm') {
      this.store.dispatch(
        new DeleteDirectChatMessage({ messageId: this.message._UID })
      );
    } else if (this.type === 'post') {
      this.store.dispatch(
        new DeleteVillageMessage({ messageId: this.message._UID })
      );
    } else if (this.type === 'courtyard') {
      this.store.dispatch(
        new CourtyardActions.DeleteMessage({ messageId: this.message._UID })
      );
    } else {
      alert(
        'There was an issue deleting the message. Please try again. ' +
          this.type
      );
    }

    setTimeout(async () => {
      const existing = await this.modalCtrl.getTop();
      if (existing) this.modalCtrl.dismiss();
    }, 300);
  }
}
