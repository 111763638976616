import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { Observable, of } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AVATAR_BASE_PATH } from '../app.config';
import { ThemeService } from './theme.service';
import { Store } from '@ngxs/store';
import { VillageState } from '../state/village.state';

@Injectable({
  providedIn: 'root',
})
export class ImageService {
  // avatarBgHex = environment.avatarBackgroundHex || 'e2e2e2';
  COVER_URL = environment.coverUrl;

  constructor(
    private bucket: AngularFireStorage,
    private themeSvc: ThemeService,
    private store: Store
  ) {}

  getAvatar$(
    villagerId: string,
    firstName: string,
    lastName: string,
    size: '64' | '256' | '512' | '1080'
  ): Observable<string> {
    // console.log('[Image Service] looking up photo for villager: ', villagerId);
    try {
      let sizePath = '';
      switch (size) {
        case '64':
          sizePath = '64x64';
          break;
        case '256':
          sizePath = '256x256';
          break;
        case '512':
          sizePath = '512x512';
          break;
        case '1080':
          sizePath = '1080x1080';
          break;
      }

      const path = `${AVATAR_BASE_PATH}/thumbs/avatar_${villagerId}_${sizePath}`;
      const ref = this.bucket.ref(path);

      // ref.getMetadata().subscribe((meta) => {
      //   console.log('Meta data for photo: ', meta);
      // });

      const theme = this.store.selectSnapshot(VillageState.theme);
      const avatarBgHex = this.themeSvc.getPrimaryColor(theme).replace('#', '');

      return ref.getDownloadURL().pipe(
        take(1),
        catchError(() =>
          of(
            `https://ui-avatars.com/api/?name=${firstName.charAt(
              0
            )}+${lastName.charAt(
              0
            )}&size=${size}&background=${avatarBgHex}&color=fff`
          )
        )
      );
    } catch (e) {
      console.warn(e);
    }
  }

  getGroupChatAvatar$(title: string) {
    const theme = this.store.selectSnapshot(VillageState.theme);
    const avatarBgHex = this.themeSvc.getPrimaryColor(theme).replace('#', '');
    return of(
      `https://ui-avatars.com/api/?name=${title}&size=256&background=${avatarBgHex}&color=fff`
    );
  }

  getCirclePfp$(
    villageId: string,
    circleId: string,
    circleName: string,
    size: '64' | '256'
  ) {
    const path = `villages/${villageId}/circles/thumbs/pfp_${circleId}_${size}x${size}`;
    const ref = this.bucket.ref(path);
    const theme = this.store.selectSnapshot(VillageState.theme);
    const avatarBgHex = this.themeSvc.getPrimaryColor(theme).replace('#', '');
    console.log(`[Image service] color: ${avatarBgHex}`);
    return ref.getDownloadURL().pipe(
      take(1),
      catchError(() =>
        of(
          `https://ui-avatars.com/api/?name=${circleName.charAt(
            0
          )}&size=256&background=${avatarBgHex}&color=fff`
        )
      )
    );
  }

  getVillagePfp$(villageId: string, villageName: string, size: '64' | '256') {
    const path = `villages/${villageId}/theme/thumbs/pfp_${villageId}_${size}x${size}`;
    const ref = this.bucket.ref(path);
    const theme = this.store.selectSnapshot(VillageState.theme);
    const avatarBgHex = this.themeSvc.getPrimaryColor(theme).replace('#', '');
    console.log(`[Image service] color: ${avatarBgHex}`);
    return ref.getDownloadURL().pipe(
      take(1),
      catchError(() =>
        of(
          `https://ui-avatars.com/api/?name=${villageName.charAt(
            0
          )}&size=256&background=${avatarBgHex}&color=fff`
        )
      )
    );
  }

  getVillageCover$(villageId: string, size: '1080' | '2160') {
    const path = `villages/${villageId}/theme/thumbs/cover_${villageId}_${size}x${size}`;
    const ref = this.bucket.ref(path);

    return ref.getDownloadURL().pipe(
      take(1),
      catchError(() =>
        of(this.COVER_URL

        )
      )
    );
  }

  // post photos are stored with their path. i.e.:
  // villages/dev/pictures/beazy-d_If-VScyBY-unsplash
  // we need to append the size and the extension (always .jpeg)
  getPostPhoto(
    filePath: string,
    size: '64' | '256' | '512' | '1080'
  ): Observable<any> {
    let sizePath = '';
    switch (size) {
      case '64':
        sizePath = '64x64';
        break;
      case '256':
        sizePath = '256x256';
        break;
      case '512':
        sizePath = '512x512';
        break;
      case '1080':
        sizePath = '1080x1080';
        break;
    }
    const path = `${filePath}_${sizePath}.jpeg`;
    // console.log('[Image Service] Getting image: ', path);

    const ref = this.bucket.ref(path);
    // this is in place to help catch-up any images uploaded before cache headers were put in place.
    // ref.updateMetadata({ cacheControl: 'public, max-age=86400' });
    return ref.getDownloadURL().pipe(take(1));
  }
}
