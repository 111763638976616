import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { Announcement } from 'src/app/models/announcement.model';
import { GenericPost } from 'src/app/models/post-core.model';
import { EMOTIONS } from 'src/app/models/support-request.model';
import { SetSelectedPost } from 'src/app/state/app.actions';
import { FeedState } from 'src/app/state/feed.state';

@Component({
  selector: 'app-village-announcement-card-body',
  templateUrl: './village-announcement-card-body.component.html',
  styleUrls: ['./village-announcement-card-body.component.scss'],
})
export class VillageAnnouncementCardBodyComponent implements OnInit {
  announcement: Announcement;
  emotions = EMOTIONS;
  emoji: string = null;

  @Select(FeedState.allPosts) allPosts$: Observable<GenericPost[]>;
  @Input() showFullBody = true;
  @Input() post: GenericPost;

  constructor(
    private store: Store,
    private router: Router,
    private modalCtrl: ModalController
  ) {}

  ngOnInit() {
    this.announcement = this.post as Announcement;
    if (this.announcement.FEELING) this.getEmoji();
  }

  goToDetails() {
    this.store.dispatch(
      new SetSelectedPost({ post: this.announcement, location: 'DETAILS' })
    );
    this.router.navigateByUrl(`pages/post-details`);
  }

  getEmoji() {
    this.emoji = this.emotions.find(
      (x) => x.emotion === this.announcement.FEELING
    ).icon;
  }

  async clickBody(ev: Event) {
    const target = ev.target as HTMLElement;
    if (target?.className === 'mentionified') {
      const uid = target.getAttribute('data-mention-uid');
      if (uid) {
        const existing = await this.modalCtrl.getTop();
        if (existing) await this.modalCtrl.dismiss();
        this.router.navigateByUrl(`member/${uid}`);
      }
    }
  }
}
