import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ImageService } from 'src/app/services/images.service';
import { VillageState } from 'src/app/state/village.state';

@Component({
  selector: 'app-village-avatar',
  templateUrl: './village-avatar.component.html',
  styleUrls: ['./village-avatar.component.scss'],
})
export class VillageAvatarComponent implements OnInit {

  thumbnailUrl$: Observable<string>;
  @Input() villageId: string;
  @Input() villageName: string;
  @Input() slot = 'start';
  constructor(private imageSvc: ImageService) { }

  ngOnInit() {
    this.thumbnailUrl$ = this.imageSvc.getVillagePfp$(
      this.villageId,
      this.villageName,
      '256'
    );
  }

}
