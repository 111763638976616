
<ion-card *ngIf="signupStepTwo" class="main-card clear-card">
  <ion-card-header>
    <ion-button
      color="dark"
      style="margin-left: -20px; margin-bottom: 20px"
      fill="clear"
      (click)="backStepOne()"
    >
      <ion-icon name="chevron-back"></ion-icon>
      Back</ion-button
    >
    <ion-card-subtitle>Step Two</ion-card-subtitle>
    <ion-card-title>Enter Your Village</ion-card-title>
  </ion-card-header>
  <ion-card class="option-card" (click)="showVillageCodeForm()">
    <ion-card-content>
      <div>
        <div class="card-option-header">Join an Existing Village</div>
        <div>Use the village's private code to look them up</div>
        <p style="margin-top: 20px">
          👋 Coming from Kickstarter? Use the invite code emailed to you
          to join the Founding Members village
        </p>
      </div>
      <div>
        <ion-icon
          color="primary"
          name="chevron-forward-outline"
        ></ion-icon>
      </div>
    </ion-card-content>
    <div *ngIf="showInviteForm" style="margin: 0 15px 25px 15px">
      <form [formGroup]="inviteForm">
        <ion-item
          color="transparent"
          class="clear-input"
          [ngClass]="{
        invalid:
        inviteCode.invalid && (inviteCode.dirty || inviteCode.touched)
      }"
        >
          <ion-label position="floating">Village Invite Code</ion-label>
          <ion-input
            type="text"
            clearOnEdit="false"
            required
            autocapitalize="characters"
            formControlName="inviteCode"
          >
          </ion-input>
        </ion-item>
        <ion-item
          color="transparent"
          lines="none"
          class="error-message"
          *ngIf="
          inviteCode.invalid &&
          (inviteCode.dirty || inviteCode.touched)
        "
        >
          <ion-label class="ion-text-wrap"
            >Please enter a valid invite code</ion-label
          >
        </ion-item>
        <ion-button
          [disabled]="inviteCode.invalid"
          style="margin-top: 20px"
          expand="block"
          color="primary"
          (click)="joinOtherVillage()"
        >
          Join
          <ion-spinner
            style="margin-left: 10px"
            slot="end"
            name="lines-small"
            *ngIf="saving"
          ></ion-spinner>
        </ion-button>
      </form>
    </div>
  </ion-card>
  <ion-card class="option-card" (click)="proceedToVillageTypeOptions()">
    <ion-card-content>
      <div>
        <div class="card-option-header">Create a New Village</div>
        <div>Create your village and invite members to join</div>
      </div>
      <div>
        <ion-icon
          color="primary"
          name="chevron-forward-outline"
        ></ion-icon>
      </div>
    </ion-card-content>
  </ion-card>
  <!-- <ion-card class="option-card" (click)="joinGlobalVillage()">
    <ion-card-content>
      <div>
        <div class="subheader" style="margin-top: 0">
          Not sure where to start?
        </div>
        <div class="card-option-header">Join the Global Village</div>
        <div>
          The Global Village is a great place to get familiar with
          reVillager and network with others before starting your own
          village.
        </div>
      </div>
      <div>
        <ion-icon
          color="primary"
          name="chevron-forward-outline"
        ></ion-icon>
      </div>
    </ion-card-content>
  </ion-card> -->
</ion-card>

<ion-card *ngIf="showVillageTypeOptions" class="main-card clear-card">
  <ion-card-header>
    <ion-button
      color="dark"
      style="margin-left: -20px; margin-bottom: 20px"
      fill="clear"
      (click)="backStepTwo()"
    >
      <ion-icon name="chevron-back"></ion-icon>
      Back</ion-button
    >
    <ion-card-title>Select a Village Template</ion-card-title>
  </ion-card-header>

  <ion-card
    class="option-card"
    *ngFor="let type of villageTypes"
    (click)="setVillageType(type)"
  >
    <ion-card-content>
      <div>
        <div class="card-option-header">{{type.name}}</div>
        <div class="village-description">{{type.description}}</div>
      </div>

      <div class="village-clear">
        <ion-icon
          color="primary"
          name="chevron-forward-outline"
        ></ion-icon>
      </div>
    </ion-card-content>
  </ion-card>
</ion-card>

<ion-card *ngIf="showVillageForm" class="main-card">
  <ion-card-header>
    <ion-button
      color="dark"
      style="margin-left: -20px; margin-bottom: 20px"
      fill="clear"
      (click)="exitVillageForm()"
    >
      <ion-icon name="chevron-back"></ion-icon>
      Back</ion-button
    >
    <ion-card-subtitle></ion-card-subtitle>
    <ion-card-title
      >Create your {{villageType.name}}
      <span
        *ngIf="villageType.value !== 'ECO_VILLAGE' && villageType.value !== 'INTENTIONAL_COMMUNITY'"
        >Village</span
      ></ion-card-title
    >
  </ion-card-header>
  <ion-card-content>
    <form [formGroup]="villageCreateForm">
      <ion-item color="transparent" class="clear-input full-width-item">
        <ion-label position="stacked">Village Name</ion-label>
        <ion-input
          clearOnEdit="false"
          required
          placeholder="Enter a Name"
          autocapitalize="words"
          formControlName="name"
          [ngClass]="{
                        invalid:
                        name.invalid && (name.dirty || name.touched)
                      }"
        >
        </ion-input>
      </ion-item>

      <div
        class="subheader"
        style="margin-top: 20px; margin-bottom: 5px"
      >
        Vision
      </div>
      <ion-item lines="none" class="full-width-item">
        <ion-textarea
          rows="8"
          autocapitalize="on"
          autocorrect="on"
          formControlName="vision"
          placeholder="This village envisions... (you can edit this later)"
          [ngClass]="{
            invalid: vision.invalid && vision.touched
          }"
        ></ion-textarea>
      </ion-item>
      <div
        class="subheader"
        style="margin-top: 10px; margin-bottom: 5px"
      >
        Values
      </div>
      <ion-item lines="none" class="full-width-item">
        <ion-textarea
          rows="8"
          autocapitalize="on"
          autocorrect="on"
          formControlName="values"
          placeholder="This village values... (you can edit this later)"
          [ngClass]="{
            invalid: values.invalid && values.touched
          }"
        ></ion-textarea>
      </ion-item>
    </form>
    <ion-button
      [disabled]="villageCreateForm.invalid"
      expand="block"
      (click)="createNewVillage()"
    >
      <ion-label>Create</ion-label>
      <ion-spinner
        style="margin-left: 10px"
        slot="end"
        name="lines-small"
        *ngIf="saving"
      ></ion-spinner>
    </ion-button>
  </ion-card-content>
</ion-card>
