import { Injectable } from '@angular/core';
import {
  Action,
  NgxsAfterBootstrap,
  Selector,
  State,
  StateContext,
} from '@ngxs/store';

export namespace Tutorial {
  export class WelcomeSeen {
    static readonly type = '[Tutorial] Toggle Create Village';
    constructor(public payload: { seen: boolean }) {}
  }

  export class SlidesSeen {
    static readonly type = '[Tutorial] Slides Seen';
    constructor(public payload: { seen: boolean }) {}
  }

  export class ResetTutorial {
    static readonly type = '[Tutorial] Reset Tutorial';
  }

  export class VideoOverviewSeen {
    static readonly type = '[Tutorial] Video Overview Seen';
    constructor(public payload: { seen: boolean }) {}
  }
}

export interface TutorialStateModel {
  welcomeSeen: boolean;
  tutorialSlidesSeen: boolean;
  videoOverviewSeen: boolean;
}

@State<TutorialStateModel>({
  name: 'tutorial',
  defaults: {
    welcomeSeen: false,
    tutorialSlidesSeen: false,
    videoOverviewSeen: false,
  },
})
@Injectable()
export class TutorialState implements NgxsAfterBootstrap {
  ngxsAfterBootstrap(ctx: StateContext<TutorialStateModel>) {
    // alert('The application has been fully rendered');
    // const {tutorialSlidesSeen, welcomeSeen} = ctx.getState();
    // alert('Inited state values: ' + tutorialSlidesSeen + ' ' + welcomeSeen)
  }

  @Selector()
  static welcomeSeen(state: TutorialStateModel): boolean {
    return state.welcomeSeen;
  }

  @Selector()
  static tutorialSlidesSeen(state: TutorialStateModel): boolean {
    return state.tutorialSlidesSeen;
  }

  @Selector()
  static videoOverviewSeen(state: TutorialStateModel): boolean {
    console.log('is video seen? ', state.videoOverviewSeen);
    return state.videoOverviewSeen;
  }

  @Action(Tutorial.WelcomeSeen)
  welcomeSeen(
    ctx: StateContext<TutorialStateModel>,
    action: Tutorial.WelcomeSeen
  ) {
    ctx.patchState({
      welcomeSeen: action.payload.seen,
    });
  }

  @Action(Tutorial.SlidesSeen)
  slidesSeen(
    ctx: StateContext<TutorialStateModel>,
    action: Tutorial.SlidesSeen
  ) {
    ctx.patchState({
      tutorialSlidesSeen: action.payload.seen,
    });
  }

  @Action(Tutorial.VideoOverviewSeen)
  videoOverviewSeen(
    ctx: StateContext<TutorialStateModel>,
    action: Tutorial.VideoOverviewSeen
  ) {
    ctx.patchState({
      videoOverviewSeen: action.payload.seen,
    });
  }

  @Action(Tutorial.ResetTutorial)
  resetTutorial(ctx: StateContext<TutorialStateModel>) {
    ctx.setState({
      welcomeSeen: false,
      tutorialSlidesSeen: false,
      videoOverviewSeen: false,
    });
  }
}
